import { Component } from '@angular/core';
import { GlobeComponent } from '../globe/globe.component';
import { assetUrl } from 'src/single-spa/asset-url';

@Component({
  selector: 'app-utm-map-setting',
  templateUrl: './map-setting.component.html',
  styleUrls: ['./map-setting.component.scss'],
})
export class MapSettingComponent {
  on_nav_tap: any = '';
  on_mapstyle_tap = 1;

  // sites page asset filter select value
  industry: any;
  asset_health: any;
  project: any;
  userUtm: boolean = false;

  //style url
  url: string = 'mapbox://styles/intuitivebadger/clnbqvtnk03la01pjfg65g3r3';
  constructor(public globe: GlobeComponent) {}

  ngOnInit(): void {
    const userUtm = localStorage.getItem('user-utm');
    if (userUtm) {
      this.userUtm = true;
    }

    window.addEventListener('dronos:utm:sidebar-click', (event: any) => {
      const iconClicked = event.detail.icon;
      this.sideBarClicked(iconClicked);
    });
  }

  OnClickStyle(name: string) {
    const lightMode = 'mapbox://styles/intuitivebadger/clfhqv8dq000g01p9mupoco1v';
    const darkMode = 'mapbox://styles/intuitivebadger/clf659i6e003m01o9seb9u6l0';
    const satelliteMode = 'mapbox://styles/intuitivebadger/clnbqvtnk03la01pjfg65g3r3';

    let newUrl = '';

    if (name === 'lightMode') newUrl = lightMode;
    else if (name === 'darkMode') newUrl = darkMode;
    else if (name === 'satelliteMode') newUrl = satelliteMode;

    if (this.url != newUrl) {
      this.globe.setStyle(newUrl);
      this.url = newUrl;
    }
  }

  OnClickTerrain() {
    this.globe.toggleTerrain();
  }

  // change eye icon
  onChangeEyeIcon(event: { target: any; currentTarget: any }) {
    const element = event.target || event.currentTarget;
    const elementId = element.id;

    if (element.src == assetUrl('/sidebar/eye-slash.png')) {
      element.src = assetUrl('/sidebar/eye.png');
      this.globe.loadDataKmz(elementId);
      this.globe.layerStyle.push(elementId);
    } else {
      element.src = assetUrl('/sidebar/eye-slash.png');
      this.globe.loadDataKmz(elementId);
      var elementIdToRemove = 'elementId'; // Replace with the actual value you want to remove
      var index = this.globe.layerStyle.indexOf(elementIdToRemove);
      if (index !== -1) {
        this.globe.layerStyle.splice(index, 1);
      }
    }
  }

  // clear selected data on sites page asset filter popup box
  onClearAll() {
    this.industry = null;
    this.project = null;
    this.asset_health = null;
  }

  // common for all dropdown
  dropdown(event: any, targetElement: string = 'div.dropdownBox'): void {
    event.target.parentElement.closest(targetElement).classList.toggle('active');
  }

  sideBarClicked(icon: any) {
    this.on_nav_tap = icon;
  }
}
