import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { state } from '@app/utility';
import { Observable } from 'rxjs';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl() + '/data-resource/data-resource/';

const workspaceId = state.getUserInfo().member[0].workspace._id.toString();
const headers =  new HttpHeaders({
  Authorization: 'Bearer ' + environmentService.getToken(),
  'Content-Type': 'application/json',
  workspaceId: workspaceId,
  });
@Injectable({
  providedIn: 'root'
})
export class TelemetryService {

 
  constructor(private http: HttpClient) {}

  

  getData(id:any): Observable<any> {
    return this.http.get(apiUrl+id+'?pre_signed_url=true', { headers : headers });
  }
  passUrl(){
    // console.log("pass the url")
   
  }
}
