<div class="header">
    <p>PAYLOAD DETAILS</p>
    <!-- <img (click)="closeModal()" [src]="'/icon/icon_x.png' | assetUrl" alt="warning" class="icon-close"/> -->
</div>
<div class="body">
    <div class="form-row">
        <div class="form-group col-md-12">
            <label for="inputEmail4">Serial Number
            </label>
            <input type="text" class="form-control" placeholder="{{ payloadData.serialNumber }}" disabled>
        </div>
     
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputEmail4">Brand 
            </label>
            <input type="text" class="form-control" placeholder="{{ payloadData.catalogId.maker }}" disabled>
        </div>
        <div class="form-group col-md-6">
            <label for="inputEmail4">Model
            </label>
            <input type="text" class="form-control" placeholder="{{ payloadData.catalogId.model }}" disabled>

        </div>
     
    </div>

    <div class="form-row">
        <div class="form-group col-md-12">
            <label for="inputEmail4">Category
            </label>
            <input type="text" class="form-control" placeholder="" disabled>
        </div>
     
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputEmail4">Main Gimbal
            </label>
            <input type="text" class="form-control" placeholder="{{ payloadData.catalogId.specs.mainGimbal }}"  disabled>
        </div>
        <div class="form-group col-md-6">
            <label for="inputEmail4">Sensor
            </label>
            <input type="text" class="form-control" placeholder="{{ payloadData.catalogId.specs.sensor }}"  disabled>

        </div>
     
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputEmail4">Weight
            </label>
            <input type="text" class="form-control" placeholder="{{ payloadData.catalogId.specs.weight }}"  disabled>

        </div>
        <div class="form-group col-md-6">
            <label for="inputEmail4">Resolution
            </label>
            <input type="text" class="form-control" placeholder="{{ payloadData.catalogId.specs.resolution }}"  disabled>

        </div>
     
    </div>
    <div class="form-row">
        <div class="form-group col-md-12">
            <label for="keyFeaturesInput">Key Features</label>
            <input type="text" class="form-control" id="keyFeaturesInput" placeholder=""
              [disabled]="true"
              [value]="badgeList.join(', ')"> 
          </div>
          
       
     
    </div>
    
    <p (click)="deletePayload()" class="txt-delete">Delete Payload</p>
</div>
<div class="footer">
    <button (click)="closeModal()" class=" btn-cancel">Cancel</button>
    <button type="submit" class=" btn-save">Update</button>
</div>