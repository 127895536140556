<div *ngIf="globe.missionPanel">
    <div class="map_setting_container">
        <div class="setting">
            <div class="setting_head">
                <p class="mb-0">Mission</p>
                <img [src]="'/icon/close.svg' | assetUrl" alt="arrow-left" (click)="tabClosed()" />
            </div>
            <div class="sites_search_box"></div>
            <div class="map_layer_search" *ngIf="isSearch">
                <input class="input_tag" type="text" placeholder="Search mission name or id" [(ngModel)]="search"
                    (input)="onInputChange()" />
                <img [src]="'/icon/search-mission.svg' | assetUrl" alt="search-icon" />
                <img [src]="'/icon/close.svg' | assetUrl" alt="close-icon" (click)="isSearch = false" />
            </div>
            <div class="top-menu" *ngIf="!isSearch">
                <div class="btns">
                    <div class="new-mission-btn" (click)="isNewMission = !isNewMission">
                        <img [src]="'/context-button/plus.svg' | assetUrl" alt="search-icon" />
                        <span>New Mission</span>
                    </div>
                    <div class="new-mission-menu" *ngIf="isNewMission">
                        <span class="title" (click)="createNewMission('pin')">Drop pin</span>
                        <span class="title" (click)="createNewMission('polygon')">Draw polygon</span>
                        <span class="title" (click)="createNewMission('upload')">Upload geojson</span>
                        <input class="hidden" type="file" id="upload-file" name="upload-file" accept=".kml, .kmz"
                            ngf-max-size="2MB" (change)="handleFileInput($event)" (mouseover)="enableTooltip($event)" />
                    </div>
                    <div class="upload-dataset-btn" (click)="missionDetails()">
                        <img [src]="'/icon/upload-dataset.svg' | assetUrl" class="width-15 height-15" />
                    </div>
                </div>
                <div class="btns justify-content-end">
                    <img [src]="'/icon/search-mission.svg' | assetUrl" alt="search-icon" class="cursor-pointer width-20"
                        (click)="isSearch = true" />
                    <img [src]="'/sidebar/filter.svg' | assetUrl" alt="filter-icon" class="cursor-pointer width-17"
                        (click)="isFilter = !isFilter" />
                    <img [src]="'/icon/sort_24.svg' | assetUrl" alt="sort-icon" class="cursor-pointer width-26"
                        (click)="isSort = !isSort;" />
                </div>

            </div>
            <hr class="setting_bottom_border" />

            <div class="map_tap_container">
                <div class="map_tap_content">
                    <div class="mission-list" (scroll)="onScroll($event)" id="scroll-cont">
                        <div class="map_layer_content">
                            <div *ngFor="let missionAll of missionList; let idx = index"
                                [ngClass]="missionAll._id == selectedMissionId || currentIdx == idx ? 'selected-mission' : ''"
                                (click)="onMissionClick(missionAll, $event)" class="position-relative missions"
                                (mouseover)="currentIdx = idx" (mouseleave)="currentIdx = null"
                                (mouseenter)="onMouseEnter()" [id]="'card-' + missionAll._id">
                                <div class="mission-content">
                                    <div class="mission-box">
                                        <div class="action-section">
                                            <div class="status-badge"
                                                [style]="missionStatusColors[missionAll?.status?.toUpperCase()] || bgTransparent">
                                                {{ missionAll.status }}
                                            </div>
                                            <div class="d-flex gap-10" *ngIf="currentIdx == idx">
                                                <img [src]="'/icon/view-mission-details.svg' | assetUrl" alt="Details"
                                                    class="cursor-pointer"
                                                    (click)="currentIdx == idx ? viewMissionDetails($event, missionAll._id) : null" />
                                                <img [src]="'/icon/mission-menu.svg' | assetUrl" alt="Options"
                                                    class="cursor-pointer"
                                                    (click)="currentIdx == idx ? showMoreSection(missionAll._id, $event) : null" />
                                                <div class="mission-menu" *ngIf="showMore">
                                                    <span
                                                        (click)="onOptionClick('/2d-annotation/' + missionAll._id + '/visual');callOrtho()"
                                                        class="mission-title">Canvas</span>
                                                    <span
                                                        (click)="onOptionClick('/dashboard/mission-report/' + missionAll._id)"
                                                        class="mission-title">Generate Report</span>
                                                    <span class="mission-title"
                                                        (click)="redirectToUploadDataset(missionAll)">Upload Data</span>
                                                    <span class="mission-title"
                                                        (click)="initGenerateStreamUrl(missionAll._id)">Generate Stream
                                                        URL</span>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="mission-name"> {{ missionAll.name || '-' }} </p>
                                        <div class="mission-detail">
                                            <p class="mission-objective"> {{ missionAll.objective.name }} </p>
                                            <div class="mission-status-detail">
                                                <div class="mission-date">
                                                    {{ formatDate(missionAll.createdAt) }}
                                                </div>
                                                <div class="dot-divider"> </div>
                                                <div class="mission-time">
                                                    {{ formatTime(missionAll.createdAt) }}
                                                </div>
                                                <div [ngSwitch]="missionAll?.videoStreamStatus">
                                                    <button *ngSwitchCase="'Started'" type="button"
                                                        (click)="initLiveStream(missionAll._id,missionAll.trackingId)"
                                                        class="clear-video-btn video-btn">
                                                        <img [src]="'/images/video.svg' | assetUrl"
                                                            alt="Video Streaming">
                                                    </button>
                                                    <button *ngSwitchCase="'Active'" type="button"
                                                        (click)="initLiveStream(missionAll._id,missionAll.trackingId)"
                                                        class="clear-video-btn video-btn">
                                                        <img [src]="'/images/live-video.svg' | assetUrl"
                                                            alt="Video Streaming">
                                                    </button>
                                                    <button *ngSwitchDefault type="button"
                                                        class="clear-video-btn disabled-video-btn">
                                                        <img [src]="'/images/video-slash.svg' | assetUrl"
                                                            alt="Video Streaming">
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="filter-container" *ngIf="isFilter">
        <div class="title">
            <span class="filter">filter</span>
            <img [src]="'/icon/close.svg' | assetUrl" alt="close-filter" class="cursor-pointer"
                (click)="isFilter = false" />
        </div>
        <hr class="setting_bottom_border w-100 m-0" />
        <div class="w-100">
            <span class="mission-status-txt">Status</span>
            <app-utm-multi-select-dropdown 
                [list]="statusList" 
                [placeholder]="'Select mission status'" 
                (shareCheckedList)="onFilterStatusChanged($event)">
            </app-utm-multi-select-dropdown>
        </div>
        <div class="w-100">
            <span class="mission-objective-title">Mission Objective</span>
            <app-utm-multi-select-dropdown 
                [list]="objectiveList" 
                [placeholder]="'Mission Objective'" 
                (shareCheckedList)="onFilterObjectiveChanged($event)">
            </app-utm-multi-select-dropdown>
        </div>
        <hr class="setting_bottom_border w-100 m-0" />
        <div class="btn-bottom">
            <div class="cancel">CLEAR</div>
            <div class="ok">APPLY</div>
        </div>
    </div>

    <div class="filter-container" *ngIf="isSort">
        <div class="title">
            <span class="filter">sort</span>
            <img [src]="'/icon/close.svg' | assetUrl" alt="close-filter cursor-pointer" (click)="isSort = false" />
        </div>
        <hr class="setting_bottom_border w-100 m-0" />
        <div class="item" *ngFor="let item of sortingList; let idx = index">
            <label class="container-radio">{{ item.name }}
                <input type="radio" [checked]="item.checked" [(ngModel)]="selectedSortOption" [value]="item.value"
                    name="radio" (change)="sortChange()" />
                <span class="checkmark"></span>
            </label>
        </div>
        <hr class="setting_bottom_borde w-100 m-0" />
        <div class="d-flex align-items-center w-100">
            <label class="switch">
                <input type="checkbox" [(ngModel)]="isAscending" (change)="onSwitchChange()" />
                <span class="slider"></span>
            </label>
            <span class="asc-text">Ascending</span>
        </div>
        <hr class="setting_bottom_border w-100 m-0" />
        <div class="btn-bottom" (click)="clearSort()">
            <div class="cancel">CLEAR</div>
        </div>
    </div>
</div>
<ng-template [ngIf]="isShowToaster">
    <app-utm-toaster-custom [toasterClass]="toasterClass" [toasterMsg]="toasterMsg"></app-utm-toaster-custom>
</ng-template>

<app-generate-url [selectedGenerateUrlStreamId]="selectedGenerateUrlStreamId"
    (responseDetail)="manageGenerateUrlResponse($event)"></app-generate-url>