<!DOCTYPE html>
<html>

<head>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@100;400&display=swap" rel="stylesheet">
  <script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script>
</head>

<body>
  <div class="grid-container">
    <app-navbar></app-navbar>
    <div class="content">
      <h1>Drones</h1>

      <table class="table">
        <tr>
          <th>No</th>
          <th>Serial Number</th>
          <th>Brand</th>
          <th>Model</th>
          <th>Registered date</th>
          <th>Action</th>
        </tr>
        <tr *ngFor="let drone of drones; let i = index">
          <td>{{ i+1 }}.</td>
          <td>{{ drone.serial_number }}</td>
          <!-- backend need to provide relationship for model and brand -->
          <td>{{ drone.brand_id }}</td>
          <td>{{ drone.model_id }}</td>
          <td>{{ drone.created_at | date: 'medium' }}</td>
          <td>
            <a href="/drone/{{i +1}}" class="link">
              <span>View</span>
            </a>
            <a href="/drone/{{i +1}}/edit" class="link">
              <span>Edit</span>
            </a>
            <a href="/drone/{{i +1}}/edit" class="link">
              <span>Delete</span>
            </a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</body>

</html>
