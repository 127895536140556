<div class="main-payment-container">
  <div class="container-fluid mt-10 custom-aligment">
    <div class="card th-card">
      <div class="card-body txt-12 color-txt-gray font-weight-normal card-body-th letter-spacing-4 d-flex align-items-center">
        <div class="flex-container">
          <div class="flex-item" [style.flex]="item == 'Applicant' ? '120px' : '1'" *ngFor="let item of allColumns">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="row txt-12" *ngIf="paginatedPermits.length == 0">
      <div class="no-permit">No permit available</div>
    </div>
    <div
      class="row txt-12 cursor-style"
      *ngFor="let permit of paginatedPermits; let idx = index"
      (click)="permit.applicationStatus == 'DRAFT' && allRoles.Applicant ? redirectToAppSummary(permit.missions): tracker(permit._id);">
      <!-- Header titles -->
      <div class="col-md-12">
        <div id="tooltip" style="display: none">Click to view permit details</div>
        <div class="card th-card">
          <div
            id="permit-card"
            class="card-body txt-12 color-txt-gray font-weight-normal letter-spacing-4 d-flex align-items-center card-body-th-2"
            (mouseover)="onHover(idx); onHoverCard($event)"
            (mouseleave)="onLeave()">
            <div class="flex-container">
              <img
                [src]="'/icon/close-expand.svg' | assetUrl"
                style="margin-right: 5px"
                *ngIf="allClickedRows.includes(idx)"
                (click)="openMission(idx); $event.stopPropagation()" />
              <img
                [src]="'/icon/open-expand.svg' | assetUrl"
                style="margin-right: 5px"
                *ngIf="currentIdx == idx && !allClickedRows.includes(idx)"
                (click)="openMission(idx); $event.stopPropagation()" />
              <div class="flex-item">
                <p class="mb-0">{{ permit.trackingId }}</p>
                <img [src]="'/icon/list-missions.png' | assetUrl" />
                <span style="margin-left: 5px">{{ permit.missions.length }} Missions</span>
              </div>
              <div class="flex-item" *ngIf="allRoles.Applicant">
                <div [ngClass]="returnStatusColor(permit.applicationStatus)">
                  {{ permit.applicationStatus == 'PENDING' ? 'IN REVIEW' : permit.applicationStatus }}
                </div>
              </div>
              <div class="flex-item">ATF</div>
              <div style="flex: 120px">
                <div style="display: flex; align-items: center">
                  <div style="margin-left: 10px">
                    <p class="mb-0">{{ permit.fullname }}</p>
                    <p class="color-txt-mission mb-0">{{ permit.email }}</p>
                  </div>
                </div>
              </div>
              <div class="flex-item">
                <p class="mb-0">{{ formatDate(permit.createdAt) }}</p>
                <span>{{ formatTime(permit.createdAt) }}</span>
              </div>
              <div class="flex-item" *ngIf="!allRoles.Applicant">
                <div [ngClass]="getPaymentStatus(permit.paymentStatus)" class="d-flex flex-row align-items-center">
                  <i class="fa-solid fa-check" *ngIf="permit.paymentStatus == 'PAID'"></i>
                  <i class="fa-solid fa-xmark" *ngIf="permit.paymentStatus == 'UNPAID'"></i>
                  <span style="padding-left: 5px">{{ permit.paymentStatus }}</span>
                </div>
              </div>
              <div class="flex-item" *ngIf="!allRoles.Authority">
                <span>{{ !allRoles.HumanValidator ? '(est.)' : '' }} RM{{ permit.totalFees }}</span>
              </div>
              <div class="flex-item" style="width: 10%" *ngIf="allRoles.HumanValidator">
                <div [ngClass]="getPaymentClass(permit)" (click)="onPaymentClick($event, permit)">
                  {{ convertPaymentStatus(permit.paymentStatus, permit) }}
                </div>
              </div>
              <div class="flex-item d-flex align-items-center" *ngIf="allRoles.Authority">
                <div style="width: 70%; margin-right: 10px">
                  <div
                    class="progress"
                    role="progressbar"
                    aria-label="Example 5px high"
                    style="height: 5px"
                    [style.--bs-progress-bg]="permit.progress >= 50 ? 'rgba(102, 214, 166, 0.1)' : 'rgba(255, 70, 70, 0.2)'"
                    [style.--bs-progress-bar-bg]="permit.progress >= 50 ? 'rgba(102, 214, 166, 1)' : 'rgba(255, 70, 70, 1)'">
                    <div class="progress-bar" [style.width]="permit.progress + '%'" [attr.aria-valuenow]="permit.progress"></div>
                  </div>
                </div>
                <span> {{ checkTotalApproved(permit) }} </span>
              </div>
              <div class="flex-item color-alert-low" style="width: 10%" *ngIf="!allRoles.Authority">
                {{ permit.permit_status }}
              </div>
            </div>

            <div class="row txt-12" *ngIf="paginatedPermits.length == 0">
              <div class="no-permit">No permit available</div>
            </div>
          </div>

          <div *ngIf="allClickedRows.includes(idx)">
            <div
              class="mission-card"
              *ngFor="let mission of permit.missions"
              (click)="permit.applicationStatus != 'DRAFT' ? redirectToSummary(mission, permit._id, permit.trackingId) : null;  $event.stopPropagation();">
              <div class="content-mission-layout">
                <span class="header-mission" *ngFor="let missionHeader of missionHeader" [style.flex]="missionHeader == 'Mission Area' ? '70px' : '1'">
                  {{ missionHeader }}</span
                >
              </div>
              <!-- <div class="content-mission-layout"> -->
              <div class="content-mission-layout">
                <span class="content-mission flex-1">{{ mission.missionId.trackingId }}</span>
                <span class="content-mission flex-1">{{ mission.missionId.name == '' ? 'No mission name' : mission.missionId.name }}</span>
                <span class="content-mission flex-1">{{ mission.missionId.objective.name }}</span>
                <div class="d-flex flex-column flex-1">
                  <span class="content-mission">{{ formatDate(mission.missionId.createdAt) }}</span>
                  <span class="content-mission-text">{{ formatTime(mission.missionId.createdAt) }}</span>
                </div>
                <div class="d-flex flex-column" style="flex: 70px">
                  <span class="content-mission"> {{ mission.location_area }} </span>
                </div>
                <div [ngClass]="returnStatusColor(mission.permitStatus)" class="flex-1">{{ mission.permitStatus }}</div>
              </div>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center mt-3 d-flex-container">
      <p class="txt-12 color-txt-mission">Showing {{ paginationRange }}</p>
      <nav aria-label="Page navigation">
        <ul class="pagination">
          <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
            <a class="page-link" aria-label="Previous" (click)="goToPreviousPage()">
              <img [src]="'/images/chevron_left_24px.png' | assetUrl" style="max-width: 100px" />
            </a>
          </li>
          <li class="page-item" *ngFor="let page of pages" [ngClass]="{ active: currentPage === page }" (click)="changePage(page)">
            <a class="page-link"
              ><span>{{ page }}</span></a
            >
          </li>
          <li class="page-item" [ngClass]="{ disabled: currentPage === pages.length }">
            <a class="page-link" aria-label="Next" (click)="goToNextPage()">
              <img [src]="'/images/chevron_right_24px.png' | assetUrl" style="max-width: 100px" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <!-- modal confirm approve or reject receipt -->
    <div class="confirm-modal" *ngIf="confirmApproveModal">
      <div class="confirm-modal-content text-center">
        <img class="" [src]="'/icon/Warning.svg' | assetUrl" />
        <p class="confirm-title">Approve Receipt</p>
        <p class="confirm-desc">Are you sure you want to approve this receipt? This action will indicate <br />that the receipt has been received.</p>
        <div class="receipt-modal-buttons">
          <a class="receipt-modal-button cancel" (click)="cancelConfirm()">Cancel</a>
          <a class="receipt-modal-button approve" (click)="approveRejectService('confirm')">Confirm</a>
        </div>
      </div>
    </div>
    <!-- modal reject receipt -->
    <div class="reject-modal" *ngIf="rejectModal">
      <div class="reject-modal-content text-center">
        <img class="" [src]="'/icon/Warning.png' | assetUrl" />
        <p class="confirm-title">Reject Receipt</p>
        <p class="confirm-desc">Are you sure you want to reject this receipt? <br />You won’t be able to revert this action.</p>
        <textarea class="remarks" [(ngModel)]="rejectRemark"></textarea>
        <div class="receipt-modal-buttons">
          <a class="receipt-modal-button cancel" (click)="cancelConfirm()">Cancel</a>
          <a class="receipt-modal-button reject" (click)="approveRejectService('reject')">Reject</a>
        </div>
      </div>
    </div>
  </div>
</div>
