import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
@Pipe({
  name: 'matchingOperatorCount',
})
export class MatchingOperatorCountPipe implements PipeTransform {
  checkSchedule = true;
  transform(operatorListMatch: any, scheduleDate: string, type: string, allMissionList?: any) {
    if (type == 'count') {
      // if (operatorListMatch) {
      //   const matchingOperators = operatorListMatch.filter(
      //     // (data: any) => data.bookedDate == 'None' || moment(data.bookedDate !== 'None' ? data.bookedDate : null)?.format('DD MMM YYYY') === scheduleDate,
      //     (data: any) => data.user.username || moment(data.bookedDate !== 'None' ? data.bookedDate : null)?.format('DD MMM YYYY') === scheduleDate,
      //   );
      //   return matchingOperators;
      // }
      if (operatorListMatch && allMissionList) {
        let matchingOperators: any = [];
        allMissionList.map(({ scheduleIds }: any) => {
          scheduleIds.map(({ startDate, operatorList }: any) => {
            if (moment(startDate).format('DD-MM-YYYY') == moment(scheduleDate, 'DD MMM YYYY').format('DD-MM-YYYY')) {
              this.checkSchedule = false;
              operatorListMatch.forEach(({ user: { _id } }: any) => {
                if (!operatorList.includes(_id)) {
                  if (matchingOperators.includes(_id)) {
                    matchingOperators.splice(matchingOperators.indexOf(_id), 1);
                  } else {
                    matchingOperators.push(_id);
                  }
                }
              });
            }
          });
        });

        return this.checkSchedule && matchingOperators.length == 0 ? operatorListMatch : matchingOperators;
      }
    }

    if (type == 'operatorMatch') {
      const matchingOperatorBooked = operatorListMatch.find((data: any) => moment(data.bookedDate)?.format('DD MMM YYYY') === scheduleDate);
      // const matchingOperatorNone = operatorListMatch.find((data: any) => data.bookedDate == 'None');
      const matchingOperatorNone = operatorListMatch.find((data: any) => data.user.username);
      return matchingOperatorBooked ? matchingOperatorBooked : matchingOperatorNone ? matchingOperatorNone : true;
    }
  }
}
