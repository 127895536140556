import { Directive, HostBinding, HostListener, Output, EventEmitter } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

export interface FileHandle {
  file: File;
  url: SafeUrl;
}

@Directive({
  selector: '[dragFile]',
})
export class DragFileUploadDirective {
  @Output() files: EventEmitter<FileHandle[]> = new EventEmitter();

  @HostBinding('style.background') private background = 'rgba(255, 255, 255, 0.1)';
  @HostBinding('style.border') private border = '1px dashed rgba(135, 154, 166,0.4)';

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'rgb(69 69 69 / 78%)';
    this.border = '1px dashed rgba(0, 240, 255, 1)';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'rgba(255, 255, 255, 0.1)';
    this.border = '1px dashed rgba(200, 200, 200, 1)';
  }

  @HostListener('drop', ['$event']) public onDrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.border = '1px solid rgba(200, 200, 200, 1)';
    const formData = new FormData();
    let files: FileHandle[] = [];
    for (let i = 0; i < evt.dataTransfer.files.length; i++) {
      const file = evt.dataTransfer.files[i];
      files.push(file);
      formData.append('file', file);
    }
    if (files.length > 0) {
      this.files.emit(files);
    }
  }
}
