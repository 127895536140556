import { Component, OnInit } from '@angular/core';
import { Red5proService } from 'src/app/red5pro.service';

@Component({
  selector: 'app-utm-modal-live',
  templateUrl: './modal-live.component.html',
  styleUrls: ['./modal-live.component.scss']
})
export class ModalLiveComponent implements OnInit {

  constructor( private streams:Red5proService){}

  ngOnInit(){

   

  }

  runStreams(key:any){
    this.streams.red5proInit(key);
  }

}
