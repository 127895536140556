<div [ngClass]="{'d-none': isHideMissionSection,'upload-dataset-wrapper': !isHideMissionSection}">
  <h3 class="title">Uploading new dataset</h3>
  <div class="progress-holder">
    <ul class="progress-list">
      <li class="complete"><span class="bullet-step">Mission Detail</span></li>
      <li class="active"><span class="bullet-step">Upload Dataset</span></li>
    </ul>
  </div>
  <div class="form-holder">
    <form>
      <div class="upload-accordion accordion" id="uploadDatasetAccordion">
        <div class="card">
          <div class="card-header" id="uploadOne">
            <h2 class="title mb-0">
              <button
                class="btn btn-link btn-block text-left"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#uploadCollapseOne"
                aria-expanded="true"
                aria-controls="uploadCollapseOne">
                CAMERA PARAMETERS<em class="required">*</em>
              </button>
            </h2>
          </div>
          <div
            id="uploadCollapseOne"
            class="collapse show"
            aria-labelledby="uploadOne"
            data-bs-parent="#uploadDatasetAccordion">
            <div class="card-body">
              <h4 class="title">All folders</h4>
              <div class="uploaded-assets-holder">
                <button class="btn add-new-btn" type="button">
                  <img
                    [src]="'/images/folders.png' | assetUrl"
                    alt="Add Folder"/>
                  <span class="sub-title">Add New</span>
                  <input
                  [ngClass]="[cpInputDisable ? 'd-none' : '']"
                  type="file"
                  #fileDropRef12
                  id="fileDropRef12"
                  (change)="uploadSingleFile($event.target, CONSTANTS.datasetCP)"/>
                </button>
                <div class="d-flex flex-wrap">
                  <div
                    class="uploaded-files input-disabled"
                    [ngClass]="[isUploadCameraParamDone ? 'uploaded' : 'in-process']"
                    *ngFor="let upFile of uniqueCPFiles; let i = index">
                    <img
                      [src]="'/images/uploadedFolder.png' | assetUrl"
                      alt="Uploaded Folder"/>
                    <input
                      class="sub-title input-field"
                      type="text"
                      name="{{ upFile.folder }}"
                      value="{{ upFile.folder }}"
                      (blur)="showInputField($event.target)"
                    />
                    <small class="count">{{ upFile.fileLen }} file</small>
                  </div>
                </div>
              </div>
              <div
                class="upload-file-holder dropzone" [ngClass]="[cpInputDisable ? 'disabled' : '']"
                fileDragDrop
                (filesChangeEmiter)="uploadSingleFile($event, '')">
                <input
                  type="file"
                  #fileDropRef1
                  id="fileDropRef1"
                  (change)="uploadSingleFile($event.target, CONSTANTS.datasetCP)"/>
                <div class="upload-content">
                  <div class="img-holder">
                    <img
                      [src]="'/images/download.svg' | assetUrl"
                      alt="Download File image"/>
                  </div>
                  <div class="info">
                    <h3 class="heading">
                      Drop files here or
                      <a href="javascript:void(0);">click</a> to upload.
                    </h3>
                    <label class="file-required" for="fileDropRef">Accepted format: .xml only</label>
                  </div>
                </div>
              </div>
              <!-- <div class="btn-holder btn-holder-sec">
                <button
                  type="button"
                  class="btn btn-outline"
                  (click)="backToCreateMissionComp(true)">
                  Back
                </button>
                <button
                  type="button"
                  class="btn btn-default" [disabled]="isCameraParamDone"
                  (click)="startUploadSingleFile()">
                  Upload
                </button>
              </div> -->
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="uploadTwo">
            <h2 class="title mb-0">
              <button
                class="btn btn-link btn-block text-left collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#uploadCollapseTwo"
                aria-expanded="false"
                aria-controls="uploadCollapseTwo">
                DIGITAL TWIN<em class="required">*</em>
              </button>
            </h2>
          </div>
          <div
            id="uploadCollapseTwo"
            class="collapse"
            aria-labelledby="uploadTwo"
            data-bs-parent="#uploadDatasetAccordion">
            <div class="card-body">
              <h4 class="title">All folders</h4>
              <div class="uploaded-assets-holder">
                <button class="btn add-new-btn" type="button">
                  <img
                    [src]="'/images/folders.png' | assetUrl"
                    alt="Add Folder"/>
                  <span class="sub-title">Add New</span>
                  <input type="file" [ngClass]="[dtInputDisable ? 'd-none' : '']" #fileDropRef21 id="fileDropRef21" webkitdirectory mozdirectory multiple (change)="uploadFiles(fileDropRef21.files, CONSTANTS.datasetDT,'')"/>
                </button>
                <div class="d-flex flex-wrap">
                  <div
                    class="uploaded-files input-disabled"
                    [ngClass]="[isUploadDigitalTwinDone ? 'uploaded' : 'in-process']"
                    *ngFor="let upFileDT of uniqueDTFiles; let i = index">
                    <img
                      [src]="'/images/uploadedFolder.png' | assetUrl"
                      alt="Uploaded Folder"/>
                    <input
                      class="sub-title input-field"
                      type="text"
                      name="{{ upFileDT.folder }}"
                      value="{{ upFileDT.folder }}"
                      (blur)="showInputField($event.target)"/>
                    <small class="count">{{ upFileDT.fileLen }} files</small>
                  </div>
                </div>
              </div>
              <div
                class="upload-file-holder dropzone" [ngClass]="[dtInputDisable ? 'disabled' : '']"
                fileDragDrop (filesChangeEmiter)="uploadFiles($event, '','')">
                <input type="file" #fileDropRef2 id="fileDropRef2" webkitdirectory mozdirectory multiple (change)="uploadFiles(fileDropRef2.files, CONSTANTS.datasetDT,'')"/>
                <div class="upload-content">
                  <div class="img-holder">
                    <img
                      [src]="'/images/download.svg' | assetUrl"
                      alt="Download File image"/>
                  </div>
                  <div class="info">
                    <h3 class="heading">
                      Drop files here or
                      <a href="javascript:void(0);">click</a> to upload.
                    </h3>
                    <label class="file-required" for="fileDropRef">Accepted format: .xml, .mtl, .jpeg, .obj only</label>
                  </div>
                </div>
              </div>
              <div class="btn-holder btn-holder-sec d-none">
                <button type="button" class="btn btn-outline">Back</button>
                <button type="button" class="btn btn-default">Upload</button>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="uploadThree">
            <h2 class="title mb-0">
              <button
                class="btn btn-link btn-block text-left collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#uploadCollapseThree"
                aria-expanded="false"
                aria-controls="uploadCollapseThree">
                DRONE IMAGES<em class="required">*</em>
              </button>
            </h2>
          </div>
          <div
            id="uploadCollapseThree"
            class="collapse {{ isFolderClicked }}"
            aria-labelledby="uploadThree"
            data-bs-parent="#uploadDatasetAccordion">
            <div class="card-body">
              <div class="uploaded-assets-holder">
                <div
                  class="img-default-folder-holder"
                  [ngClass]="[isFolderClicked ? 'd-none' : 'd-flex']">
                  <h4 class="title w-100">All folders</h4>
                  <div
                    class="img-holder uploaded-files-img input-disabled"
                    [ngClass]="[isUploadTVDone ? 'uploaded' : 'in-progress']" >
                    <input
                      [ngClass]="[tVInputDisable ? 'd-none' : '']"
                      type="file"
                      #towerViewImg
                      id="towerViewImg"
                      webkitdirectory mozdirectory multiple
                      (change)="uploadFiles(towerViewImg.files, CONSTANTS.droneImgTv, CONSTANTS.datasetDI)"/>
                    <!-- <div
                      class="folder-holder"
                      (dblclick)="openFolderDblClick(towerViewImg.files,CONSTANTS.droneImgTv)"> -->
                    <div class="folder-holder">
                      <img
                        [src]="'/images/default_folder.png' | assetUrl"
                        alt="Tower View Folder"/>
                      <input
                        class="sub-title input-field"
                        type="text"
                        name="tower_view"
                        value="Tower View"
                        (blur)="showInputField($event.target)"/>
                    </div>
                  </div>
                  <div
                    class="img-holder uploaded-files-img input-disabled"
                    [ngClass]="[isUploadLOSDone ? 'uploaded' : 'in-progress']">
                    <input
                      [ngClass]="[losInputDisable ? 'd-none' : '']"
                      type="file"
                      #lineOfSightImg
                      id="lineOfSightImg"
                      webkitdirectory mozdirectory multiple (change)="uploadFiles(lineOfSightImg.files, CONSTANTS.droneImgLos, CONSTANTS.datasetDI)"/>
                    <!-- <div
                      class="folder-holder"
                      (dblclick)="openFolderDblClick(lineOfSightImg.files,CONSTANTS.droneImgLos)"> -->
                    <div class="folder-holder">
                      <img
                        [src]="'/images/default_folder.png' | assetUrl"
                        alt="Line of Sight Folder"/>
                      <input
                        class="sub-title input-field"
                        type="text"
                        name="tower_view"
                        value="Line of Sight"
                        (blur)="showInputField($event.target)"
                      />
                    </div>
                  </div>
                  <div
                    class="img-holder uploaded-files-img input-disabled"
                    [ngClass]="[isUploadTDDone ? 'uploaded' : 'in-progress']">
                    <input
                      [ngClass]="[tdInputDisable ? 'd-none' : '']"
                      type="file"
                      #TopToDownImg
                      id="TopToDownImg"
                      webkitdirectory mozdirectory multiple
                      (change)="uploadFiles(TopToDownImg.files, CONSTANTS.droneImgTD, CONSTANTS.datasetDI)"/>
                    <!-- <div
                      class="folder-holder"
                      (dblclick)="openFolderDblClick(TopToDownImg.files,CONSTANTS.droneImgTD)"> -->
                    <div class="folder-holder">
                      <img
                        [src]="'/images/default_folder.png' | assetUrl"
                        alt="Top to Down Folder" />
                      <input
                        class="sub-title input-field"
                        type="text"
                        name="tower_view"
                        value="Side View" (blur)="showInputField($event.target)"/>
                    </div>
                  </div>
                  <div
                    class="img-holder uploaded-files-img input-disabled"
                    [ngClass]="[isUploadNadirDone ? 'uploaded' : 'in-progress']">
                    <input [ngClass]="[nadirInputDisable ? 'd-none' : '']" type="file" #nadirImg  id="nadirImg" multiple (change)="uploadFiles(nadirImg.files, CONSTANTS.droneImgNadir, CONSTANTS.datasetDI)"/>
                    <!-- <div
                      class="folder-holder"
                      (dblclick)="openFolderDblClick(nadirImg.files,CONSTANTS.droneImgNadir)" > -->
                    <div class="folder-holder">
                      <img
                        [src]="'/images/default_folder.png' | assetUrl"
                        alt="NADIR Folder" />
                      <input
                        class="sub-title input-field"
                        type="text"
                        name="tower_view"
                        value="From Above View"
                        (blur)="showInputField($event.target)" />
                    </div>
                  </div>
                </div>
                <!-- <app-utm-upload-files-view class="w-100"
                  (backToFolderView)="initBackTofolderView($event)"
                  [uploadedTvFiles]="uploadedTVImgList"
                  [uploadedLosFiles]="uploadedLosImgList"
                  [uploadedTbFiles]="uploadedTbImgList"
                  [uploadedNadirFiles]="uploadedNadirImgList"
                  [folderName]="currentFolderName"
                  [subFolderTvList]="subFolderTvList"
                  [subFolderLosList]="subFolderLosList"
                  [subFolderTbList]="subFolderTbList"
                  *ngIf="isFolderClicked"
                ></app-utm-upload-files-view> -->
              </div>
              <div class="d-flex flex-wrap">
                <div
                  class="uploaded-files input-disabled"
                  [ngClass]="[isUploadDone ? 'uploaded' : 'in-process']"
                  *ngFor="let upFileDI of uniqueDIFiles; let i = index" >
                  <img
                    [src]="'/images/uploadedFolder.png' | assetUrl"
                    alt="Uploaded Folder" />
                  <input
                    class="sub-title input-field"
                    type="text"
                    name="{{ upFileDI.folder }}"
                    value="{{ upFileDI.folder }}"
                    (blur)="showInputField($event.target)" />
                  <small class="count">{{ upFileDI.fileLen }} files</small>
                </div>
              </div>
              <div
                class="upload-file-holder dropzone" [ngClass]="[losInputDisable || tVInputDisable || tdInputDisable || nadirInputDisable ? 'disabled' : '']"
                fileDragDrop
                (filesChangeEmiter)="uploadFiles($event, '','')" >
                <input type="file" #fileDropRef3 id="fileDropRef3" webkitdirectory mozdirectory multiple (change)="uploadFiles(fileDropRef3.files, CONSTANTS.datasetDI, '')" />
                <div class="upload-content">
                  <div class="img-holder">
                    <img
                      [src]="'/images/download.svg' | assetUrl"
                      alt="Download File image"/>
                  </div>
                  <div class="info">
                    <h3 class="heading">
                      Drop files here or
                      <a href="javascript:void(0);">click</a> to upload.
                    </h3>
                    <label class="file-required" for="fileDropRef">Accepted format: .jpeg only</label>
                  </div>
                </div>
              </div>
              <div class="btn-holder btn-holder-sec d-none">
                <button type="button" class="btn btn-outline">Back</button>
                <button type="button" class="btn btn-default">Upload</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="btn-holder">
    <button
      type="button"
      class="btn btn-outline"
      (click)="backToCreateMissionComp(true)">
      Back
    </button>
    <button type="submit" class="btn btn-default" (click)="onUploadDataset()" [disabled]="(!isCameraParamDone || !isDigitalTwinDone || !isDroneImgDone)">
      Process
    </button>
  </div>
</div>
<ng-template [ngIf]="isCameraParamUploadStart || isDigitalTwinUploadStart || isDroneImgUploadStart || isWorkFlowStarted">
  <app-utm-upload-progressbar [isWorkFlowStarted]="isWorkFlowStarted" 
  [isCameraParamDone]="isCameraParamUploadStart" [isDigitalTwinDone]="isDigitalTwinUploadStart" [isDroneImgDone]="isDroneImgUploadStart"
  [cameraParamProgress]="currentCPProgress" [digitalTwinProgress]="currentDTProgress" [droneImgProgress]="currentDIProgress"
  [workflowSteps]="workflowSteps" [workflowProgress]="progress"></app-utm-upload-progressbar>
</ng-template>
<ng-template [ngIf]="isShowToaster">
  <app-utm-toaster-custom [toasterClass]="toasterClass" [toasterMsg]="toasterMsg"></app-utm-toaster-custom>
</ng-template>
<ng-template [ngIf]="isShowProgressModal">
  <div class="modal fade show mission-confirm-modal" id="closeMissionConfirmation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="img-holder">
            <img [src]="'/images/warning.png' | assetUrl" alt="warning image" />
          </div>
          <div class="content-body">
            <h2 class="heading">Upload is in progress</h2>
            <p class="info">Please wait. You cannot go back until the upload is in progress.</p>
          </div>
        </div>
        <div class="btn-holder">
          <button type="button" class="btn btn-default" (click)="stayOnUploadComponent()">Ok</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
