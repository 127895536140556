<div class="modal-header">
    <h4 class="modal-title">PDF Viewer</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
  
    <iframe [src]="pdfUrl | safeUrl" width="100%" height="500px" frameborder="0"></iframe>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
  </div>