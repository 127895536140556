export type BaseModel = {
    _id: string;
    createdAt?: string;
    updatedAt?: string;
}

export function isModelPopulated<T>(model: T | string): model is T {
  return (model as BaseModel)?._id !== undefined;
}

export function getModel<T>(model: T | string): T | null {
  return isModelPopulated(model) ? model : null;
}
