import { Component } from '@angular/core';
import { NgbActiveModal, NgbDatepickerI18n, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-utm-delete-permit',
  templateUrl: './delete-permit.component.html',
  styleUrls: ['./delete-permit.component.scss']
})
export class DeletePermitComponent {

  constructor ( private aModalService: NgbActiveModal,){
    
  }

  closeModal() {
    this.aModalService.close();
  }

  confirmDelete() {
    this.aModalService.close('confirm');
  }

}
