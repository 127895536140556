<div class="main-permit">
  <div class="content-permit">
    <div class="top-menu">
      <div class="left-menu">
        <a routerLink="/permit-application">
          <img [src]="'/images/arrow_back_24px.svg' | assetUrl" alt="download-cloud" />
        </a>
        <div class="application-id">{{ permitList?.trackingId }}</div>
        <div [ngClass]="checkPaymentStatus(permitList?.paymentStatus || '')">
          {{ permitList?.paymentStatus }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-row both-container">
      <div class="applicant-info">
        <div class="d-flex justify-content-between top-profile">
          <div class="label-applicant">Applicant</div>
          <div class="applicant-profile">
            <div class="d-flex flex-column">
              <div class="applicant-name d-flex align-items-center">
                {{ permitList?.trackingId }}
              </div>
              <div class="applicant-comp">
                {{ permitList?.trackingId }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-between top-profile"
          *ngIf="role === RoleType.AuthorityAdmin || role === RoleType.Authority || role === RoleType.HumanValidator">
          <div class="label-applicant">Fees</div>

          <div class="applicant-profile">
            <span class="price-fees">RM{{ permitList?.totalFees }}</span>
          </div>
        </div>
        <div class="separator"></div>

        <div class="d-flex justify-content-center first-vertical-line">
          <img [src]="'/icon/first-vertical-line.svg' | assetUrl" class="first-ver-line-height" />
        </div>
        <div class="d-flex align-items-start" *ngFor="let item of trackerList">
          <div class="time-position col-4">
            <span class="time-text">{{ convertTime(item.createdAt) }}</span>
          </div>
          <div class="d-flex flex-column justify-content-center col-2">
            <div
              class="bg-icon"
              [style.background]="item.title == 'CAAM Approval' || item.title == 'JUPEM Approval' || item.title == 'Internal Review' ? 'none' : ''">
              <img [src]="getImageForTitle(item.title) | assetUrl" class="icon-size" />
            </div>
            <div class="ver-line">
              <img [src]="'/icon/vertical-line.svg' | assetUrl" class="ver-line-height" />
            </div>
          </div>
          <span>
            <span class="d-flex flex-column col-5" style="margin-bottom: 20px" *ngIf="item.title === 'Payment'">
              <div class="content-label-application payment-txt">
                {{ item.title }}
              </div>
              <div *ngIf="permitList?.paymentInfo == undefined">
                <div class="time-text content-text">Kindly attach the proof of payment.</div>
                <div class="mb-3">
                  <input class="form-control" type="file" id="formFile" (change)="onChangeFile($event)" />
                </div>
              </div>
              <div *ngIf="permitList?.paymentInfo != undefined" class="d-flex" style="cursor: pointer; margin-top: 7px; width: 130px">
                <img [src]="'/icon/pdf.svg' | assetUrl" />
                <span
                  style="color: white; margin-left: 5px"
                  (click)="openModal('https://dronos-utm.s3.ap-southeast-1.amazonaws.com/permit-' + permitList?._id + '/' + permitList?.paymentInfo?.receipt)"
                  >{{ permitList?.paymentInfo?.receipt }}
                </span>
              </div>
            </span>

            <div class="d-flex flex-column col-5" style="margin-bottom: 20px" *ngIf="item.title === 'Invoice Received'">
              <div class="content-label-application">
                {{ item.title }}
              </div>
              <div class="time-text content-text">Invoice has been generated and please proceed with payment.</div>

              <button class="view-invoice" (click)="viewInvoice()">View Invoice</button>
            </div>

            <div class="d-flex flex-column col-5" style="margin-bottom: 20px" *ngIf="item.title === 'Application Completed'">
              <span class="content-label-application">{{ item.title }}</span>
              <div class="d-flex completed" style="font-size: 12px; color: var(--system-secondary-200, #879aa6)">
                <div style="white-space: nowrap">
                  <img [src]="'/icon/completed.svg' | assetUrl" class="icon-size icon-status" />
                  <span style="white-space: nowrap"> {{ item.message['approved'] }} approved</span>
                </div>
                <div style="white-space: nowrap">
                  <img [src]="'/icon/rejected.svg' | assetUrl" class="icon-size icon-status" style="margin-left: 3px" />
                  <span style="white-space: nowrap"> {{ item.message['rejected'] }} rejected </span>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column col-5" style="margin-bottom: 20px" *ngIf="item.title === 'Authority Review'">
              <div class="content-label-application">
                {{ item.title }}
              </div>
              <div class="time-text content-text">The authorities are currently reviewing the application.</div>
            </div>

            <div
              class="d-flex flex-column col-5"
              style="margin-bottom: 20px"
              *ngIf="
                item.title === 'CAAM Approval' ||
                item.title === 'JUPEM Approval' ||
                item.title === 'Internal Review' ||
                item.title == 'Permit Released' ||
                item.title == 'Permit Received'
              ">
              <div class="content-label-application">
                {{ item.title }}
              </div>
              <div class="time-text content-text">{{ item.message['content'] }}</div>
            </div>

            <div class="d-flex flex-column col-5" style="margin-bottom: 20px" *ngIf="item.title === 'Application Submitted'">
              <div class="content-label-application">
                {{ item.title }}
              </div>
              <div class="time-text content-text">New permit application has been submitted</div>
            </div>
          </span>
          <span>
            <span class="d-flex flex-column col-5" style="margin-bottom: 20px" *ngIf="item.title === 'Application Received'">
              <div class="content-label-application payment-txt">
                <span class="head-received">{{ item.title }}</span>
              </div>
              <div class="time-text content-text">
                <span class="recieved">Application received awaiting for your approval.</span>
              </div>
            </span>
          </span>

          <ng-container *ngIf="role === RoleType.HumanValidator">
            <div class="d-flex flex-column" style="margin-bottom: 20px" *ngIf="item.title === 'Payment Received'">
              <div class="content-label-application payment-txt">
                <span class="head-received">{{ item.title }}</span>
              </div>
              <div class="time-text content-text">
                <span class="recieved">Payment received awaiting for your approval.</span>
              </div>
              <div class="btn-receipt">
                <button
                  class="view-receipt btn"
                  (click)="
                    permitList?.paymentApprovalInfo == undefined
                      ? viewReceiptModal(permitList?.paymentInfo?.receipt || '', permitList?._id || '')
                      : openModal('https://dronos-utm.s3.ap-southeast-1.amazonaws.com/permit-' + permitList?._id + '/' + (permitList?.paymentInfo)!.receipt)
                  ">
                  View Receipt
                </button>
                <img
                  [src]="'/icon/completed.svg' | assetUrl"
                  class="icon-size icon-status"
                  *ngIf="permitList?.paymentApprovalInfo && permitList?.paymentApprovalInfo?.paymentApprovalStatus == 'VERIFIED'" />
                <img
                  [src]="'/icon/rejected.svg' | assetUrl"
                  class="icon-size icon-status"
                  *ngIf="permitList?.paymentApprovalInfo && permitList?.paymentApprovalInfo?.paymentApprovalStatus == 'RETURNED'" />
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- mission list -->
      <div style="width: 100%">
        <div class="d-flex justify-content-between">
          <div class="tabs">
            <!-- sini tab -->
            <div class="d-flex flex-column">
              <div class="d-flex tabs-menu-active">
                <div>Mission List</div>
                <div class="total-amount-active">{{ missionList.length || 0 }}</div>
              </div>
              <div class="active-line"></div>
            </div>
          </div>
        </div>
        <div class="mission-table">
          <div class="table-head">
            <span
              *ngFor="let title of missionHeader; let idx = index"
              class="text-header"
              [style.flex]="idx == 3 ? '70px' : '1'"
              [style.display]="role != 'Authority' ? 'flex' : 'none'">
              {{ title }}
            </span>
          </div>
          <div class="body-card-height">
            <div class="table-body-card" *ngFor="let item of missionList">
              <div class="d-flex flex-column">
                <div class="text-color" style="flex: 1; margin-right: 12px; max-width: 150px;
                ">
                  {{ item.name }}
                </div>
                <div class="label-applicant" style="flex: 1">
                  {{ item.trackingId }}
                </div>
              </div>

              <div class="text-color" style="flex: 1">
                {{ item.objective.name }}
              </div>
              <div class="text-color" style="flex: 1">
                {{ convertDate(item.createdAt) }}
              </div>
              <div class="margin-profile" style="flex: 70px">
                <div class="text-color">
                  {{ item.applicantId.username }}
                </div>
                <div class="label-applicant">
                  {{ item.applicantId.email }}
                </div>
              </div>
              <div class="text-color" style="flex: 1">
                {{ item.permitInfo.permitTypeId.name }}
              </div>
              <div *ngIf="item.permitStatus == 'APPROVED'" style="flex: 1">
                <div class="mission-status-badge-approved" style="width: 80%">
                  {{ item.permitStatus }}
                </div>
              </div>
              <div *ngIf="item.permitStatus == 'REJECTED'" style="flex: 1">
                <div class="mission-status-badge-rejected" style="width: 80%">
                  {{ item.permitStatus }}
                </div>
              </div>
              <div *ngIf="item.permitStatus === 'DRAFT' || item.permitStatus === 'PENDING APPROVAL' || item.permitStatus == 'IN REVIEW'" style="flex: 1">
                <div class="mission-status-badge" style="width: 80%">
                  {{ item.permitStatus }}
                </div>
              </div>
              <div
                class="text-color"
                *ngIf="getFileName(item) == 'N/A' && (role == RoleType.Owner || role == RoleType.Admin)"
                style="text-align: left; flex: 1">
                N/A
              </div>
              <a
                href="javascript:void(0);"
                class="text-color col-1"
                *ngIf="getFileName(item) == 'Issue Permits'"
                style="text-align: center; pointer-events: auto"
                (click)="issuePermitUpload(item._id, permitList?._id || '')"
                style="flex: 1"
                >Issue Permits</a
              >
              <div *ngIf="item.issuedPermits != undefined">
                <div class="d-flex mb-1" *ngFor="let item of item.issuedPermits" style="flex: 1">
                  <img [src]="'/icon/pdf.svg' | assetUrl" />
                  <span style="color: white" (click)="openModal(item)">{{ getExactFileName(item) }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center d-flex-container">
          <p class="txt-12 color-txt-mission">{{ paginationRange }}</p>
          <nav aria-label="Page navigation">
            <ul class="pagination">
              <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
                <a class="page-link" aria-label="Previous" (click)="goToPreviousPage()">
                  <img [src]="'/images/chevron_left_24px.png' | assetUrl" style="max-width: 100px" />
                </a>
              </li>
              <li class="page-item" *ngFor="let page of pages" [ngClass]="{ active: currentPage === page }" (click)="changePage(page)">
                <a class="page-link"
                  ><span>{{ page }}</span></a
                >
              </li>
              <li class="page-item" [ngClass]="{ disabled: currentPage === pages.length }">
                <a class="page-link" aria-label="Next" (click)="goToNextPage()">
                  <img [src]="'/images/chevron_right_24px.png' | assetUrl" style="max-width: 100px" />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <app-utm-toast *ngIf="showToast" [message]="toastMessage" [state]="'success'"></app-utm-toast>
  <div class="confirm-modal" *ngIf="confirmApproveModal">
    <div class="confirm-modal-content text-center">
      <img class="" [src]="'/icon/Warning.svg' | assetUrl" />

      <p class="confirm-title">Approve Receipt</p>

      <p class="confirm-desc">Are you sure you want to approve this receipt? This action will indicate <br />that the receipt has been received.</p>

      <div class="receipt-modal-buttons">
        <a class="receipt-modal-button cancel" (click)="cancelConfirm()">Cancel</a>

        <a class="receipt-modal-button approve" (click)="approveRejectService('confirm')">Confirm</a>
      </div>
    </div>
  </div>

  <div class="reject-modal" *ngIf="rejectModal">
    <div class="reject-modal-content text-center">
      <img class="" [src]="'/icon/Warning.png' | assetUrl" />

      <p class="confirm-title">Reject Receipt</p>

      <p class="confirm-desc">Are you sure you want to reject this receipt? <br />You won’t be able to revert this action.</p>

      <textarea class="remarks" [(ngModel)]="rejectRemark"></textarea>

      <div class="receipt-modal-buttons">
        <a class="receipt-modal-button cancel" (click)="cancelConfirm()">Cancel</a>

        <a class="receipt-modal-button reject" (click)="approveRejectService('reject')">Reject</a>
      </div>
    </div>
  </div>
</div>
