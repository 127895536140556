import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EnvironmentService } from 'src/app/services/environment.service';
import { state } from '@app/utility';
import { LiveStreamVideoResponse } from '../interfaces/video-stream-api-response.interface';
import { JwtTokenResponse } from '../interfaces/live-stream-jwt-token-response.interface';
import { GenerateStreamUrlRequestInterface } from '../interfaces/generate-stream-url-request.interface';
import { GenerateLiveStreamUrlResponse } from '../interfaces/generate-stream-url-response.interface';

@Injectable({
  providedIn: 'root'
})

export class VideoService {

  constructor(private http: HttpClient, private environmentService: EnvironmentService) { }
  private apiUrl = this.environmentService.getApiUrl() + '/stream-resource/live-streams';
  private workspaceId: string = state.getUserInfo().activeWorkspaceId;
  private headers = new HttpHeaders({
    Authorization: 'Bearer ' + this.environmentService.getToken(),
    workspaceId: this.workspaceId,
    'Content-Type': 'application/json',
  });

  getStreamDetailByMissionId(missionId: string) {
    const params = new HttpParams()
      .set('missionId', missionId);
    return this.http.get<LiveStreamVideoResponse>(this.apiUrl, { headers: this.headers, params: params });
  }
  getLiveStreamMissionByIds(queryParams: any) {
    return this.http.get<LiveStreamVideoResponse>(this.apiUrl, { headers: this.headers, params: queryParams });
  }
  createLiveStreamByMissionId(payload: GenerateStreamUrlRequestInterface) {
    payload.workspaceId = this.workspaceId;
    const params = new HttpParams()
      .set('missionId', payload.missionId);
    return this.http.post<GenerateLiveStreamUrlResponse>(
      this.apiUrl, payload, { headers: this.headers, params: params });
  }
  getStreamVideoToken(missionId: string) {
    return this.http.get<JwtTokenResponse>(this.apiUrl + `/${missionId}/generate-jwt`, { headers: this.headers });
  }
}
