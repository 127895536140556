<div class="uploaded-img-view tower-view" *ngIf="folderName == CONSTANTS.droneImgTv">
    <ul class="folder-breadcrumb">
      <li (click)="backToFolder()">All folders</li>
      <li (click)="backToMainFolder()">Tower View</li>
      <li [ngClass]="getFolderName ? 'd-inline-block' : 'd-none'">{{getFolderName}}</li>
    </ul>
    <div class="uploaded-inner-folder {{isFolderClicked}}">
      <div [ngClass]="!isFolderClicked ? 'd-flex' : 'd-none'">
        <div class="img-holder" *ngFor="let subFolderName of subFolderTvList">
          <div class="inner-folder" (dblclick)="openFolderDblClick(subFolderName)">
            <img [src]="'/images/uploadedFolder.png' | assetUrl" alt="Uploaded Folder" />
            <p class="img-title">{{subFolderName}}</p>
          </div>
        </div>
      </div>
      <div [ngClass]="isFolderClicked ? 'd-flex' : 'd-none'">
        <div [ngClass]="getFolderName === CONSTANTS_DI.DI_FTV ? 'd-flex flex-wrap align-items-center' : 'd-none'">
          <div class="img-holder" *ngFor="let ftvImg of ftvFiles">
            <img class="img-inner" [src]="domSanitizer.bypassSecurityTrustUrl(ftvImg.src)" alt={{ftvImg.name}}/>
            <p class="img-title">{{ftvImg.name}}</p>
            <span class="img-size">{{ftvImg.size}}</span>
          </div>
        </div>
        <div [ngClass]="getFolderName === CONSTANTS_DI.DI_L1AD ? 'd-flex flex-wrap align-items-center' : 'd-none'">
          <div class="img-holder" *ngFor="let l1adImg of l1adFiles">
            <img class="img-inner" [src]="domSanitizer.bypassSecurityTrustUrl(l1adImg.src)" alt={{l1adImg.name}}/>
            <p class="img-title">{{l1adImg.name}}</p>
            <span class="img-size">{{l1adImg.size}}</span>
          </div>
        </div>
        <div [ngClass]="getFolderName === CONSTANTS_DI.DI_CV ? 'd-flex flex-wrap align-items-center' : 'd-none'">
          <div class="img-holder" *ngFor="let cvImg of cvFiles">
            <img class="img-inner" [src]="domSanitizer.bypassSecurityTrustUrl(cvImg.src)" alt={{cvImg.name}}/>
            <p class="img-title">{{cvImg.name}}</p>
            <span class="img-size">{{cvImg.size}}</span>
          </div>
        </div>
        <div [ngClass]="getFolderName === CONSTANTS_DI.DI_L1AV ? 'd-flex flex-wrap align-items-center' : 'd-none'">
          <div class="img-holder" *ngFor="let l1avImg of l1avFiles">
            <img class="img-inner" [src]="domSanitizer.bypassSecurityTrustUrl(l1avImg.src)" alt={{l1avImg.name}}/>
            <p class="img-title">{{l1avImg.name}}</p>
            <span class="img-size">{{l1avImg.size}}</span>
          </div>
        </div>
      </div>
    </div>
</div>
<div class="uploaded-img-view los-view" *ngIf="folderName == CONSTANTS.droneImgLos">
  <ul class="folder-breadcrumb">
    <li (click)="backToFolder()">All folders</li>
    <li (click)="backToMainFolder()">Line of Sight</li>
    <li [ngClass]="getFolderName ? 'd-inline-block' : 'd-none'">{{getFolderName}}</li>
  </ul>
  <div class="uploaded-inner-folder">
    <div [ngClass]="!isFolderClicked ? 'd-flex' : 'd-none'">
      <div class="img-holder" *ngFor="let subFolderName of subFolderLosList">
        <div class="inner-folder" (dblclick)="openFolderDblClick(subFolderName)">
          <img [src]="'/images/uploadedFolder.png' | assetUrl" alt="Uploaded Folder" />
          <p class="img-title">{{subFolderName}}</p>
        </div>
      </div>
    </div>
    <div [ngClass]="isFolderClicked ? 'd-flex' : 'd-none'">
      <div [ngClass]="getFolderName === CONSTANTS_DI.DI_L1LOS ? 'd-flex flex-wrap align-items-center' : 'd-none'">
        <div class="img-holder" *ngFor="let l1losImg of l1losFiles">
          <img class="img-inner" [src]="domSanitizer.bypassSecurityTrustUrl(l1losImg.src)" alt={{l1losImg.name}}/>
          <p class="img-title">{{l1losImg.name}}</p>
          <span class="img-size">{{l1losImg.size}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="uploaded-img-view los-view" *ngIf="folderName == CONSTANTS.droneImgTD">
  <ul class="folder-breadcrumb">
    <li (click)="backToFolder()">All folders</li>
    <li (click)="backToMainFolder()">Top to Down</li>
    <li [ngClass]="getFolderName ? 'd-inline-block' : 'd-none'">{{getFolderName}}</li>
  </ul>
  <div class="uploaded-inner-folder">
    <div [ngClass]="!isFolderClicked ? 'd-flex' : 'd-none'">
      <div class="img-holder" *ngFor="let subFolderName of subFolderTbList">
        <div class="inner-folder" (dblclick)="openFolderDblClick(subFolderName)">
          <img [src]="'/images/uploadedFolder.png' | assetUrl" alt="Uploaded Folder" />
          <p class="img-title">{{subFolderName}}</p>
        </div>
      </div>
    </div>
    <div [ngClass]="isFolderClicked ? 'd-flex' : 'd-none'">
      <div [ngClass]="getFolderName === CONSTANTS_DI.DI_TBA ? 'd-flex flex-wrap align-items-center' : 'd-none'">
        <div class="img-holder" *ngFor="let tbaImg of tbaFiles">
          <img class="img-inner" [src]="domSanitizer.bypassSecurityTrustUrl(tbaImg.src)" alt={{tbaImg.name}}/>
          <p class="img-title">{{tbaImg.name}}</p>
          <span class="img-size">{{tbaImg.size}}</span>
        </div>
      </div>
      <div [ngClass]="getFolderName === CONSTANTS_DI.DI_TBB ? 'd-flex flex-wrap align-items-center' : 'd-none'">
        <div class="img-holder" *ngFor="let tbbImg of tbbFiles">
          <img class="img-inner" [src]="domSanitizer.bypassSecurityTrustUrl(tbbImg.src)" alt={{tbbImg.name}}/>
          <p class="img-title">{{tbbImg.name}}</p>
          <span class="img-size">{{tbbImg.size}}</span>
        </div>
      </div>
      <div [ngClass]="getFolderName === CONSTANTS_DI.DI_TBC ? 'd-flex flex-wrap align-items-center' : 'd-none'">
        <div class="img-holder" *ngFor="let tbcImg of tbcFiles">
          <img class="img-inner" [src]="domSanitizer.bypassSecurityTrustUrl(tbcImg.src)" alt={{tbcImg.name}}/>
          <p class="img-title">{{tbcImg.name}}</p>
          <span class="img-size">{{tbcImg.size}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="uploaded-img-view los-view" *ngIf="folderName == CONSTANTS.droneImgNadir">
  <ul class="folder-breadcrumb">
    <li (click)="backToFolder()">All folders</li>
    <li (click)="backToMainFolder()">Nadir</li>
  </ul>
  <div class="uploaded-inner-folder">
    <div d-flex flex-wrap align-items-center>
      <div class="img-holder" *ngFor="let nadirImg of uploadedNadirFiles">
        <img class="img-inner" [src]="domSanitizer.bypassSecurityTrustUrl(nadirImg.src)" alt={{nadirImg.name}}/>
        <p class="img-title">{{nadirImg.name}}</p>
        <span class="img-size">{{nadirImg.size}}</span>
      </div>
    </div>
  </div>
</div>
