<div class="airspace_informatics_container">
  <div class="airspace_informatics_head">
    <p>AIRSPACE INFORMATICS</p>
    <img (click)="airSpaceInformatics.emit(false)" class="airspace_informatics_close" [src]="'/union.svg' | assetUrl" />
  </div>
  <div class="airspace_informatics_body">
    <div *ngFor="let airspace of airspaceInformation; let last = last">
      <div class="airspace_card" [style]="{ 'margin-bottom': last ? '0px' : '' }">
        <div class="status_bar" [style.background-color]="airspace.layer.paint['fill-color']"></div>
        <div class="airspace_content">
          <div class="d-flex justify-content-between">
            <p class="airspace_name">{{ airspace.properties.name }}</p>
            <div class="airspace_type">Airspace</div>
          </div>
          <div class="d-flex justify-content-between">
            <span class="airspace_description"> {{ airSpc.status }} </span>
            <!-- <span class="airspace_description"> {{ airSpc.height }} m<sup>2</sup></span> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="airspace_informatics_button">
        <p (click)="airspaceInfermaticTap=1" [ngClass]="{ airspaceInfermaticTap_active: airspaceInfermaticTap == 1 }">Airspace Risk</p>
        <p (click)="airspaceInfermaticTap=2" [ngClass]="{ airspaceInfermaticTap_active: airspaceInfermaticTap == 2 }">Permit Advisory</p>
    </div>
    <div class="airspace_informatics_content_risk" *ngIf="airspaceInfermaticTap==1" [style]="'--dynborder : ' + (airspaceInfermaticTap == 1 ? '4px solid'+featureProperties.fillColor : 0)">
        <div class="content_area">
            <p class="content_area_name">Pengerang Town Hall</p>
            <p class="content_area_type">{{featureProperties.hazardFactorName}}</p>
            <p class="content_area_approval">Preapproval required</p>
            <p class="content_area_distance">450m</p>
        </div>
        <div class="content_authority">
            <p>Authority</p>
            <p>{{featureProperties.designator}}</p>
        </div>
        <div class="content_vertical">
            <p>Vertical Limits</p>
            <p>1300ft MSL</p>
        </div>
        <div class="content_type">
            <p>Type</p>
            <p>{{featureProperties.airspaceType || null}}</p>
        </div>

    </div>
    <div class="airspace_informatics_content_permit" *ngIf="airspaceInfermaticTap==2">
        <div class="content_area">
            <p class="content_area_name">Special Flight Operation</p>
            <p class="content_area_type">CAAM</p>
            <p class="content_area_approval">Preapproval required</p>
            <p class="content_area_distance">450m</p>
        </div>
        <div class="content_authority">
            <p>Authority</p>
            <p>{{featureProperties.designator}}</p>
        </div>
        <div class="content_vertical">
            <p>Vertical Limits</p>
            <p>1300ft MSL</p>
        </div>
        <div class="content_type">
            <p>Type</p>
            <p>Flying Permit Section 300A</p>
        </div>

    </div> -->
</div>
