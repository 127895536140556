<div class="main-payment-container">
    <div class="container-fluid mt-10 m-20">
        <div class="d-flex justify-content-between mt-10 mb-10">
            <!-- Content aligned to the left -->
            <div>
                <div class="me-3">
                    <a (click)="back()" style="cursor: pointer;">
                    <img [src]="'/images/arrow_back_24px.svg' | assetUrl" style="max-width: 100px;">

                    </a>
                    <span class="header-txt mt-10 ml-10">APPLICATION INVOICE</span>
                </div>
            </div>

            <div>
                <button class="btn-export" (click)="exportToPDF()">
                    <img [src]="'/icon/cloud.png' | assetUrl">
                    <span class="ml-10">Export PDF </span>
                </button>
            </div>
        </div>

        <div class="row">
            <div class="main-body" id="exportInvoice">
                <div style="padding: 0px 20px;">

               
                <div class="d-flex justify-content-between mt-10 mb-10">
                    <!-- Content aligned to the left -->
                    <div>
                        <p class="txt-invoice">INVOICE</p>
                        <span class="sub-invoice">#{{invoiceData?.invoiceId}}</span>
                    </div>

                    <div class="d-flex justify-content-center align-items-center">
                        <img [src]="'/icon/aerodyne-logo.svg' | assetUrl" alt="aerodyne-logo">
                        <span class="dronos-txt">DRON<span>OS</span></span>
                    </div>
                </div>

                <div class="d-flex justify-content-between mt-10 mb-10">
                    <!-- Content aligned to the left -->
                    <div>
                        <p class="txt-permit">PERMIT APPLICATION ID:<span> {{ invoiceData?.applicationId }}</span> </p>
                    </div>
                    <div>
                        <p class="txt-paid">Total to be paid:<span> RM{{invoiceData?.totalFees}}</span></p>
                    </div>
                </div>

                <div class="container-fluid" style="margin-bottom: 17px;">
                    <div class="row">
                        <div class="col-sm-4 custom-border custom-right">
                            <p class="title-color">Issued</p>
                            <span class="text-color">{{invoiceData?.issuedDate}}</span>
                            <p class="title-color mt-3">Due</p>
                            <span class="text-color">{{invoiceData?.dueDate}}</span>

                        </div>
                        <div class="col-sm-4 custom-border custom-right">
                            <p class="title-color">Billed to</p>
                            <p style="margin-top: 20px;" class="text-color">{{invoiceData?.billedTo?.name}}</p>
                            <p class="text-color">{{invoiceData?.billedTo?.name}}</p>
                            <p class="text-color">{{invoiceData?.billedTo?.address}}</p>
                            <p class="text-color" style="margin-top: 15px;">Tel No. : </p>
                            <p class="text-color" style="margin-top: 5px;">Registration No: {{invoiceData?.billedTo?.companyRegistration}}</p>
                        </div>
                        <div class="col-sm-4 custom-border">
                            <p class="title-color">From</p>
                            <p class="text-color" style="margin-top: 15px;"><span>{{invoiceData?.fromOrganisation?.name}}</span></p>
                            <p class="text-color">{{invoiceData?.fromOrganisation?.address}}</p>
                            <p class="text-color">TAX ID: {{invoiceData?.fromOrganisation?.companyRegistration}}</p>
                            <p class="text-color">Registration No: {{invoiceData?.fromOrganisation?.taxRegistration}}</p>

                        </div>
                    </div>
                </div>
                <p class="orga-txt">Jabatan Ukur dan Pemetaan Malaysia (JUPEM)</p>
                <hr>
              <div class="row col-12 p-0" *ngFor="let mission of invoiceData?.jupemMissionsData">

                <p class="mission-id">Mission ID: {{ mission?.missionId }} </p>

                <div class="sub-body">
                    <div class="row col-12">
                        <div class="flex-container ">
                            <div class="flex-item head-sub" style="width: 25%;">DETAILS</div>
                            <div class="flex-item head-sub" style="width: 25%;">PERIOD</div>
                            <div class="flex-item head-sub" style="width: 20%;">QTY</div>
                            <div class="flex-item head-sub" style="width: 20%;">UNIT PRICE</div>
                            <div class="flex-item head-sub" style="width: 10%;">TOTAL PRICE</div>
                        </div>
                    </div>
                    <hr>
                    <div class="row col-12 mt-2">
                        <div class="flex-container">
                            <div class="flex-item sub-text" style="width: 25%;">
                                {{mission?.location}}
                            </div>
                            <div class="flex-item sub-text" style="width: 25%;">
                                <div class="d-flex">
                                    <span style="color: #EBF2F4; font-size: 14px; font-weight: 400;"> {{ getPeriodDate(mission.period.split(' - ')[0])}}</span>
                                    <span style="color: #C0CDD4; font-size: 14px; font-weight: 400; margin-left: 8px;">{{ getPeriodTime(mission.period.split(' - ')[0])}}</span>
                                    </div>
                                    <div class="d-flex">
                                        <span style="color: #EBF2F4; font-size: 14px; font-weight: 400;"> {{ getPeriodDate(mission.period.split(' - ')[1])}}</span>
                                        <span style="color: #C0CDD4; font-size: 14px; font-weight: 400; margin-left: 8px;">{{ getPeriodTime(mission.period.split(' - ')[1])}}</span>
                                        </div>
                            </div>
                            <div class="flex-item sub-text" style="width: 20%;">{{mission?.totalDay}} DAY(S)
                                <!-- <span class="small-txt">11Hrs</span>  -->
                            </div>
                            <div class="flex-item sub-text" style="width: 20%;">{{mission?.unitPrice}}</div>
                            <div class="flex-item sub-text" style="width: 10%;">{{mission?.totalPrice}}</div>
                        </div>
                    </div>
                </div>

                <!-- <hr> -->

              </div>
                <!-- subtotal -->
                <div class="d-flex justify-content-between mb-10 subtotal">
                    <!-- Content aligned to the left -->
                    <div>
                        <p>Subtotal</p>
                    </div>

                    <div>
                        <p>RM{{SubPriceSumJupem}}</p>
                    </div>
                </div>

              <p class="orga-txt">Civil Aviation Authority of Malaysia (CAAM)</p>
              <hr>
            <div class="row col-12 p-0" *ngFor="let mission of invoiceData?.caamMissionsData">

              <p class="mission-id">Mission ID: {{ mission?.missionId }} </p>

              <div class="sub-body">
                  <div class="row col-12">
                      <div class="flex-container ">
                          <div class="flex-item head-sub" style="width: 25%;">DETAILS</div>

                          <div class="flex-item head-sub" style="width: 25%;">QTY</div>
                          <div class="flex-item head-sub" style="width: 25%;">UNIT PRICE</div>
                          <div class="flex-item head-sub" style="width: 20%;">TOTAL PRICE</div>
                      </div>
                  </div>
                  <hr>
                  <div class="row col-12">
                      <div class="flex-container">
                          <div class="flex-item sub-text" style="width: 25%;">
                              {{mission?.location}}
                          </div>

                          <div class="flex-item sub-text" style="width: 25%;">{{mission?.totalUA}} UA</div>
                          <div class="flex-item sub-text" style="width: 25%;">{{mission?.unitPrice}}</div>
                          <div class="flex-item sub-text" style="width: 20%;">{{mission?.totalPrice}}</div>
                      </div>
                  </div>
              </div>

            </div>
              <!-- <hr> -->


              <!-- subtotal -->
              <div class="d-flex justify-content-between mt-10 mb-10 subtotal">
                  <!-- Content aligned to the left -->
                  <div>
                      <p>Subtotal</p>
                  </div>

                  <div>
                      <p>RM{{SubPriceSumCaam}}</p>
                  </div>
              </div>

                <div class="d-flex justify-content-between mt-10 subtotal">
                    <!-- Content aligned to the left -->
                    <div>
                        <p style="font-size: 20px;">Processing Fees</p>
                    </div>

                    <div>
                        <p style="font-size: 20px;">RM{{invoiceData?.processingFees}}.00</p>
                    </div>
                </div>
                <hr style="margin: 0px;">
                <div class="d-flex justify-content-between subtotal" style="margin-top: 10px;">
                    <!-- Content aligned to the left -->
                    <div>
                        <p style="font-size: 24px;">Total</p>
                    </div>

                    <div>
                        <p style="font-size: 24px;">RM{{invoiceData?.totalFees}}</p>
                    </div>
                </div>
                <hr style="margin: 0px;">
            </div>
                <!-- <p class="payment">Payment information</p> -->
                <div class="sub-body payment-details" style="margin-top: 50px; margin-left: 0px;">
                    <p style="padding: 0px 25px;">Payment information</p>
                    <hr>
                    <div class="row" style="padding: 0px 25px">
                        <div class="col-sm-6">
                            <p>Company details </p>
                            <p>DRONOS IO.AERODYNE GROUP </p>
                            <p>Bank Name</p>
                            <p>CIMB BANK
                            <p>

                        </div>
                        <div class="col-sm-6">
                            <p>CIMB BANK
                            <p>

                            <p>78920011372 </p>
                            <p>SWIFT Code</p>
                            <p>PBBE MYKL XXX
                            <p>
                        </div>
                    </div>
                </div>


        </div>
        <p class="footer">Thank you for the business!</p>
    </div>
</div>
