<div class="navbar">
  <div class="left-element">
    <img href="/" src="https://aerodynenorthamerica.com/wp-content/uploads/2022/03/Icon-Wt.png" height="24">
    <span href="/" class="dron">DRON</span><span class="os">OS</span>
    <span href="/" class="seperator-left"></span>
    <span href="/" class="launchpad">Launchpad</span>
  </div>

  <img class="icon-search" src="/assets/navbar/search.png" height="24">
  <img class="icon-coin" src="/assets/navbar/coin.png" height="24">
  <span class="seperator-right"></span>
  <img class="icon-notification" src="/assets/navbar/notification.png" height="24">
  <img class="icon-avatar" src="http://zvhir.com/assets/zvhir.png" height="24">
  <img class="icon-arrow-down" src="/assets/navbar/arrow-down.png">

</div>
