import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'repeatModalSubmitValidation',
})
export class RepeatModalSubmitValidationPipe implements PipeTransform {
  transform(
    endDate: string,
    repeatModeSelect: string,
    onDayChecked: boolean,
    monthlyOnDayValue: number | string,
    onTheChecked: boolean,
    dayNumberSelect: string,
    weekdayNameSelect: string,
    weekDaysIdLength: number,
  ): boolean {
    if (
      !endDate ||
      (repeatModeSelect === 'Monthly' && onDayChecked && !monthlyOnDayValue) ||
      (repeatModeSelect === 'Monthly' && onTheChecked && (dayNumberSelect === 'Select type' || weekdayNameSelect === 'Select Day')) ||
      (repeatModeSelect === 'Weekly' && !weekDaysIdLength)
    ) {
      return true;
    }
    return false;
  }
}
