import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-utm-add-drone',
  templateUrl: './add-drone.component.html',
  styleUrls: ['./add-drone.component.scss']
})
export class AddDroneComponent {
  selectedImage: any;
  constructor ( private aModalService: NgbActiveModal,){
    
  }

 

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedImage = e.target?.result;
      };
      reader.readAsDataURL(file);
    }
  }
  closeModal() {
    this.aModalService.close();
  }
}
