<div class="header">
    <p>ADD NEW PAYLOAD</p>


    <!-- <img (click)="closeModal()" [src]="'/icon/icon_x.png' | assetUrl" alt="warning" class="icon-close"/> -->



</div>
<div class="body">


    <div class="upload-container row">
        <div class="col-6">
            <div class="image-preview">
                <img *ngIf="selectedImage" [src]="selectedImage" alt="Uploaded Image" />
            </div>
        </div>
        <div class="col-6 upload-section">
            <button type="button" class="upload-button" (click)="fileInput.click()">Choose file</button>
            <input type="file" #fileInput (change)="onFileSelected($event)" accept="image/*" id="fileInput" style="display: none;" />
        </div>




    </div>


    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputEmail4">Serial Number
            </label>
            <input type="text" class="form-control" placeholder=" Enter serial number">
        </div>
        <div class="form-group col-md-6">
            <label for="inputPassword4">Nickname (if any)</label>
            <input type="text" class="form-control" placeholder="Enter nickname">
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputEmail4">Brand
            </label>
            <input type="text" class="form-control" placeholder=" Enter serial number">
        </div>
        <div class="form-group col-md-6">
            <label for="inputPassword4">Model</label>
            <input type="text" class="form-control" placeholder="Enter nickname">
        </div>
    </div>
    <div class="form-group">
        <label for="inputAddress">Category
        </label>
        <input type="text" class="form-control" id="inputAddress" placeholder="Select category">
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputEmail4">Main Gimbal
            </label>
            <input type="text" class="form-control" placeholder="Select main gimbal">
        </div>
        <div class="form-group col-md-6">
            <label for="inputPassword4">Sensor</label>
            <input type="text" class="form-control col-form-label-lg" placeholder="Select sensor">
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputEmail4">Weight(g)
            </label>
            <input type="text" class="form-control" placeholder=" Enter weight">
        </div>
        <div class="form-group col-md-6">
            <label for="inputPassword4">Resolution</label>
            <input type="text" class="form-control" placeholder="Enter resolution">
        </div>
    </div>
    <div class="form-group">
        <label for="inputAddress">Key Features
        </label>
        <input type="text" class="form-control" id="inputAddress" placeholder="">
    </div>
   
</div>



<div class="footer">
    <button (click)="closeModal()"  class=" btn-cancel">Cancel</button>
    <button type="submit" class=" btn-save">Save</button>
</div>