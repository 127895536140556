import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MaximumAltitudeComponent } from '../modal-contents/maximum-altitude/maximum-altitude.component';
import { GlobeComponent } from '../globe/globe.component';
@Component({
  selector: 'app-utm-drop-pin',
  templateUrl: './drop-pin.component.html',
  styleUrls: ['./drop-pin.component.scss'],
})
export class DropPinComponent {
  @Input() modalState: any = 'pin';
  @Input() area: any;
  @Input() volume: any;
  @Input() distance: any;
  altitude: any = 120;
  radius: any = 500;
  edit: boolean = false;

  constructor(private modalService: NgbModal, private globe: GlobeComponent) {}

  removeArea(state: any) {
    const modalRef = this.modalService.open(MaximumAltitudeComponent, {
      centered: true,
      windowClass: 'modal-card',
      backdrop: 'static',
      size: 'md',
    });

    modalRef.componentInstance.state = state;

    modalRef.result.then((result) => {
      if (result == 'remove') {
        // if remove area
        this.globe.removeAllPopups();
        this.globe.createMission = false;
        this.globe.clearDraw();
        this.globe.clearLayer();
        this.globe.removeObjective();
        this.globe.altitude = 120;
        this.globe.radius = 500;
        this.globe.missionPanel = true;
      } else if (result == 'alert') {
        // if alert altitude
        this.globe.showObjective();
      }
    });
  }

  altitudeData(value: number) {
    if (value !== null) {
      if (value !== 0) {
        this.globe.altitude = value;
        this.altitude = value;
        this.globe.refreshMapLayers();
         if (value > 120){
          $('#warning').css({'color': 'rgba(255, 163, 70, 0.5)',});
         }
         else{
          $('#warning').css({'color': 'rgba(255, 255, 255, 0.4)',});
         }
      }
    }
  }

  radiusData(value: number) {
    if (value !== null) {
      if (value !== 0) {
        this.globe.radius = value;
        this.radius = value;
        this.globe.refreshMapLayers();
      } 
    }
  }

  confirm() {
    if (this.altitude <= 120) {
      this.globe.showObjective();
      this.edit = true;
    } else {
      this.removeArea('alert');
    }
  }

  editArea(){
    this.globe.showObjective();
    this.edit = false;
  }
}
