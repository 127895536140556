import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { state } from '@app/utility';
import { ApiService } from '../api-permit.service';
import moment from 'moment';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewReceiptComponent } from 'src/app/modal-contents/view-receipt/view-receipt.component';
import { IssuePermitComponent } from 'src/app/modal-contents/issue-permit/issue-permit.component';
import { EnvironmentService } from 'src/app/services/environment.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ViewPdfComponent } from '../modal-contents/view-pdf/view-pdf.component';
import { MissionService } from '../services/mission.service';
import { PermitService } from '../services/permit.service';
import { IPermitMission } from '../models/permit-mission.model';
import { isModelPopulated } from '../models/utils.mode';
import { IMission } from '../models/mission.model';
import { IPermit, IPermitIssues } from '../models/permit.model';
import { PermitTrackerService } from '../services/permit-tracker.service';
import { IPermitTracker, IPermitTrackerFilter } from '../models/permit-tracker.model';
import { RoleType } from '../enums/roleType.enum';

type STSParams = {
  accessKeyId: string;
  secretAccessKey: string;
  region: string;
  s3Bucket: string;
  s3Prefix: string;
  sessionToken?: string;
} | null;

@Component({
  selector: 'app-utm-application-tracker',
  templateUrl: './application-tracker.component.html',
  styleUrls: ['./application-tracker.component.scss'],
})
export class ApplicationTrackerComponent implements OnInit {
  pdfUrl!: SafeResourceUrl;
  @ViewChild('pdfModal') pdfModal: any;

  readonly RoleType = RoleType;
  fileSelected: any = null;
  filesize: any;
  imageName: any;
  dataetag: any;

  AWS_S3_BUCKET = 'sts1-bucket';

  AWS_KEY = 'workspace-64be821c04c3486120998051/';
  @Input() id: any; // call the input from component permit application
  searchText: any;
  showToast: boolean = false;

  constructor(
    private api: ApiService,
    private missionService: MissionService,
    private permitService: PermitService,
    private permitTrackerService: PermitTrackerService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
  ) {}

  missionHeader: string[] = ['Mission', 'Objective', 'Created Date', 'Applicant', 'Permit Type', 'Status', 'Permit'];
  permitList!: IPermit;
  missionList: any[] = [];
  trackerList: IPermitTracker[] = [];
  formattedDate: string = '';
  formattedTime: string = '';
  lastIndex: number = 0;
  sts!: STSParams;
  workspaceid: string = '';
  role: string = '';
  fileName: string = '';
  toastMessage: string = '';
  rejectModal: boolean = false;
  confirmApproveModal: boolean = false;
  rejectRemark: string = '';

  openModal(filename: any) {
    const modalRef = this.modalService.open(ViewPdfComponent, {
      centered: true,
      windowClass: 'view-receipt',
      backdropClass: 'view-receipt-static',
      backdrop: 'static',
      size: 'xl',
    });

    modalRef.componentInstance.pdfUrl = filename;
  }

  ngOnInit(): void {
    this.getUserRole();
    this.id = this.route.snapshot.paramMap.get('id');
    this.workspaceid = state.getUserInfo().member[0].workspace._id.toString();

    this.getPermitslist();
    this.getTrackerList();

    this.lastIndex = this.trackerList.length - 1;
  }

  getTrackerList() {
    const queryParams: IPermitTrackerFilter = { permitId: this.id };

    this.permitTrackerService.getAll(queryParams).subscribe(
      (response) => {
        this.trackerList = response.records;
      },
      (error: any) => {
        console.error('Error fetching permit data:', error);
      },
    );
  }

  getUserRole() {
    const role = state.getUserInfo().member[0].role.name;
    this.role = role;
  }

  viewInvoice() {
    this.router.navigate(['invoice', this.id]);
  }

  redirectToSummary(mission: any, permitId: string, applicationId: string) {
    this.api.setMissionPermitId(mission, permitId, applicationId);
    this.router.navigate(['/mission-summary']);
  }

  viewReceiptModal(filename: string, id: string) {
    const modalRef = this.modalService.open(ViewReceiptComponent, {
      centered: true,
      windowClass: 'view-receipt',
      backdropClass: 'view-receipt-static',
      backdrop: 'static',
      size: 'md',
    });

    modalRef.componentInstance.fileName = filename;
    modalRef.componentInstance.permitId = id;

    modalRef.result.then((result) => {
      if (result == 'confirm') {
        this.approveReceipt();
      } else if (result == 'rejected') {
        this.rejectReceipt();
      }
    });
  }

  checkPaymentStatus(status: string | undefined) {
    let badgeColor = '';
    switch (status) {
      case 'PAID':
        badgeColor = 'mission-status-badge-approved';
        break;
      case 'UNPAID':
        badgeColor = 'mission-status-badge-rejected';
        break;
      default:
        badgeColor = 'mission-status-badge';
    }
    return badgeColor;
  }

  approveReceipt() {
    this.confirmApproveModal = true;
    this.rejectModal = false;
  }

  cancelConfirm() {
    this.confirmApproveModal = false;
    this.rejectModal = false;
  }

  rejectReceipt() {
    this.rejectModal = true;
    this.confirmApproveModal = false;
  }

  approveRejectService(result: any) {
    if (result == 'confirm') {
      const body = {
        paymentApprovalStatus: 'VERIFIED',
        remarks: '',
      };

      this.permitService.paymentApproval(this.permitList!._id, body).subscribe((data) => {
        this.getPermitslist();
        this.getTrackerList();
      });
    } else {
      const body = {
        paymentApprovalStatus: 'RETURNED',
        remarks: this.rejectRemark,
      };

      this.permitService.paymentApproval(this.permitList!._id, body).subscribe((data) => {
        this.getPermitslist();
        this.getTrackerList();
      });
    }
    this.confirmApproveModal = false;
    this.rejectModal = false;
  }

  issuePermitUpload(missionId: string, applicationId: string) {
    const modalRef = this.modalService.open(IssuePermitComponent, {
      centered: true,
      windowClass: 'issue-permit',
      backdropClass: 'issue-permit-static',
      backdrop: 'static',
      size: 'md',
    });

    modalRef.result.then((result) => {
      if (result.status == 'confirm') {
        const body: IPermitIssues = {
          missionId: missionId,
          issuedPermitIds: result.data,
        };

        this.permitService.issuePermit(applicationId, body).subscribe((data) => {
          this.getPermitslist();
          this.getTrackerList();
        });
      }
    });
  }

  getFileName(item: any) {
    if (this.role != 'Human Validator' && item.issuedPermits == undefined) {
      return 'N/A';
    } else if (this.role == 'Human Validator' && item.issuedPermits == undefined) {
      return 'Issue Permits';
    } else {
      return 'attachment';
    }
  }

  getExactFileName(name: any) {
    const parts = name.split('/');
    const fileName = parts[parts.length - 1];
    return fileName;
  }

  getImageForTitle(title: string): string {
    switch (title) {
      case 'Payment':
        return '/icon/payment.svg';
      case 'Invoice Received':
        return '/icon/invoice.svg';
      case 'Payment Received':
        return '/icon/payment.svg';
      case 'Application Completed':
        return '/icon/apps-completed.svg';
      case 'Permit Released':
      case 'Permit Received':
        return '/icon/permit-released.svg';
      case 'Authority Review':
        return '/icon/bank.svg';
      case 'Application Submitted':
        return '/icon/submit.svg';
      case 'Application Received':
        return '/icon/cardreceive.svg';
      case 'JUPEM Approval':
      case 'CAAM Approval':
      case 'Internal Review':
        return '/icon/authority-icon.svg';
      default:
        return '';
    }
  }

  checkPayment(permit: any) {
    let status = '';
    if (permit.paymentStatus != undefined) {
      if (permit.paymentStatus == 'PAID' && typeof permit.paymentApprovalInfo == 'object') {
        status = 'PAID';
      } else if (permit.paymentStatus == 'PAID') {
        status = 'Waiting for Approval';
      } else {
        status = 'UNPAID';
      }
    }
    return status;
  }

  checkHumanValidator(title: string) {
    const keywords = ['Payment Received'];
    return keywords.some((keyword) => title.includes(keyword));
  }

  checkAuthority(title: string): boolean {
    // Keywords to check for
    const keywords = ['Application Received'];

    return keywords.some((keyword) => title.includes(keyword));
  }

  checkApplicant(title: string): boolean {
    // Keywords to check for
    const keywords = ['Payment', 'Invoice Received', 'Application Completed', 'Authority Review', 'Application Submitted', 'JUPEM Approval', 'CAAM Approval'];

    return keywords.some((keyword) => title.includes(keyword));
  }
  getPermitslist() {
    this.permitService.get(this.id).subscribe((permitList) => {
      this.permitList = permitList;

      this.getAllMissions(permitList!.missions);
      this.getSTSCredential();
    });
  }

  getAllMissions(missions: IPermitMission[]) {
    this.missionList = [];
    // missions[0]._id
    for (const item of missions) {
      if (!isModelPopulated<IMission>(item.missionId._id)) {
        this.missionService.get(item.missionId._id).subscribe(
          (data) => {
            const all = Object.assign(item, data);
            this.missionList.push(all);
          },
          (err) => console.log('this.missionList error', err),
        );
      }
    }
  }
  getSTSCredential() {
    this.sts = {
      accessKeyId: 'AKIAQSHXYQXJU5DM7AFG',

      secretAccessKey: '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',

      region: 'ap-southeast-1',

      s3Bucket: 'dronos-utm',

      s3Prefix: `permit-${this.permitList!._id}`,
    };
  }

  async s3_upload(file: File) {
    const client = new S3Client({
      region: this.sts!.region,

      credentials: {
        accessKeyId: this.sts!.accessKeyId,
        sessionToken: this.sts!.sessionToken,
        secretAccessKey: this.sts!.secretAccessKey,
      },
    });

    const fileExt = file.name.split('.')[1].toLowerCase();
    const params = {
      Bucket: this.sts!.s3Bucket,
      Key: this.sts!.s3Prefix + '/' + file.name, // This is the path where the file will be saved in S3
      Body: file,
      ContentType: fileExt == 'pdf' ? 'application/pdf' : `image/${fileExt}`,
      ContentDisposition: `inline; filename="${file.name}"`,
    };

    try {
      const result = await client.send(new PutObjectCommand(params));
      this.showSuccess(file.name);

      setTimeout(() => {
        this.makePayment(file.name);
      }, 500);
    } catch (err) {
      console.error('Error uploading file: ', err);
    }
  }

  makePayment(fileName: string) {
    const body = {
      paymentMethod: 'DEBIT',
      receiptId: fileName,
    };

    this.permitService.permitPayment(this.id, body).subscribe((data) => {
      this.showToast = true;
      this.toastMessage = 'You have successfully make payment for this permit';
    });
  }

  showSuccess(fileName: any) {
    this.showToast = true;
    this.toastMessage = `File ${fileName} has been uploaded successfuly`;

    setTimeout(() => (this.showToast = false), 5100);
  }
  convertTime(time: any) {
    const dateTimeString = time;
    const dateObject = moment(dateTimeString);
    this.formattedTime = dateObject.format('h:mmA');
    return this.formattedTime;
  }

  convertDate(time: any) {
    const dateTimeString = time;
    const dateObject = moment(dateTimeString);

    this.formattedDate = dateObject.format('DD MMM YYYY');

    return this.formattedDate;
  }

  currentPage = 1;
  itemsPerPage = 8;

  get paginatedMissions(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.missionList.slice(startIndex, endIndex);
  }

  get paginationRange(): string {
    const startRange = (this.currentPage - 1) * this.itemsPerPage + 1;
    const endRange = Math.min(this.currentPage * this.itemsPerPage, this.missionList.length);
    return `Showing ${startRange}-${endRange} of ${this.missionList.length} results`;
  }

  get pages(): number[] {
    const pageCount = Math.ceil(this.missionList.length / this.itemsPerPage);
    return Array.from({ length: pageCount }, (_, i) => i + 1);
  }

  changePage(pageNumber: number): void {
    this.currentPage = pageNumber;
  }

  goToNextPage(): void {
    if (this.currentPage < this.pages.length) {
      this.currentPage++;
    }
  }

  goToPreviousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
  selectedFiles: string[] = [];

  async handleSendFile() {
    await this.s3_upload(this.fileSelected);
  }

  onChangeFile(event: any) {
    this.filesize = event.target.files[0].size;
    this.imageName = event.target.files[0].name;
    this.fileSelected = event.target.files[0];
    this.handleSendFile();
  }

  async uploadFilesToS3(): Promise<void> {
    try {
      for (const fileName of this.selectedFiles) {
        const file = new File([fileName], fileName); // Create a File object
        // You can perform further actions or update UI based on the uploadResult
      }
    } catch (error) {
      console.error('Error uploading files to S3:', error);
    }
  }

  onFileSelect(event: any): void {
    const files: FileList = event.target.files;
    this.selectedFiles = [];

    for (let i = 0; i < files.length; i++) {
      this.selectedFiles.push(files[i].name);
    }
  }
}
