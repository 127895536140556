<div class="container">
    <!-- icon x -->
    <div class="row">
        <div class="col">
          <p class="txt-header">DELETE DRONE</p>
        </div>
        <div class="col text-right" style="text-align: right;">
          <img (click)="closeModal()" [src]="'/icon/icon_x.png' | assetUrl" alt="warning" class="icon-close"/>
        </div>
      </div>

    <hr style="color: #ffffff">

    <div class="img-warning">
        <img [src]="'/icon/Warning.png' | assetUrl" alt="warning" />
    </div>

    <p class="head-delete">Are you sure you want to remove this drone?</p>
    <p class="sub-delete">ID : {{id}}</p>

    <div class="button-container">
        <button type="save-draft" class="btn  mx-2 save-draft" (click)="closeModal()">Cancel</button>
        <button type="button btn-submit" class="btn btn-secondary btn-submit" (click)="confirmDelete(id)">Confirm</button>
    </div>

</div>