import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError,catchError,Subscription,timer, interval} from 'rxjs';
import { map,mergeMap,tap,concatMap, switchMap, filter, retryWhen, scan, delay } from 'rxjs/operators';
import {IMissionWorkflowDef, IMissionWorkflowRun} from '../models/create-mission-workflow.model';
import { Routes } from "./routes";

@Injectable({
  providedIn: 'root'
})
export class CreateMissionWorkflowService {
  missionDataResParam:any;
  localMissionID:any;
  localProjectID:any;
  localSiteID:any;
  workflowData:any;
  minutes?: number;
  subscription?: Subscription;

  constructor(private httpClient: HttpClient) { }

  getWorkflowDef(): Observable<IMissionWorkflowDef[]> {
    return this.httpClient.get<IMissionWorkflowDef[]>(Routes.WORKFLOW_DEF_URL).pipe(map(res => res));
  }
  
  runWorkflowForMission(data:IMissionWorkflowRun): Observable<IMissionWorkflowRun[]> {
    return this.httpClient.post<IMissionWorkflowRun[]>(Routes.WORKFLOW_RUN_URL,data);
  }

  startWorkflowforMission(data:any): Observable<IMissionWorkflowRun[]> {
   const workFlowStartUrl = Routes.WORKFLOW_RUN_URL+data.id+'/run';
    return this.httpClient.post<IMissionWorkflowRun[]>(workFlowStartUrl,data);
  }

  getWorkflowRun(data:any): Observable<IMissionWorkflowDef[]> {
    const workFlowUrl = Routes.WORKFLOW_RUN_URL+data.id;
    return this.httpClient.get<IMissionWorkflowDef[]>(workFlowUrl).pipe(map(res => res));
  }

}
