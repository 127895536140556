<div [ngClass]="state === 'success' ? 'toast-success active' : 'toast-error'" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-container">
        <div class="background-icon" [style.background-color]="state == 'success' ? 'rgba(6, 247, 161, 1)' : '#ff4646'">
            <img *ngIf="state == 'success'" [src]="'/icon/download_done_24px.svg'
            | assetUrl" alt="download-cloud">
            <img *ngIf="state != 'success'" [src]="'/icon/x.svg'
            | assetUrl" alt="download-cloud">
        </div>
       
        <div class="toastification-title">
          {{ message }}
        </div>
    </div>
  </div>