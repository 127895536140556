import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
@Component({
  selector: 'app-pilot-upsert',
  templateUrl: './pilot-create-edit.component.html',
  styleUrls: ['./pilot-create-edit.component.scss']
})

export class PilotCreateEditComponent {

  //for user
  first_name: string;
  last_name: string;
  profile_photo: number;
  role_id: number;
  organisation_id: number;
  email: string;
  password: string;
  remember_me_token: string;
  created_at: string;
  updated_at: string;

  phone: string;
  address: string;
  dob: string;
  country: string;
  username: string;


  //for pilot
  user_id: string;
  model_uas: string;
  competency: number;
  flightHours: number;
  lastFlownDate: number;
  currency: string;

  constructor(private http: HttpClient) {

    //for user
    this.first_name = ''
    this.last_name = '';
    this.profile_photo = 0;
    this.role_id = 0;
    this.organisation_id = 0;
    this.email = '';
    this.password = '';
    this.remember_me_token = '';
    this.created_at = '';
    this.updated_at = '';

    this.phone = '';
    this.address = '';
    this.dob = '';
    this.country = '';
    this.username = '';


    //for pilot
    this.user_id = ''
    this.model_uas = '';
    this.competency = 0;
    this.flightHours = 0;
    this.lastFlownDate = 0;
    this.currency = '';
  }

  onSubmit() {

    const dataUser = {
      first_name: this.first_name,
      last_name: this.last_name,
      profile_photo: this.profile_photo,
      role_id: 1,
      organisation_id: 1, //pilot role id == 2
      email: this.email,
      password: this.password,
      remember_me_token: this.remember_me_token,
      created_at: this.created_at,
      updated_at: this.updated_at,

      phone: this.phone,
      address: this.address,
      dob: this.dob,
      country: this.country,
      username: this.username,

    };

    const dataPilot = {
      user_id: 1,
      model_uas: this.model_uas,
      competency: this.competency,
      flightHours: this.flightHours,
      // lastFlownDate: this.lastFlownDate,
      lastFlownDate: "2024-02-28",
      currency: this.currency,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'bearer MQ.EUhBawEkT0dVSj3NrBYRVFYBillPx5c-v2KLqIhZQftYvU7CQza4jb6ABmlA'
      })
    };

    // create user
    this.http.post('http://127.0.0.1:3333/api/users', dataUser, httpOptions).subscribe(response => {
      // create pilot
      this.http.post('http://127.0.0.1:3333/api/pilots', dataPilot, httpOptions).subscribe(response => {
        alert('Pilot added successfully!')
      }, error => {
        console.log('Error while creating pilot', error);
      });
    }, error => {
      console.log('Error while creating user', error);
    });
  }
}
