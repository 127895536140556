import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { PermitService } from '../services/permit.service';
// import * as html2pdf from 'html2pdf.js';
const html2pdf = require('html2pdf.js');

interface jupemMissionData {
  missionId: string;
  location: string;
  period: string;
  totalDay: number;
  unitPrice: number;
  totalPrice: number;
}

interface caamMissionData {
  missionId: string;
  location: string;
  totalUA: string;
  unitPrice: number;
  totalPrice: number;
}

interface PermitData {
  applicationId: string;
  issuedDate: string;
  dueDate: string;
  billedTo: {
    name: string;
    address: string;
    companyRegistration: string;
    companyPhone: string;
    taxRegistration: string;
  };
  fromOrganisation: {
    name: string;
    address: string;
    companyRegistration: string;
    taxRegistration: string;
  };
  jupemMissionsData: jupemMissionData[];
  caamMissionsData: caamMissionData[];
  jupemTotalAmount: number;
  caamTotalAmount: number;
  processingFees: number;
  totalFees: number;
  invoiceId: string;
  permitType: string;
}

@Component({
  selector: 'app-utm-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  providers: [DatePipe],
})
export class InvoiceComponent implements OnInit {
  @ViewChild('exportDiv')
  exportDiv!: ElementRef;

  invoiceData: PermitData | undefined;
  SubPriceSumJupem: number = 0;
  SubPriceSumCaam: number = 0;
  missionId :any;

  constructor(private http: HttpClient, private datePipe: DatePipe, private route: ActivatedRoute, private router: Router, private permitService: PermitService) {}

  ngOnInit() {
    this.missionId = this.route.snapshot.paramMap.get('id');
    this.fetchDataFromApi(this.missionId);
  }

  fetchDataFromApi(missionId: string) {
    this.permitService.getInvoice(missionId).subscribe(
      (data) => {
        this.processInvoiceData(data);
        this.calculateTotalPriceSumCaam(data.caamMissionsData);
        this.calculateTotalPriceSumJupem(data.jupemMissionsData);
      },
      (error) => {
        console.error('API Error:', error);
        // Handle error cases here
      }
    );
  }

  private processInvoiceData(data: PermitData) {
    data.jupemMissionsData.forEach((mission) => {
      mission.period = this.formatDateRange(mission.period); // Format the period
    });

    this.invoiceData = data;
  }

  private calculateTotalPriceSumJupem(jupemMissionsData: jupemMissionData[]) {
    this.SubPriceSumJupem = jupemMissionsData.reduce(
      (sum, mission) => sum + mission.totalPrice,
      0
    );
  }

  private calculateTotalPriceSumCaam(caamMissionsData: caamMissionData[]) {
    this.SubPriceSumCaam = caamMissionsData.reduce(
      (sum, mission) => sum + mission.totalPrice,
      0
    );
  }

  private formatDateRange(dateRange: string): string {
    const [startDateString, endDateString] = dateRange.split(' - ');

    try {
      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);

      const formattedStartDate = this.datePipe.transform(startDate, 'yyyy-MM-dd HH:mm:ss') || '';
      const formattedEndDate = this.datePipe.transform(endDate, 'yyyy-MM-dd HH:mm:ss') || '';

      return `${formattedStartDate} - ${formattedEndDate}`;
    } catch (error) {
      console.error('Error parsing date range:', error);
      return 'Invalid Date Range';
    }
  }

  getPeriodDate(date: string) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const dateObj = new Date(date);

    return `${months[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
  }

  getPeriodTime(date: string) {
    const dateObj = new Date(date);

    let hours = dateObj.getHours();
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');

    const amOrPm = hours < 12 ? 'AM' : 'PM';
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes} ${amOrPm}`;
  }

  back(){
    this.router.navigate(['application-tracker', this.missionId]);
  }
  
  exportToPDF() {
    const content = document.getElementById('exportInvoice');

    const options = {
      margin: 10,
      filename: 'exported-document.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      pagebreak: { mode: 'avoid-all'}
    };

    html2pdf().from(content).save();

    // html2pdf().from(content).set(options).outputPdf((pdf: any) => {
    //   // You can save or display the PDF here
    //   // Example: pdf.save('exported-document.pdf');
    //   pdf.save('invoice-aerodyne.pdf');
    // });
  }
}
