import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment.service';
import { IPayload, IPayloadFilter } from '../models/payload.model';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl() + '/inventory/payloads';
const headers = new HttpHeaders({
  Authorization: 'Bearer ' + environmentService.getToken(),
});

@Injectable({
  providedIn: 'root',
})
export class PayloadService {
  constructor(private http: HttpClient) {}

  create(data: any): Observable<IPayload> {
    return this.http.post<IPayload>(`${apiUrl}`, data, { headers: headers });
  }

  getAll(queryParams?: IPayloadFilter): Observable<{ records: IPayload[]; meta: { nextCursor: string; prevCursor: string } }> {
    return this.http.get<{ records: IPayload[]; meta: { nextCursor: string; prevCursor: string } }>(apiUrl, { headers: headers, params: { ...queryParams } });
  }

  get(id: string): Observable<IPayload> {
    return this.http.get<IPayload>(`${apiUrl}/${id}`, { headers: headers });
  }

  update(id: string, data: Partial<IPayload>): Observable<IPayload> {
    return this.http.put<IPayload>(`${apiUrl}/${id}`, data);
  }

  delete(id: string): Observable<IPayload> {
    return this.http.delete<IPayload>(`${apiUrl}/${id}`, { headers: headers });
  }
}
