import { IMissionStatusColors } from "../shared/interface/mission.interface"

export const CONSTANTS_MISSION = {
  'droneImgTv': 'TV',
  'droneImgLos': 'LOS',
  'droneImgTD': 'TD',
  'droneImgNadir': 'Nadir',
  'droneImgSubfolder': 'DIF',
  'datasetCP': 'CP',
  'datasetDT': 'DT',
  'datasetDI': 'DI',
  'PROGRESS_PERCENTAGE': 100,
  'MULTIPART_FILE_SIZE': 100 * 1024 * 1024,
  'MULTIPART_CHUNK_SIZE': 50 * 1024 * 1024,
  'FILE_KBS': 1024,
  'CREATE_FOLDER_COUNT': 100000,
  'PIPELINE_SIZE': 8,
  'API_TIMER': 60 * 60 * 60,
}

export const MISSION_STATUS = {
  'DRAFT': 'DRAFT',
  'ACTIVE': 'ACTIVE'
}

export const NOTIFY_MSG = {
  'ERROR_CLASS': 'toaster-error',
  'SUCCESS_CLASS': 'toaster-success',
  'INVALID_FILE_XML': 'Please drop XML format only!',
  'INVALID_FILE_FORMAT': 'Invalid file format!',
  'INVALID_FOLDER_FORMAT': 'Invalid Folder Name!',
  'WORKFLOW_FAILED': 'Workflow run cannot be run due to failure.',
  'ERROR_UNDEFINED': 'undefined',
  'MISSION_CREATED': 'Mission created successfully.',
  'MISSION_FAILED': 'Mission not created.',
  'STREAM_MISSION_FAILED': 'Mission streaming details were not found.',
  'UPLOAD_FAILED': 'Credentials access failed. Please try again.',
  'UPLOAD_CAMERA_PARAM': 'Successfully uploaded Camera Parameters.',
  'UPLOAD_DIGITAL_TWIN': 'Successfully uploaded Digital Twin.',
  'UPLOAD_TOWER_VIEW': 'Successfully uploaded Tower View images.',
  'UPLOAD_LINE_SIGHT': 'Successfully uploaded Line of Sight images.',
  'UPLOAD_TOP_DOWN': 'Successfully uploaded Side View images.',
  'UPLOAD_NADIR': 'Successfully uploaded Nadir images.',
  'CLEAR_STREAM': 'Clear the current streaming video and continue.',
  'SUCCESS_GENERATE_URL': 'Successfully copying a generated URL.',
  'FAILURE_GENERATE_URL': 'Failed to generate stream url.',
}

export const DRONE_IMG_TYPE = {
  'DI_FTV': 'FTV',
  'DI_L1AD': 'L1AD',
  'DI_L1AV': 'L1AV',
  'DI_CV': 'CV',
  'DI_L1LOS': 'L1LOS',
  'DI_TBA': 'TBA',
  'DI_TBB': 'TBB',
  'DI_TBC': 'TBC',
  'TOWER_VIEW': 'tower_view',
  'LINE_OF_SIGHT': 'line_of_sight',
  'TOP_TO_DOWN': 'top_to_down',
  'SIDE_VIEW': 'side_view',
  'FROM_ABOVE_VIEW': 'from_above_view',
  'DIGITAL_TWIN': 'digital_twin',
  'DIGITAL_TWIN_TILE': 'digital_twin_tile',
  'ORIG_FULL_RES': 'orig_full_res'
}

export const WORKFLOW_STATUS = {
  'FAILED': 'failed',
  'COMPLETED': 'completed',
  'NOT_STARTED': 'not_started'
}

export const WORKFLOW_STEPS = {
  'RESOURCE_GENERATION': 'ResourceIngestion',
  'NEXUS_GENERATION': 'NexusMeshGeneration'
}

export const BG_TRANSPARENT = 'background: transparent; vertical-align:text-top;'

export const MISSION_STATUS_COLORS: IMissionStatusColors = {
  DRAFT: 'background: rgb(255, 163, 70, 0.3);   border: 1px solid rgb(255, 163, 70);',

  'PENDING APPROVAL': 'background: #00F0FF; color: black;',
  PENDING: 'background: #00F0FF; color: black;',
  COMPLETED: 'background: rgba(35, 190, 106, 0.50); color: white;',
  APPROVED: 'background: #66D6A6; color: black;',
  FLYING: 'background: #107B80; color: white;',
  REJECTED: 'background: #FF4646; color: white;',
  EXECUTED: 'background: #3C4EF2; color: white;',
  ACTIVE: 'background: rgb(102, 214, 166, 0.3); border: 1px solid rgb(102, 214, 166);'
}
