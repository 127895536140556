import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeletePayloadComponent } from '../delete-payload/delete-payload.component';
import { PayloadService } from 'src/app/services/payload.service';
import { getModel } from 'src/app/models/utils.mode';
import { ICatalogPayload } from 'src/app/models/payload.model';

@Component({
  selector: 'app-utm-payload-details',
  templateUrl: './payload-details.component.html',
  styleUrls: ['./payload-details.component.scss'],
})
export class PayloadDetailsComponent {
  @Input() id: any;
  payloadData: any = {};
  badgeList: string[] = [];
  constructor(private aModalService: NgbActiveModal, private modalService: NgbModal, private payloadService: PayloadService) {}

  ngOnInit() {
    this.getPayloadbyId(this.id);
  }

  getPayloadbyId(id: any) {
    this.payloadService.get(id).subscribe(
      (response) => {
        this.payloadData = response;
        this.badgeList = getModel<ICatalogPayload>(response!.catalogId)!.specs.keyFeatures;
      },
      (error) => {
        console.error('Error:', error);
      },
    );
  }

  insertBadges() {
    const badgesHtml = this.badgeList.map((badge) => `<span class="badge">${badge}</span>`).join('');
    return badgesHtml;
  }

  closeModal() {
    this.aModalService.close();
  }

  deletePayload() {
    const modalRef = this.modalService.open(DeletePayloadComponent, {
      centered: true,
      windowClass: 'add-new',
      backdropClass: 'add-new-static',
      backdrop: 'static',
    });
    modalRef.componentInstance.id = this.id;
  }
}
