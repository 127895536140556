import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'droneSpecsRange',
})
export class DroneSpecsRangePipe implements PipeTransform {
  transform(currentValue: string, maxValue: number): string {
    let currentData = +currentValue?.split(' ')[0];
    if (currentData) {
      const percent = (currentData / maxValue) * 100;
      return percent.toFixed(2) + '%';
    }
    return '0%';
  }
}
