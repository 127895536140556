<div #contex_btn class="button-container" *ngIf="isUserRolePresent()">
    <!-- <button class="main-button" (click)="toggleExpanded()"> -->
    <!-- <span class="plus-sign">+</span> -->

    <!-- </button> -->

    <div class="expanded-buttons-container" [class.show]="getExpandedClass()">
        <div class="expanded-button-document" data-bs-toggle="utm-tooltip" data-bs-title="Drop Pin" data-bs-placement="left" (mouseover)="enableTooltip($event)">
            <img class="expanded-button" (click)="triggerCenter()" [src]="'/context-button/droppin.svg' | assetUrl" alt="drop pin" />
        </div>
        <div class="expanded-button-droppin" data-bs-toggle="utm-tooltip" data-bs-title="Draw Polygon" data-bs-placement="left" (mouseover)="enableTooltip($event)">
            <img class="expanded-button" (click)="triggerDraw()" [src]="'/context-button/polygon.svg' | assetUrl" alt="polygon" />
        </div>
        <div class="expanded-button-polygon" data-bs-toggle="utm-tooltip" data-bs-title="Upload Mission Area" data-bs-placement="left" (mouseover)="enableTooltip($event)">
            <img class="expanded-button" (click)="triggerInputUpload()" [src]="'/context-button/upload.svg' | assetUrl" alt="upload mission area" />
        </div>
        <input class="hidden" type="file" id="upload-file" name="upload-file" accept=".kml, .kmz" ngf-max-size="2MB" (change)="handleFileInput($event)" (mouseover)="enableTooltip($event)" />
        <div class="expanded-button-upload" data-bs-toggle="utm-tooltip" data-bs-title="Upload Dataset" data-bs-placement="left" (mouseover)="enableTooltip($event)">
            <img class="expanded-button" (click)="missionDetails()" [src]="'/context-button/upload.svg' | assetUrl" alt="upload data set" />
        </div>
    </div>
    <div class="main-button" (click)="toggleExpanded()">
        <div class="main-button-plus" (click)="contex_btn.classList.toggle('active')">
            <img class="plus-sign" [src]="'/context-button/plus.svg' | assetUrl" alt="plus" />
        </div>
    </div>
</div>