import { Component, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ICreateMissionObjective, ICreateMissionProject, ICreateMissionSiteData } from '../../models/create-mission-dataset.model';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { MissionSharedService } from 'src/app/services/mission-shared.service';
import { UploadDatasetService } from 'src/app/services/upload-dataset.service';
import { CreateMissionService } from 'src/app/services/create-mission.service';

@Component({
  selector: 'app-create-mission',
  templateUrl: './create-mission.component.html',
  styleUrls: ['./create-mission.component.scss']
})
export class CreateMissionComponent implements OnInit, OnDestroy {

  UploadDatasetForm!: FormGroup;
  loadMissionDetail: boolean = false;
  loadUploadDataset: boolean = false;
  backToCreateMission: boolean = false;
  hidemission: boolean = true;
  isSubmitted = false;
  createMissionFormData: any;
  updateMissionFormData: any = [];
  getSiteID: any;
  missionProjects: ICreateMissionProject = {
    meta:{},
    records:[]
  };
  missionSiteAsset: ICreateMissionSiteData[] = [];
  missionSiteInfo: any = [];
  siteId!: string;
  missionProjectEvent!: Subscription;
  missionSiteEvent!: Subscription;
  missionSiteInfoEvent!: Subscription;
  hideMissionSection: boolean = false;
  isShowMissionConfirmation: boolean = false;
  selectedProjectId!: string;
  selectedAssetId!: string;
  canvasLink: string = "";
  isMissionCreated: boolean = false;
  isValidCanvasUrl: boolean = false;
  projectRequired: boolean = true;
  assetRequired: boolean = true;
  missionObjective!: Array<any>;
  missionObjectiveResponse: any;
  missionObjectiveList?: ICreateMissionObjective;
  isDetailUpdated: boolean = false;
  siteInfoList:any = [];
  sitePlaceholderImage!:string;
  hasSiteImg: boolean = false;

  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(private uploadDatasetFBuilder: FormBuilder, private router: Router, private missionSharedService: MissionSharedService, private uploadDatasetService:UploadDatasetService, private createMissionService:CreateMissionService) { }

  ngOnInit() {
    window.addEventListener('dronos:utm:missionFloating', (event: any) => {
      if (!this.hidemission) {
        this.isShowMissionConfirmation = true;
      }
      else {
        this.siteId = event.detail.site_id;
        const detail = event?.detail;
        this.hidemission = !detail.active ? 0 : detail.navItem
        this.missionProjectEvent = this.createMissionService.getMissionProjects().subscribe((response: ICreateMissionProject) => {
          this.missionProjects = response;
        });
        this.missionSiteEvent = this.createMissionService.getMissionSiteInfo(this.siteId).subscribe((response: ICreateMissionSiteData[]) => {
          this.missionSiteInfo = response;
          this.missionSiteAsset = this.missionSiteInfo.attachedAsset;
          if (typeof this.missionSiteInfo.siteImageDataResourceId !== "undefined") {
            this.hasSiteImg = true;
            this.missionSiteInfoEvent = this.uploadDatasetService.getImgFromDataResource(this.missionSiteInfo.siteImageDataResourceId).subscribe((response: ICreateMissionSiteData[]) => {
              this.siteInfoList = response;
              this.sitePlaceholderImage = this.siteInfoList.storage.files[0].preSignedUrl;
            });
          }
        });
        this.missionObjectiveResponse = this.createMissionService.getMissionObjective();
        this.isShowMissionConfirmation = false;
      }
    })
    this.getFormValues();
  }

  ngOnDestroy() {
    this.missionProjectEvent?.unsubscribe();
    this.missionSiteEvent?.unsubscribe();
    this.missionSharedService._selectedCPItems.unsubscribe();
    this.missionSharedService._selectedDTItems.unsubscribe();
    this.missionSharedService._selectedDIItems.unsubscribe();
    this.missionSharedService._selectedTVItems.unsubscribe();
    this.missionSharedService._selectedLosItems.unsubscribe();
    this.missionSharedService._selectedTDItems.unsubscribe();
    this.missionSharedService._selectedNadirItems.unsubscribe();
    this.missionSharedService._selectedObjective.unsubscribe();
  }

  getFormValues() {
    this.UploadDatasetForm = this.uploadDatasetFBuilder.group({
      missionProject: ['', [Validators.required]],
      missionAsset: ['', [Validators.required]],
    })
  }

  changeMissionProject(event: any) {
    this.projectRequired = true;
    this.selectedProjectId = event.target.value;
    this.isDetailUpdated = true
  }

  changeMissionAsset(event: any) {
    this.assetRequired = true;
    this.selectedAssetId = event.target.value;
  }

  validateCanvasBtn() {
    if (this.selectedProjectId && this.selectedAssetId && this.siteId) {
      this.isValidCanvasUrl = true;
      this.projectRequired = true;
      this.assetRequired = true;
      this.router.navigate(['/canvas'], {
        queryParams: {
          project_id: this.selectedProjectId,
          site_id: this.siteId,
          asset_id: this.selectedAssetId,
        },
      });
    }
    else {
      this.isValidCanvasUrl = false;
      this.projectRequired = false;
      this.assetRequired = false;
    }
  }

  initUploadDatasetComponent(value: any) {
    this.loadUploadDataset = value;
    this.backToCreateMission = false;
    if (value === false) {
      this.loadMissionDetail = false;
    }
  }
  backToParentComp(value: any) {
    this.backToCreateMission = value;
    this.loadUploadDataset = false;
    this.isMissionCreated = value;
    this.isDetailUpdated = false;
  }
  initHideMission() {
    this.isShowMissionConfirmation = true;
    this.isMissionCreated = false;
  }

  closeMission() {
    this.hidemission = true;
    this.isMissionCreated = false;
    this.isShowMissionConfirmation = false;
    this.loadUploadDataset = false;
    this.loadMissionDetail = false;
    this.isDetailUpdated = false;
    localStorage.removeItem('camera_param');
    localStorage.removeItem('drone_img');
    localStorage.removeItem('digital_twin');
    localStorage.removeItem('digital_twin_tile');
    localStorage.removeItem('missionDataParams');
  }

  stayAliveMission() {
    this.hidemission = false;
    this.isShowMissionConfirmation = false;
  }

  hideMission(value: boolean) {
    this.hidemission = value;
    this.isMissionCreated = false;
  }

  initHideMissionSection(value: boolean) {
    this.hideMissionSection = value;
    this.isMissionCreated = false;
  }

  onSumbitDataset() {
    this.isSubmitted = true;
    if (!this.UploadDatasetForm.valid) {
      this.isSubmitted = false;
    } else {
      const projectField = this.UploadDatasetForm.get('missionProject');
      const assetField = this.UploadDatasetForm.get('missionAsset');
      projectField?.valueChanges.subscribe(value => {
        this.isDetailUpdated = true
        if (this.updateMissionFormData.projectId !== 'undefined') {
          this.updateMissionFormData.push({
            projectId: value
          });
        }
      })
      assetField?.valueChanges.subscribe(value => {
        this.isDetailUpdated = true
        if (this.updateMissionFormData.assetId !== 'undefined') {
          this.updateMissionFormData.push({
            assetId: value
          });
        }
      });
      this.createMissionFormData = this.UploadDatasetForm.value;
      this.getSiteID = this.siteId;
      this.loadMissionDetail = true;
      this.missionObjectiveList = this.missionObjectiveResponse;
    }
  }
}
