import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICreateMissionObjective } from '../models/create-mission-dataset.model';
import {state} from "@app/utility";
@Injectable({
  providedIn: 'root'
})
export class MissionSharedService {

  constructor() { }
  _selectedObjective = new BehaviorSubject<string>('');
  _selectedCPItems = new BehaviorSubject<Array<string>>([]);
  _selectedDTItems = new BehaviorSubject<Array<string>>([]);
  _selectedDIItems = new BehaviorSubject<Array<string>>([]);
  _selectedTVItems = new BehaviorSubject<Array<string>>([]);
  _selectedLosItems = new BehaviorSubject<Array<string>>([]);
  _selectedTDItems = new BehaviorSubject<Array<string>>([]);
  _selectedNadirItems = new BehaviorSubject<Array<string>>([]);
  
  getUserInfo(){
    return state.getUserInfo();
  }
}
