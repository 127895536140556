import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component } from '@angular/core';
import { MapdataService } from '../../services/mapdata.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ViewPdfComponent } from 'src/app/modal-contents/view-pdf/view-pdf.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MissionService } from 'src/app/services/mission.service';
import { IDrone } from 'src/app/models/drone.model';
import { IPayload } from 'src/app/models/payload.model';
import { getModel } from 'src/app/models/utils.mode';
import { IMission, IMissionCoordinate } from 'src/app/models/mission.model';
import { DroneService } from 'src/app/services/drone.service';
import { PayloadService } from 'src/app/services/payload.service';
import { UserService } from 'src/app/services/user.service';
import { IUser, IUserPilotInfo } from 'src/app/models/user.model';
import { Observable, firstValueFrom, forkJoin, mergeMap } from 'rxjs';

type MissionExtend =
  | (Omit<IMission, 'workspaceId' | 'status' | 'permitInfo' | 'schedules' | 'area' | 'attachmentIds'> & {
      assocProject?: any;
      attachmentIds: any;
      schedule: any;
      area?: IMissionCoordinate[];
      coordinate: any;
      buffer?: boolean;
      center?: IMissionCoordinate;
    })
  | null;

// workspaceId, status, permitInfo, schedules

@Component({
  selector: 'app-utm-mission-details',
  templateUrl: './mission-details.component.html',
  styleUrls: ['./mission-details.component.scss'],
})
export class MissionDetailsComponent implements AfterViewInit {
  public missionId: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private mapDataService: MapdataService,
    private aModalService: NgbActiveModal,
    private modalService: NgbModal,
    private missionService: MissionService,
    private droneService: DroneService,
    private payloadService: PayloadService,
    private userService: UserService,
  ) {}
  missionDetails: any;
  // id: any;
  operators: IUser[] = [];
  showTabOperator = false;
  payloads: IPayload[] = [];
  drones: IDrone[] = [];
  showTabDrone = false;
  specificMission!: any;
  showCoordinate = false;
  showTabMission = true;
  activeTab = 1;
  currIdx: number | null = null;
  droneIdx: number | null = null;

  ngOnInit(): void {
    this.fetchMissionById(this.missionId);
    this.droneIdx = 0;
    this.currIdx = 0;
    this.specificMission = this.missionDetails;
  }

  setOperators() {
    for (let item of this.specificMission.scheduleIds) {
      this.fetchOperatorById(item.operatorList);
    }
  }

  setDrones() {
    for (let item of this.specificMission.scheduleIds) {
      this.fetchDronesById(item.droneList);
    }
  }

  setPayloads() {
    for (let item of this.specificMission.scheduleIds) {
      this.fetchPayloadById(item.payloadList);
    }
  }

  async setAttachments() {
    const allAttachments = await this.getAllAttachment(this.specificMission.attachmentIds);
    this.specificMission.allAttachments = allAttachments;
  }

  ngAfterViewInit(): void {
    // this.fetchMissionById(this.missionId);
  }
  checkCurrentIdx(idx: any) {
    if (this.showTabDrone) {
      let droneElement = document.getElementById('drone-chevron');
      if (droneElement) {
        if (this.droneIdx == idx) {
          droneElement.classList.add('collapsed');
          this.droneIdx = null;
        } else {
          droneElement.classList.remove('collapsed');
          this.droneIdx = idx;
        }
      }
    } else {
      let droneElement = document.getElementById('operator-chevron');
      if (droneElement) {
        if (this.currIdx == idx) {
          droneElement.classList.add('collapsed');
          this.currIdx = null;
        } else {
          droneElement.classList.remove('collapsed');
          this.currIdx = idx;
        }
      }
    }
  }

  openTabOperator() {
    this.showTabMission = false;
    this.showTabOperator = true;
    this.showTabDrone = false;
    this.activeTab = 2;
  }

  openTabMission() {
    this.showTabMission = true;
    this.showTabOperator = false;
    this.showTabDrone = false;
    this.activeTab = 1;
  }

  openCoordinateModal(center: any, area: any, id: string) {
    this.showCoordinate = true;
    setTimeout(() => {
      this.mapDataService.initializeMap('showmap', center.longitude, center.latitude, 9);
      setTimeout(() => {
        this.mapDataService.drawAreaBuffer(area.location, center, id);
      }, 100);
    }, 50);
  }

  closeCoordinateModal() {
    this.showCoordinate = false;
  }

  formatTime(date: Date): string {
    const dateObj = new Date(date);

    let hours = dateObj.getUTCHours();
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');

    const amOrPm = hours < 12 ? 'AM' : 'PM';

    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes} ${amOrPm}`;
  }

  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  async getAllAttachment(item: any) {
    let attachment = [];
    for (const url of item) {
      const response = await fetch(url, { method: 'HEAD' });
      if (response.ok) {
        const size = response.headers.get('Content-Length');
        const info = {
          url: url,
          size: this.formatBytes(size),
          name: url.split('/').pop(),
        };
        attachment.push(info);
      }
    }

    return attachment;
  }

  viewAttachment(url: string) {
    const modalRef = this.modalService.open(ViewPdfComponent, {
      centered: true,
      windowClass: 'view-receipt',
      backdropClass: 'view-receipt-static',
      backdrop: 'static',
      size: 'xl',
    });

    // modalRef.componentInstance.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(filename)
    modalRef.componentInstance.pdfUrl = url;
  }

  async fetchMissionById(id: string) {
    try {
      const data = await firstValueFrom(this.missionService.get(id));
      data.scheduleIds.forEach((scheduleData) => {
        scheduleData.payloadList = [...new Set(scheduleData.payloadList)];
      });
      this.specificMission = data;
      
      this.setOperators();
      this.setDrones();
      this.setPayloads();
      this.setAttachments();
    } catch (err) {
      console.log('fetchMissionById', err);
    }

    this.missionService
      .get(id)
      .pipe(mergeMap((data) => this.userService.get(data.scheduleIds[0].operatorList[0])))
      .subscribe((res) => {
        // console.log('res++++++++++++++++++++++', res);
      });
  }

  async fetchOperatorById(operatorList: string[]) {
    this.operators = [];
    try {
      const operatorData = await firstValueFrom(forkJoin(operatorList.map((operatorId) => this.userService.get(operatorId))));
      operatorData && (this.operators = operatorData);
    } catch (err) {
      console.log('fetchOperatorById Error', err);
    }
  }

  async fetchDronesById(droneList: string[]) {
    try {
      const droneData = await firstValueFrom(forkJoin(droneList.map((droneId) => this.droneService.get(droneId))));
      droneData && (this.drones = droneData);
    } catch (err) {
      console.log('fetchDronesById Error', err);
    }
  }

  async fetchPayloadById(payloadList: string[]) {
    this.payloads = [];
    try {
      const payloadData = await firstValueFrom(forkJoin(payloadList.map((payload) => this.payloadService.get(payload))));
      payloadData && (this.payloads = payloadData);
    } catch (err) {
      console.log('fetchPayloadById Error', err);
    }
  }

  closeModal() {
    this.aModalService.close();
  }

  openTabDrone() {
    this.showTabMission = false;
    this.showTabOperator = false;
    this.showTabDrone = true;
    this.activeTab = 3;
  }

  redirectToSchedule() {
    this.router.navigate(['/schedule'], { queryParams: { missionId: this.specificMission!._id } });
    this.closeModal();
  }

  getPilotInformation(pilotInformation: undefined | IUserPilotInfo[]): IUserPilotInfo[] {
    return pilotInformation as IUserPilotInfo[];
  }
}
