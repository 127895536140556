<div>
  <div (mouseleave)="showDropDown = false">
    <button class="drop-toggle btn flat" (click)="showDropDown = !showDropDown">
      @if (checkedList.length <= 0) {
        <span>{{ placeholder }}</span>
      } @else {
        <span>{{ currentSelected }}</span>
      }
      <i class="fa fa-angle-down"></i>
    </button>
    @if (showDropDown) {
      <div class="drop-show">
        @for (item of list; track item; let idx = $index) {
          <label>
            <input type="checkbox" [(ngModel)]="item.checked" (change)="getSelectedValue(item)" />
            <span class="checkmark"></span>
            <div [ngClass]="missionStatusColors[item.name.toUpperCase()] ? 'found-badge' : 'no-badge'" [style]="missionStatusColors[item.name.toUpperCase()] || bgTransparent">
              <div class="item">
                <span>{{ item.name }}</span>
              </div>
            </div>
          </label>
        }
      </div>
    }
  </div>
</div>