import { Component, OnInit ,AfterViewInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../api-permit.service';
import { GetObjectCommand, PutObjectCommand, S3Client } from '@aws-sdk/client-s3';


@Component({
  selector: 'app-utm-view-receipt',
  templateUrl: './view-receipt.component.html',
  styleUrls: ['./view-receipt.component.scss']
})
export class ViewReceiptComponent implements OnInit,AfterViewInit {

  public fileName: any
  public permitId: any

  constructor(private aModalService: NgbActiveModal, private api: ApiService){}
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  sts:any
  fileURL: any

  closeModal() {
    this.aModalService.close();
  }



  approveRejectReceipt(status: string) {
    this.aModalService.close(status)
  }

  ngAfterViewInit() {
    this.getSTSCredential()
    
    
  }

  getSTSCredential() {
    // this.api.getSTSCredential().subscribe((sts: { credentials: any; }) => {
      this.sts = {
        accessKeyId: 'AKIAQSHXYQXJU5DM7AFG',

        secretAccessKey: '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',

        region: 'ap-southeast-1',


        s3Bucket: 'dronos-utm',

        s3Prefix: `permit-${this.permitId}`,
      };
      this.getReceipt()

    // });
  }

  checkExtension() {
    const ext = this.fileName.split('.')[1]
    return ext.toLowerCase()
  }

   getReceipt() {
    const client = new S3Client({
      region: this.sts.region,

      credentials: {
        accessKeyId: this.sts.accessKeyId,
        sessionToken: this.sts.sessionToken,
        secretAccessKey: this.sts.secretAccessKey,
      },
    });

    const bucketHack = this.sts.s3Bucket.split("dronos-")[1]

    const params = {
        // Bucket: 'workspace-' + bucketHack,
        // Bucket: 'workspace-64be821c04c3486120998051',
        // Bucket:"sts1-bucket",
        Bucket: this.sts.s3Bucket,
        Key: this.sts.s3Prefix + '/' + this.fileName,
        // Key:"workspace-64be821c04c3486120998051" + '/' + 'sample.pdf'  // This is the path where the file will be saved in S3
    };

    const s3file ={
      name:params.Key,
      url:`https://${this.sts.s3Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`,
    }
    this.fileURL = s3file.url
    // this.fileURL = 'https://dronos-utm.s3.ap-southeast-1.amazonaws.com/permit-650aa5809d1b8dd48b7ac1e1/dummy-2.pdf'

    // try {
    //     const result = await client.send(new GetObjectCommand(params));
    // } catch (err) {
    //     console.error("Error uploading file: ", err);
    // }
  }


}
