import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
@Pipe({
  name: 'timeDifference',
})
export class TimeDifferencePipe implements PipeTransform {
  transform(startTime: string, endTime: string): any {
    const startMoment = moment(startTime, 'h:mm A');
    const endMoment = moment(endTime, 'h:mm A');

    const duration = moment.duration(endMoment.diff(startMoment));

    let hours = duration.hours();
    let minutes = duration.minutes();

    if (hours < 0 || minutes < 0) {
      const duration = moment.duration({ hours: hours || 0, minutes: minutes || 0 });
      const endTime = moment('24:00', 'HH:mm').subtract(duration);
      const formattedEndTime = endTime.format('HH:mm');
      const timeDifference = moment('24:00', 'HH:mm').subtract(formattedEndTime);
      hours = timeDifference.hours();
      minutes = timeDifference.minutes();
    }
    const time = {
      hours,
      minutes,
    };
    // return `${hours}h ${minutes}m`;
    // return `${hours}`;
    return time;
  }
}
