
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-drone-index',
  templateUrl: './drone-index.component.html',
  styleUrls: ['./drone-index.component.scss']
})
export class DroneIndexComponent implements OnInit {
  drones: any[] = [];

  constructor(private http: HttpClient) { }

  ngOnInit() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'bearer MQ.mK0nB6_hMJ26_fECXHa5bhRhz74B17jP-ORUfZoe6CzpsBqTvfCAS81ynnS-'
      })
    };

    // get all drones
    this.http.get('http://127.0.0.1:3333/api/drones', httpOptions).subscribe((response: any) => {
      this.drones = response;
    }, (error: any) => {
      console.log('Error while fetching drones', error);
    });
  }
}
