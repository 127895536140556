import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'droneListCheckMark',
})
export class DroneListCheckMarkPipe implements PipeTransform {
  transform(droneList: any, droneMatch: any): any {
    let val: boolean = false;
    droneList &&
      droneList.map((data: any) => {
        if (droneMatch._id == data._id) val = true;
      });
    return val;
  }
}
