import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-utm-maximum-altitude',
  templateUrl: './maximum-altitude.component.html',
  styleUrls: ['./maximum-altitude.component.scss']
})
export class MaximumAltitudeComponent {
  public state = ''

  constructor ( private aModalService: NgbActiveModal,){
    
  }

  closeModal() {
    this.aModalService.close();
  }

  confirm(status: any) {
    this.aModalService.close(status);
  }
}
