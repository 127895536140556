import { Injectable ,EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LiveService {
  addVideoEvent: EventEmitter<void> = new EventEmitter<void>();
  addVideoPlaybackEvent :EventEmitter<void>= new EventEmitter<void>();

  triggerAddVideo(MissionKeyPass:any) {
    this.addVideoEvent.emit(MissionKeyPass);
  }

  triggerPlayback(e:any){
    this.addVideoPlaybackEvent.emit(e);
  }

  constructor() { }
}
