import { Component } from '@angular/core';
import { state } from '@app/utility';
import { Router } from '@angular/router';
import { forkJoin, map } from 'rxjs';
import { IPermit, IPermitFilter } from '../models/permit.model';
import { PermitService } from '../services/permit.service';
import { getModel, isModelPopulated } from '../models/utils.mode';
import { IMission } from '../models/mission.model';
import { IPermitMission } from '../models/permit-mission.model';
import { UserService } from '../services/user.service';
import { RoleType } from '../enums/roleType.enum';
import { EnvironmentService } from 'src/app/services/environment.service';

const environmentService = new EnvironmentService();
const accessToken = environmentService.getAccessToken();


type PermitMissionExtend = IPermitMission & {
  location_area: string;
};

type PermitExtend = Omit<NonNullable<IPermit>, 'missions'> & {
  progress: any;
  approvedTotal: any;
  missions: PermitMissionExtend[];
};

interface AllStatus {
  all: number,
  pending: number,
  drafts: number,
  completed: number,
}
@Component({
  selector: 'app-utm-permit-applications',
  templateUrl: './permit-applications.component.html',
  styleUrls: ['./permit-applications.component.scss'],
})
export class PermitApplicationsComponent {
  // private geocoder: new Geocoder;
  countAllStatus: AllStatus = {
    all: 0,
    pending: 0,
    drafts: 0,
    completed: 0,
  };
  constructor(
    // private modalService: NgbModal,
    private router: Router,
    private permitService: PermitService,
    private userService: UserService,
  ) {
    // this.geocoder = new Geocoder();
  }
  readonly RoleType = RoleType
  activeTab: string = 'all'; // Default to the 'All' tab
  permitList: PermitExtend[] = [];
  originalList: PermitExtend[] = [];
  all = 0;
  drafts = 0;
  pending = 0;
  completed = 0;
  role: string = '';
  showToast = false;
  showTab(tabName: string) {
    this.activeTab = tabName;

    switch (tabName) {
      case 'all':
        this.permitList = this.originalList;
        break;
      case 'drafts':
        this.permitList = this.originalList.filter((item) => item!.applicationStatus == 'DRAFT');
        break;
      case this.role == RoleType.Owner || this.role == RoleType.Admin ? 'in-review' : 'pending':
        this.permitList = this.originalList.filter((item) => item!.applicationStatus == 'PENDING');
        break;
      case 'completed':
        this.permitList = this.originalList.filter((item) => item!.applicationStatus == 'COMPLETED');

        break;
    }
  }

  ngOnInit() {
    const role = state.getUserInfo().member[0].role.name;
    this.role = role;
    this.getAllPermits();

    // setTimeout(() => {
    //   this.showToast = true
    // }, 300);

    // setTimeout(() => this.showToast = false, 5100)
  }

  redirectToMissionList() {
    this.router.navigate(['/mission']);
  }

  getAllPermits() {
    const queryParams: IPermitFilter = { pageLimit: '1000' }

    this.permitService.getAll(queryParams).subscribe(async ({ records }) => {
      this.originalList = this.role !== 'Admin' && this.role !== 'Owner'
        ? records.filter((item: IPermit) => item?.applicationStatus !== 'DRAFT') as any
        : records;
      this.countAllStatus.all = this.originalList.length;
      this.countAllStatus.drafts = this.originalList.filter(item => item.applicationStatus === 'DRAFT').length;
      this.countAllStatus.pending = this.originalList.filter(item => item.applicationStatus === 'PENDING').length;
      this.countAllStatus.completed = this.originalList.filter(item => item.applicationStatus === 'COMPLETED').length;
      this.permitList = this.originalList;

      // Execute these functions in a more organized manner
      await Promise.all([
        this.getPermitStatus(),
        this.getArea(),
        this.getProgress()
      ]);
    });
  }

  getProgress() {
    this.permitList = this.permitList.map((item) => ({
      ...item,
      progress: this.returnProgress(item!.missions).progress,
      approvedTotal: this.returnProgress(item!.missions).total,
    }));
  }

  returnProgress(mission: any) {
    const approvedMission = mission.filter((item: { permitStatus: string }) => item.permitStatus == 'APPROVED');
    const progress = (approvedMission.length / mission.length) * 100;
    const all = {
      progress: progress,
      total: mission.length,
    };
    return all;
  }

  async getArea() {
    const promises: Promise<void>[] = []; // Collect all promises here
    this.permitList.forEach((missions) => {
      missions!.missions?.forEach((item) => {
        const points = getModel<IMission>(item.missionId)?.area.coordinate;
        // const points = item.missionId.area.coordinate;
        let totalLatitude = 0;
        let totalLongitude = 0;

        points!.forEach((point) => {
          totalLatitude += parseFloat(point.latitude);
          totalLongitude += parseFloat(point.longitude);
        });

        const lat = totalLatitude / points!.length;
        const lng = totalLongitude / points!.length;

        // Push the promise into the array
        promises.push(
          this.findArea(lat, lng).then((data) => {
            item.location_area = data
          }),
        );
      });
    });

    await Promise.all(promises);
  }

  async findArea(lat: number, lng: number) {
    try {
      const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessToken}`)
      const data = await response.json();
      const area = data.features[0]?.place_name || 'Area not found';
      return area;
    } catch (error) {
      console.error('Error fetching location:', error);
      return null;
    }
  }

  getPermitStatus() {
    const httpRequests = this.originalList.map((item) => {
      return this.permitService.hasAllPermitIssued(item!._id).pipe(
        map((data) => {
          return {
            ...item,
            permit_status: data.allPermitIssued ? 'Permit Released' : 'N/A',
          };
        }),
      );
    });

    forkJoin(httpRequests).subscribe({
      next: (data) => {
        this.originalList = data;
        this.getUsers()
      }
    })

  }

  getUsers() {
    // Create an array of observables
    const httpRequests = this.originalList.map((item) => {
      if(!isModelPopulated(item.applicantId)){
        // Return the observable, but use the `map` operator from RxJS to transform the response
        return this.userService.get(item!.applicantId).pipe(
          map((data) => {
            // Modify and return the item with new values
            return {
              ...item,
              fullname: data?.firstName || '' + data?.lastName,
              email: data?.email,
              // profile_pic: data.profilePicture
            };
          }),
        );
      }

      return [];
    });

    // Use forkJoin to execute all the observables
    forkJoin(httpRequests).subscribe((updatedList) => {
      this.originalList = updatedList;
      this.showTab('all');
    });
  }
}
