import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-airspace-informatics',
  templateUrl: './airspace-informatics.component.html',
  styleUrls: ['./airspace-informatics.component.scss'],
})
export class AirspaceInformaticsComponent implements OnChanges {
  airspaceInfermaticTap = 1;

  airSpc = {
    status: 'Pre-approval Required',
    type: 'Ground Risk',
    height: '450',
    color: 'rgba(255, 193, 7, 1)',
  };

  @Input('featureProperties') airspaceInformation: any;
  @Output() airSpaceInformatics = new EventEmitter<boolean>();

  ngOnChanges(changes: SimpleChanges): void {
  }
}
