import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { GlobeComponent } from './globe/globe.component';
import { ProfileComponent } from './profile/profile.component';
import { MissionComponent } from './mission/mission.component';
import { PilotIndexComponent } from './pilot-management/pilot-index/pilot-index.component';
import { PilotCreateEditComponent } from './pilot-management/pilot-create-edit/pilot-create-edit.component';
import { PilotDetailComponent } from './pilot-management/pilot-detail/pilot-detail.component';
import { DroneIndexComponent } from './drone-management/drone-index/drone-index.component';
import { SampleComponent } from './sample/sample.component';
import { DroneCreateEditComponent } from './drone-management/drone-create-edit/drone-create-edit.component';
import { DroneDetailComponent } from './drone-management/drone-detail/drone-detail.component';
import { TestComponent } from './test/test/test.component';
import { APP_BASE_HREF } from '@angular/common';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { ViewMissionComponent } from './view-mission/view-mission.component';
import { AirspaceInformaticsComponent } from './airspace-informatics/airspace-informatics.component';
import { TelemetryComponent } from './telemetry/telemetry.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { PermitApplicationsComponent } from './permit-applications/permit-applications.component';
import { NotificationComponent } from './notification/notification.component';
import { ApplicationTrackerComponent } from './application-tracker/application-tracker.component';
import { PaymentApprovalComponent } from './payment-approval/payment-approval.component';
import { MissionSummaryComponent } from './mission-summary/mission-summary.component';
import { DropPinComponent } from './drop-pin/drop-pin.component';
import { InventoryComponent } from './inventory/inventory.component';
import { resolveOperatorDronePayloadMissionData } from './shared/route-guard/route-guard';
import { PermitComponent } from './permit/permit.component';

const routes: Routes = [
  // authentication
  { path: 'login', component: LoginComponent },

  //dashboard
  { path: '', component: GlobeComponent,
    children: [
      { path: 'asset-management', component: GlobeComponent },
    ]
  },
  // { path: 'livestreams', component: GlobeComponent },
  { path: 'profile', component: ProfileComponent },

  // mission planning
  { path: 'mission', component: MissionComponent },
  { path: 'view-mission', component: ViewMissionComponent },

  //pilot management
  { path: 'pilot', component: PilotIndexComponent },
  { path: 'pilot/create', component: PilotCreateEditComponent },
  { path: 'pilot/:id', component: PilotDetailComponent },
  { path: 'pilot/:id/edit', component: PilotCreateEditComponent },

  //drone management
  { path: 'drone', component: DroneIndexComponent },
  { path: 'drone/create', component: DroneCreateEditComponent },
  { path: 'drone/:id', component: DroneDetailComponent },
  { path: 'drone/:id/edit', component: DroneCreateEditComponent },

  { path: 'airspace', component: AirspaceInformaticsComponent },

  //invoice
  { path: 'invoice/:id', component: InvoiceComponent },

  //permit-application
  { path: 'permit', component: PermitComponent},
  { path: 'permit-application', component: PermitApplicationsComponent },

  //authority
  { path: 'notification', component: NotificationComponent },

  //application-tracker

  { path: 'application-tracker/:id', component: ApplicationTrackerComponent },

  // Wildcard route to redirect to dev.dronos.ai
  // { path: '**', component: GlobeComponent },

  //application-tracker
  { path: 'payment-approval', component: PaymentApprovalComponent },
  //mission-summary
  { path: 'mission-summary', component: MissionSummaryComponent },

  { path: 'drop-pin', component: DropPinComponent },
  //inventory
  { path: 'inventory', component: InventoryComponent },

  // sample page
  { path: 'sample', component: SampleComponent },

  // test servec
  { path: 'test', component: TestComponent },

  // user schedule
  {
    path: 'schedule',
    loadChildren: () => import('./modules/user-schedule/user-schedule.module').then((m) => m.UserScheduleModule),
    resolve: { resolveOperatorDronePayloadMissionData },
  },
  // telemetry mapglobe
  { path: 'telemetry', component: TelemetryComponent },
  { path: ':missionId/:livestream', component: GlobeComponent },

  // empty route component
  { path: '**', component: EmptyRouteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
  exports: [RouterModule],
})
export class AppRoutingModule {}
