import { Injectable } from '@angular/core';
import { Paho } from 'ng2-mqtt/mqttws31';
import { HttpClient } from '@angular/common/http';
import { DroneService } from './services/drone.service';

@Injectable({
  providedIn: 'root'
})
export class MqttserviceService {

  private client: Paho.MQTT.Client;
  private messageCallback: ((topic: string, payload: string) => void) | null = null;

  constructor(private http:HttpClient, private droneService: DroneService) {
    this.client = new Paho.MQTT.Client('wss://emqx-mqtt.dronos.ai:8085/mqtt', 'mqttx_' + Math.random());

    
    this.client.onConnectionLost = (responseObject: Object) => {
      console.log('Connection lost: ' + JSON.stringify(responseObject));
      console.log(("connect"));
      
    };

    this.client.onConnectionLost = (responseObject: Object) => {
      console.log('Connection lost: ' + JSON.stringify(responseObject));
    };

    this.client.onMessageArrived = (message: Paho.MQTT.Message) => {
      if (this.messageCallback) {
        this.messageCallback(message.destinationName, message.payloadString);
      }
    };
    
  }

  setCallback(callback: (topic: string, payload: string) => void): void {
    this.messageCallback = callback;
  }

  connect(): void {
    this.client.connect({

      onSuccess: () => {
        console.log('Connected to MQTT server');
        this.client.subscribe('thing/product/1581F5FHB22A70020AD8/osd', {}); // Use an empty object as options
        const message = new Paho.MQTT.Message('Hello mqtt');
        message.destinationName = 'presence';
        this.client.send(message);
      },
      onFailure: (errorCode: any) => {
        console.log('Failed to connect to MQTT server: ' + errorCode);
      },
      useSSL:true

    });
  }

  sendMessage(topic: string, message: string): void {
    const mqttMessage = new Paho.MQTT.Message(message);
    mqttMessage.destinationName = topic;
    this.client.send(mqttMessage);
  }
  subscribeToTopic(topic: string): void {
    this.client.subscribe(topic, {});
  }

  disconnect(): void {
    this.client.disconnect();
  }

  getDroneSn(sn:string){
    console.log("get drone run");
    
    return this.droneService.get(sn);
  }

  

}
