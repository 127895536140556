import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteDroneComponent } from 'src/app/modal-contents/delete-drone/delete-drone.component';
import { DroneDetailsComponent } from 'src/app/modal-contents/drone-details/drone-details.component';
import { DroneService } from 'src/app/services/drone.service';

@Component({
  selector: 'app-utm-drone-list',
  templateUrl: './drone-list.component.html',
  styleUrls: ['./drone-list.component.scss'],
})
export class DroneListComponent {
  droneList: any;
  currentIdx = null;
  clickedIdx = null;
  itemsPerPage = 5;
  currentPage = 1;

  constructor(private modalService: NgbModal, private droneService: DroneService) {}

  ngOnInit() {
    this.getAllDrone();
  }

  getAllDrone() {
    this.droneService.getAll().subscribe(
      (response) => {
        this.droneList = response.records;
      },
      (error) => {
        console.error('Error:', error);
      },
    );
  }

  changePage(pageNumber: number): void {
    this.currentPage = pageNumber;
  }

  get pages(): number[] {
    const pageCount = Math.ceil(this.droneList.length / this.itemsPerPage);
    return Array.from({ length: pageCount }, (_, i) => i + 1);
  }

  goToNextPage(): void {
    this.currentIdx = null;
    this.clickedIdx = null;
    if (this.currentPage < this.pages.length) {
      this.currentPage++;
    }
  }

  get paginationRange(): string {
    const startRange = (this.currentPage - 1) * this.itemsPerPage + 1;
    const endRange = Math.min(this.currentPage * this.itemsPerPage, this.droneList.length);
    return `${startRange} - ${endRange} of ${this.droneList.length} records`;
  }

  goToPreviousPage(): void {
    this.currentIdx = null;
    this.clickedIdx = null;
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  getStatusStyle(status: string) {
    switch (status) {
      case 'Available':
        return { color: '#66D6A6' };
      case 'in Flight':
        return { color: '#FF4646' };
      case 'Scheduled':
        return { color: '#FFA346' };
      default:
        return {};
    }
  }

  droneDetails(id: any) {
    const modalRef = this.modalService.open(DroneDetailsComponent, {
      centered: true,
      windowClass: 'drone-details',
      backdropClass: 'drone-details',
      backdrop: 'static',
    });
    modalRef.componentInstance.id = id;
  }

  deleteDrone(id: any) {
    const modalRef = this.modalService.open(DeleteDroneComponent, {
      centered: true,
      windowClass: 'delete-drone',
      backdropClass: 'delete-drone-static',
      backdrop: 'static',
    });
    modalRef.componentInstance.id = id;
  }
}
