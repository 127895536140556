import { Injectable, Input } from '@angular/core';
import { HttpClientModule,HttpClient } from '@angular/common/http';
import { catchError, map, tap, throwError } from "rxjs";
import { Routes } from "./routes";
import { Observable, of } from 'rxjs';
import {ICreateDataResource, ICreateMissionSiteData} from '../models/create-mission-dataset.model';
import { CONSTANTS_MISSION, DRONE_IMG_TYPE } from 'src/app/constants/create-mission.const';
@Injectable({
  providedIn: 'root'
})
export class UploadDatasetService {
  missionDataResParam:any;
  resource_type:any;
  @Input() isHidemission!:boolean;
 
  constructor(private httpClient: HttpClient) { }

  public uploadDataset(src:any, file:any): Observable<any>{
    return of('ok');
  }
  public createDatasetFolder(folderName:any): Observable<any> {
    const rnd = Math.round(Math.random() * CONSTANTS_MISSION.CREATE_FOLDER_COUNT); 
    return of(rnd);
  }
  getCredentials = (resourceType:string): Observable<any> => {
    this.missionDataResParam = localStorage.getItem("missionDataParams");
    const dataParams = JSON.parse(this.missionDataResParam);
    const missionID = dataParams.missionId;
    const projectID = dataParams.missionProjectId;

    const getXmlResType = localStorage.getItem('camera_param');
    const getDTResType = localStorage.getItem('digital_twin');
    const getDIResType = localStorage.getItem('drone_img');
    const getDItileResType = localStorage.getItem('digital_twin_tile');

    if(resourceType === CONSTANTS_MISSION.datasetCP){
      this.resource_type = "cameraParams";
    }
    if(resourceType === CONSTANTS_MISSION.datasetDT){
      this.resource_type = "meshObj";
    }
    if(resourceType === DRONE_IMG_TYPE.DIGITAL_TWIN_TILE){
      this.resource_type = "meshObjTile";
    }
    if(resourceType === CONSTANTS_MISSION.datasetDI || resourceType === CONSTANTS_MISSION.droneImgTv || resourceType === CONSTANTS_MISSION.droneImgLos || resourceType === CONSTANTS_MISSION.droneImgTD || resourceType === CONSTANTS_MISSION.droneImgNadir){
      this.resource_type = "imageRGB";
    }
    const createDataResUrl = Routes.CREATE_DATA_RESOURCE_URL+'/'+"credentials?project_id="+projectID+"&mission_id="+missionID+"&resource_type="+this.resource_type+"";
    return this.httpClient.get(createDataResUrl).pipe(map(res => res));
  };

  createDataResources(data:ICreateDataResource): Observable<ICreateDataResource[]> {
    return this.httpClient.post<ICreateDataResource[]>(Routes.CREATE_DATA_RESOURCE_URL,data);
  }

  getImgFromDataResource(resourceId:number): Observable<ICreateMissionSiteData[]> {
    return this.httpClient.get<ICreateMissionSiteData[]>(Routes.CREATE_DATA_RESOURCE_URL+'/'+resourceId+'?pre_signed_url=true').pipe(map(res => res));
  }

  updateSitePlaceholder(siteId:number,siteInfo:Object): Observable<any>{
    return this.httpClient.patch<any>(Routes.UPDATE_SITE_INFO+"/"+siteId, siteInfo).pipe(
      catchError((err) => {
        return throwError(err);
      })
    )
  }
}
