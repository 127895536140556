import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { IMissionResponse } from '../interface/mission.interface';

@Pipe({
  name: 'inventoryAvailableCheck',
})
export class InventoryAvailableCheckPipe implements PipeTransform {
  transform(currentScheduleDate: any, allMissionList: IMissionResponse[], inventoryId: any, type: string) {
    const searchCriteria: any = {
      operator: (schedule: any) => schedule.operatorList.some((id: any) => inventoryId === id),
      drone: (schedule: any) => schedule.droneList.some((id: any) => inventoryId === id),
      payload: (schedule: any) => schedule.payloadList.some((id: any) => inventoryId === id),
      // payload: (schedule: any) => schedule.payloadList.some((payload: any) => payload.payloadIds.some((payloadId: any) => inventoryId === payloadId._id)),
    };

    if (
      allMissionList.some((record) =>
        record.scheduleIds.some((schedule) => currentScheduleDate === moment(schedule.startDate).format('DD MMM YYYY') && searchCriteria[type](schedule)),
      )
    ) {
      return true;
    }

    return false;
  }
}
