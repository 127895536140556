import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
  selector: 'app-drone',
  templateUrl: './drone-create-edit.component.html',
  styleUrls: ['./drone-create-edit.component.scss']
})
export class DroneCreateEditComponent {
  serialNumber: string;
  brandId: number;
  modelId: number;
  uas_type: string;
  weight: number;
  length: number;
  wingspan: number;
  powerSource: string;
  maxFlightDuration: number;
  maxSpeed: number;
  maxHeightCapable: number;
  maxDistanceCapable: number;
  frequencies: number;
  powerOutout: number;
  documents: string;

  constructor(private http: HttpClient) {
    this.serialNumber = '';
    this.brandId = 0;
    this.modelId = 0;
    this.uas_type = '';
    this.weight = 0;
    this.length = 0;
    this.wingspan = 0;
    this.powerSource = '';
    this.maxFlightDuration = 0;
    this.maxSpeed = 0;
    this.maxHeightCapable = 0;
    this.maxDistanceCapable = 0;
    this.frequencies = 0;
    this.powerOutout = 0;
    this.documents = '';
  }

  onSubmit() {

    const data = {
      serialNumber: this.serialNumber,
      brandId: this.brandId,
      modelId: this.modelId,
      uas_type: this.uas_type,
      weight: this.weight,
      length: this.length,
      wingspan: this.wingspan,
      powerSource: this.powerSource,
      maxFlightDuration: this.maxFlightDuration,
      maxSpeed: this.maxSpeed,
      maxHeightCapable: this.maxHeightCapable,
      maxDistanceCapable: this.maxDistanceCapable,
      frequencies: this.frequencies,
      powerOutout: this.powerOutout,
      documents: this.documents
    };
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'bearer MTY.K5HTtsLDJZAJXjREBAHby55XAjWGhHdhaxxRSq7h5vXj4zyV5exbCErcURie'
      })
    };

    this.http.post('http://127.0.0.1:3333/api/drones', data, httpOptions).subscribe(response => {
      alert('Drone added successfully!')
    }, error => {
      console.log('Error posting data', error);
    });
  }
}
