import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MISSION_STATUS,NOTIFY_MSG } from 'src/app/constants/create-mission.const';
import { ICreateMissionObjective, ICreateMissionPostData, IMissionDataParams } from '../../models/create-mission-dataset.model';
import { MissionSharedService } from 'src/app/services/mission-shared.service';
import { CreateMissionWorkflowService } from 'src/app/services/create-mission-workflow.service';
import { CreateMissionService } from 'src/app/services/create-mission.service';

@Component({
  selector: 'app-mission-detail',
  templateUrl: './mission-detail.component.html',
  styleUrls: ['./mission-detail.component.scss']
})
export class MissionDetailComponent implements OnInit {
  @Input() loadUploadDataset?: boolean;
  @Input() getCreateMissionForm1Data?: any;
  @Input() updateMissionForm1Data?: any;
  @Input() missionSiteID?: any;
  @Input() missionObjectiveList: any;
  @Input() isMissionCreatedCheck: boolean = false;
  @Input() isDetailUpdated: boolean = false;
  @Output() uploadDatasetComponent: EventEmitter<boolean> = new EventEmitter();

  createMissionForm!: FormGroup;
  isSubmitted = false;
  missionIdControl?: string;
  missionIDErrorMsg?: string;
  missionObjErrorMsg?: string;
  isDisabled: boolean = true;
  createMissionDataParam!: IMissionDataParams;
  toasterClass: string = "";
  toasterMsg: string = "";
  isShowToaster: boolean = false;
  isNextClick: boolean = false;
  missionCreatedTag: boolean = false;
  missionObjectiveRecord!: Array<any>;
  selectedObjectiveId!: string;
  selectedObjective!: string;
  missionId!: string;
  updatedMissionFormsDataArr?: Array<any>;
  missionObjectiveItems?: Array<any>;
  prevSelectedObjective!: string;

  constructor(private cmFBuilder: FormBuilder,private createMissionService:CreateMissionService, private missionSharedService: MissionSharedService,private missionWorkflowService: CreateMissionWorkflowService) { }

  ngOnInit(): void {
    this.getFormValues();
    this.selectedObjective = this.missionSharedService._selectedObjective.getValue() || '';
    this.selectedObjectiveId = this.selectedObjective;
    if (this.selectedObjectiveId !== '') {
      this.createMissionForm.controls["missionObj"].setValue(this.selectedObjectiveId);
    }
    this.missionObjectiveList
      .subscribe((response: ICreateMissionObjective[]) => {
        this.missionObjectiveList = response;
        this.missionObjectiveRecord = this.missionObjectiveList.records;
      });

  }

  changeMissionObjective(event: any) {
    this.isDetailUpdated = this.selectedObjectiveId !== event.target.value ? true : false;
    this.selectedObjectiveId = event.target.value;
    this.missionSharedService._selectedObjective.next(this.selectedObjectiveId);
  }
  getFormValues() {
    this.createMissionForm = this.cmFBuilder.group({
      missionObj: ['', [Validators.required]],
    })
  }
  initUploadDatasetComponent(loadUploadDataset: boolean) {
    if (loadUploadDataset === true) {
      this.uploadDatasetComponent.emit(true);
    }
    else {
      this.uploadDatasetComponent.emit(false);
    }
  }
  onSumbitInfo() {
    this.isNextClick = true;
    this.isSubmitted = true;

    if (!this.createMissionForm.valid) {
      this.isSubmitted = false;
    } else {
      if (!this.isMissionCreatedCheck) {
        let getLoggedInUserInfo = this.missionSharedService.getUserInfo();
        let workspaceID = getLoggedInUserInfo.member[0].workspace._id;
        let postMissionFormsData = {
          workspaceId: workspaceID,
          _id: '',
          siteId: this.missionSiteID, //missionName parameter need to be added for Sprint 1.8
          assetIds: this.getCreateMissionForm1Data.missionAsset,
          projectId: this.getCreateMissionForm1Data.missionProject,
          objective: this.createMissionForm.value.missionObj,
          status: MISSION_STATUS.DRAFT
        };
        this.createMissionService.postMissionData(postMissionFormsData)
          .subscribe((response: ICreateMissionPostData[]) => {
            let responseList: any = response;
            this.missionId = responseList.id;
            this.createMissionDataParam = {
              siteId: this.missionSiteID,
              missionId: this.missionId,
              missionProjectId: responseList.projectId,
              missionAssetId: responseList.assetIds
            }
            localStorage.setItem("missionDataParams", JSON.stringify(this.createMissionDataParam));
            this.isShowToaster = true;
            this.toasterClass = NOTIFY_MSG.SUCCESS_CLASS;
            this.toasterMsg = NOTIFY_MSG.MISSION_CREATED;
            setTimeout(() => {
              this.isShowToaster = false;
              this.loadUploadDataset = true;
              this.initUploadDatasetComponent(this.loadUploadDataset);
            }, 1000);

          }, error => {
            this.isShowToaster = true;
            this.toasterClass = NOTIFY_MSG.ERROR_CLASS;
            this.toasterMsg = NOTIFY_MSG.MISSION_FAILED;
            setTimeout(() => {
              this.isShowToaster = false;
            }, 3000);
          });
      }
      else {
        let assetId, projectID;
        this.updatedMissionFormsDataArr = [];
        let missionDataResParam: any = localStorage.getItem('missionDataParams');
        const dataParams = JSON.parse(missionDataResParam);
        let localMissionID = dataParams.missionId;
        let localProjectID = dataParams.missionProjectId;
        let localAssetID = dataParams.missionAssetId;

        for (let i = 0; i < this.updateMissionForm1Data.length; i++) {
          this.updatedMissionFormsDataArr = [];
          assetId = this.updateMissionForm1Data[i].assetId;
          projectID = this.updateMissionForm1Data[i].projectId;
          let prevAssetID = localAssetID.toString().replaceAll('"', '').replace(/[\[\]']+/g, '');

          this.updatedMissionFormsDataArr.push({
            assetIds: typeof assetId !== 'undefined' ? assetId.toString() : prevAssetID,
            projectId: typeof projectID !== 'undefined' ? projectID : localProjectID,
          })
        }

        this.updatedMissionFormsDataArr.push({
          objective: this.selectedObjectiveId
        })

        let updatedMissionData = {};
        for (let i = 0; i < this.updatedMissionFormsDataArr.length; i++) {
          Object.assign(updatedMissionData, this.updatedMissionFormsDataArr[i]);
        }

        if (this.isDetailUpdated) {
          this.createMissionService.updateMissionData(localMissionID, updatedMissionData)
            .subscribe((response: ICreateMissionPostData[]) => {
              let responseList: any = response;
              this.missionId = responseList.id;
              this.createMissionDataParam = {
                siteId: this.missionSiteID,
                missionId: localMissionID,
                missionProjectId: responseList.projectId,
                missionAssetId: JSON.stringify(responseList.assetIds)
              }
              localStorage.setItem("missionDataParams", JSON.stringify(this.createMissionDataParam));
            }, error => {
              this.isShowToaster = true;
              this.toasterClass = NOTIFY_MSG.ERROR_CLASS;
              this.toasterMsg = NOTIFY_MSG.MISSION_FAILED;
              setTimeout(() => {
                this.isShowToaster = false;
              }, 3000);
            });
        };

        this.loadUploadDataset = true;
        this.initUploadDatasetComponent(this.loadUploadDataset);

      }
    }
  }
}
