import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment.service';
import { state } from '@app/utility';
import { ICreatePermit, IPaymentApproval, IPermit, IPermitFilter, IPermitIssues, IPermitMissionApproval, IPermitPayment, IPermitPaymentPayload, IUpdatePermitMission } from '../models/permit.model';

const environmentService = new EnvironmentService();
const workspaceId = state.getUserInfo().member[0].workspace._id.toString();
const headers = new HttpHeaders({
  Authorization: 'Bearer ' + environmentService.getToken(),
  workspaceId: workspaceId,
  'Content-Type': 'application/json',
});

const apiUrl = environmentService.getApiUrl() + '/permits/permits';
const createPermitUrl = `${apiUrl}/create-application`;
const updateMissionUrl = `${apiUrl}/update-missions`;
const permitSubmissionUrl = `${apiUrl}/submission`;
const missionApprovalUrl = `${apiUrl}/mission-approval`;
const permitPaymentUrl = `${apiUrl}/make-payment`;
const paymentApprovalUrl = `${apiUrl}/payment-approval`;
const permitIssueUrl = `${apiUrl}/issue-permit`;
const isAllPermitIssuedUrl = `${apiUrl}/all-permit-issued`;
const reviewStatusUrl = `${apiUrl}/review-status`;
const permitInvoiceUrl = `${apiUrl}/get-invoice`;

@Injectable({
  providedIn: 'root'
})
export class PermitService {

  constructor(private http: HttpClient) {}

  create(data: ICreatePermit): Observable<IPermit> {
    return this.http.post<IPermit>(`${createPermitUrl}`, data, { headers: headers });
  }

  updateMissions(id: string, data: IUpdatePermitMission): Observable<IPermit> {
    return this.http.patch<IPermit>(`${updateMissionUrl}/${id}`, data, { headers: headers });
  }

  submission(id: string): Observable<IPermit> {
    return this.http.get<IPermit>(`${permitSubmissionUrl}/${id}`, { headers: headers });
  }

  missionApproval(id: string, data: IPermitMissionApproval): Observable<IPermit> {
    return this.http.patch<IPermit>(`${missionApprovalUrl}/${id}`, data, { headers: headers });
  }

  permitPayment(id: string, data: IPermitPaymentPayload): Observable<IPermit> {
    return this.http.patch<IPermit>(`${permitPaymentUrl}/${id}`, data, { headers: headers });
  }

  paymentApproval(id: string, data: IPaymentApproval): Observable<IPermit> {
    return this.http.patch<IPermit>(`${paymentApprovalUrl}/${id}`, data, { headers: headers });
  }

  issuePermit(id: string, data: IPermitIssues): Observable<IPermit> {
    return this.http.patch<IPermit>(`${permitIssueUrl}/${id}`, data, { headers: headers });
  }

  hasAllPermitIssued(id: string): Observable<any> {
    return this.http.get<any>(`${isAllPermitIssuedUrl}/${id}`, { headers: headers });
  }

  getAuthorityReviewStatus(id: string, queryParams: any): Observable<any> {
    return this.http.get<any>(`${reviewStatusUrl}/${id}`, { headers: headers,  params: queryParams });
  }

  getCertificates(id: string): Observable<any> {
    return this.http.get<any>(`https://dev-api.dronos.ai/data-resource/data-resource/${id}?pre_signed_url=true`, {headers:headers});
  }

  getInvoice(id: string): Observable<any> {
    return this.http.get<any>(`${permitInvoiceUrl}/${id}`, { headers: headers });
  }

  getAll(queryParams?: IPermitFilter): Observable<{ records: IPermit[]; meta: { nextCursor: string; prevCursor: string } }> {
    return this.http.get<{ records: IPermit[]; meta: { nextCursor: string; prevCursor: string } }>(`${apiUrl}`, { headers: headers, params: {...queryParams} });
  }

  get(id: string): Observable<IPermit> {
    return this.http.get<IPermit>(`${apiUrl}/${id}`, { headers: headers });
  }

  update(id: string, data: Partial<IPermit>): Observable<IPermit> {
    return this.http.patch<IPermit>(`${apiUrl}/${id}`, data);
  }

  delete(id: string): Observable<IPermit> {
    return this.http.delete<IPermit>(`${apiUrl}/${id}`, { headers: headers });
  }
}
