import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription, from, interval, lastValueFrom } from 'rxjs';
import {
  S3Client,
  PutObjectCommand,
  CreateMultipartUploadCommand,
  UploadPartCommand,
  CompleteMultipartUploadCommand,
} from '@aws-sdk/client-s3';
import { mergeMap, map, toArray, startWith, switchMap, takeWhile, takeUntil } from 'rxjs/operators';
import { UploadDatasetService } from 'src/app/services/upload-dataset.service';
import { ICreateDataResource } from '../../models/create-mission-dataset.model';
import {
  CONSTANTS_MISSION,
  MISSION_STATUS,
  NOTIFY_MSG,
  DRONE_IMG_TYPE,
  WORKFLOW_STATUS,
  WORKFLOW_STEPS,
} from 'src/app/constants/create-mission.const';
import { CreateMissionWorkflowService } from 'src/app/services/create-mission-workflow.service';
import {
  IMissionWorkflowDef,
  IMissionWorkflowRun,
} from 'src/app/models/create-mission-workflow.model';
import { MissionSharedService } from 'src/app/services/mission-shared.service';
import { CreateMissionService } from 'src/app/services/create-mission.service';

@Component({
  selector: 'app-upload-new-dataset',
  templateUrl: './upload-new-dataset.component.html',
  styleUrls: ['./upload-new-dataset.component.scss'],
})
export class UploadNewDatasetComponent {
  @Input() isHidemission!: boolean;
  @Input() backToCreateMission?: boolean;
  @Input() isDetailUpdated?: boolean;
  @Output() initBackToParentComponent: EventEmitter<boolean> =
    new EventEmitter();
  @Output() hideMission: EventEmitter<boolean> = new EventEmitter();
  @Output() hideMissionSection: EventEmitter<boolean> = new EventEmitter();
  private unsubscribeUploadData: Subject<void> = new Subject<void>();

  wrongFilesList = [];
  filesList = [];
  currentFolder: any;
  isUploadStart: boolean = false;
  isUploadDone: boolean = false;
  isUploadTVDone: boolean = false;
  isUploadLOSDone: boolean = false;
  isUploadTDDone: boolean = false;
  isUploadNadirDone: boolean = false;
  uploadedCPFiles: any = [];
  uniqueCPFiles: any;
  uploadedDTFiles: any = [];
  uniqueDTFiles: any;
  uploadedDIFiles: any = [];
  uniqueDIFiles: any;
  resourceData: any = [];
  fileList: any = [];
  fileTypeCat: any;
  progress: number = 0;
  piplineSize: number = CONSTANTS_MISSION.PIPELINE_SIZE;
  allFiles: any = 0;
  multipartFileChunkSize: number = CONSTANTS_MISSION.MULTIPART_CHUNK_SIZE; // 100MB (adjust as needed)
  fileSizeLineToCallMutipart: number = CONSTANTS_MISSION.MULTIPART_FILE_SIZE; // 100MB (adjust as needed)
  progressPercentage: Number = CONSTANTS_MISSION.PROGRESS_PERCENTAGE;
  missionDataResParam: any;
  createDataResSingle: any;
  localMissionID: any;
  localProjectID: any;
  localSiteID: any;
  localAssetID: any;
  uploadedTVImgDetail: any;
  uploadedTVImgList: any = [];
  uploadedLosImgDetail: any;
  uploadedLosImgList: any = [];
  uploadedTbImgDetail: any;
  uploadedTbImgList: any = [];
  uploadedNadirImgDetail: any;
  uploadedNadirImgList: any = [];
  isFolderClicked: boolean = false;
  tVInputDisable: boolean = false;
  losInputDisable: boolean = false;
  tdInputDisable: boolean = false;
  nadirInputDisable: boolean = false;
  fileBodyList: any = [];
  fileBodyDIList: any = [];
  fileRespList: any = [];
  getCredsDataMultiFile: any;
  getRelativePathMultiFile: any;
  multiFileInputData: any;
  currentFolderName: any;
  subFolderTvList: any = [];
  subFolderLosList: any = [];
  subFolderTbList: any = [];
  CONSTANTS = CONSTANTS_MISSION;
  isCameraParamDone: boolean = false;
  isDigitalTwinDone: boolean = false;
  isDroneImgDone: boolean = false;
  isSubmitted: boolean = false;
  workflowData: any;
  isWorkFlowStarted: boolean = false;
  isCallApi: boolean = false;
  toasterClass: string = '';
  toasterMsg: string = '';
  isShowToaster: boolean = false;
  isHideMissionSection: boolean = false;
  currentCPProgress: number = 0;
  subFolderNameTvList: any = [];
  subFolderNameLosList: any = [];
  subFolderNameTbList: any = [];
  isLargeFile: boolean = false;
  isAllDTFileDone: boolean = false;
  isMultiChunkFileDone: boolean = false;
  timeInterval?: Subscription;
  stopWorkflow: boolean = false;
  workflowSteps!: string;
  uniqueTVFiles: any = [];
  uniqueLosFiles: any = [];
  uniqueTdFiles: any = [];
  uniqueNadirFiles: any = [];
  selectedCPItem!: string[];
  selectedDTItem!: string[];
  selectedDIItem!: string[];
  selectedTVItem!: string[];
  selectedLosItem!: string[];
  selectedTdItem!: string[];
  selectedNadirItem!: string[];
  isShowProgressModal: boolean = false;
  tagsList!: string[];
  dataResourceIdList: Array<any> = [];
  dataResTag!: string;
  workspaceID!: string;
  isUploadCameraParamDone: boolean = false;
  isUploadDigitalTwinDone: boolean = false;
  dtInputDisable: boolean = false;
  cpInputDisable: boolean = false;
  currentDTProgress: number = 0;
  currentDIProgress: number = 0;
  cameraParamProgress: number = 0;
  digitalTwinProgress: number = 0;
  droneImgProgress: number = 0;
  isCameraParamUploadStart: boolean = false;
  isDigitalTwinUploadStart: boolean = false;
  isDroneImgUploadStart: boolean = false;
  getS3CredsDT: any;
  getS3CredsDI: any;
  s3CredentialsInfo: any;
  multis3CredentialsInfo: any;

  constructor(
    private uploadDatasetService: UploadDatasetService,
    private missionWorkflowService: CreateMissionWorkflowService,
    private router: Router, private missionSharedService: MissionSharedService,
    private createMissionService: CreateMissionService
  ) { }

  ngOnInit(): void {
    this.selectedCPItem = this.missionSharedService._selectedCPItems.getValue() || [];
    this.selectedDTItem = this.missionSharedService._selectedDTItems.getValue() || [];
    this.selectedDIItem = this.missionSharedService._selectedDIItems.getValue() || [];
    this.selectedTVItem = this.missionSharedService._selectedTVItems.getValue() || [];
    this.selectedLosItem = this.missionSharedService._selectedLosItems.getValue() || [];
    this.selectedTdItem = this.missionSharedService._selectedTDItems.getValue() || [];
    this.selectedNadirItem = this.missionSharedService._selectedNadirItems.getValue() || [];

    if ((this.selectedCPItem.length > 0 || this.selectedDTItem.length > 0 || this.selectedDIItem.length > 0 || this.selectedTVItem.length > 0 || this.selectedLosItem.length > 0 || this.selectedTdItem.length > 0 || this.selectedNadirItem.length > 0)
      && (!this.isUploadDone || !this.isUploadTVDone || !this.isUploadLOSDone || !this.isUploadTDDone || !this.isUploadNadirDone)) {

      if (this.selectedCPItem.length > 0) {
        let tmpCPItemList: any = [];
        tmpCPItemList = this.selectedCPItem;
        this.uniqueCPFiles = tmpCPItemList;
        this.isUploadDone = true;
        this.cpInputDisable = true;
        this.isUploadCameraParamDone = true;
        this.isCameraParamDone = true;
      }
      if (this.selectedDTItem.length > 0) {
        let tmpDTItemList: any = [];
        tmpDTItemList = this.selectedDTItem;
        this.uniqueDTFiles = tmpDTItemList;
        this.isUploadDone = true;
        this.isUploadDigitalTwinDone = true;
        this.dtInputDisable = true;
        this.isDigitalTwinDone = true;
      }
      if (this.selectedDIItem.length > 0) {
        let tmpDIItemList: any = [];
        tmpDIItemList = this.selectedDIItem;
        this.uniqueDIFiles = tmpDIItemList;
        this.isUploadDone = true;
      }

      if (this.selectedTVItem.length > 0) {
        let tmpTVItemList: any = [];
        tmpTVItemList = this.selectedTVItem;
        this.uniqueTVFiles = tmpTVItemList;
        this.isUploadTVDone = true;
        this.tVInputDisable = true;
      }

      if (this.selectedLosItem.length > 0) {
        let tmpLosItemList: any = [];
        tmpLosItemList = this.selectedLosItem;
        this.uniqueLosFiles = tmpLosItemList;
        this.isUploadLOSDone = true;
        this.losInputDisable = true;
      }

      if (this.selectedTdItem.length > 0) {
        let tmpTdItemList: any = [];
        tmpTdItemList = this.selectedTdItem;
        this.uniqueTdFiles = tmpTdItemList;
        this.isUploadTDDone = true;
        this.tdInputDisable = true;
      }

      if (this.selectedNadirItem.length > 0) {
        let tmpNadirItemList: any = [];
        tmpNadirItemList = this.selectedNadirItem;
        this.uniqueNadirFiles = tmpNadirItemList;
        this.isUploadNadirDone = true;
        this.nadirInputDisable = true;
      }
      if (
        this.isUploadTVDone &&
        this.isUploadLOSDone &&
        this.isUploadTDDone &&
        this.isUploadNadirDone
      ) {
        this.isDroneImgDone = true;
      }
    }
    let getLoggedInUserInfo = this.missionSharedService.getUserInfo();
    this.workspaceID = getLoggedInUserInfo.member[0].workspace._id;
  }

  ngOnDestroy() {
    this.unsubscribeUploadData.next();
    this.unsubscribeUploadData.complete();
  }

  backToCreateMissionComp(backToCreateMission: boolean) {
    if (!this.isUploadStart && !this.isWorkFlowStarted) {
      this.initBackToParentComponent.emit(backToCreateMission);
    }
    else {
      this.isShowProgressModal = true;
    }
  }
  stayOnUploadComponent() {
    this.isShowProgressModal = false;
  }

  validateFileType(getFile: any) {
    let newFile: any;
    let fileNames = [];
    let extObj = {};
    let isXMLAllowed = false,
      isJpgAllowed = false,
      isObjAllowed = false;
    for (newFile of Array.from(getFile)) {
      fileNames.push(newFile.name);

      const getExtension = newFile.name.substr(newFile.name.lastIndexOf('.'));
      //CP
      const allowedXmlRegx = /(\.xml)$/i;
      isXMLAllowed = allowedXmlRegx.test(getExtension);

      //DT
      const allowedObjRegx = /(\.xml|\.obj|\.mtl|\.jpg)$/i;
      isObjAllowed = allowedObjRegx.test(getExtension);

      //DI
      const allowedJpegRegx = /(\.jpeg|\.jpg|\.JPG)$/i;
      isJpgAllowed = allowedJpegRegx.test(getExtension);
    }
    return (extObj = {
      xml: isXMLAllowed,
      jpg: isJpgAllowed,
      obj: isObjAllowed,
    });
  }

  openFolderDblClick(getFiles: any, folderType: string) {
    this.uploadedTVImgList = [];
    this.uploadedLosImgList = [];
    this.uploadedTbImgList = [];
    this.uploadedNadirImgList = [];

    let allFiles = Array.from(getFiles);
    let subFolderName!: string;
    this.isFolderClicked = true;
    allFiles.forEach((uploadedFile: any) => {
      const filePathPieces = uploadedFile.webkitRelativePath.split('/');
      const folderNamePieces = filePathPieces.slice(0, -1);

      if (folderType === CONSTANTS_MISSION.droneImgTv) {
        if (folderNamePieces.length <= 2) {
          subFolderName = folderNamePieces.pop();
          this.subFolderNameTvList.push(subFolderName);
        }
        this.subFolderTvList = this.subFolderNameTvList.filter(
          (obj: any, index: any) =>
            this.subFolderNameTvList.findIndex((item: any) => item === obj) ===
            index,
        );
        this.currentFolderName = folderType;
        this.uploadedTVImgDetail = {
          src: window.URL.createObjectURL(uploadedFile),
          name: uploadedFile.name,
          size: this.formatFileBytes(uploadedFile.size),
          folderName: subFolderName,
        };
        this.uploadedTVImgList.push(this.uploadedTVImgDetail);
      }
      if (folderType === CONSTANTS_MISSION.droneImgLos) {
        if (folderNamePieces.length <= 2) {
          subFolderName = folderNamePieces.pop();
          this.subFolderNameLosList.push(subFolderName);
        }
        this.subFolderLosList = this.subFolderNameLosList.filter(
          (obj: any, index: any) =>
            this.subFolderNameLosList.findIndex((item: any) => item === obj) ===
            index,
        );
        this.currentFolderName = folderType;
        this.uploadedLosImgDetail = {
          src: window.URL.createObjectURL(uploadedFile),
          name: uploadedFile.name,
          size: this.formatFileBytes(uploadedFile.size),
          folderName: subFolderName,
        };
        this.uploadedLosImgList.push(this.uploadedLosImgDetail);
      }
      if (folderType === CONSTANTS_MISSION.droneImgTD) {
        if (folderNamePieces.length <= 2) {
          subFolderName = folderNamePieces.pop();
          this.subFolderNameTbList.push(subFolderName);
        }
        this.subFolderTbList = this.subFolderNameTbList.filter(
          (obj: any, index: any) =>
            this.subFolderNameTbList.findIndex((item: any) => item === obj) ===
            index,
        );
        this.currentFolderName = folderType;
        this.uploadedTbImgDetail = {
          src: window.URL.createObjectURL(uploadedFile),
          name: uploadedFile.name,
          size: this.formatFileBytes(uploadedFile.size),
          folderName: subFolderName,
        };
        this.uploadedTbImgList.push(this.uploadedTbImgDetail);
      }
      if (folderType === CONSTANTS_MISSION.droneImgNadir) {
        this.currentFolderName = folderType;
        this.uploadedNadirImgDetail = {
          src: window.URL.createObjectURL(uploadedFile),
          name: uploadedFile.name,
          size: this.formatFileBytes(uploadedFile.size),
          folderName: subFolderName,
        };
        this.uploadedNadirImgList.push(this.uploadedNadirImgDetail);
      }
    });
  }
  initBackTofolderView(value: boolean) {
    this.isFolderClicked = value;
  }
  formatFileBytes(bytes: any, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const kbs = CONSTANTS_MISSION.FILE_KBS;
    const decimalValue = decimals < 0 ? 0 : decimals;
    const sizes = [
      'Bytes',
      'KiB',
      'MiB',
      'GiB',
      'TiB',
      'PiB',
      'EiB',
      'ZiB',
      'YiB',
    ];

    const formattedFileResult = Math.floor(Math.log(bytes) / Math.log(kbs));

    return `${parseFloat(
      (bytes / Math.pow(kbs, formattedFileResult)).toFixed(decimalValue),
    )} ${sizes[formattedFileResult]}`;
  }

  async uploadFiles(files: any, fileType: any, parentFileType: any) {
    this.allFiles = Array.from(files);
    this.wrongFilesList = [];
    let isValidFile = this.validateFileType(files);
    this.isUploadDone = false;
    this.isUploadStart = false;
    let isValidDIFolder: boolean = false;

    if (fileType === CONSTANTS_MISSION.datasetDT && isValidFile.obj) {
      isValidFile.jpg = false;
      isValidFile.xml = false;
    }

    if (parentFileType === CONSTANTS_MISSION.datasetDI && isValidFile.jpg) {
      for (let i = 0; i < files.length; i++) {
        const fileRelativePath = files[i].webkitRelativePath.split('/');
        const filePieces = fileRelativePath.slice(0, -1);

        if (fileType === CONSTANTS_MISSION.droneImgTv) {
          isValidDIFolder = true;
          isValidFile.obj = false;
        } else if (fileType === CONSTANTS_MISSION.droneImgLos) {
          isValidDIFolder = true;
          isValidFile.obj = false;
        } else if (fileType === CONSTANTS_MISSION.droneImgTD) {
          isValidDIFolder = true;
          isValidFile.obj = false;
        } else {
          isValidDIFolder = true;
          isValidFile.obj = false;
        }
      }
    }

    if (
      fileType === CONSTANTS_MISSION.datasetDT &&
      isValidFile.obj &&
      !isValidFile.jpg &&
      !isValidFile.xml
    ) {
      localStorage.removeItem('camera_param');
      localStorage.removeItem('drone_img');

      let uploadedFiles = Array.from(files);
      uploadedFiles.map((item: any) => {
        let filePath = item.webkitRelativePath.split('/');
        if (filePath.length > 2) {
          localStorage.removeItem('digital_twin');
          localStorage.setItem('digital_twin_tile', DRONE_IMG_TYPE.DIGITAL_TWIN_TILE)
        }
        else {
          localStorage.removeItem('digital_twin_tile');
          localStorage.setItem('digital_twin', fileType)
        }
      });
      this.uploadDirectoryAssets(Array.from(files), fileType);
      this.isUploadStart = true;
      this.initCreateFolders(files, fileType);
    } else if (
      fileType === CONSTANTS_MISSION.datasetDI &&
      !isValidFile.obj &&
      isValidFile.jpg &&
      !isValidFile.xml
    ) {
      localStorage.removeItem('camera_param');
      localStorage.removeItem('digital_twin');
      localStorage.removeItem('digital_twin_tile');
      localStorage.setItem('drone_img', fileType);
      this.uploadDirectoryAssets(Array.from(files), fileType);
      this.isUploadStart = true;
      this.initCreateFolders(files, fileType);
    } else if (
      (fileType === CONSTANTS_MISSION.droneImgTv ||
        fileType === CONSTANTS_MISSION.droneImgLos ||
        fileType === CONSTANTS_MISSION.droneImgTD ||
        fileType === CONSTANTS_MISSION.droneImgNadir) &&
      !isValidFile.obj &&
      isValidFile.jpg &&
      !isValidFile.xml &&
      parentFileType === CONSTANTS_MISSION.datasetDI &&
      isValidDIFolder
    ) {
      localStorage.removeItem('camera_param');
      localStorage.removeItem('digital_twin');
      localStorage.removeItem('digital_twin_tile');
      localStorage.setItem('drone_img', parentFileType);
      this.createSubFoldersDI(Array.from(files), fileType);
      this.uploadDirectoryAssets(Array.from(files), fileType);
      this.isUploadStart = true;
    } else {
      this.isShowToaster = true;
      this.toasterClass = NOTIFY_MSG.ERROR_CLASS;
      this.toasterMsg = NOTIFY_MSG.INVALID_FILE_FORMAT;
      setTimeout(() => {
        this.isShowToaster = false;
      }, 3000);
    }
  }

  createSubFoldersDI(filesArray: File[], fileType: any) {
    if (fileType == CONSTANTS_MISSION.droneImgTv) {
      this.uniqueTVFiles.push(filesArray);
      this.missionSharedService._selectedTVItems.next(this.uniqueTVFiles);
    }
    if (fileType == CONSTANTS_MISSION.droneImgLos) {
      this.uniqueLosFiles.push(filesArray);
      this.missionSharedService._selectedLosItems.next(this.uniqueLosFiles);
    }
    if (fileType == CONSTANTS_MISSION.droneImgTD) {
      this.uniqueTdFiles.push(filesArray);
      this.missionSharedService._selectedTDItems.next(this.uniqueTdFiles);
    }
    if (fileType == CONSTANTS_MISSION.droneImgNadir) {
      this.uniqueNadirFiles.push(filesArray);
      this.missionSharedService._selectedNadirItems.next(this.uniqueNadirFiles);
    }
  }

  async uploadDirectoryAssets(
    filesArray: File[],
    fileType: any,
    retryAttempt = 0,
  ) {
    this.fileBodyDIList = [];
    this.droneImgProgress = 0;

    if ((this.isDigitalTwinUploadStart && !this.isDigitalTwinDone && !this.isDroneImgUploadStart) || (!this.isDroneImgUploadStart && this.isDigitalTwinDone)) this.s3CredentialsInfo = [];
    if (fileType !== CONSTANTS_MISSION.droneImgTv && fileType !== CONSTANTS_MISSION.droneImgLos && fileType !== CONSTANTS_MISSION.droneImgTD && fileType !== CONSTANTS_MISSION.droneImgNadir) {
      this.s3CredentialsInfo = [];
      this.isDigitalTwinUploadStart = true;
      this.isMultiChunkFileDone = false;
    } else {
      this.isDroneImgUploadStart = true;
    }

    if (retryAttempt == 2) {
      return;
    }
    let retryFiles: File[] = [];
    if (typeof this.s3CredentialsInfo === 'undefined' ||
      (this.s3CredentialsInfo === undefined || this.s3CredentialsInfo.length == 0)) {
      this.s3CredentialsInfo = await this.getS3Credentials(fileType);
    }

    const client = new S3Client({
      region: this.s3CredentialsInfo.credentials.region,
      credentials: {
        accessKeyId: this.s3CredentialsInfo.credentials.accessKeyId,
        secretAccessKey: this.s3CredentialsInfo.credentials.secretAccessKey,
        sessionToken: this.s3CredentialsInfo.credentials.sessionToken,
      },
    })
    const url$ = from([
      ...this.getAllFilesArray(filesArray, this.s3CredentialsInfo, fileType),
    ]);

    const pipFunc = async (urls: any) => {
      await urls
        .pipe(
          mergeMap(async (param: any) => {
            if (param.Body?.size > this.fileSizeLineToCallMutipart) {
              this.isLargeFile = true;
              this.multiFileInputData = {
                Body: param.Body,
                Bucket: this.s3CredentialsInfo.credentials.s3Bucket,
                Key: this.s3CredentialsInfo.credentials.s3Prefix + '/' + param.Body.name,
                tag: param.Body.webkitRelativePath,
                tagList: param.tags
              };
              const multiUploadResp = await this.multiPartFileUpload(
                this.s3CredentialsInfo,
                param,
                client,
                this.s3CredentialsInfo.credentials.s3Bucket,
                this.s3CredentialsInfo.credentials.s3Prefix,
                this.multiFileInputData,
                retryFiles
              );
              return multiUploadResp;
            }
            const uploadResp = await from(
              client
                .send(new PutObjectCommand(param))
                .then((resp) => {
                  let relativepath = param.Key.split('/').slice(0, -1).join('/');
                  const input = {
                    Body: param.Body,
                    Bucket: this.s3CredentialsInfo.credentials.s3Bucket,
                    ETag: resp.ETag,
                    Key: param.Key,
                    tag: param.Body.webkitRelativePath,
                    tagList: param.tags
                  };
                  if (fileType === CONSTANTS_MISSION.droneImgTv) {
                    this.droneImgProgress = this.droneImgProgress > CONSTANTS_MISSION.PROGRESS_PERCENTAGE ? Math.abs(this.droneImgProgress - CONSTANTS_MISSION.PROGRESS_PERCENTAGE) : this.droneImgProgress;
                    this.fileBodyDIList = [];
                    this.fileBodyDIList.push(input);
                    this.tVInputDisable = true;
                    this.droneImgProgress +=
                      (1 / this.allFiles.length) *
                      CONSTANTS_MISSION.PROGRESS_PERCENTAGE;
                    this.currentDIProgress = this.droneImgProgress > CONSTANTS_MISSION.PROGRESS_PERCENTAGE ? CONSTANTS_MISSION.PROGRESS_PERCENTAGE : this.droneImgProgress;
                    this.createMultiDataResource(
                      this.s3CredentialsInfo,
                      this.fileBodyDIList
                    );
                  } else if (fileType === CONSTANTS_MISSION.droneImgLos) {
                    this.droneImgProgress = this.droneImgProgress > CONSTANTS_MISSION.PROGRESS_PERCENTAGE ? Math.abs(this.droneImgProgress - CONSTANTS_MISSION.PROGRESS_PERCENTAGE) : this.droneImgProgress;
                    this.fileBodyDIList = [];
                    this.fileBodyDIList.push(input);
                    this.losInputDisable = true;
                    this.droneImgProgress +=
                      (1 / this.allFiles.length) *
                      CONSTANTS_MISSION.PROGRESS_PERCENTAGE;
                    this.currentDIProgress = this.droneImgProgress > CONSTANTS_MISSION.PROGRESS_PERCENTAGE ? CONSTANTS_MISSION.PROGRESS_PERCENTAGE : this.droneImgProgress;
                    this.createMultiDataResource(
                      this.s3CredentialsInfo,
                      this.fileBodyDIList
                    );
                  } else if (fileType === CONSTANTS_MISSION.droneImgTD) {
                    this.droneImgProgress = this.droneImgProgress > CONSTANTS_MISSION.PROGRESS_PERCENTAGE ? Math.abs(this.droneImgProgress - CONSTANTS_MISSION.PROGRESS_PERCENTAGE) : this.droneImgProgress;
                    this.fileBodyDIList = [];
                    this.fileBodyDIList.push(input);
                    this.tdInputDisable = true;
                    this.droneImgProgress +=
                      (1 / this.allFiles.length) *
                      CONSTANTS_MISSION.PROGRESS_PERCENTAGE;
                    this.currentDIProgress = this.droneImgProgress > CONSTANTS_MISSION.PROGRESS_PERCENTAGE ? CONSTANTS_MISSION.PROGRESS_PERCENTAGE : this.droneImgProgress;
                    this.createMultiDataResource(
                      this.s3CredentialsInfo,
                      this.fileBodyDIList
                    );
                  } else if (fileType === CONSTANTS_MISSION.droneImgNadir) {
                    this.droneImgProgress = this.droneImgProgress > CONSTANTS_MISSION.PROGRESS_PERCENTAGE ? Math.abs(this.droneImgProgress - CONSTANTS_MISSION.PROGRESS_PERCENTAGE) : this.droneImgProgress;
                    this.isLargeFile = false;
                    this.fileBodyDIList = [];
                    this.fileBodyDIList.push(input);
                    this.nadirInputDisable = true;
                    this.droneImgProgress +=
                      (1 / this.allFiles.length) *
                      CONSTANTS_MISSION.PROGRESS_PERCENTAGE;
                    this.currentDIProgress = this.droneImgProgress > CONSTANTS_MISSION.PROGRESS_PERCENTAGE ? CONSTANTS_MISSION.PROGRESS_PERCENTAGE : this.droneImgProgress;
                    this.createMultiDataResource(
                      this.s3CredentialsInfo,
                      this.fileBodyDIList
                    );
                  }
                  else {
                    this.fileBodyList.push(input);
                    this.dtInputDisable = true;
                    this.isDigitalTwinUploadStart = true;
                    this.digitalTwinProgress +=
                      (1 / this.allFiles.length) *
                      CONSTANTS_MISSION.PROGRESS_PERCENTAGE;
                    this.currentDTProgress = this.digitalTwinProgress > CONSTANTS_MISSION.PROGRESS_PERCENTAGE ? CONSTANTS_MISSION.PROGRESS_PERCENTAGE : this.digitalTwinProgress;
                  }
                  return resp;
                })
                .catch(() => {
                  retryFiles.push(param);
                }),
            ).toPromise();
          }, this.piplineSize),
          takeUntil(this.unsubscribeUploadData),
          map((response) => { }),
          toArray(),
        )
        .toPromise();

      switch (fileType) {
        case CONSTANTS_MISSION.droneImgTv:
          this.isUploadTVDone = true;
          this.isShowToaster = true;
          this.toasterClass = NOTIFY_MSG.SUCCESS_CLASS;
          this.toasterMsg = NOTIFY_MSG.UPLOAD_TOWER_VIEW;
          setTimeout(() => {
            this.isShowToaster = false;
          }, 5000);
          break;
        case CONSTANTS_MISSION.droneImgLos:
          this.isUploadLOSDone = true;
          this.isShowToaster = true;
          this.toasterClass = NOTIFY_MSG.SUCCESS_CLASS;
          this.toasterMsg = NOTIFY_MSG.UPLOAD_LINE_SIGHT;
          setTimeout(() => {
            this.isShowToaster = false;
          }, 5000);
          break;
        case CONSTANTS_MISSION.droneImgTD:
          this.isUploadTDDone = true;
          this.isShowToaster = true;
          this.toasterClass = NOTIFY_MSG.SUCCESS_CLASS;
          this.toasterMsg = NOTIFY_MSG.UPLOAD_TOP_DOWN;
          setTimeout(() => {
            this.isShowToaster = false;
          }, 5000);
          break;
        case CONSTANTS_MISSION.droneImgNadir:
          this.isUploadNadirDone = true;
          this.isShowToaster = true;
          this.toasterClass = NOTIFY_MSG.SUCCESS_CLASS;
          this.toasterMsg = NOTIFY_MSG.UPLOAD_NADIR;
          setTimeout(() => {
            this.isShowToaster = false;
          }, 5000);
          break;
        case CONSTANTS_MISSION.datasetDT:
          this.isAllDTFileDone = true;
          if (!this.isLargeFile || this.isMultiChunkFileDone) {
            if (this.s3CredentialsInfo) this.createMultiDataResource(this.s3CredentialsInfo, this.fileBodyList);
            this.isUploadDone = true;
            this.isUploadStart = true;
            this.isDigitalTwinDone = true;
            this.isUploadDigitalTwinDone = true;
            this.isShowToaster = true;
            this.toasterClass = NOTIFY_MSG.SUCCESS_CLASS;
            this.toasterMsg = NOTIFY_MSG.UPLOAD_DIGITAL_TWIN;
            setTimeout(() => {
              this.isShowToaster = false;
            }, 5000);
            setTimeout(() => {
              this.isDigitalTwinUploadStart = false;
              this.isUploadStart = false;
              this.digitalTwinProgress = 0;
            }, 1000);
          }
          break;
      }
      if (
        this.isUploadTVDone &&
        this.isUploadLOSDone &&
        this.isUploadTDDone &&
        this.isUploadNadirDone
      ) {
        this.isDroneImgDone = true;
        setTimeout(() => {
          this.isUploadStart = false;
          this.isDroneImgUploadStart = false;
        }, 1000);
      }
    };
    await pipFunc(url$);

    if (retryFiles.length) {
      this.uploadDirectoryAssets(retryFiles, ++retryAttempt);
    }
  }

  async getS3Credentials(fileType: any) {
    let s3Data;
    try {
      const getDItileResType = localStorage.getItem('digital_twin_tile');
      if (fileType === CONSTANTS_MISSION.datasetDT) {
        this.getS3CredsDT = getDItileResType ? await lastValueFrom(
          this.uploadDatasetService.getCredentials(DRONE_IMG_TYPE.DIGITAL_TWIN_TILE),
        ) : await lastValueFrom(
          this.uploadDatasetService.getCredentials(fileType),
        );
        s3Data = this.getS3CredsDT;
      }
      else {
        this.getS3CredsDI = await lastValueFrom(
          this.uploadDatasetService.getCredentials(fileType),
        );
        s3Data = this.getS3CredsDI
      }
    }
    catch (error) {
      this.isDigitalTwinUploadStart = false;
      this.isDroneImgUploadStart = false;
      this.isShowToaster = true;
      this.toasterClass = NOTIFY_MSG.ERROR_CLASS;
      this.toasterMsg = NOTIFY_MSG.UPLOAD_FAILED;
      setTimeout(() => {
        this.isShowToaster = false;
      }, 3000);
    }
    return s3Data;

  }

  async initCreateFolders(files: any, fileType: any) {
    let inputFile: any;
    const folders: any = [];
    const folderHolder: any = {};

    for (inputFile of Array.from(files)) {
      const path: string = inputFile['webkitRelativePath'];
      const pathPieces = path.split('/');

      if (fileType == CONSTANTS_MISSION.datasetCP) {
        this.uploadedCPFiles.push({
          folder: 'camera_parameters',
          folderId: '',
          fileLen: files.length,
        });

        this.uniqueCPFiles = this.uploadedCPFiles.filter(
          (obj: any, index: any) =>
            this.uploadedCPFiles.findIndex(
              (item: any) => item.folder === obj.folder,
            ) === index,
        );
        this.missionSharedService._selectedCPItems.next(this.uniqueCPFiles);
      }

      if (!pathPieces.reverse()[0].startsWith('.')) {
        this.currentFolder = pathPieces.at(-1);
        if (folderHolder[this.currentFolder]) {
          const b = await lastValueFrom(
            this.uploadDatasetService.uploadDataset(
              folderHolder[this.currentFolder],
              inputFile,
            ),
          );
          if (fileType == CONSTANTS_MISSION.datasetDT) {
            this.uploadedDTFiles.push({
              // fileName: pathPieces[0],
              folder: this.currentFolder,
              folderId: folderHolder[this.currentFolder],
              fileLen: this.allFiles.length,
            });
            this.uniqueDTFiles = this.uploadedDTFiles.filter(
              (obj: any, index: any) =>
                this.uploadedDTFiles.findIndex(
                  (item: any) => item.folder === obj.folder,
                ) === index,
            );
            this.missionSharedService._selectedDTItems.next(this.uniqueDTFiles);
          }
          if (fileType == CONSTANTS_MISSION.datasetDI) {
            this.uploadedDIFiles.push({
              folder: this.currentFolder,
              folderId: folderHolder[this.currentFolder],
              fileLen: this.allFiles.length,
            });
            this.uniqueDIFiles = this.uploadedDIFiles.filter(
              (obj: any, index: any) =>
                this.uploadedDIFiles.findIndex(
                  (item: any) => item.folder === obj.folder,
                ) === index,
            );
            this.missionSharedService._selectedDIItems.next(this.uniqueDIFiles);
          }

        } else {
          const folderId = await lastValueFrom(
            this.uploadDatasetService.createDatasetFolder(this.currentFolder),
          );
          folderHolder[this.currentFolder] = folderId;
          const p = await lastValueFrom(
            this.uploadDatasetService.uploadDataset(
              folderHolder[this.currentFolder],
              inputFile,
            ),
          );
          if (fileType == CONSTANTS_MISSION.datasetDI) {
            this.uploadedDTFiles.push({
              folder: this.currentFolder,
              folderId: folderHolder[this.currentFolder],
              fileLen: this.allFiles.length,
            });
            this.uniqueDTFiles = this.uploadedDTFiles.filter(
              (obj: any, index: any) =>
                this.uploadedDTFiles.findIndex(
                  (item: any) => item.folder === obj.folder,
                ) === index,
            );
            this.missionSharedService._selectedDTItems.next(this.uniqueDTFiles);
          }
          if (fileType == CONSTANTS_MISSION.datasetDI) {
            this.uploadedDIFiles.push({
              folder: this.currentFolder,
              folderId: folderHolder[this.currentFolder],
              fileLen: this.allFiles.length,
            });
            this.uniqueDIFiles = this.uploadedDIFiles.filter(
              (obj: any, index: any) =>
                this.uploadedDIFiles.findIndex(
                  (item: any) => item.folder === obj.folder,
                ) === index,
            );
            this.missionSharedService._selectedDIItems.next(this.uniqueDIFiles);
          }
        }
      }
    }
  }
  getAllFilesArray(allFiles: any, data: any, fileType: string) {
    const relativepath = data.credentials.s3Prefix;

    return allFiles.map((item: any) => {
      let getRelativePath = item.webkitRelativePath.split('/');
      let folderName, s3KeyValue;
      switch (fileType) {
        case CONSTANTS_MISSION.droneImgTv:
          folderName = DRONE_IMG_TYPE.TOWER_VIEW;
          s3KeyValue = getRelativePath.length > 2 ? relativepath + '/' + item.webkitRelativePath : relativepath + '/' + folderName + '/' + item.webkitRelativePath;
          this.tagsList = getRelativePath.length > 2 ? [DRONE_IMG_TYPE.ORIG_FULL_RES, 'image_group:' + folderName, 'image_group_hierarchy:' + getRelativePath[1]] : [DRONE_IMG_TYPE.ORIG_FULL_RES, 'image_group:' + folderName, 'image_group_hierarchy:' + getRelativePath.slice(0, -1).toString()];
          break;
        case CONSTANTS_MISSION.droneImgLos:
          folderName = DRONE_IMG_TYPE.LINE_OF_SIGHT;
          s3KeyValue = getRelativePath.length > 2 ? relativepath + '/' + item.webkitRelativePath : relativepath + '/' + folderName + '/' + item.webkitRelativePath;
          this.tagsList = getRelativePath.length > 2 ? [DRONE_IMG_TYPE.ORIG_FULL_RES, 'image_group:' + folderName, 'image_group_hierarchy:' + getRelativePath[1]] : [DRONE_IMG_TYPE.ORIG_FULL_RES, 'image_group:' + folderName, 'image_group_hierarchy:' + getRelativePath.slice(0, -1).toString()];
          break;
        case CONSTANTS_MISSION.droneImgTD:
          folderName = DRONE_IMG_TYPE.TOP_TO_DOWN;
          s3KeyValue = getRelativePath.length > 2 ? relativepath + '/' + item.webkitRelativePath : relativepath + '/' + folderName + '/' + item.webkitRelativePath;
          this.tagsList = getRelativePath.length > 2 ? [DRONE_IMG_TYPE.ORIG_FULL_RES, 'image_group:' + folderName, 'image_group_hierarchy:' + getRelativePath[1]] : [DRONE_IMG_TYPE.ORIG_FULL_RES, 'image_group:' + folderName, 'image_group_hierarchy:' + getRelativePath.slice(0, -1).toString()];
          break;
        case CONSTANTS_MISSION.droneImgNadir:
          folderName = DRONE_IMG_TYPE.FROM_ABOVE_VIEW;
          s3KeyValue = getRelativePath.length > 2 ? relativepath + '/' + item.name : relativepath + '/' + folderName + '/' + item.name;
          this.tagsList = getRelativePath.length > 2 ? [DRONE_IMG_TYPE.ORIG_FULL_RES, 'image_group:' + folderName, 'image_group_hierarchy:' + CONSTANTS_MISSION.droneImgNadir] : [DRONE_IMG_TYPE.ORIG_FULL_RES, 'image_group:' + folderName, 'image_group_hierarchy:' + CONSTANTS_MISSION.droneImgNadir];
          break;
        case fileType:
          folderName = DRONE_IMG_TYPE.DIGITAL_TWIN;
          s3KeyValue = relativepath + '/' + item.name;
          this.tagsList = getRelativePath.length > 2 ? [folderName, getRelativePath[1]] : [folderName];
          break;
      }

      const uploadFileparams = {
        Body: item,
        Bucket: data.credentials.s3Bucket,
        Key: s3KeyValue,
        tags: this.tagsList
      };

      return uploadFileparams;

    });
  }

  async multiPartFileUpload(
    s3CredInfo: any,
    params: any,
    client: any,
    bucketName: string,
    relativepath: string,
    body: any,
    retryFiles: File[]
  ) {

    const createParam = {
      Bucket: bucketName,
      Key: relativepath + '/' + params.Body.name,
    };

    const createMultipartUploadCommand = new CreateMultipartUploadCommand(
      createParam,
    );
    client.send(createMultipartUploadCommand).then(
      async (createResponse: any) => {
        const uploadId = createResponse.UploadId;
        const fileSize = params.Body.size;
        const uploadPartWithProgress = async (
          params: any,
          partNumber: any,
          body: any,
          UploadId: any,
        ) => {
          const command = new UploadPartCommand({
            ...params,
            PartNumber: partNumber,
            Body: body,
            UploadId,
          });

          const partResp = await client
            .send(command)
            .then((resp: any) => {
              return resp;
            })
            .catch(() => {
              retryFiles.push(params);
            });
          return partResp;
        };
        const parts = [];

        for (
          let start = 0;
          start < fileSize;
          start += this.multipartFileChunkSize
        ) {
          const end = Math.min(start + this.multipartFileChunkSize, fileSize);
          const partBuffer = params.Body.slice(start, end);
          parts.push(partBuffer);
        }

        // Upload each part
        const uploadPromises = parts.map((part, index) => {
          return uploadPartWithProgress(createParam, index + 1, part, uploadId);
        });
        let uploadResults = [];
        try {
          uploadResults = await Promise.all(uploadPromises);
        }
        catch {
          retryFiles.push(params);
        }
        const completeCommand = new CompleteMultipartUploadCommand({
          ...params,
          UploadId: uploadId,
          MultipartUpload: {
            Parts: uploadResults.map((result, index) => ({
              ETag: result.ETag,
              PartNumber: index + 1,
            })),
          },
        });

        await client.send(completeCommand).then((resp: any) => {
          body.ETag = resp.ETag;
          this.fileBodyList.push(this.multiFileInputData);
        })
        this.isMultiChunkFileDone = true;
        if (this.isAllDTFileDone) {
          if (s3CredInfo) this.createMultiDataResource(s3CredInfo, this.fileBodyList);
          this.isUploadDone = true;
          this.isUploadStart = true;
          this.isDigitalTwinDone = true;
          this.isUploadDigitalTwinDone = true;
          this.isShowToaster = true;
          this.toasterClass = NOTIFY_MSG.SUCCESS_CLASS;
          this.toasterMsg = NOTIFY_MSG.UPLOAD_DIGITAL_TWIN;
          setTimeout(() => {
            this.isShowToaster = false;
          }, 5000);
          setTimeout(() => {
            this.isUploadStart = false;
            this.digitalTwinProgress = 0;
            this.isDigitalTwinUploadStart = false;
          }, 1000);
        }
      },
      (createError: any) => { },
    );
  }

  startUploadSingleFile() {
    let isValidFile = this.validateFileType(this.fileList);

    if (this.fileList.length > 0 && isValidFile.xml) {
      this.isUploadStart = true;
      this.initCreateFolders(this.fileList, this.fileTypeCat);
      //this.processedToUpload(this.fileList);
    } else {
      this.isShowToaster = true;
      this.toasterClass = NOTIFY_MSG.ERROR_CLASS;
      this.toasterMsg = NOTIFY_MSG.INVALID_FILE_XML;
      setTimeout(() => {
        this.isShowToaster = false;
      }, 3000);
    }
  }

  uploadSingleFile(pFileList: any, fileType: any) {
    this.fileList = pFileList.files;
    this.fileTypeCat = fileType;
    localStorage.removeItem('digital_twin');
    localStorage.removeItem('digital_twin_tile');
    localStorage.removeItem('drone_img');
    localStorage.setItem('camera_param', fileType);

    let isValidFile = this.validateFileType(this.fileList);

    if (this.fileList.length > 0 && isValidFile.xml) {
      this.isUploadStart = true;
      this.isCameraParamUploadStart = true;
      this.cpInputDisable = true;
      this.initCreateFolders(this.fileList, this.fileTypeCat);
      this.processedToUpload(this.fileList, fileType);
    } else {
      this.isShowToaster = true;
      this.toasterClass = NOTIFY_MSG.ERROR_CLASS;
      this.toasterMsg = NOTIFY_MSG.INVALID_FILE_XML;
      setTimeout(() => {
        this.isShowToaster = false;
      }, 3000);
    }
  }
  async processedToUpload(files: any, fileType: any) {
    try {
      this.callCreateDataResApi(files, fileType);
    } catch (err) {

      console.log('err', err);
    }
  }

  async callCreateDataResApi(files: any, fileType: any) {
    let credData = await this.uploadDatasetService.getCredentials(fileType).toPromise();
    const client = new S3Client({
      region: credData.credentials.region,
      credentials: {
        accessKeyId: credData.credentials.accessKeyId,
        secretAccessKey: credData.credentials.secretAccessKey,
        sessionToken: credData.credentials.sessionToken,
      },
    });
    let relativepath = credData.credentials.s3Prefix;
    const input = {
      Body: files[0],
      Bucket: credData.credentials.s3Bucket,
      Key: relativepath + '/' + files[0].name,
    };

    const command = new PutObjectCommand(input);
    const response = await client.send(command);

    this.createDataResource(credData, input, response, relativepath);
    this.cameraParamProgress = 0;
    this.cameraParamProgress += (1 / files.length) * 100;
    this.currentCPProgress = this.cameraParamProgress;
    this.isUploadDone = true;
    this.isUploadCameraParamDone = true;
    this.isCameraParamDone = true;
    this.isShowToaster = true;
    this.toasterClass = NOTIFY_MSG.SUCCESS_CLASS;
    this.toasterMsg = NOTIFY_MSG.UPLOAD_CAMERA_PARAM;
    setTimeout(() => {
      this.isShowToaster = false;
    }, 5000);
    setTimeout(() => {
      this.isUploadStart = false;
      this.currentCPProgress = 0;
      this.isCameraParamUploadStart = false;
    }, 1000);
  }

  async createDataResource(
    credInfo: any,
    file: { Body: File; Bucket: string; Key: string },
    uploadResponse: any,
    relativepath: string,
  ) {
    this.missionDataResParam = localStorage.getItem('missionDataParams');
    const dataParams = JSON.parse(this.missionDataResParam);
    this.localMissionID = dataParams.missionId;
    this.localProjectID = dataParams.missionProjectId;
    this.localSiteID = dataParams.siteId;

    let fileExt: any = [];
    fileExt = file.Body.type.split('/').pop();

    const payload: ICreateDataResource = {
      workspaceId: this.workspaceID,
      type: 'data_resource',
      projectId: this.localProjectID,
      missionId: this.localMissionID,
      siteId: this.localSiteID,
      resourceType: credInfo.credentials.s3Prefix.split('/').pop(),
      tags: [],
      metadata: {},
      storage: {
        storageType: 's3',
        files: [
          {
            s3Key: file.Key,
            s3Bucket: file.Bucket,
            sizeBytes: file.Body.size,
            extension: fileExt,
            s3Etag: uploadResponse.ETag.replaceAll('"', ''),
            origUploadRelativePath: '',
          },
        ],
      },
    };
    try {
      let data = await this.uploadDatasetService
        .createDataResources(payload)
        .toPromise();
    } catch (error) {
      console.log(error);
    }
  }
  async createMultiDataResource(
    credInfo: any,
    file: any,
  ) {
    this.missionDataResParam = localStorage.getItem('missionDataParams');
    const dataParams = JSON.parse(this.missionDataResParam);
    this.localMissionID = dataParams.missionId;
    this.localProjectID = dataParams.missionProjectId;
    this.localSiteID = dataParams.siteId;
    let respFiles: any = [];
    let counter = 0;

    for (let i = 0; i < file.length; i++) {
      respFiles[i] = {
        s3Key: file[i].Key,
        s3Bucket: file[i].Bucket,
        sizeBytes: file[i].Body.size,
        extension: file[i].Body.name.substr(file[i].Body.name.indexOf('.') + 1),
        s3Etag: file[i].ETag.replaceAll('"', ''),
        origUploadRelativePath: file[i].tag,
      };
    }

    const payload: ICreateDataResource = {
      workspaceId: this.workspaceID,
      type: 'data_resource',
      projectId: this.localProjectID,
      missionId: this.localMissionID,
      siteId: this.localSiteID,
      resourceType: credInfo.credentials.s3Prefix.split('/').pop() || '',
      tags: file[counter].tagList,
      metadata: {},
      storage: {
        storageType: 's3',
        files: respFiles,
      },
    };

    try {
      let data = await this.uploadDatasetService
        .createDataResources(payload)
        .toPromise();
      counter++;
      if (this.dataResourceIdList.length === 0) this.dataResourceIdList.push(data);
      let dataResTag = this.dataResourceIdList[0].tags[this.dataResourceIdList[0].tags.length - 2];
      if(dataResTag === DRONE_IMG_TYPE.TOWER_VIEW) this.dataResTag = this.dataResourceIdList[0].id;
    } catch (error) {
      console.log(error);
    }
  }

  showInputField(inputValue: any) {
    let getValue = inputValue.value;
    let tempCPArr = [],
      tempDTArr = [],
      tempDIArr = [];
    tempCPArr = this.uniqueCPFiles;
    tempDTArr = this.uniqueDTFiles;
    tempDIArr = this.uniqueDIFiles;

    for (let i = 0; i < tempCPArr.length; i++) {
      tempCPArr[i].folder = getValue;
    }
    for (let i = 0; i < tempDTArr.length; i++) {
      tempDTArr[i].folder = getValue;
    }
    for (let i = 0; i < tempDIArr.length; i++) {
      tempDIArr[i].folder = getValue;
    }
  }

  async onUploadDataset(retryWorkflowCounter: number = 0) {
    if (!this.isCameraParamDone && !this.isDigitalTwinDone && !this.isDroneImgDone) {
      this.isSubmitted = false;
    } else {
      this.isSubmitted = true;
      this.hideMissionSection.emit(true);
      this.isHideMissionSection = true;

      const updateSiteInfo = {
        siteImageDataResourceId: this.dataResTag
      }
      this.uploadDatasetService.updateSitePlaceholder(this.localSiteID, updateSiteInfo).toPromise();
      try {
        const missionStatus = {
          status: MISSION_STATUS.ACTIVE
        }
        this.createMissionService.updateMissionStatus(this.localMissionID, missionStatus).toPromise();
        this.progress = 0;
        this.isUploadStart = true;
        this.isWorkFlowStarted = true;
        this.workflowSteps = "Workflow process initiated!";

        let data = await this.missionWorkflowService
          .getWorkflowDef()
          .toPromise();

        let workflowDefList;
        let workflowStepList: IMissionWorkflowDef[] = [];
        this.workflowData = data;
        let payload!: IMissionWorkflowRun;
        workflowDefList = this.workflowData.records;

        for (let i = 0; i < workflowDefList.length; i++) {
          for (let j = 0; j < workflowDefList[i].workflowSteps.length; j++) {
            workflowStepList.push(workflowDefList[i].workflowSteps[j]);
          }
          payload = {
            workspaceId: this.workspaceID,
            name: workflowDefList[i].name,
            workflowDefId: workflowDefList[i].id,
            airflowDagId: workflowDefList[i].airflowDagId,
            projectId: this.localProjectID,
            missionId: this.localMissionID,
            siteId: this.localSiteID,
            status: workflowDefList[i].status,
            createdBy: workflowDefList[i].createdBy,
            updatedBy: workflowDefList[i].updatedBy,
            workflowSteps: workflowStepList,
            createdAt: workflowDefList[i].createdAt,
            updatedAt: workflowDefList[i].updatedAt,
          };
        }
        let workflowResponse = await this.missionWorkflowService
          .runWorkflowForMission(payload)
          .toPromise();
        let workflowRunResponse = await this.missionWorkflowService
          .startWorkflowforMission(workflowResponse)
          .toPromise();
        let counter = 0;
        while (this.isCallApi === false) {
          let workflowRunResp = await this.missionWorkflowService.getWorkflowRun(
            workflowRunResponse
          ).toPromise();

          let workflowRespArr = JSON.stringify(workflowRunResp);
          let workflowArr = JSON.parse(workflowRespArr);
          let getProgressRes;
          if (workflowArr.status !== WORKFLOW_STATUS.FAILED) {
            for (let k = counter; k < 3; k++) {
              if (workflowArr.workflowSteps[counter].status !== WORKFLOW_STATUS.FAILED) {
                if (
                  workflowArr.workflowSteps[counter].status !== WORKFLOW_STATUS.FAILED &&
                  workflowArr.workflowSteps[counter].stepName == WORKFLOW_STEPS.RESOURCE_GENERATION &&
                  workflowArr.workflowSteps[counter].parentStepNames == ''
                ) {
                  if (typeof workflowArr.workflowSteps[counter].progress !== 'undefined') {
                    getProgressRes = workflowArr.workflowSteps[counter].progress.percentageCompleted;
                    this.progress = this.progress < CONSTANTS_MISSION.PROGRESS_PERCENTAGE ? this.progress +=
                      (getProgressRes * CONSTANTS_MISSION.PROGRESS_PERCENTAGE) : this.progress = CONSTANTS_MISSION.PROGRESS_PERCENTAGE;
                    this.workflowSteps = workflowArr.workflowSteps[counter].progress.detail;
                  }
                  else {
                    this.workflowSteps = workflowArr.workflowSteps[counter].stepName + " is in " + workflowArr.workflowSteps[counter].status + " state.";
                  }
                } if (
                  workflowArr.workflowSteps[counter].status != WORKFLOW_STATUS.FAILED &&
                  workflowArr.workflowSteps[counter].parentStepNames == WORKFLOW_STEPS.RESOURCE_GENERATION
                ) {
                  this.progress = 0;
                  if (typeof workflowArr.workflowSteps[counter].progress !== 'undefined') {
                    getProgressRes = workflowArr.workflowSteps[counter].progress.percentageCompleted;
                    this.progress = this.progress < CONSTANTS_MISSION.PROGRESS_PERCENTAGE ? this.progress +=
                      (getProgressRes * CONSTANTS_MISSION.PROGRESS_PERCENTAGE) : this.progress = CONSTANTS_MISSION.PROGRESS_PERCENTAGE;
                    this.workflowSteps = workflowArr.workflowSteps[counter].progress.detail;
                  }
                  else {
                    this.workflowSteps = workflowArr.workflowSteps[counter].stepName + " is in " + workflowArr.workflowSteps[counter].status + " state.";
                  }
                } if (
                  workflowArr.workflowSteps[k].stepName == WORKFLOW_STEPS.NEXUS_GENERATION &&
                  workflowArr.workflowSteps[k].status != WORKFLOW_STATUS.FAILED &&
                  workflowArr.workflowSteps[k].parentStepNames == WORKFLOW_STEPS.RESOURCE_GENERATION
                ) {
                  this.stopWorkflow = true;
                  this.progress = 0;
                  if (typeof workflowArr.workflowSteps[counter].progress !== 'undefined') {
                    getProgressRes = workflowArr.workflowSteps[counter].progress.percentageCompleted;
                    this.progress = this.progress < CONSTANTS_MISSION.PROGRESS_PERCENTAGE ? this.progress +=
                      (getProgressRes * CONSTANTS_MISSION.PROGRESS_PERCENTAGE) : this.progress = CONSTANTS_MISSION.PROGRESS_PERCENTAGE;
                    this.workflowSteps = workflowArr.workflowSteps[counter].progress.detail;
                  }
                  else {
                    this.workflowSteps = workflowArr.workflowSteps[counter].stepName + " is in " + workflowArr.workflowSteps[counter].status + " state.";
                  }
                }
                if (
                  workflowArr.workflowSteps[counter].stepName == WORKFLOW_STEPS.NEXUS_GENERATION &&
                  workflowArr.workflowSteps[counter].status == WORKFLOW_STATUS.COMPLETED &&
                  workflowArr.workflowSteps[counter].parentStepNames == WORKFLOW_STEPS.RESOURCE_GENERATION
                ) {
                  this.progress = 0;
                  if (typeof workflowArr.workflowSteps[counter].progress !== 'undefined') {
                    getProgressRes = workflowArr.workflowSteps[counter].progress.percentageCompleted;
                    this.progress = this.progress < CONSTANTS_MISSION.PROGRESS_PERCENTAGE ? this.progress +=
                      (getProgressRes * CONSTANTS_MISSION.PROGRESS_PERCENTAGE) : this.progress = CONSTANTS_MISSION.PROGRESS_PERCENTAGE;
                    this.workflowSteps = workflowArr.workflowSteps[counter].progress.detail;
                  }
                  else {
                    this.workflowSteps = workflowArr.workflowSteps[counter].stepName + " is in " + workflowArr.workflowSteps[counter].status + " state.";
                  }
                  this.isWorkFlowStarted = false;
                  this.isCallApi = true;
                  this.router.navigate(['/canvas'], {
                    queryParams: {
                      project_id: this.localProjectID,
                      site_id: this.localSiteID,
                      asset_id: this.localAssetID,
                      mission_id: this.localMissionID,
                    },
                  });
                }
              }
            }
            if (workflowArr.workflowSteps[counter].status ==
              WORKFLOW_STATUS.COMPLETED) {
              counter++;
            }
            await this.callApiMultiple(CONSTANTS_MISSION.API_TIMER);
          }
          else {
            retryWorkflowCounter++;
            if (retryWorkflowCounter <= 2) {
              this.onUploadDataset(retryWorkflowCounter);
            }
            else {
              this.isShowToaster = true;
              this.toasterClass = NOTIFY_MSG.ERROR_CLASS;
              this.toasterMsg = NOTIFY_MSG.WORKFLOW_FAILED;
              setTimeout(() => {
                this.isShowToaster = false;
              }, 5000);
              setTimeout(() => {
                this.isWorkFlowStarted = false;
                this.isUploadStart = false;
                this.isCallApi = true;
                this.progress = 0;
              }, 1000);
              this.stopWorkflow = false;
              this.hideMission.emit(true);
              localStorage.clear();
            }
          }

        }
        this.hideMission.emit(true);
        localStorage.clear();
      } catch (error) {
        this.isShowToaster = true;
        this.toasterClass = NOTIFY_MSG.ERROR_CLASS;
        this.toasterMsg = NOTIFY_MSG.WORKFLOW_FAILED;
        setTimeout(() => {
          this.isShowToaster = false;
        }, 5000);
        setTimeout(() => {
          this.isWorkFlowStarted = false;
          this.isUploadStart = false;
          this.isCallApi = true;
          this.progress = 0;
        }, 1000);
        this.stopWorkflow = false;
        this.hideMission.emit(true);
        localStorage.clear();
      }
    }
  }
  callApiMultiple(milliseconds: number) {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds)
    });
  }
}


