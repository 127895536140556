export let weather_1H = {
  version: '3.0',
  user: 'kg_dronos',
  dateGenerated: '2023-06-07T05:25:28Z',
  status: 'OK',
  data: [
    {
      parameter: 't_2m:C',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 24,
            },
            {
              date: '2023-06-07T01:00:00Z',
              value: 24.3,
            },
            {
              date: '2023-06-07T02:00:00Z',
              value: 26,
            },
            {
              date: '2023-06-07T03:00:00Z',
              value: 28.4,
            },
            {
              date: '2023-06-07T04:00:00Z',
              value: 30.8,
            },
            {
              date: '2023-06-07T05:00:00Z',
              value: 32.3,
            },
            {
              date: '2023-06-07T06:00:00Z',
              value: 33.8,
            },
            {
              date: '2023-06-07T07:00:00Z',
              value: 34.4,
            },
            {
              date: '2023-06-07T08:00:00Z',
              value: 34,
            },
            {
              date: '2023-06-07T09:00:00Z',
              value: 32.4,
            },
            {
              date: '2023-06-07T10:00:00Z',
              value: 32.7,
            },
            {
              date: '2023-06-07T11:00:00Z',
              value: 32.2,
            },
            {
              date: '2023-06-07T12:00:00Z',
              value: 30.3,
            },
            {
              date: '2023-06-07T13:00:00Z',
              value: 28,
            },
            {
              date: '2023-06-07T14:00:00Z',
              value: 25.9,
            },
            {
              date: '2023-06-07T15:00:00Z',
              value: 25,
            },
            {
              date: '2023-06-07T16:00:00Z',
              value: 24.4,
            },
            {
              date: '2023-06-07T17:00:00Z',
              value: 24,
            },
            {
              date: '2023-06-07T18:00:00Z',
              value: 24,
            },
            {
              date: '2023-06-07T19:00:00Z',
              value: 24.1,
            },
            {
              date: '2023-06-07T20:00:00Z',
              value: 23.8,
            },
            {
              date: '2023-06-07T21:00:00Z',
              value: 23.6,
            },
            {
              date: '2023-06-07T22:00:00Z',
              value: 23.3,
            },
            {
              date: '2023-06-07T23:00:00Z',
              value: 23.3,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 23.4,
            },
            {
              date: '2023-06-08T01:00:00Z',
              value: 23.6,
            },
            {
              date: '2023-06-08T02:00:00Z',
              value: 25.2,
            },
            {
              date: '2023-06-08T03:00:00Z',
              value: 27.5,
            },
            {
              date: '2023-06-08T04:00:00Z',
              value: 30.1,
            },
            {
              date: '2023-06-08T05:00:00Z',
              value: 32.2,
            },
            {
              date: '2023-06-08T06:00:00Z',
              value: 33.7,
            },
            {
              date: '2023-06-08T07:00:00Z',
              value: 33.4,
            },
            {
              date: '2023-06-08T08:00:00Z',
              value: 33.7,
            },
            {
              date: '2023-06-08T09:00:00Z',
              value: 33.5,
            },
            {
              date: '2023-06-08T10:00:00Z',
              value: 32,
            },
            {
              date: '2023-06-08T11:00:00Z',
              value: 31.1,
            },
            {
              date: '2023-06-08T12:00:00Z',
              value: 29,
            },
            {
              date: '2023-06-08T13:00:00Z',
              value: 26.7,
            },
            {
              date: '2023-06-08T14:00:00Z',
              value: 25.6,
            },
            {
              date: '2023-06-08T15:00:00Z',
              value: 25,
            },
            {
              date: '2023-06-08T16:00:00Z',
              value: 24.6,
            },
            {
              date: '2023-06-08T17:00:00Z',
              value: 24.3,
            },
            {
              date: '2023-06-08T18:00:00Z',
              value: 24.1,
            },
            {
              date: '2023-06-08T19:00:00Z',
              value: 24,
            },
            {
              date: '2023-06-08T20:00:00Z',
              value: 23.8,
            },
            {
              date: '2023-06-08T21:00:00Z',
              value: 23.6,
            },
            {
              date: '2023-06-08T22:00:00Z',
              value: 23.4,
            },
            {
              date: '2023-06-08T23:00:00Z',
              value: 23.2,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 22.9,
            },
            {
              date: '2023-06-09T01:00:00Z',
              value: 24.6,
            },
            {
              date: '2023-06-09T02:00:00Z',
              value: 26.3,
            },
            {
              date: '2023-06-09T03:00:00Z',
              value: 27.9,
            },
            {
              date: '2023-06-09T04:00:00Z',
              value: 29.8,
            },
            {
              date: '2023-06-09T05:00:00Z',
              value: 31.6,
            },
            {
              date: '2023-06-09T06:00:00Z',
              value: 33.5,
            },
            {
              date: '2023-06-09T07:00:00Z',
              value: 33,
            },
            {
              date: '2023-06-09T08:00:00Z',
              value: 32.6,
            },
            {
              date: '2023-06-09T09:00:00Z',
              value: 32.1,
            },
            {
              date: '2023-06-09T10:00:00Z',
              value: 31.1,
            },
            {
              date: '2023-06-09T11:00:00Z',
              value: 30.1,
            },
            {
              date: '2023-06-09T12:00:00Z',
              value: 29.1,
            },
            {
              date: '2023-06-09T13:00:00Z',
              value: 27.8,
            },
            {
              date: '2023-06-09T14:00:00Z',
              value: 26.5,
            },
            {
              date: '2023-06-09T15:00:00Z',
              value: 25.3,
            },
            {
              date: '2023-06-09T16:00:00Z',
              value: 25.1,
            },
            {
              date: '2023-06-09T17:00:00Z',
              value: 24.9,
            },
            {
              date: '2023-06-09T18:00:00Z',
              value: 24.7,
            },
            {
              date: '2023-06-09T19:00:00Z',
              value: 24.7,
            },
            {
              date: '2023-06-09T20:00:00Z',
              value: 24.6,
            },
            {
              date: '2023-06-09T21:00:00Z',
              value: 24.5,
            },
            {
              date: '2023-06-09T22:00:00Z',
              value: 24.3,
            },
            {
              date: '2023-06-09T23:00:00Z',
              value: 24,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 23.8,
            },
            {
              date: '2023-06-10T01:00:00Z',
              value: 25,
            },
            {
              date: '2023-06-10T02:00:00Z',
              value: 26.1,
            },
            {
              date: '2023-06-10T03:00:00Z',
              value: 27.2,
            },
            {
              date: '2023-06-10T04:00:00Z',
              value: 29.1,
            },
            {
              date: '2023-06-10T05:00:00Z',
              value: 30.9,
            },
            {
              date: '2023-06-10T06:00:00Z',
              value: 32.7,
            },
            {
              date: '2023-06-10T07:00:00Z',
              value: 32.8,
            },
            {
              date: '2023-06-10T08:00:00Z',
              value: 32.8,
            },
            {
              date: '2023-06-10T09:00:00Z',
              value: 32.9,
            },
            {
              date: '2023-06-10T10:00:00Z',
              value: 31.4,
            },
            {
              date: '2023-06-10T11:00:00Z',
              value: 29.9,
            },
            {
              date: '2023-06-10T12:00:00Z',
              value: 28.5,
            },
            {
              date: '2023-06-10T13:00:00Z',
              value: 27.4,
            },
            {
              date: '2023-06-10T14:00:00Z',
              value: 26.4,
            },
            {
              date: '2023-06-10T15:00:00Z',
              value: 25.4,
            },
            {
              date: '2023-06-10T16:00:00Z',
              value: 25,
            },
            {
              date: '2023-06-10T17:00:00Z',
              value: 24.7,
            },
            {
              date: '2023-06-10T18:00:00Z',
              value: 24.3,
            },
            {
              date: '2023-06-10T19:00:00Z',
              value: 24.1,
            },
            {
              date: '2023-06-10T20:00:00Z',
              value: 23.9,
            },
            {
              date: '2023-06-10T21:00:00Z',
              value: 23.7,
            },
            {
              date: '2023-06-10T22:00:00Z',
              value: 23.6,
            },
            {
              date: '2023-06-10T23:00:00Z',
              value: 23.4,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 23.2,
            },
            {
              date: '2023-06-11T01:00:00Z',
              value: 24.6,
            },
            {
              date: '2023-06-11T02:00:00Z',
              value: 25.9,
            },
            {
              date: '2023-06-11T03:00:00Z',
              value: 27.3,
            },
            {
              date: '2023-06-11T04:00:00Z',
              value: 29.2,
            },
            {
              date: '2023-06-11T05:00:00Z',
              value: 31.2,
            },
            {
              date: '2023-06-11T06:00:00Z',
              value: 33.2,
            },
            {
              date: '2023-06-11T07:00:00Z',
              value: 33.1,
            },
            {
              date: '2023-06-11T08:00:00Z',
              value: 33.1,
            },
            {
              date: '2023-06-11T09:00:00Z',
              value: 33.1,
            },
            {
              date: '2023-06-11T10:00:00Z',
              value: 31.9,
            },
            {
              date: '2023-06-11T11:00:00Z',
              value: 30.8,
            },
            {
              date: '2023-06-11T12:00:00Z',
              value: 29.7,
            },
            {
              date: '2023-06-11T13:00:00Z',
              value: 28.3,
            },
            {
              date: '2023-06-11T14:00:00Z',
              value: 26.9,
            },
            {
              date: '2023-06-11T15:00:00Z',
              value: 25.5,
            },
            {
              date: '2023-06-11T16:00:00Z',
              value: 25,
            },
            {
              date: '2023-06-11T17:00:00Z',
              value: 24.5,
            },
            {
              date: '2023-06-11T18:00:00Z',
              value: 23.9,
            },
            {
              date: '2023-06-11T19:00:00Z',
              value: 23.9,
            },
            {
              date: '2023-06-11T20:00:00Z',
              value: 23.9,
            },
            {
              date: '2023-06-11T21:00:00Z',
              value: 23.9,
            },
            {
              date: '2023-06-11T22:00:00Z',
              value: 23.9,
            },
            {
              date: '2023-06-11T23:00:00Z',
              value: 23.8,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 23.8,
            },
            {
              date: '2023-06-12T01:00:00Z',
              value: 24.9,
            },
            {
              date: '2023-06-12T02:00:00Z',
              value: 25.9,
            },
            {
              date: '2023-06-12T03:00:00Z',
              value: 27,
            },
            {
              date: '2023-06-12T04:00:00Z',
              value: 29,
            },
            {
              date: '2023-06-12T05:00:00Z',
              value: 31.1,
            },
            {
              date: '2023-06-12T06:00:00Z',
              value: 33.1,
            },
            {
              date: '2023-06-12T07:00:00Z',
              value: 32,
            },
            {
              date: '2023-06-12T08:00:00Z',
              value: 30.9,
            },
            {
              date: '2023-06-12T09:00:00Z',
              value: 29.8,
            },
            {
              date: '2023-06-12T10:00:00Z',
              value: 29.3,
            },
            {
              date: '2023-06-12T11:00:00Z',
              value: 28.8,
            },
            {
              date: '2023-06-12T12:00:00Z',
              value: 28.3,
            },
            {
              date: '2023-06-12T13:00:00Z',
              value: 27.7,
            },
            {
              date: '2023-06-12T14:00:00Z',
              value: 27.1,
            },
            {
              date: '2023-06-12T15:00:00Z',
              value: 26.4,
            },
            {
              date: '2023-06-12T16:00:00Z',
              value: 25.8,
            },
            {
              date: '2023-06-12T17:00:00Z',
              value: 25.2,
            },
            {
              date: '2023-06-12T18:00:00Z',
              value: 24.5,
            },
            {
              date: '2023-06-12T19:00:00Z',
              value: 24.4,
            },
            {
              date: '2023-06-12T20:00:00Z',
              value: 24.4,
            },
            {
              date: '2023-06-12T21:00:00Z',
              value: 24.3,
            },
            {
              date: '2023-06-12T22:00:00Z',
              value: 24.2,
            },
            {
              date: '2023-06-12T23:00:00Z',
              value: 24.1,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 24,
            },
            {
              date: '2023-06-13T01:00:00Z',
              value: 25.4,
            },
            {
              date: '2023-06-13T02:00:00Z',
              value: 26.8,
            },
            {
              date: '2023-06-13T03:00:00Z',
              value: 28.1,
            },
            {
              date: '2023-06-13T04:00:00Z',
              value: 29.5,
            },
            {
              date: '2023-06-13T05:00:00Z',
              value: 30.9,
            },
            {
              date: '2023-06-13T06:00:00Z',
              value: 32.3,
            },
            {
              date: '2023-06-13T07:00:00Z',
              value: 31.7,
            },
            {
              date: '2023-06-13T08:00:00Z',
              value: 31,
            },
            {
              date: '2023-06-13T09:00:00Z',
              value: 30.3,
            },
            {
              date: '2023-06-13T10:00:00Z',
              value: 29.7,
            },
            {
              date: '2023-06-13T11:00:00Z',
              value: 29,
            },
            {
              date: '2023-06-13T12:00:00Z',
              value: 28.3,
            },
            {
              date: '2023-06-13T13:00:00Z',
              value: 27.6,
            },
            {
              date: '2023-06-13T14:00:00Z',
              value: 26.9,
            },
            {
              date: '2023-06-13T15:00:00Z',
              value: 26.2,
            },
            {
              date: '2023-06-13T16:00:00Z',
              value: 25.5,
            },
            {
              date: '2023-06-13T17:00:00Z',
              value: 24.7,
            },
            {
              date: '2023-06-13T18:00:00Z',
              value: 24,
            },
            {
              date: '2023-06-13T19:00:00Z',
              value: 23.9,
            },
            {
              date: '2023-06-13T20:00:00Z',
              value: 23.8,
            },
            {
              date: '2023-06-13T21:00:00Z',
              value: 23.7,
            },
            {
              date: '2023-06-13T22:00:00Z',
              value: 23.6,
            },
            {
              date: '2023-06-13T23:00:00Z',
              value: 23.6,
            },
          ],
        },
      ],
    },
    {
      parameter: 'uv:idx',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T01:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T02:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T03:00:00Z',
              value: 2,
            },
            {
              date: '2023-06-07T04:00:00Z',
              value: 5,
            },
            {
              date: '2023-06-07T05:00:00Z',
              value: 8,
            },
            {
              date: '2023-06-07T06:00:00Z',
              value: 9,
            },
            {
              date: '2023-06-07T07:00:00Z',
              value: 8,
            },
            {
              date: '2023-06-07T08:00:00Z',
              value: 8,
            },
            {
              date: '2023-06-07T09:00:00Z',
              value: 5,
            },
            {
              date: '2023-06-07T10:00:00Z',
              value: 3,
            },
            {
              date: '2023-06-07T11:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T12:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T13:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T14:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T15:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T16:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T17:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T18:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T19:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T20:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T21:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T22:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T23:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T01:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T02:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T03:00:00Z',
              value: 3,
            },
            {
              date: '2023-06-08T04:00:00Z',
              value: 5,
            },
            {
              date: '2023-06-08T05:00:00Z',
              value: 9,
            },
            {
              date: '2023-06-08T06:00:00Z',
              value: 10,
            },
            {
              date: '2023-06-08T07:00:00Z',
              value: 7,
            },
            {
              date: '2023-06-08T08:00:00Z',
              value: 5,
            },
            {
              date: '2023-06-08T09:00:00Z',
              value: 4,
            },
            {
              date: '2023-06-08T10:00:00Z',
              value: 3,
            },
            {
              date: '2023-06-08T11:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T12:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T13:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T14:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T15:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T16:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T17:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T18:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T19:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T20:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T21:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T22:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T23:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T01:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T02:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T03:00:00Z',
              value: 3,
            },
            {
              date: '2023-06-09T04:00:00Z',
              value: 6,
            },
            {
              date: '2023-06-09T05:00:00Z',
              value: 8,
            },
            {
              date: '2023-06-09T06:00:00Z',
              value: 10,
            },
            {
              date: '2023-06-09T07:00:00Z',
              value: 9,
            },
            {
              date: '2023-06-09T08:00:00Z',
              value: 6,
            },
            {
              date: '2023-06-09T09:00:00Z',
              value: 4,
            },
            {
              date: '2023-06-09T10:00:00Z',
              value: 3,
            },
            {
              date: '2023-06-09T11:00:00Z',
              value: 2,
            },
            {
              date: '2023-06-09T12:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T13:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T14:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T15:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T16:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T17:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T18:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T19:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T20:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T21:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T22:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T23:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T01:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T02:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T03:00:00Z',
              value: 3,
            },
            {
              date: '2023-06-10T04:00:00Z',
              value: 5,
            },
            {
              date: '2023-06-10T05:00:00Z',
              value: 7,
            },
            {
              date: '2023-06-10T06:00:00Z',
              value: 10,
            },
            {
              date: '2023-06-10T07:00:00Z',
              value: 11,
            },
            {
              date: '2023-06-10T08:00:00Z',
              value: 9,
            },
            {
              date: '2023-06-10T09:00:00Z',
              value: 5,
            },
            {
              date: '2023-06-10T10:00:00Z',
              value: 3,
            },
            {
              date: '2023-06-10T11:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T12:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T13:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T14:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T15:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T16:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T17:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T18:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T19:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T20:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T21:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T22:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T23:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T01:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T02:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T03:00:00Z',
              value: 3,
            },
            {
              date: '2023-06-11T04:00:00Z',
              value: 5,
            },
            {
              date: '2023-06-11T05:00:00Z',
              value: 7,
            },
            {
              date: '2023-06-11T06:00:00Z',
              value: 8,
            },
            {
              date: '2023-06-11T07:00:00Z',
              value: 8,
            },
            {
              date: '2023-06-11T08:00:00Z',
              value: 7,
            },
            {
              date: '2023-06-11T09:00:00Z',
              value: 6,
            },
            {
              date: '2023-06-11T10:00:00Z',
              value: 4,
            },
            {
              date: '2023-06-11T11:00:00Z',
              value: 2,
            },
            {
              date: '2023-06-11T12:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T13:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T14:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T15:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T16:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T17:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T18:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T19:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T20:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T21:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T22:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T23:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T01:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T02:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T03:00:00Z',
              value: 2,
            },
            {
              date: '2023-06-12T04:00:00Z',
              value: 5,
            },
            {
              date: '2023-06-12T05:00:00Z',
              value: 7,
            },
            {
              date: '2023-06-12T06:00:00Z',
              value: 8,
            },
            {
              date: '2023-06-12T07:00:00Z',
              value: 8,
            },
            {
              date: '2023-06-12T08:00:00Z',
              value: 6,
            },
            {
              date: '2023-06-12T09:00:00Z',
              value: 4,
            },
            {
              date: '2023-06-12T10:00:00Z',
              value: 2,
            },
            {
              date: '2023-06-12T11:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T12:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T13:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T14:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T15:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T16:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T17:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T18:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T19:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T20:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T21:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T22:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T23:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T01:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T02:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T03:00:00Z',
              value: 2,
            },
            {
              date: '2023-06-13T04:00:00Z',
              value: 4,
            },
            {
              date: '2023-06-13T05:00:00Z',
              value: 5,
            },
            {
              date: '2023-06-13T06:00:00Z',
              value: 6,
            },
            {
              date: '2023-06-13T07:00:00Z',
              value: 6,
            },
            {
              date: '2023-06-13T08:00:00Z',
              value: 5,
            },
            {
              date: '2023-06-13T09:00:00Z',
              value: 4,
            },
            {
              date: '2023-06-13T10:00:00Z',
              value: 2,
            },
            {
              date: '2023-06-13T11:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T12:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T13:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T14:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T15:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T16:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T17:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T18:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T19:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T20:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T21:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T22:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T23:00:00Z',
              value: 0,
            },
          ],
        },
      ],
    },
    {
      parameter: 'wind_speed_10m:kmh',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 11.8,
            },
            {
              date: '2023-06-07T01:00:00Z',
              value: 10.2,
            },
            {
              date: '2023-06-07T02:00:00Z',
              value: 8.1,
            },
            {
              date: '2023-06-07T03:00:00Z',
              value: 9.9,
            },
            {
              date: '2023-06-07T04:00:00Z',
              value: 13.9,
            },
            {
              date: '2023-06-07T05:00:00Z',
              value: 18.1,
            },
            {
              date: '2023-06-07T06:00:00Z',
              value: 17,
            },
            {
              date: '2023-06-07T07:00:00Z',
              value: 18.8,
            },
            {
              date: '2023-06-07T08:00:00Z',
              value: 18.1,
            },
            {
              date: '2023-06-07T09:00:00Z',
              value: 15.9,
            },
            {
              date: '2023-06-07T10:00:00Z',
              value: 16.8,
            },
            {
              date: '2023-06-07T11:00:00Z',
              value: 16.1,
            },
            {
              date: '2023-06-07T12:00:00Z',
              value: 19.9,
            },
            {
              date: '2023-06-07T13:00:00Z',
              value: 22.7,
            },
            {
              date: '2023-06-07T14:00:00Z',
              value: 19,
            },
            {
              date: '2023-06-07T15:00:00Z',
              value: 18.9,
            },
            {
              date: '2023-06-07T16:00:00Z',
              value: 16.6,
            },
            {
              date: '2023-06-07T17:00:00Z',
              value: 14.9,
            },
            {
              date: '2023-06-07T18:00:00Z',
              value: 13.9,
            },
            {
              date: '2023-06-07T19:00:00Z',
              value: 12.7,
            },
            {
              date: '2023-06-07T20:00:00Z',
              value: 9.5,
            },
            {
              date: '2023-06-07T21:00:00Z',
              value: 9.2,
            },
            {
              date: '2023-06-07T22:00:00Z',
              value: 8.1,
            },
            {
              date: '2023-06-07T23:00:00Z',
              value: 10.8,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 11.3,
            },
            {
              date: '2023-06-08T01:00:00Z',
              value: 11.6,
            },
            {
              date: '2023-06-08T02:00:00Z',
              value: 8.1,
            },
            {
              date: '2023-06-08T03:00:00Z',
              value: 10.1,
            },
            {
              date: '2023-06-08T04:00:00Z',
              value: 10.5,
            },
            {
              date: '2023-06-08T05:00:00Z',
              value: 10.6,
            },
            {
              date: '2023-06-08T06:00:00Z',
              value: 10.3,
            },
            {
              date: '2023-06-08T07:00:00Z',
              value: 15.6,
            },
            {
              date: '2023-06-08T08:00:00Z',
              value: 14.3,
            },
            {
              date: '2023-06-08T09:00:00Z',
              value: 14.7,
            },
            {
              date: '2023-06-08T10:00:00Z',
              value: 16.9,
            },
            {
              date: '2023-06-08T11:00:00Z',
              value: 20.7,
            },
            {
              date: '2023-06-08T12:00:00Z',
              value: 20.6,
            },
            {
              date: '2023-06-08T13:00:00Z',
              value: 18.8,
            },
            {
              date: '2023-06-08T14:00:00Z',
              value: 16.9,
            },
            {
              date: '2023-06-08T15:00:00Z',
              value: 15.3,
            },
            {
              date: '2023-06-08T16:00:00Z',
              value: 14,
            },
            {
              date: '2023-06-08T17:00:00Z',
              value: 15.6,
            },
            {
              date: '2023-06-08T18:00:00Z',
              value: 13.2,
            },
            {
              date: '2023-06-08T19:00:00Z',
              value: 11.9,
            },
            {
              date: '2023-06-08T20:00:00Z',
              value: 10.6,
            },
            {
              date: '2023-06-08T21:00:00Z',
              value: 9.3,
            },
            {
              date: '2023-06-08T22:00:00Z',
              value: 9.1,
            },
            {
              date: '2023-06-08T23:00:00Z',
              value: 8.9,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 8.7,
            },
            {
              date: '2023-06-09T01:00:00Z',
              value: 10.1,
            },
            {
              date: '2023-06-09T02:00:00Z',
              value: 11.5,
            },
            {
              date: '2023-06-09T03:00:00Z',
              value: 12.9,
            },
            {
              date: '2023-06-09T04:00:00Z',
              value: 12.8,
            },
            {
              date: '2023-06-09T05:00:00Z',
              value: 12.7,
            },
            {
              date: '2023-06-09T06:00:00Z',
              value: 12.6,
            },
            {
              date: '2023-06-09T07:00:00Z',
              value: 13.6,
            },
            {
              date: '2023-06-09T08:00:00Z',
              value: 14.5,
            },
            {
              date: '2023-06-09T09:00:00Z',
              value: 15.5,
            },
            {
              date: '2023-06-09T10:00:00Z',
              value: 15.8,
            },
            {
              date: '2023-06-09T11:00:00Z',
              value: 16.2,
            },
            {
              date: '2023-06-09T12:00:00Z',
              value: 16.6,
            },
            {
              date: '2023-06-09T13:00:00Z',
              value: 16.2,
            },
            {
              date: '2023-06-09T14:00:00Z',
              value: 15.8,
            },
            {
              date: '2023-06-09T15:00:00Z',
              value: 15.5,
            },
            {
              date: '2023-06-09T16:00:00Z',
              value: 15.6,
            },
            {
              date: '2023-06-09T17:00:00Z',
              value: 15.8,
            },
            {
              date: '2023-06-09T18:00:00Z',
              value: 15.9,
            },
            {
              date: '2023-06-09T19:00:00Z',
              value: 14.7,
            },
            {
              date: '2023-06-09T20:00:00Z',
              value: 13.5,
            },
            {
              date: '2023-06-09T21:00:00Z',
              value: 12.4,
            },
            {
              date: '2023-06-09T22:00:00Z',
              value: 11.9,
            },
            {
              date: '2023-06-09T23:00:00Z',
              value: 11.5,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 11.1,
            },
            {
              date: '2023-06-10T01:00:00Z',
              value: 9.9,
            },
            {
              date: '2023-06-10T02:00:00Z',
              value: 8.7,
            },
            {
              date: '2023-06-10T03:00:00Z',
              value: 7.5,
            },
            {
              date: '2023-06-10T04:00:00Z',
              value: 9.5,
            },
            {
              date: '2023-06-10T05:00:00Z',
              value: 11.4,
            },
            {
              date: '2023-06-10T06:00:00Z',
              value: 13.4,
            },
            {
              date: '2023-06-10T07:00:00Z',
              value: 15.3,
            },
            {
              date: '2023-06-10T08:00:00Z',
              value: 17.3,
            },
            {
              date: '2023-06-10T09:00:00Z',
              value: 19.2,
            },
            {
              date: '2023-06-10T10:00:00Z',
              value: 21.3,
            },
            {
              date: '2023-06-10T11:00:00Z',
              value: 23.4,
            },
            {
              date: '2023-06-10T12:00:00Z',
              value: 25.4,
            },
            {
              date: '2023-06-10T13:00:00Z',
              value: 22,
            },
            {
              date: '2023-06-10T14:00:00Z',
              value: 18.5,
            },
            {
              date: '2023-06-10T15:00:00Z',
              value: 15,
            },
            {
              date: '2023-06-10T16:00:00Z',
              value: 14.8,
            },
            {
              date: '2023-06-10T17:00:00Z',
              value: 14.6,
            },
            {
              date: '2023-06-10T18:00:00Z',
              value: 14.5,
            },
            {
              date: '2023-06-10T19:00:00Z',
              value: 12.8,
            },
            {
              date: '2023-06-10T20:00:00Z',
              value: 11.2,
            },
            {
              date: '2023-06-10T21:00:00Z',
              value: 9.5,
            },
            {
              date: '2023-06-10T22:00:00Z',
              value: 9.9,
            },
            {
              date: '2023-06-10T23:00:00Z',
              value: 10.3,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 10.7,
            },
            {
              date: '2023-06-11T01:00:00Z',
              value: 10.7,
            },
            {
              date: '2023-06-11T02:00:00Z',
              value: 10.6,
            },
            {
              date: '2023-06-11T03:00:00Z',
              value: 10.6,
            },
            {
              date: '2023-06-11T04:00:00Z',
              value: 13.3,
            },
            {
              date: '2023-06-11T05:00:00Z',
              value: 15.9,
            },
            {
              date: '2023-06-11T06:00:00Z',
              value: 18.6,
            },
            {
              date: '2023-06-11T07:00:00Z',
              value: 17.3,
            },
            {
              date: '2023-06-11T08:00:00Z',
              value: 16.1,
            },
            {
              date: '2023-06-11T09:00:00Z',
              value: 14.9,
            },
            {
              date: '2023-06-11T10:00:00Z',
              value: 15.6,
            },
            {
              date: '2023-06-11T11:00:00Z',
              value: 16.4,
            },
            {
              date: '2023-06-11T12:00:00Z',
              value: 17.2,
            },
            {
              date: '2023-06-11T13:00:00Z',
              value: 16.1,
            },
            {
              date: '2023-06-11T14:00:00Z',
              value: 15,
            },
            {
              date: '2023-06-11T15:00:00Z',
              value: 13.9,
            },
            {
              date: '2023-06-11T16:00:00Z',
              value: 14,
            },
            {
              date: '2023-06-11T17:00:00Z',
              value: 14.1,
            },
            {
              date: '2023-06-11T18:00:00Z',
              value: 14.1,
            },
            {
              date: '2023-06-11T19:00:00Z',
              value: 12.7,
            },
            {
              date: '2023-06-11T20:00:00Z',
              value: 11.3,
            },
            {
              date: '2023-06-11T21:00:00Z',
              value: 10,
            },
            {
              date: '2023-06-11T22:00:00Z',
              value: 10,
            },
            {
              date: '2023-06-11T23:00:00Z',
              value: 10,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 10,
            },
            {
              date: '2023-06-12T01:00:00Z',
              value: 9.4,
            },
            {
              date: '2023-06-12T02:00:00Z',
              value: 8.8,
            },
            {
              date: '2023-06-12T03:00:00Z',
              value: 8.2,
            },
            {
              date: '2023-06-12T04:00:00Z',
              value: 8.7,
            },
            {
              date: '2023-06-12T05:00:00Z',
              value: 9.2,
            },
            {
              date: '2023-06-12T06:00:00Z',
              value: 9.7,
            },
            {
              date: '2023-06-12T07:00:00Z',
              value: 11.7,
            },
            {
              date: '2023-06-12T08:00:00Z',
              value: 13.7,
            },
            {
              date: '2023-06-12T09:00:00Z',
              value: 15.7,
            },
            {
              date: '2023-06-12T10:00:00Z',
              value: 15.9,
            },
            {
              date: '2023-06-12T11:00:00Z',
              value: 16,
            },
            {
              date: '2023-06-12T12:00:00Z',
              value: 16.2,
            },
            {
              date: '2023-06-12T13:00:00Z',
              value: 15.5,
            },
            {
              date: '2023-06-12T14:00:00Z',
              value: 14.9,
            },
            {
              date: '2023-06-12T15:00:00Z',
              value: 14.3,
            },
            {
              date: '2023-06-12T16:00:00Z',
              value: 13.7,
            },
            {
              date: '2023-06-12T17:00:00Z',
              value: 13.1,
            },
            {
              date: '2023-06-12T18:00:00Z',
              value: 12.5,
            },
            {
              date: '2023-06-12T19:00:00Z',
              value: 12.2,
            },
            {
              date: '2023-06-12T20:00:00Z',
              value: 12,
            },
            {
              date: '2023-06-12T21:00:00Z',
              value: 11.8,
            },
            {
              date: '2023-06-12T22:00:00Z',
              value: 11.6,
            },
            {
              date: '2023-06-12T23:00:00Z',
              value: 11.4,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 11.1,
            },
            {
              date: '2023-06-13T01:00:00Z',
              value: 10.5,
            },
            {
              date: '2023-06-13T02:00:00Z',
              value: 9.9,
            },
            {
              date: '2023-06-13T03:00:00Z',
              value: 9.3,
            },
            {
              date: '2023-06-13T04:00:00Z',
              value: 8.7,
            },
            {
              date: '2023-06-13T05:00:00Z',
              value: 8.1,
            },
            {
              date: '2023-06-13T06:00:00Z',
              value: 7.5,
            },
            {
              date: '2023-06-13T07:00:00Z',
              value: 8.9,
            },
            {
              date: '2023-06-13T08:00:00Z',
              value: 10.2,
            },
            {
              date: '2023-06-13T09:00:00Z',
              value: 11.6,
            },
            {
              date: '2023-06-13T10:00:00Z',
              value: 13,
            },
            {
              date: '2023-06-13T11:00:00Z',
              value: 14.4,
            },
            {
              date: '2023-06-13T12:00:00Z',
              value: 15.8,
            },
            {
              date: '2023-06-13T13:00:00Z',
              value: 15.2,
            },
            {
              date: '2023-06-13T14:00:00Z',
              value: 14.6,
            },
            {
              date: '2023-06-13T15:00:00Z',
              value: 14,
            },
            {
              date: '2023-06-13T16:00:00Z',
              value: 13.4,
            },
            {
              date: '2023-06-13T17:00:00Z',
              value: 12.9,
            },
            {
              date: '2023-06-13T18:00:00Z',
              value: 12.3,
            },
            {
              date: '2023-06-13T19:00:00Z',
              value: 12.1,
            },
            {
              date: '2023-06-13T20:00:00Z',
              value: 12,
            },
            {
              date: '2023-06-13T21:00:00Z',
              value: 11.8,
            },
            {
              date: '2023-06-13T22:00:00Z',
              value: 11.6,
            },
            {
              date: '2023-06-13T23:00:00Z',
              value: 11.5,
            },
          ],
        },
      ],
    },
    {
      parameter: 'wind_dir_10m:d',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 208.7,
            },
            {
              date: '2023-06-07T01:00:00Z',
              value: 214.3,
            },
            {
              date: '2023-06-07T02:00:00Z',
              value: 218.5,
            },
            {
              date: '2023-06-07T03:00:00Z',
              value: 219,
            },
            {
              date: '2023-06-07T04:00:00Z',
              value: 221.5,
            },
            {
              date: '2023-06-07T05:00:00Z',
              value: 229.7,
            },
            {
              date: '2023-06-07T06:00:00Z',
              value: 235.3,
            },
            {
              date: '2023-06-07T07:00:00Z',
              value: 232.8,
            },
            {
              date: '2023-06-07T08:00:00Z',
              value: 237,
            },
            {
              date: '2023-06-07T09:00:00Z',
              value: 236.1,
            },
            {
              date: '2023-06-07T10:00:00Z',
              value: 236.2,
            },
            {
              date: '2023-06-07T11:00:00Z',
              value: 234.5,
            },
            {
              date: '2023-06-07T12:00:00Z',
              value: 228.4,
            },
            {
              date: '2023-06-07T13:00:00Z',
              value: 216,
            },
            {
              date: '2023-06-07T14:00:00Z',
              value: 221.8,
            },
            {
              date: '2023-06-07T15:00:00Z',
              value: 204.8,
            },
            {
              date: '2023-06-07T16:00:00Z',
              value: 198.1,
            },
            {
              date: '2023-06-07T17:00:00Z',
              value: 200.3,
            },
            {
              date: '2023-06-07T18:00:00Z',
              value: 206.2,
            },
            {
              date: '2023-06-07T19:00:00Z',
              value: 214.4,
            },
            {
              date: '2023-06-07T20:00:00Z',
              value: 206.2,
            },
            {
              date: '2023-06-07T21:00:00Z',
              value: 197,
            },
            {
              date: '2023-06-07T22:00:00Z',
              value: 210.5,
            },
            {
              date: '2023-06-07T23:00:00Z',
              value: 207.6,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 221.4,
            },
            {
              date: '2023-06-08T01:00:00Z',
              value: 222.3,
            },
            {
              date: '2023-06-08T02:00:00Z',
              value: 205.3,
            },
            {
              date: '2023-06-08T03:00:00Z',
              value: 197.9,
            },
            {
              date: '2023-06-08T04:00:00Z',
              value: 210.5,
            },
            {
              date: '2023-06-08T05:00:00Z',
              value: 215.5,
            },
            {
              date: '2023-06-08T06:00:00Z',
              value: 210.2,
            },
            {
              date: '2023-06-08T07:00:00Z',
              value: 236.5,
            },
            {
              date: '2023-06-08T08:00:00Z',
              value: 244.6,
            },
            {
              date: '2023-06-08T09:00:00Z',
              value: 239.8,
            },
            {
              date: '2023-06-08T10:00:00Z',
              value: 221.4,
            },
            {
              date: '2023-06-08T11:00:00Z',
              value: 232.4,
            },
            {
              date: '2023-06-08T12:00:00Z',
              value: 223.3,
            },
            {
              date: '2023-06-08T13:00:00Z',
              value: 215.5,
            },
            {
              date: '2023-06-08T14:00:00Z',
              value: 202.4,
            },
            {
              date: '2023-06-08T15:00:00Z',
              value: 210,
            },
            {
              date: '2023-06-08T16:00:00Z',
              value: 202.1,
            },
            {
              date: '2023-06-08T17:00:00Z',
              value: 202.1,
            },
            {
              date: '2023-06-08T18:00:00Z',
              value: 209.5,
            },
            {
              date: '2023-06-08T19:00:00Z',
              value: 207.7,
            },
            {
              date: '2023-06-08T20:00:00Z',
              value: 205.3,
            },
            {
              date: '2023-06-08T21:00:00Z',
              value: 202.3,
            },
            {
              date: '2023-06-08T22:00:00Z',
              value: 206.5,
            },
            {
              date: '2023-06-08T23:00:00Z',
              value: 210.9,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 215.5,
            },
            {
              date: '2023-06-09T01:00:00Z',
              value: 212.2,
            },
            {
              date: '2023-06-09T02:00:00Z',
              value: 209.7,
            },
            {
              date: '2023-06-09T03:00:00Z',
              value: 207.7,
            },
            {
              date: '2023-06-09T04:00:00Z',
              value: 215,
            },
            {
              date: '2023-06-09T05:00:00Z',
              value: 222.5,
            },
            {
              date: '2023-06-09T06:00:00Z',
              value: 230,
            },
            {
              date: '2023-06-09T07:00:00Z',
              value: 232.5,
            },
            {
              date: '2023-06-09T08:00:00Z',
              value: 234.6,
            },
            {
              date: '2023-06-09T09:00:00Z',
              value: 236.5,
            },
            {
              date: '2023-06-09T10:00:00Z',
              value: 233.2,
            },
            {
              date: '2023-06-09T11:00:00Z',
              value: 230,
            },
            {
              date: '2023-06-09T12:00:00Z',
              value: 227,
            },
            {
              date: '2023-06-09T13:00:00Z',
              value: 226.6,
            },
            {
              date: '2023-06-09T14:00:00Z',
              value: 226.1,
            },
            {
              date: '2023-06-09T15:00:00Z',
              value: 225.6,
            },
            {
              date: '2023-06-09T16:00:00Z',
              value: 221.5,
            },
            {
              date: '2023-06-09T17:00:00Z',
              value: 217.4,
            },
            {
              date: '2023-06-09T18:00:00Z',
              value: 213.5,
            },
            {
              date: '2023-06-09T19:00:00Z',
              value: 213.8,
            },
            {
              date: '2023-06-09T20:00:00Z',
              value: 214.1,
            },
            {
              date: '2023-06-09T21:00:00Z',
              value: 214.6,
            },
            {
              date: '2023-06-09T22:00:00Z',
              value: 218.8,
            },
            {
              date: '2023-06-09T23:00:00Z',
              value: 223.4,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 228.3,
            },
            {
              date: '2023-06-10T01:00:00Z',
              value: 224.5,
            },
            {
              date: '2023-06-10T02:00:00Z',
              value: 219.6,
            },
            {
              date: '2023-06-10T03:00:00Z',
              value: 213.1,
            },
            {
              date: '2023-06-10T04:00:00Z',
              value: 229.8,
            },
            {
              date: '2023-06-10T05:00:00Z',
              value: 241,
            },
            {
              date: '2023-06-10T06:00:00Z',
              value: 248.5,
            },
            {
              date: '2023-06-10T07:00:00Z',
              value: 243.4,
            },
            {
              date: '2023-06-10T08:00:00Z',
              value: 239.3,
            },
            {
              date: '2023-06-10T09:00:00Z',
              value: 236.1,
            },
            {
              date: '2023-06-10T10:00:00Z',
              value: 232.9,
            },
            {
              date: '2023-06-10T11:00:00Z',
              value: 230.2,
            },
            {
              date: '2023-06-10T12:00:00Z',
              value: 227.9,
            },
            {
              date: '2023-06-10T13:00:00Z',
              value: 223.6,
            },
            {
              date: '2023-06-10T14:00:00Z',
              value: 217.6,
            },
            {
              date: '2023-06-10T15:00:00Z',
              value: 208.8,
            },
            {
              date: '2023-06-10T16:00:00Z',
              value: 207.6,
            },
            {
              date: '2023-06-10T17:00:00Z',
              value: 206.5,
            },
            {
              date: '2023-06-10T18:00:00Z',
              value: 205.3,
            },
            {
              date: '2023-06-10T19:00:00Z',
              value: 209.7,
            },
            {
              date: '2023-06-10T20:00:00Z',
              value: 215.4,
            },
            {
              date: '2023-06-10T21:00:00Z',
              value: 223,
            },
            {
              date: '2023-06-10T22:00:00Z',
              value: 221.4,
            },
            {
              date: '2023-06-10T23:00:00Z',
              value: 220,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 218.6,
            },
            {
              date: '2023-06-11T01:00:00Z',
              value: 215.4,
            },
            {
              date: '2023-06-11T02:00:00Z',
              value: 212.2,
            },
            {
              date: '2023-06-11T03:00:00Z',
              value: 209.1,
            },
            {
              date: '2023-06-11T04:00:00Z',
              value: 228.9,
            },
            {
              date: '2023-06-11T05:00:00Z',
              value: 242.6,
            },
            {
              date: '2023-06-11T06:00:00Z',
              value: 251.7,
            },
            {
              date: '2023-06-11T07:00:00Z',
              value: 246,
            },
            {
              date: '2023-06-11T08:00:00Z',
              value: 239.4,
            },
            {
              date: '2023-06-11T09:00:00Z',
              value: 231.8,
            },
            {
              date: '2023-06-11T10:00:00Z',
              value: 227.6,
            },
            {
              date: '2023-06-11T11:00:00Z',
              value: 223.8,
            },
            {
              date: '2023-06-11T12:00:00Z',
              value: 220.3,
            },
            {
              date: '2023-06-11T13:00:00Z',
              value: 216.4,
            },
            {
              date: '2023-06-11T14:00:00Z',
              value: 211.9,
            },
            {
              date: '2023-06-11T15:00:00Z',
              value: 206.7,
            },
            {
              date: '2023-06-11T16:00:00Z',
              value: 203.3,
            },
            {
              date: '2023-06-11T17:00:00Z',
              value: 200,
            },
            {
              date: '2023-06-11T18:00:00Z',
              value: 196.6,
            },
            {
              date: '2023-06-11T19:00:00Z',
              value: 198.1,
            },
            {
              date: '2023-06-11T20:00:00Z',
              value: 199.9,
            },
            {
              date: '2023-06-11T21:00:00Z',
              value: 202.3,
            },
            {
              date: '2023-06-11T22:00:00Z',
              value: 206.8,
            },
            {
              date: '2023-06-11T23:00:00Z',
              value: 211.4,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 215.9,
            },
            {
              date: '2023-06-12T01:00:00Z',
              value: 208.6,
            },
            {
              date: '2023-06-12T02:00:00Z',
              value: 200.2,
            },
            {
              date: '2023-06-12T03:00:00Z',
              value: 190.8,
            },
            {
              date: '2023-06-12T04:00:00Z',
              value: 198.8,
            },
            {
              date: '2023-06-12T05:00:00Z',
              value: 206.2,
            },
            {
              date: '2023-06-12T06:00:00Z',
              value: 212.6,
            },
            {
              date: '2023-06-12T07:00:00Z',
              value: 221,
            },
            {
              date: '2023-06-12T08:00:00Z',
              value: 226.9,
            },
            {
              date: '2023-06-12T09:00:00Z',
              value: 231.3,
            },
            {
              date: '2023-06-12T10:00:00Z',
              value: 229.9,
            },
            {
              date: '2023-06-12T11:00:00Z',
              value: 228.5,
            },
            {
              date: '2023-06-12T12:00:00Z',
              value: 227.2,
            },
            {
              date: '2023-06-12T13:00:00Z',
              value: 224,
            },
            {
              date: '2023-06-12T14:00:00Z',
              value: 220.4,
            },
            {
              date: '2023-06-12T15:00:00Z',
              value: 216.5,
            },
            {
              date: '2023-06-12T16:00:00Z',
              value: 212.3,
            },
            {
              date: '2023-06-12T17:00:00Z',
              value: 207.6,
            },
            {
              date: '2023-06-12T18:00:00Z',
              value: 202.6,
            },
            {
              date: '2023-06-12T19:00:00Z',
              value: 204.7,
            },
            {
              date: '2023-06-12T20:00:00Z',
              value: 206.9,
            },
            {
              date: '2023-06-12T21:00:00Z',
              value: 209.2,
            },
            {
              date: '2023-06-12T22:00:00Z',
              value: 211.6,
            },
            {
              date: '2023-06-12T23:00:00Z',
              value: 214,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 216.5,
            },
            {
              date: '2023-06-13T01:00:00Z',
              value: 215.2,
            },
            {
              date: '2023-06-13T02:00:00Z',
              value: 213.7,
            },
            {
              date: '2023-06-13T03:00:00Z',
              value: 211.9,
            },
            {
              date: '2023-06-13T04:00:00Z',
              value: 209.9,
            },
            {
              date: '2023-06-13T05:00:00Z',
              value: 207.7,
            },
            {
              date: '2023-06-13T06:00:00Z',
              value: 205,
            },
            {
              date: '2023-06-13T07:00:00Z',
              value: 208.4,
            },
            {
              date: '2023-06-13T08:00:00Z',
              value: 210.8,
            },
            {
              date: '2023-06-13T09:00:00Z',
              value: 212.7,
            },
            {
              date: '2023-06-13T10:00:00Z',
              value: 214.2,
            },
            {
              date: '2023-06-13T11:00:00Z',
              value: 215.4,
            },
            {
              date: '2023-06-13T12:00:00Z',
              value: 216.4,
            },
            {
              date: '2023-06-13T13:00:00Z',
              value: 213.6,
            },
            {
              date: '2023-06-13T14:00:00Z',
              value: 210.6,
            },
            {
              date: '2023-06-13T15:00:00Z',
              value: 207.3,
            },
            {
              date: '2023-06-13T16:00:00Z',
              value: 203.7,
            },
            {
              date: '2023-06-13T17:00:00Z',
              value: 199.8,
            },
            {
              date: '2023-06-13T18:00:00Z',
              value: 195.7,
            },
            {
              date: '2023-06-13T19:00:00Z',
              value: 196.1,
            },
            {
              date: '2023-06-13T20:00:00Z',
              value: 196.6,
            },
            {
              date: '2023-06-13T21:00:00Z',
              value: 197.2,
            },
            {
              date: '2023-06-13T22:00:00Z',
              value: 197.7,
            },
            {
              date: '2023-06-13T23:00:00Z',
              value: 198.3,
            },
          ],
        },
      ],
    },
    {
      parameter: 'effective_cloud_cover:p',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 48.2,
            },
            {
              date: '2023-06-07T01:00:00Z',
              value: 24.6,
            },
            {
              date: '2023-06-07T02:00:00Z',
              value: 31,
            },
            {
              date: '2023-06-07T03:00:00Z',
              value: 49.3,
            },
            {
              date: '2023-06-07T04:00:00Z',
              value: 29,
            },
            {
              date: '2023-06-07T05:00:00Z',
              value: 4.9,
            },
            {
              date: '2023-06-07T06:00:00Z',
              value: 5.6,
            },
            {
              date: '2023-06-07T07:00:00Z',
              value: 17.2,
            },
            {
              date: '2023-06-07T08:00:00Z',
              value: 51.7,
            },
            {
              date: '2023-06-07T09:00:00Z',
              value: 63.2,
            },
            {
              date: '2023-06-07T10:00:00Z',
              value: 38,
            },
            {
              date: '2023-06-07T11:00:00Z',
              value: 30.1,
            },
            {
              date: '2023-06-07T12:00:00Z',
              value: 52,
            },
            {
              date: '2023-06-07T13:00:00Z',
              value: 57.7,
            },
            {
              date: '2023-06-07T14:00:00Z',
              value: 42.4,
            },
            {
              date: '2023-06-07T15:00:00Z',
              value: 23.9,
            },
            {
              date: '2023-06-07T16:00:00Z',
              value: 20.3,
            },
            {
              date: '2023-06-07T17:00:00Z',
              value: 26.9,
            },
            {
              date: '2023-06-07T18:00:00Z',
              value: 27.2,
            },
            {
              date: '2023-06-07T19:00:00Z',
              value: 45.2,
            },
            {
              date: '2023-06-07T20:00:00Z',
              value: 53.2,
            },
            {
              date: '2023-06-07T21:00:00Z',
              value: 54.1,
            },
            {
              date: '2023-06-07T22:00:00Z',
              value: 53.7,
            },
            {
              date: '2023-06-07T23:00:00Z',
              value: 53.2,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 76.9,
            },
            {
              date: '2023-06-08T01:00:00Z',
              value: 79.3,
            },
            {
              date: '2023-06-08T02:00:00Z',
              value: 58.3,
            },
            {
              date: '2023-06-08T03:00:00Z',
              value: 50.8,
            },
            {
              date: '2023-06-08T04:00:00Z',
              value: 50.1,
            },
            {
              date: '2023-06-08T05:00:00Z',
              value: 51.4,
            },
            {
              date: '2023-06-08T06:00:00Z',
              value: 51,
            },
            {
              date: '2023-06-08T07:00:00Z',
              value: 69.8,
            },
            {
              date: '2023-06-08T08:00:00Z',
              value: 46.4,
            },
            {
              date: '2023-06-08T09:00:00Z',
              value: 62.1,
            },
            {
              date: '2023-06-08T10:00:00Z',
              value: 46.4,
            },
            {
              date: '2023-06-08T11:00:00Z',
              value: 46.7,
            },
            {
              date: '2023-06-08T12:00:00Z',
              value: 40.9,
            },
            {
              date: '2023-06-08T13:00:00Z',
              value: 37.4,
            },
            {
              date: '2023-06-08T14:00:00Z',
              value: 53.5,
            },
            {
              date: '2023-06-08T15:00:00Z',
              value: 55.7,
            },
            {
              date: '2023-06-08T16:00:00Z',
              value: 50.5,
            },
            {
              date: '2023-06-08T17:00:00Z',
              value: 12.3,
            },
            {
              date: '2023-06-08T18:00:00Z',
              value: 17.2,
            },
            {
              date: '2023-06-08T19:00:00Z',
              value: 12.3,
            },
            {
              date: '2023-06-08T20:00:00Z',
              value: 7.4,
            },
            {
              date: '2023-06-08T21:00:00Z',
              value: 2.4,
            },
            {
              date: '2023-06-08T22:00:00Z',
              value: 12.9,
            },
            {
              date: '2023-06-08T23:00:00Z',
              value: 22.9,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 32.5,
            },
            {
              date: '2023-06-09T01:00:00Z',
              value: 36.3,
            },
            {
              date: '2023-06-09T02:00:00Z',
              value: 40.3,
            },
            {
              date: '2023-06-09T03:00:00Z',
              value: 44.4,
            },
            {
              date: '2023-06-09T04:00:00Z',
              value: 37.5,
            },
            {
              date: '2023-06-09T05:00:00Z',
              value: 31.1,
            },
            {
              date: '2023-06-09T06:00:00Z',
              value: 25.2,
            },
            {
              date: '2023-06-09T07:00:00Z',
              value: 36.9,
            },
            {
              date: '2023-06-09T08:00:00Z',
              value: 48,
            },
            {
              date: '2023-06-09T09:00:00Z',
              value: 58.4,
            },
            {
              date: '2023-06-09T10:00:00Z',
              value: 57.6,
            },
            {
              date: '2023-06-09T11:00:00Z',
              value: 56.9,
            },
            {
              date: '2023-06-09T12:00:00Z',
              value: 56.2,
            },
            {
              date: '2023-06-09T13:00:00Z',
              value: 57.5,
            },
            {
              date: '2023-06-09T14:00:00Z',
              value: 59,
            },
            {
              date: '2023-06-09T15:00:00Z',
              value: 60.5,
            },
            {
              date: '2023-06-09T16:00:00Z',
              value: 58.1,
            },
            {
              date: '2023-06-09T17:00:00Z',
              value: 55.8,
            },
            {
              date: '2023-06-09T18:00:00Z',
              value: 53.4,
            },
            {
              date: '2023-06-09T19:00:00Z',
              value: 55.3,
            },
            {
              date: '2023-06-09T20:00:00Z',
              value: 57.9,
            },
            {
              date: '2023-06-09T21:00:00Z',
              value: 61.1,
            },
            {
              date: '2023-06-09T22:00:00Z',
              value: 66.8,
            },
            {
              date: '2023-06-09T23:00:00Z',
              value: 72,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 76.7,
            },
            {
              date: '2023-06-10T01:00:00Z',
              value: 65.4,
            },
            {
              date: '2023-06-10T02:00:00Z',
              value: 51,
            },
            {
              date: '2023-06-10T03:00:00Z',
              value: 33.3,
            },
            {
              date: '2023-06-10T04:00:00Z',
              value: 29.5,
            },
            {
              date: '2023-06-10T05:00:00Z',
              value: 26,
            },
            {
              date: '2023-06-10T06:00:00Z',
              value: 22.8,
            },
            {
              date: '2023-06-10T07:00:00Z',
              value: 34.1,
            },
            {
              date: '2023-06-10T08:00:00Z',
              value: 44.5,
            },
            {
              date: '2023-06-10T09:00:00Z',
              value: 54,
            },
            {
              date: '2023-06-10T10:00:00Z',
              value: 56.2,
            },
            {
              date: '2023-06-10T11:00:00Z',
              value: 58.7,
            },
            {
              date: '2023-06-10T12:00:00Z',
              value: 61.3,
            },
            {
              date: '2023-06-10T13:00:00Z',
              value: 57.4,
            },
            {
              date: '2023-06-10T14:00:00Z',
              value: 54.4,
            },
            {
              date: '2023-06-10T15:00:00Z',
              value: 52.5,
            },
            {
              date: '2023-06-10T16:00:00Z',
              value: 59.8,
            },
            {
              date: '2023-06-10T17:00:00Z',
              value: 66.6,
            },
            {
              date: '2023-06-10T18:00:00Z',
              value: 72.9,
            },
            {
              date: '2023-06-10T19:00:00Z',
              value: 57.2,
            },
            {
              date: '2023-06-10T20:00:00Z',
              value: 37.3,
            },
            {
              date: '2023-06-10T21:00:00Z',
              value: 13,
            },
            {
              date: '2023-06-10T22:00:00Z',
              value: 26.9,
            },
            {
              date: '2023-06-10T23:00:00Z',
              value: 40,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 52.3,
            },
            {
              date: '2023-06-11T01:00:00Z',
              value: 54.5,
            },
            {
              date: '2023-06-11T02:00:00Z',
              value: 56.9,
            },
            {
              date: '2023-06-11T03:00:00Z',
              value: 59.7,
            },
            {
              date: '2023-06-11T04:00:00Z',
              value: 57.2,
            },
            {
              date: '2023-06-11T05:00:00Z',
              value: 55.3,
            },
            {
              date: '2023-06-11T06:00:00Z',
              value: 53.9,
            },
            {
              date: '2023-06-11T07:00:00Z',
              value: 54.8,
            },
            {
              date: '2023-06-11T08:00:00Z',
              value: 55.7,
            },
            {
              date: '2023-06-11T09:00:00Z',
              value: 56.6,
            },
            {
              date: '2023-06-11T10:00:00Z',
              value: 55,
            },
            {
              date: '2023-06-11T11:00:00Z',
              value: 53.4,
            },
            {
              date: '2023-06-11T12:00:00Z',
              value: 51.7,
            },
            {
              date: '2023-06-11T13:00:00Z',
              value: 46.9,
            },
            {
              date: '2023-06-11T14:00:00Z',
              value: 42.4,
            },
            {
              date: '2023-06-11T15:00:00Z',
              value: 38.1,
            },
            {
              date: '2023-06-11T16:00:00Z',
              value: 37.8,
            },
            {
              date: '2023-06-11T17:00:00Z',
              value: 38.4,
            },
            {
              date: '2023-06-11T18:00:00Z',
              value: 40.1,
            },
            {
              date: '2023-06-11T19:00:00Z',
              value: 34.5,
            },
            {
              date: '2023-06-11T20:00:00Z',
              value: 28.6,
            },
            {
              date: '2023-06-11T21:00:00Z',
              value: 22.6,
            },
            {
              date: '2023-06-11T22:00:00Z',
              value: 29.2,
            },
            {
              date: '2023-06-11T23:00:00Z',
              value: 36.1,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 43.3,
            },
            {
              date: '2023-06-12T01:00:00Z',
              value: 49.4,
            },
            {
              date: '2023-06-12T02:00:00Z',
              value: 55.1,
            },
            {
              date: '2023-06-12T03:00:00Z',
              value: 60.3,
            },
            {
              date: '2023-06-12T04:00:00Z',
              value: 59,
            },
            {
              date: '2023-06-12T05:00:00Z',
              value: 58,
            },
            {
              date: '2023-06-12T06:00:00Z',
              value: 57.2,
            },
            {
              date: '2023-06-12T07:00:00Z',
              value: 61,
            },
            {
              date: '2023-06-12T08:00:00Z',
              value: 64.8,
            },
            {
              date: '2023-06-12T09:00:00Z',
              value: 68.4,
            },
            {
              date: '2023-06-12T10:00:00Z',
              value: 66.3,
            },
            {
              date: '2023-06-12T11:00:00Z',
              value: 64.4,
            },
            {
              date: '2023-06-12T12:00:00Z',
              value: 62.6,
            },
            {
              date: '2023-06-12T13:00:00Z',
              value: 62.3,
            },
            {
              date: '2023-06-12T14:00:00Z',
              value: 62,
            },
            {
              date: '2023-06-12T15:00:00Z',
              value: 61.7,
            },
            {
              date: '2023-06-12T16:00:00Z',
              value: 61.4,
            },
            {
              date: '2023-06-12T17:00:00Z',
              value: 61.1,
            },
            {
              date: '2023-06-12T18:00:00Z',
              value: 60.8,
            },
            {
              date: '2023-06-12T19:00:00Z',
              value: 62.6,
            },
            {
              date: '2023-06-12T20:00:00Z',
              value: 64.3,
            },
            {
              date: '2023-06-12T21:00:00Z',
              value: 65.9,
            },
            {
              date: '2023-06-12T22:00:00Z',
              value: 67.6,
            },
            {
              date: '2023-06-12T23:00:00Z',
              value: 69.2,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 70.7,
            },
            {
              date: '2023-06-13T01:00:00Z',
              value: 69.3,
            },
            {
              date: '2023-06-13T02:00:00Z',
              value: 67.8,
            },
            {
              date: '2023-06-13T03:00:00Z',
              value: 66.4,
            },
            {
              date: '2023-06-13T04:00:00Z',
              value: 64.8,
            },
            {
              date: '2023-06-13T05:00:00Z',
              value: 63.3,
            },
            {
              date: '2023-06-13T06:00:00Z',
              value: 61.7,
            },
            {
              date: '2023-06-13T07:00:00Z',
              value: 64.5,
            },
            {
              date: '2023-06-13T08:00:00Z',
              value: 67.3,
            },
            {
              date: '2023-06-13T09:00:00Z',
              value: 70.1,
            },
            {
              date: '2023-06-13T10:00:00Z',
              value: 73,
            },
            {
              date: '2023-06-13T11:00:00Z',
              value: 75.9,
            },
            {
              date: '2023-06-13T12:00:00Z',
              value: 78.8,
            },
            {
              date: '2023-06-13T13:00:00Z',
              value: 82.6,
            },
            {
              date: '2023-06-13T14:00:00Z',
              value: 86.2,
            },
            {
              date: '2023-06-13T15:00:00Z',
              value: 89.7,
            },
            {
              date: '2023-06-13T16:00:00Z',
              value: 93,
            },
            {
              date: '2023-06-13T17:00:00Z',
              value: 96.1,
            },
            {
              date: '2023-06-13T18:00:00Z',
              value: 99,
            },
            {
              date: '2023-06-13T19:00:00Z',
              value: 99.2,
            },
            {
              date: '2023-06-13T20:00:00Z',
              value: 99.3,
            },
            {
              date: '2023-06-13T21:00:00Z',
              value: 99.5,
            },
            {
              date: '2023-06-13T22:00:00Z',
              value: 99.7,
            },
            {
              date: '2023-06-13T23:00:00Z',
              value: 99.8,
            },
          ],
        },
      ],
    },
    {
      parameter: 'visibility:km',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 20.5,
            },
            {
              date: '2023-06-07T01:00:00Z',
              value: 21.6,
            },
            {
              date: '2023-06-07T02:00:00Z',
              value: 24.1,
            },
            {
              date: '2023-06-07T03:00:00Z',
              value: 25.9,
            },
            {
              date: '2023-06-07T04:00:00Z',
              value: 26.5,
            },
            {
              date: '2023-06-07T05:00:00Z',
              value: 27.8,
            },
            {
              date: '2023-06-07T06:00:00Z',
              value: 28.6,
            },
            {
              date: '2023-06-07T07:00:00Z',
              value: 28.6,
            },
            {
              date: '2023-06-07T08:00:00Z',
              value: 27.8,
            },
            {
              date: '2023-06-07T09:00:00Z',
              value: 26.3,
            },
            {
              date: '2023-06-07T10:00:00Z',
              value: 27.7,
            },
            {
              date: '2023-06-07T11:00:00Z',
              value: 27.7,
            },
            {
              date: '2023-06-07T12:00:00Z',
              value: 26.5,
            },
            {
              date: '2023-06-07T13:00:00Z',
              value: 24.8,
            },
            {
              date: '2023-06-07T14:00:00Z',
              value: 24.3,
            },
            {
              date: '2023-06-07T15:00:00Z',
              value: 22.3,
            },
            {
              date: '2023-06-07T16:00:00Z',
              value: 21.3,
            },
            {
              date: '2023-06-07T17:00:00Z',
              value: 20.7,
            },
            {
              date: '2023-06-07T18:00:00Z',
              value: 18,
            },
            {
              date: '2023-06-07T19:00:00Z',
              value: 20.2,
            },
            {
              date: '2023-06-07T20:00:00Z',
              value: 20.6,
            },
            {
              date: '2023-06-07T21:00:00Z',
              value: 19.2,
            },
            {
              date: '2023-06-07T22:00:00Z',
              value: 19,
            },
            {
              date: '2023-06-07T23:00:00Z',
              value: 18.9,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 19.8,
            },
            {
              date: '2023-06-08T01:00:00Z',
              value: 19.9,
            },
            {
              date: '2023-06-08T02:00:00Z',
              value: 22.7,
            },
            {
              date: '2023-06-08T03:00:00Z',
              value: 24.6,
            },
            {
              date: '2023-06-08T04:00:00Z',
              value: 26.4,
            },
            {
              date: '2023-06-08T05:00:00Z',
              value: 27.7,
            },
            {
              date: '2023-06-08T06:00:00Z',
              value: 28,
            },
            {
              date: '2023-06-08T07:00:00Z',
              value: 15.5,
            },
            {
              date: '2023-06-08T08:00:00Z',
              value: 24.6,
            },
            {
              date: '2023-06-08T09:00:00Z',
              value: 22,
            },
            {
              date: '2023-06-08T10:00:00Z',
              value: 22.7,
            },
            {
              date: '2023-06-08T11:00:00Z',
              value: 27.4,
            },
            {
              date: '2023-06-08T12:00:00Z',
              value: 26.1,
            },
            {
              date: '2023-06-08T13:00:00Z',
              value: 24.5,
            },
            {
              date: '2023-06-08T14:00:00Z',
              value: 22.6,
            },
            {
              date: '2023-06-08T15:00:00Z',
              value: 22.2,
            },
            {
              date: '2023-06-08T16:00:00Z',
              value: 21.6,
            },
            {
              date: '2023-06-08T17:00:00Z',
              value: 21,
            },
            {
              date: '2023-06-08T18:00:00Z',
              value: 20.7,
            },
            {
              date: '2023-06-08T19:00:00Z',
              value: 20.7,
            },
            {
              date: '2023-06-08T20:00:00Z',
              value: 20.7,
            },
            {
              date: '2023-06-08T21:00:00Z',
              value: 20.6,
            },
            {
              date: '2023-06-08T22:00:00Z',
              value: 19.7,
            },
            {
              date: '2023-06-08T23:00:00Z',
              value: 18.8,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 17.9,
            },
            {
              date: '2023-06-09T01:00:00Z',
              value: 20.2,
            },
            {
              date: '2023-06-09T02:00:00Z',
              value: 22.4,
            },
            {
              date: '2023-06-09T03:00:00Z',
              value: 24.6,
            },
            {
              date: '2023-06-09T04:00:00Z',
              value: 25.8,
            },
            {
              date: '2023-06-09T05:00:00Z',
              value: 26.9,
            },
            {
              date: '2023-06-09T06:00:00Z',
              value: 28.1,
            },
            {
              date: '2023-06-09T07:00:00Z',
              value: 23.8,
            },
            {
              date: '2023-06-09T08:00:00Z',
              value: 19.5,
            },
            {
              date: '2023-06-09T09:00:00Z',
              value: 15.2,
            },
            {
              date: '2023-06-09T10:00:00Z',
              value: 19,
            },
            {
              date: '2023-06-09T11:00:00Z',
              value: 22.7,
            },
            {
              date: '2023-06-09T12:00:00Z',
              value: 26.5,
            },
            {
              date: '2023-06-09T13:00:00Z',
              value: 25.3,
            },
            {
              date: '2023-06-09T14:00:00Z',
              value: 24.2,
            },
            {
              date: '2023-06-09T15:00:00Z',
              value: 23,
            },
            {
              date: '2023-06-09T16:00:00Z',
              value: 22.4,
            },
            {
              date: '2023-06-09T17:00:00Z',
              value: 21.8,
            },
            {
              date: '2023-06-09T18:00:00Z',
              value: 21.3,
            },
            {
              date: '2023-06-09T19:00:00Z',
              value: 21.2,
            },
            {
              date: '2023-06-09T20:00:00Z',
              value: 21.1,
            },
            {
              date: '2023-06-09T21:00:00Z',
              value: 21,
            },
            {
              date: '2023-06-09T22:00:00Z',
              value: 18.4,
            },
            {
              date: '2023-06-09T23:00:00Z',
              value: 15.8,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 13.2,
            },
            {
              date: '2023-06-10T01:00:00Z',
              value: 17,
            },
            {
              date: '2023-06-10T02:00:00Z',
              value: 20.8,
            },
            {
              date: '2023-06-10T03:00:00Z',
              value: 24.6,
            },
            {
              date: '2023-06-10T04:00:00Z',
              value: 25.7,
            },
            {
              date: '2023-06-10T05:00:00Z',
              value: 26.7,
            },
            {
              date: '2023-06-10T06:00:00Z',
              value: 27.7,
            },
            {
              date: '2023-06-10T07:00:00Z',
              value: 27.7,
            },
            {
              date: '2023-06-10T08:00:00Z',
              value: 27.8,
            },
            {
              date: '2023-06-10T09:00:00Z',
              value: 27.8,
            },
            {
              date: '2023-06-10T10:00:00Z',
              value: 27.4,
            },
            {
              date: '2023-06-10T11:00:00Z',
              value: 27,
            },
            {
              date: '2023-06-10T12:00:00Z',
              value: 26.6,
            },
            {
              date: '2023-06-10T13:00:00Z',
              value: 25.3,
            },
            {
              date: '2023-06-10T14:00:00Z',
              value: 24.1,
            },
            {
              date: '2023-06-10T15:00:00Z',
              value: 22.9,
            },
            {
              date: '2023-06-10T16:00:00Z',
              value: 21.5,
            },
            {
              date: '2023-06-10T17:00:00Z',
              value: 20.2,
            },
            {
              date: '2023-06-10T18:00:00Z',
              value: 18.9,
            },
            {
              date: '2023-06-10T19:00:00Z',
              value: 18.8,
            },
            {
              date: '2023-06-10T20:00:00Z',
              value: 18.7,
            },
            {
              date: '2023-06-10T21:00:00Z',
              value: 18.6,
            },
            {
              date: '2023-06-10T22:00:00Z',
              value: 17.1,
            },
            {
              date: '2023-06-10T23:00:00Z',
              value: 15.6,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 14.1,
            },
            {
              date: '2023-06-11T01:00:00Z',
              value: 17.1,
            },
            {
              date: '2023-06-11T02:00:00Z',
              value: 20.2,
            },
            {
              date: '2023-06-11T03:00:00Z',
              value: 23.2,
            },
            {
              date: '2023-06-11T04:00:00Z',
              value: 24.9,
            },
            {
              date: '2023-06-11T05:00:00Z',
              value: 26.6,
            },
            {
              date: '2023-06-11T06:00:00Z',
              value: 28.3,
            },
            {
              date: '2023-06-11T07:00:00Z',
              value: 28.3,
            },
            {
              date: '2023-06-11T08:00:00Z',
              value: 28.3,
            },
            {
              date: '2023-06-11T09:00:00Z',
              value: 28.3,
            },
            {
              date: '2023-06-11T10:00:00Z',
              value: 27.8,
            },
            {
              date: '2023-06-11T11:00:00Z',
              value: 27.2,
            },
            {
              date: '2023-06-11T12:00:00Z',
              value: 26.7,
            },
            {
              date: '2023-06-11T13:00:00Z',
              value: 25.5,
            },
            {
              date: '2023-06-11T14:00:00Z',
              value: 24.3,
            },
            {
              date: '2023-06-11T15:00:00Z',
              value: 23.1,
            },
            {
              date: '2023-06-11T16:00:00Z',
              value: 20.2,
            },
            {
              date: '2023-06-11T17:00:00Z',
              value: 17.2,
            },
            {
              date: '2023-06-11T18:00:00Z',
              value: 14.3,
            },
            {
              date: '2023-06-11T19:00:00Z',
              value: 14.8,
            },
            {
              date: '2023-06-11T20:00:00Z',
              value: 15.4,
            },
            {
              date: '2023-06-11T21:00:00Z',
              value: 16,
            },
            {
              date: '2023-06-11T22:00:00Z',
              value: 15.1,
            },
            {
              date: '2023-06-11T23:00:00Z',
              value: 14.1,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 13.1,
            },
            {
              date: '2023-06-12T01:00:00Z',
              value: 17,
            },
            {
              date: '2023-06-12T02:00:00Z',
              value: 20.8,
            },
            {
              date: '2023-06-12T03:00:00Z',
              value: 24.6,
            },
            {
              date: '2023-06-12T04:00:00Z',
              value: 25.9,
            },
            {
              date: '2023-06-12T05:00:00Z',
              value: 27.2,
            },
            {
              date: '2023-06-12T06:00:00Z',
              value: 28.5,
            },
            {
              date: '2023-06-12T07:00:00Z',
              value: 27.5,
            },
            {
              date: '2023-06-12T08:00:00Z',
              value: 26.6,
            },
            {
              date: '2023-06-12T09:00:00Z',
              value: 25.6,
            },
            {
              date: '2023-06-12T10:00:00Z',
              value: 25.9,
            },
            {
              date: '2023-06-12T11:00:00Z',
              value: 26.1,
            },
            {
              date: '2023-06-12T12:00:00Z',
              value: 26.4,
            },
            {
              date: '2023-06-12T13:00:00Z',
              value: 25.4,
            },
            {
              date: '2023-06-12T14:00:00Z',
              value: 24.4,
            },
            {
              date: '2023-06-12T15:00:00Z',
              value: 23.4,
            },
            {
              date: '2023-06-12T16:00:00Z',
              value: 22.4,
            },
            {
              date: '2023-06-12T17:00:00Z',
              value: 21.4,
            },
            {
              date: '2023-06-12T18:00:00Z',
              value: 20.4,
            },
            {
              date: '2023-06-12T19:00:00Z',
              value: 19.9,
            },
            {
              date: '2023-06-12T20:00:00Z',
              value: 19.4,
            },
            {
              date: '2023-06-12T21:00:00Z',
              value: 18.9,
            },
            {
              date: '2023-06-12T22:00:00Z',
              value: 18.4,
            },
            {
              date: '2023-06-12T23:00:00Z',
              value: 17.9,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 17.4,
            },
            {
              date: '2023-06-13T01:00:00Z',
              value: 19.2,
            },
            {
              date: '2023-06-13T02:00:00Z',
              value: 21,
            },
            {
              date: '2023-06-13T03:00:00Z',
              value: 22.8,
            },
            {
              date: '2023-06-13T04:00:00Z',
              value: 24.6,
            },
            {
              date: '2023-06-13T05:00:00Z',
              value: 26.3,
            },
            {
              date: '2023-06-13T06:00:00Z',
              value: 28.1,
            },
            {
              date: '2023-06-13T07:00:00Z',
              value: 27.7,
            },
            {
              date: '2023-06-13T08:00:00Z',
              value: 27.2,
            },
            {
              date: '2023-06-13T09:00:00Z',
              value: 26.8,
            },
            {
              date: '2023-06-13T10:00:00Z',
              value: 26.3,
            },
            {
              date: '2023-06-13T11:00:00Z',
              value: 25.9,
            },
            {
              date: '2023-06-13T12:00:00Z',
              value: 25.4,
            },
            {
              date: '2023-06-13T13:00:00Z',
              value: 23.6,
            },
            {
              date: '2023-06-13T14:00:00Z',
              value: 21.8,
            },
            {
              date: '2023-06-13T15:00:00Z',
              value: 20,
            },
            {
              date: '2023-06-13T16:00:00Z',
              value: 18.3,
            },
            {
              date: '2023-06-13T17:00:00Z',
              value: 16.5,
            },
            {
              date: '2023-06-13T18:00:00Z',
              value: 14.7,
            },
            {
              date: '2023-06-13T19:00:00Z',
              value: 15.5,
            },
            {
              date: '2023-06-13T20:00:00Z',
              value: 16.2,
            },
            {
              date: '2023-06-13T21:00:00Z',
              value: 17,
            },
            {
              date: '2023-06-13T22:00:00Z',
              value: 17.8,
            },
            {
              date: '2023-06-13T23:00:00Z',
              value: 18.6,
            },
          ],
        },
      ],
    },
    {
      parameter: 'relative_humidity_2m:p',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 85.5,
            },
            {
              date: '2023-06-07T01:00:00Z',
              value: 83.7,
            },
            {
              date: '2023-06-07T02:00:00Z',
              value: 75.5,
            },
            {
              date: '2023-06-07T03:00:00Z',
              value: 64.9,
            },
            {
              date: '2023-06-07T04:00:00Z',
              value: 55.7,
            },
            {
              date: '2023-06-07T05:00:00Z',
              value: 49.8,
            },
            {
              date: '2023-06-07T06:00:00Z',
              value: 43.8,
            },
            {
              date: '2023-06-07T07:00:00Z',
              value: 41.5,
            },
            {
              date: '2023-06-07T08:00:00Z',
              value: 45,
            },
            {
              date: '2023-06-07T09:00:00Z',
              value: 50,
            },
            {
              date: '2023-06-07T10:00:00Z',
              value: 47.9,
            },
            {
              date: '2023-06-07T11:00:00Z',
              value: 49.8,
            },
            {
              date: '2023-06-07T12:00:00Z',
              value: 57.2,
            },
            {
              date: '2023-06-07T13:00:00Z',
              value: 67.7,
            },
            {
              date: '2023-06-07T14:00:00Z',
              value: 75.5,
            },
            {
              date: '2023-06-07T15:00:00Z',
              value: 78.7,
            },
            {
              date: '2023-06-07T16:00:00Z',
              value: 82.7,
            },
            {
              date: '2023-06-07T17:00:00Z',
              value: 85.2,
            },
            {
              date: '2023-06-07T18:00:00Z',
              value: 86,
            },
            {
              date: '2023-06-07T19:00:00Z',
              value: 86,
            },
            {
              date: '2023-06-07T20:00:00Z',
              value: 87.3,
            },
            {
              date: '2023-06-07T21:00:00Z',
              value: 89.3,
            },
            {
              date: '2023-06-07T22:00:00Z',
              value: 90.5,
            },
            {
              date: '2023-06-07T23:00:00Z',
              value: 90.2,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 88.3,
            },
            {
              date: '2023-06-08T01:00:00Z',
              value: 87.2,
            },
            {
              date: '2023-06-08T02:00:00Z',
              value: 79.6,
            },
            {
              date: '2023-06-08T03:00:00Z',
              value: 69.7,
            },
            {
              date: '2023-06-08T04:00:00Z',
              value: 58.1,
            },
            {
              date: '2023-06-08T05:00:00Z',
              value: 51.2,
            },
            {
              date: '2023-06-08T06:00:00Z',
              value: 45.7,
            },
            {
              date: '2023-06-08T07:00:00Z',
              value: 46,
            },
            {
              date: '2023-06-08T08:00:00Z',
              value: 45.3,
            },
            {
              date: '2023-06-08T09:00:00Z',
              value: 46.1,
            },
            {
              date: '2023-06-08T10:00:00Z',
              value: 51.5,
            },
            {
              date: '2023-06-08T11:00:00Z',
              value: 54.7,
            },
            {
              date: '2023-06-08T12:00:00Z',
              value: 63.5,
            },
            {
              date: '2023-06-08T13:00:00Z',
              value: 73.1,
            },
            {
              date: '2023-06-08T14:00:00Z',
              value: 78.9,
            },
            {
              date: '2023-06-08T15:00:00Z',
              value: 81.6,
            },
            {
              date: '2023-06-08T16:00:00Z',
              value: 83.5,
            },
            {
              date: '2023-06-08T17:00:00Z',
              value: 85.8,
            },
            {
              date: '2023-06-08T18:00:00Z',
              value: 86.6,
            },
            {
              date: '2023-06-08T19:00:00Z',
              value: 87,
            },
            {
              date: '2023-06-08T20:00:00Z',
              value: 87.4,
            },
            {
              date: '2023-06-08T21:00:00Z',
              value: 87.8,
            },
            {
              date: '2023-06-08T22:00:00Z',
              value: 89.5,
            },
            {
              date: '2023-06-08T23:00:00Z',
              value: 91.2,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 93,
            },
            {
              date: '2023-06-09T01:00:00Z',
              value: 83.9,
            },
            {
              date: '2023-06-09T02:00:00Z',
              value: 75.9,
            },
            {
              date: '2023-06-09T03:00:00Z',
              value: 68.6,
            },
            {
              date: '2023-06-09T04:00:00Z',
              value: 60.7,
            },
            {
              date: '2023-06-09T05:00:00Z',
              value: 53.7,
            },
            {
              date: '2023-06-09T06:00:00Z',
              value: 47.6,
            },
            {
              date: '2023-06-09T07:00:00Z',
              value: 49,
            },
            {
              date: '2023-06-09T08:00:00Z',
              value: 50.5,
            },
            {
              date: '2023-06-09T09:00:00Z',
              value: 52,
            },
            {
              date: '2023-06-09T10:00:00Z',
              value: 55.5,
            },
            {
              date: '2023-06-09T11:00:00Z',
              value: 59.2,
            },
            {
              date: '2023-06-09T12:00:00Z',
              value: 63.3,
            },
            {
              date: '2023-06-09T13:00:00Z',
              value: 68.8,
            },
            {
              date: '2023-06-09T14:00:00Z',
              value: 74.9,
            },
            {
              date: '2023-06-09T15:00:00Z',
              value: 81.6,
            },
            {
              date: '2023-06-09T16:00:00Z',
              value: 82.7,
            },
            {
              date: '2023-06-09T17:00:00Z',
              value: 83.9,
            },
            {
              date: '2023-06-09T18:00:00Z',
              value: 85,
            },
            {
              date: '2023-06-09T19:00:00Z',
              value: 84.6,
            },
            {
              date: '2023-06-09T20:00:00Z',
              value: 84.2,
            },
            {
              date: '2023-06-09T21:00:00Z',
              value: 83.8,
            },
            {
              date: '2023-06-09T22:00:00Z',
              value: 84.2,
            },
            {
              date: '2023-06-09T23:00:00Z',
              value: 84.6,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 85,
            },
            {
              date: '2023-06-10T01:00:00Z',
              value: 79.7,
            },
            {
              date: '2023-06-10T02:00:00Z',
              value: 74.8,
            },
            {
              date: '2023-06-10T03:00:00Z',
              value: 70.2,
            },
            {
              date: '2023-06-10T04:00:00Z',
              value: 62.2,
            },
            {
              date: '2023-06-10T05:00:00Z',
              value: 55.2,
            },
            {
              date: '2023-06-10T06:00:00Z',
              value: 49,
            },
            {
              date: '2023-06-10T07:00:00Z',
              value: 48.7,
            },
            {
              date: '2023-06-10T08:00:00Z',
              value: 48.4,
            },
            {
              date: '2023-06-10T09:00:00Z',
              value: 48.1,
            },
            {
              date: '2023-06-10T10:00:00Z',
              value: 52.6,
            },
            {
              date: '2023-06-10T11:00:00Z',
              value: 57.5,
            },
            {
              date: '2023-06-10T12:00:00Z',
              value: 63,
            },
            {
              date: '2023-06-10T13:00:00Z',
              value: 68.2,
            },
            {
              date: '2023-06-10T14:00:00Z',
              value: 73.9,
            },
            {
              date: '2023-06-10T15:00:00Z',
              value: 80.1,
            },
            {
              date: '2023-06-10T16:00:00Z',
              value: 82.3,
            },
            {
              date: '2023-06-10T17:00:00Z',
              value: 84.6,
            },
            {
              date: '2023-06-10T18:00:00Z',
              value: 87,
            },
            {
              date: '2023-06-10T19:00:00Z',
              value: 87.9,
            },
            {
              date: '2023-06-10T20:00:00Z',
              value: 88.8,
            },
            {
              date: '2023-06-10T21:00:00Z',
              value: 89.8,
            },
            {
              date: '2023-06-10T22:00:00Z',
              value: 89.8,
            },
            {
              date: '2023-06-10T23:00:00Z',
              value: 89.8,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 89.8,
            },
            {
              date: '2023-06-11T01:00:00Z',
              value: 82.5,
            },
            {
              date: '2023-06-11T02:00:00Z',
              value: 75.9,
            },
            {
              date: '2023-06-11T03:00:00Z',
              value: 69.9,
            },
            {
              date: '2023-06-11T04:00:00Z',
              value: 60.9,
            },
            {
              date: '2023-06-11T05:00:00Z',
              value: 53.1,
            },
            {
              date: '2023-06-11T06:00:00Z',
              value: 46.4,
            },
            {
              date: '2023-06-11T07:00:00Z',
              value: 46.7,
            },
            {
              date: '2023-06-11T08:00:00Z',
              value: 46.9,
            },
            {
              date: '2023-06-11T09:00:00Z',
              value: 47.2,
            },
            {
              date: '2023-06-11T10:00:00Z',
              value: 51.3,
            },
            {
              date: '2023-06-11T11:00:00Z',
              value: 55.7,
            },
            {
              date: '2023-06-11T12:00:00Z',
              value: 60.6,
            },
            {
              date: '2023-06-11T13:00:00Z',
              value: 66.1,
            },
            {
              date: '2023-06-11T14:00:00Z',
              value: 72,
            },
            {
              date: '2023-06-11T15:00:00Z',
              value: 78.6,
            },
            {
              date: '2023-06-11T16:00:00Z',
              value: 82,
            },
            {
              date: '2023-06-11T17:00:00Z',
              value: 85.6,
            },
            {
              date: '2023-06-11T18:00:00Z',
              value: 89.3,
            },
            {
              date: '2023-06-11T19:00:00Z',
              value: 89.6,
            },
            {
              date: '2023-06-11T20:00:00Z',
              value: 89.9,
            },
            {
              date: '2023-06-11T21:00:00Z',
              value: 90.3,
            },
            {
              date: '2023-06-11T22:00:00Z',
              value: 90.4,
            },
            {
              date: '2023-06-11T23:00:00Z',
              value: 90.6,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 90.7,
            },
            {
              date: '2023-06-12T01:00:00Z',
              value: 83.8,
            },
            {
              date: '2023-06-12T02:00:00Z',
              value: 77.3,
            },
            {
              date: '2023-06-12T03:00:00Z',
              value: 71.4,
            },
            {
              date: '2023-06-12T04:00:00Z',
              value: 62.5,
            },
            {
              date: '2023-06-12T05:00:00Z',
              value: 54.8,
            },
            {
              date: '2023-06-12T06:00:00Z',
              value: 48.2,
            },
            {
              date: '2023-06-12T07:00:00Z',
              value: 52.5,
            },
            {
              date: '2023-06-12T08:00:00Z',
              value: 57.3,
            },
            {
              date: '2023-06-12T09:00:00Z',
              value: 62.5,
            },
            {
              date: '2023-06-12T10:00:00Z',
              value: 63.6,
            },
            {
              date: '2023-06-12T11:00:00Z',
              value: 64.9,
            },
            {
              date: '2023-06-12T12:00:00Z',
              value: 66.1,
            },
            {
              date: '2023-06-12T13:00:00Z',
              value: 69.2,
            },
            {
              date: '2023-06-12T14:00:00Z',
              value: 72.4,
            },
            {
              date: '2023-06-12T15:00:00Z',
              value: 75.8,
            },
            {
              date: '2023-06-12T16:00:00Z',
              value: 79.4,
            },
            {
              date: '2023-06-12T17:00:00Z',
              value: 83.2,
            },
            {
              date: '2023-06-12T18:00:00Z',
              value: 87.1,
            },
            {
              date: '2023-06-12T19:00:00Z',
              value: 86.9,
            },
            {
              date: '2023-06-12T20:00:00Z',
              value: 86.6,
            },
            {
              date: '2023-06-12T21:00:00Z',
              value: 86.4,
            },
            {
              date: '2023-06-12T22:00:00Z',
              value: 86.2,
            },
            {
              date: '2023-06-12T23:00:00Z',
              value: 85.9,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 85.7,
            },
            {
              date: '2023-06-13T01:00:00Z',
              value: 79,
            },
            {
              date: '2023-06-13T02:00:00Z',
              value: 72.8,
            },
            {
              date: '2023-06-13T03:00:00Z',
              value: 67.2,
            },
            {
              date: '2023-06-13T04:00:00Z',
              value: 62,
            },
            {
              date: '2023-06-13T05:00:00Z',
              value: 57.3,
            },
            {
              date: '2023-06-13T06:00:00Z',
              value: 53,
            },
            {
              date: '2023-06-13T07:00:00Z',
              value: 55.4,
            },
            {
              date: '2023-06-13T08:00:00Z',
              value: 57.8,
            },
            {
              date: '2023-06-13T09:00:00Z',
              value: 60.4,
            },
            {
              date: '2023-06-13T10:00:00Z',
              value: 63.1,
            },
            {
              date: '2023-06-13T11:00:00Z',
              value: 65.9,
            },
            {
              date: '2023-06-13T12:00:00Z',
              value: 68.9,
            },
            {
              date: '2023-06-13T13:00:00Z',
              value: 72,
            },
            {
              date: '2023-06-13T14:00:00Z',
              value: 75.2,
            },
            {
              date: '2023-06-13T15:00:00Z',
              value: 78.7,
            },
            {
              date: '2023-06-13T16:00:00Z',
              value: 82.3,
            },
            {
              date: '2023-06-13T17:00:00Z',
              value: 86,
            },
            {
              date: '2023-06-13T18:00:00Z',
              value: 90,
            },
            {
              date: '2023-06-13T19:00:00Z',
              value: 89.6,
            },
            {
              date: '2023-06-13T20:00:00Z',
              value: 89.2,
            },
            {
              date: '2023-06-13T21:00:00Z',
              value: 88.8,
            },
            {
              date: '2023-06-13T22:00:00Z',
              value: 88.4,
            },
            {
              date: '2023-06-13T23:00:00Z',
              value: 88,
            },
          ],
        },
      ],
    },
    {
      parameter: 'prob_precip_1h:p',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T01:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T02:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T03:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T04:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T05:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T06:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T07:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T08:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T09:00:00Z',
              value: 13.7,
            },
            {
              date: '2023-06-07T10:00:00Z',
              value: 5,
            },
            {
              date: '2023-06-07T11:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T12:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T13:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T14:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T15:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T16:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T17:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T18:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T19:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T20:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T21:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T22:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T23:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T01:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T02:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T03:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T04:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T05:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T06:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T07:00:00Z',
              value: 48.3,
            },
            {
              date: '2023-06-08T08:00:00Z',
              value: 12.4,
            },
            {
              date: '2023-06-08T09:00:00Z',
              value: 4.4,
            },
            {
              date: '2023-06-08T10:00:00Z',
              value: 3.9,
            },
            {
              date: '2023-06-08T11:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T12:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T13:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T14:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T15:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T16:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T17:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T18:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T19:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T20:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T21:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T22:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T23:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T01:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T02:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T03:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T04:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T05:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T06:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T07:00:00Z',
              value: 16.8,
            },
            {
              date: '2023-06-09T08:00:00Z',
              value: 21.6,
            },
            {
              date: '2023-06-09T09:00:00Z',
              value: 26.1,
            },
            {
              date: '2023-06-09T10:00:00Z',
              value: 15.1,
            },
            {
              date: '2023-06-09T11:00:00Z',
              value: 14.9,
            },
            {
              date: '2023-06-09T12:00:00Z',
              value: 14.7,
            },
            {
              date: '2023-06-09T13:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T14:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T15:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T16:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T17:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T18:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T19:00:00Z',
              value: 2.3,
            },
            {
              date: '2023-06-09T20:00:00Z',
              value: 2.4,
            },
            {
              date: '2023-06-09T21:00:00Z',
              value: 2.5,
            },
            {
              date: '2023-06-09T22:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T23:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T01:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T02:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T03:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T04:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T05:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T06:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T07:00:00Z',
              value: 2.3,
            },
            {
              date: '2023-06-10T08:00:00Z',
              value: 2.7,
            },
            {
              date: '2023-06-10T09:00:00Z',
              value: 3,
            },
            {
              date: '2023-06-10T10:00:00Z',
              value: 4.8,
            },
            {
              date: '2023-06-10T11:00:00Z',
              value: 5,
            },
            {
              date: '2023-06-10T12:00:00Z',
              value: 5.1,
            },
            {
              date: '2023-06-10T13:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T14:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T15:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T16:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T17:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T18:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T19:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T20:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T21:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T22:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T23:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T01:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T02:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T03:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T04:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T05:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T06:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T07:00:00Z',
              value: 4.3,
            },
            {
              date: '2023-06-11T08:00:00Z',
              value: 4.3,
            },
            {
              date: '2023-06-11T09:00:00Z',
              value: 4.4,
            },
            {
              date: '2023-06-11T10:00:00Z',
              value: 5.6,
            },
            {
              date: '2023-06-11T11:00:00Z',
              value: 5.5,
            },
            {
              date: '2023-06-11T12:00:00Z',
              value: 5.3,
            },
            {
              date: '2023-06-11T13:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T14:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T15:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T16:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T17:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T18:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T19:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T20:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T21:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T22:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T23:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T01:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T02:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T03:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T04:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T05:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T06:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T07:00:00Z',
              value: 58.4,
            },
            {
              date: '2023-06-12T08:00:00Z',
              value: 61.9,
            },
            {
              date: '2023-06-12T09:00:00Z',
              value: 65.3,
            },
            {
              date: '2023-06-12T10:00:00Z',
              value: 16.7,
            },
            {
              date: '2023-06-12T11:00:00Z',
              value: 16.3,
            },
            {
              date: '2023-06-12T12:00:00Z',
              value: 15.9,
            },
            {
              date: '2023-06-12T13:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T14:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T15:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T16:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T17:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T18:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T19:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T20:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T21:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T22:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T23:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T01:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T02:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T03:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T04:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T05:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T06:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T07:00:00Z',
              value: 33.2,
            },
            {
              date: '2023-06-13T08:00:00Z',
              value: 34.6,
            },
            {
              date: '2023-06-13T09:00:00Z',
              value: 36.1,
            },
            {
              date: '2023-06-13T10:00:00Z',
              value: 37.5,
            },
            {
              date: '2023-06-13T11:00:00Z',
              value: 39,
            },
            {
              date: '2023-06-13T12:00:00Z',
              value: 40.5,
            },
            {
              date: '2023-06-13T13:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T14:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T15:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T16:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T17:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T18:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T19:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T20:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T21:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T22:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T23:00:00Z',
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export let weather_3H = {
  version: '3.0',
  user: 'kg_dronos',
  dateGenerated: '2023-06-07T05:25:28Z',
  status: 'OK',
  data: [
    {
      parameter: 't_2m:C',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 24,
            },
            {
              date: '2023-06-07T03:00:00Z',
              value: 28.4,
            },
            {
              date: '2023-06-07T06:00:00Z',
              value: 33.8,
            },
            {
              date: '2023-06-07T09:00:00Z',
              value: 32.4,
            },
            {
              date: '2023-06-07T12:00:00Z',
              value: 30.3,
            },
            {
              date: '2023-06-07T15:00:00Z',
              value: 25,
            },
            {
              date: '2023-06-07T18:00:00Z',
              value: 24,
            },
            {
              date: '2023-06-07T21:00:00Z',
              value: 23.6,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 23.4,
            },
            {
              date: '2023-06-08T03:00:00Z',
              value: 27.5,
            },
            {
              date: '2023-06-08T06:00:00Z',
              value: 33.7,
            },
            {
              date: '2023-06-08T09:00:00Z',
              value: 33.5,
            },
            {
              date: '2023-06-08T12:00:00Z',
              value: 29,
            },
            {
              date: '2023-06-08T15:00:00Z',
              value: 25,
            },
            {
              date: '2023-06-08T18:00:00Z',
              value: 24.1,
            },
            {
              date: '2023-06-08T21:00:00Z',
              value: 23.6,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 22.9,
            },
            {
              date: '2023-06-09T03:00:00Z',
              value: 27.9,
            },
            {
              date: '2023-06-09T06:00:00Z',
              value: 33.5,
            },
            {
              date: '2023-06-09T09:00:00Z',
              value: 32.1,
            },
            {
              date: '2023-06-09T12:00:00Z',
              value: 29.1,
            },
            {
              date: '2023-06-09T15:00:00Z',
              value: 25.3,
            },
            {
              date: '2023-06-09T18:00:00Z',
              value: 24.7,
            },
            {
              date: '2023-06-09T21:00:00Z',
              value: 24.5,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 23.8,
            },
            {
              date: '2023-06-10T03:00:00Z',
              value: 27.2,
            },
            {
              date: '2023-06-10T06:00:00Z',
              value: 32.7,
            },
            {
              date: '2023-06-10T09:00:00Z',
              value: 32.9,
            },
            {
              date: '2023-06-10T12:00:00Z',
              value: 28.5,
            },
            {
              date: '2023-06-10T15:00:00Z',
              value: 25.4,
            },
            {
              date: '2023-06-10T18:00:00Z',
              value: 24.3,
            },
            {
              date: '2023-06-10T21:00:00Z',
              value: 23.7,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 23.2,
            },
            {
              date: '2023-06-11T03:00:00Z',
              value: 27.3,
            },
            {
              date: '2023-06-11T06:00:00Z',
              value: 33.2,
            },
            {
              date: '2023-06-11T09:00:00Z',
              value: 33.1,
            },
            {
              date: '2023-06-11T12:00:00Z',
              value: 29.7,
            },
            {
              date: '2023-06-11T15:00:00Z',
              value: 25.5,
            },
            {
              date: '2023-06-11T18:00:00Z',
              value: 23.9,
            },
            {
              date: '2023-06-11T21:00:00Z',
              value: 23.9,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 23.8,
            },
            {
              date: '2023-06-12T03:00:00Z',
              value: 27,
            },
            {
              date: '2023-06-12T06:00:00Z',
              value: 33.1,
            },
            {
              date: '2023-06-12T09:00:00Z',
              value: 29.8,
            },
            {
              date: '2023-06-12T12:00:00Z',
              value: 28.3,
            },
            {
              date: '2023-06-12T15:00:00Z',
              value: 26.4,
            },
            {
              date: '2023-06-12T18:00:00Z',
              value: 24.5,
            },
            {
              date: '2023-06-12T21:00:00Z',
              value: 24.3,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 24,
            },
            {
              date: '2023-06-13T03:00:00Z',
              value: 28.1,
            },
            {
              date: '2023-06-13T06:00:00Z',
              value: 32.3,
            },
            {
              date: '2023-06-13T09:00:00Z',
              value: 30.3,
            },
            {
              date: '2023-06-13T12:00:00Z',
              value: 28.3,
            },
            {
              date: '2023-06-13T15:00:00Z',
              value: 26.2,
            },
            {
              date: '2023-06-13T18:00:00Z',
              value: 24,
            },
            {
              date: '2023-06-13T21:00:00Z',
              value: 23.7,
            },
          ],
        },
      ],
    },
    {
      parameter: 'uv:idx',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T03:00:00Z',
              value: 2,
            },
            {
              date: '2023-06-07T06:00:00Z',
              value: 9,
            },
            {
              date: '2023-06-07T09:00:00Z',
              value: 5,
            },
            {
              date: '2023-06-07T12:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T15:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T18:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-07T21:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T03:00:00Z',
              value: 3,
            },
            {
              date: '2023-06-08T06:00:00Z',
              value: 10,
            },
            {
              date: '2023-06-08T09:00:00Z',
              value: 4,
            },
            {
              date: '2023-06-08T12:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T15:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T18:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T21:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T03:00:00Z',
              value: 3,
            },
            {
              date: '2023-06-09T06:00:00Z',
              value: 10,
            },
            {
              date: '2023-06-09T09:00:00Z',
              value: 4,
            },
            {
              date: '2023-06-09T12:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T15:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T18:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T21:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T03:00:00Z',
              value: 3,
            },
            {
              date: '2023-06-10T06:00:00Z',
              value: 10,
            },
            {
              date: '2023-06-10T09:00:00Z',
              value: 5,
            },
            {
              date: '2023-06-10T12:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T15:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T18:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T21:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T03:00:00Z',
              value: 3,
            },
            {
              date: '2023-06-11T06:00:00Z',
              value: 8,
            },
            {
              date: '2023-06-11T09:00:00Z',
              value: 6,
            },
            {
              date: '2023-06-11T12:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T15:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T18:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T21:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T03:00:00Z',
              value: 2,
            },
            {
              date: '2023-06-12T06:00:00Z',
              value: 8,
            },
            {
              date: '2023-06-12T09:00:00Z',
              value: 4,
            },
            {
              date: '2023-06-12T12:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T15:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T18:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T21:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T03:00:00Z',
              value: 2,
            },
            {
              date: '2023-06-13T06:00:00Z',
              value: 6,
            },
            {
              date: '2023-06-13T09:00:00Z',
              value: 4,
            },
            {
              date: '2023-06-13T12:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T15:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T18:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T21:00:00Z',
              value: 0,
            },
          ],
        },
      ],
    },
    {
      parameter: 'wind_speed_10m:kmh',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 11.8,
            },
            {
              date: '2023-06-07T03:00:00Z',
              value: 9.9,
            },
            {
              date: '2023-06-07T06:00:00Z',
              value: 17,
            },
            {
              date: '2023-06-07T09:00:00Z',
              value: 15.9,
            },
            {
              date: '2023-06-07T12:00:00Z',
              value: 19.9,
            },
            {
              date: '2023-06-07T15:00:00Z',
              value: 18.9,
            },
            {
              date: '2023-06-07T18:00:00Z',
              value: 13.9,
            },
            {
              date: '2023-06-07T21:00:00Z',
              value: 9.2,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 11.3,
            },
            {
              date: '2023-06-08T03:00:00Z',
              value: 10.1,
            },
            {
              date: '2023-06-08T06:00:00Z',
              value: 10.3,
            },
            {
              date: '2023-06-08T09:00:00Z',
              value: 14.7,
            },
            {
              date: '2023-06-08T12:00:00Z',
              value: 20.6,
            },
            {
              date: '2023-06-08T15:00:00Z',
              value: 15.3,
            },
            {
              date: '2023-06-08T18:00:00Z',
              value: 13.2,
            },
            {
              date: '2023-06-08T21:00:00Z',
              value: 9.3,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 8.7,
            },
            {
              date: '2023-06-09T03:00:00Z',
              value: 12.9,
            },
            {
              date: '2023-06-09T06:00:00Z',
              value: 12.6,
            },
            {
              date: '2023-06-09T09:00:00Z',
              value: 15.5,
            },
            {
              date: '2023-06-09T12:00:00Z',
              value: 16.6,
            },
            {
              date: '2023-06-09T15:00:00Z',
              value: 15.5,
            },
            {
              date: '2023-06-09T18:00:00Z',
              value: 15.9,
            },
            {
              date: '2023-06-09T21:00:00Z',
              value: 12.4,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 11.1,
            },
            {
              date: '2023-06-10T03:00:00Z',
              value: 7.5,
            },
            {
              date: '2023-06-10T06:00:00Z',
              value: 13.4,
            },
            {
              date: '2023-06-10T09:00:00Z',
              value: 19.2,
            },
            {
              date: '2023-06-10T12:00:00Z',
              value: 25.4,
            },
            {
              date: '2023-06-10T15:00:00Z',
              value: 15,
            },
            {
              date: '2023-06-10T18:00:00Z',
              value: 14.5,
            },
            {
              date: '2023-06-10T21:00:00Z',
              value: 9.5,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 10.7,
            },
            {
              date: '2023-06-11T03:00:00Z',
              value: 10.6,
            },
            {
              date: '2023-06-11T06:00:00Z',
              value: 18.6,
            },
            {
              date: '2023-06-11T09:00:00Z',
              value: 14.9,
            },
            {
              date: '2023-06-11T12:00:00Z',
              value: 17.2,
            },
            {
              date: '2023-06-11T15:00:00Z',
              value: 13.9,
            },
            {
              date: '2023-06-11T18:00:00Z',
              value: 14.1,
            },
            {
              date: '2023-06-11T21:00:00Z',
              value: 10,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 10,
            },
            {
              date: '2023-06-12T03:00:00Z',
              value: 8.2,
            },
            {
              date: '2023-06-12T06:00:00Z',
              value: 9.7,
            },
            {
              date: '2023-06-12T09:00:00Z',
              value: 15.7,
            },
            {
              date: '2023-06-12T12:00:00Z',
              value: 16.2,
            },
            {
              date: '2023-06-12T15:00:00Z',
              value: 14.3,
            },
            {
              date: '2023-06-12T18:00:00Z',
              value: 12.5,
            },
            {
              date: '2023-06-12T21:00:00Z',
              value: 11.8,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 11.1,
            },
            {
              date: '2023-06-13T03:00:00Z',
              value: 9.3,
            },
            {
              date: '2023-06-13T06:00:00Z',
              value: 7.5,
            },
            {
              date: '2023-06-13T09:00:00Z',
              value: 11.6,
            },
            {
              date: '2023-06-13T12:00:00Z',
              value: 15.8,
            },
            {
              date: '2023-06-13T15:00:00Z',
              value: 14,
            },
            {
              date: '2023-06-13T18:00:00Z',
              value: 12.3,
            },
            {
              date: '2023-06-13T21:00:00Z',
              value: 11.8,
            },
          ],
        },
      ],
    },
    {
      parameter: 'wind_dir_10m:d',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 208.7,
            },
            {
              date: '2023-06-07T03:00:00Z',
              value: 219,
            },
            {
              date: '2023-06-07T06:00:00Z',
              value: 235.3,
            },
            {
              date: '2023-06-07T09:00:00Z',
              value: 236.1,
            },
            {
              date: '2023-06-07T12:00:00Z',
              value: 228.4,
            },
            {
              date: '2023-06-07T15:00:00Z',
              value: 204.8,
            },
            {
              date: '2023-06-07T18:00:00Z',
              value: 206.2,
            },
            {
              date: '2023-06-07T21:00:00Z',
              value: 197,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 221.4,
            },
            {
              date: '2023-06-08T03:00:00Z',
              value: 197.9,
            },
            {
              date: '2023-06-08T06:00:00Z',
              value: 210.2,
            },
            {
              date: '2023-06-08T09:00:00Z',
              value: 239.8,
            },
            {
              date: '2023-06-08T12:00:00Z',
              value: 223.3,
            },
            {
              date: '2023-06-08T15:00:00Z',
              value: 210,
            },
            {
              date: '2023-06-08T18:00:00Z',
              value: 209.5,
            },
            {
              date: '2023-06-08T21:00:00Z',
              value: 202.3,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 215.5,
            },
            {
              date: '2023-06-09T03:00:00Z',
              value: 207.7,
            },
            {
              date: '2023-06-09T06:00:00Z',
              value: 230,
            },
            {
              date: '2023-06-09T09:00:00Z',
              value: 236.5,
            },
            {
              date: '2023-06-09T12:00:00Z',
              value: 227,
            },
            {
              date: '2023-06-09T15:00:00Z',
              value: 225.6,
            },
            {
              date: '2023-06-09T18:00:00Z',
              value: 213.5,
            },
            {
              date: '2023-06-09T21:00:00Z',
              value: 214.6,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 228.3,
            },
            {
              date: '2023-06-10T03:00:00Z',
              value: 213.1,
            },
            {
              date: '2023-06-10T06:00:00Z',
              value: 248.5,
            },
            {
              date: '2023-06-10T09:00:00Z',
              value: 236.1,
            },
            {
              date: '2023-06-10T12:00:00Z',
              value: 227.9,
            },
            {
              date: '2023-06-10T15:00:00Z',
              value: 208.8,
            },
            {
              date: '2023-06-10T18:00:00Z',
              value: 205.3,
            },
            {
              date: '2023-06-10T21:00:00Z',
              value: 223,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 218.6,
            },
            {
              date: '2023-06-11T03:00:00Z',
              value: 209.1,
            },
            {
              date: '2023-06-11T06:00:00Z',
              value: 251.7,
            },
            {
              date: '2023-06-11T09:00:00Z',
              value: 231.8,
            },
            {
              date: '2023-06-11T12:00:00Z',
              value: 220.3,
            },
            {
              date: '2023-06-11T15:00:00Z',
              value: 206.7,
            },
            {
              date: '2023-06-11T18:00:00Z',
              value: 196.6,
            },
            {
              date: '2023-06-11T21:00:00Z',
              value: 202.3,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 215.9,
            },
            {
              date: '2023-06-12T03:00:00Z',
              value: 190.8,
            },
            {
              date: '2023-06-12T06:00:00Z',
              value: 212.6,
            },
            {
              date: '2023-06-12T09:00:00Z',
              value: 231.3,
            },
            {
              date: '2023-06-12T12:00:00Z',
              value: 227.2,
            },
            {
              date: '2023-06-12T15:00:00Z',
              value: 216.5,
            },
            {
              date: '2023-06-12T18:00:00Z',
              value: 202.6,
            },
            {
              date: '2023-06-12T21:00:00Z',
              value: 209.2,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 216.5,
            },
            {
              date: '2023-06-13T03:00:00Z',
              value: 211.9,
            },
            {
              date: '2023-06-13T06:00:00Z',
              value: 205,
            },
            {
              date: '2023-06-13T09:00:00Z',
              value: 212.7,
            },
            {
              date: '2023-06-13T12:00:00Z',
              value: 216.4,
            },
            {
              date: '2023-06-13T15:00:00Z',
              value: 207.3,
            },
            {
              date: '2023-06-13T18:00:00Z',
              value: 195.7,
            },
            {
              date: '2023-06-13T21:00:00Z',
              value: 197.2,
            },
          ],
        },
      ],
    },
    {
      parameter: 'effective_cloud_cover:p',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 48.2,
            },
            {
              date: '2023-06-07T03:00:00Z',
              value: 49.3,
            },
            {
              date: '2023-06-07T06:00:00Z',
              value: 5.6,
            },
            {
              date: '2023-06-07T09:00:00Z',
              value: 63.2,
            },
            {
              date: '2023-06-07T12:00:00Z',
              value: 52,
            },
            {
              date: '2023-06-07T15:00:00Z',
              value: 23.9,
            },
            {
              date: '2023-06-07T18:00:00Z',
              value: 27.2,
            },
            {
              date: '2023-06-07T21:00:00Z',
              value: 54.1,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 76.9,
            },
            {
              date: '2023-06-08T03:00:00Z',
              value: 50.8,
            },
            {
              date: '2023-06-08T06:00:00Z',
              value: 51,
            },
            {
              date: '2023-06-08T09:00:00Z',
              value: 62.1,
            },
            {
              date: '2023-06-08T12:00:00Z',
              value: 40.9,
            },
            {
              date: '2023-06-08T15:00:00Z',
              value: 55.7,
            },
            {
              date: '2023-06-08T18:00:00Z',
              value: 17.2,
            },
            {
              date: '2023-06-08T21:00:00Z',
              value: 2.4,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 32.5,
            },
            {
              date: '2023-06-09T03:00:00Z',
              value: 44.4,
            },
            {
              date: '2023-06-09T06:00:00Z',
              value: 25.2,
            },
            {
              date: '2023-06-09T09:00:00Z',
              value: 58.4,
            },
            {
              date: '2023-06-09T12:00:00Z',
              value: 56.2,
            },
            {
              date: '2023-06-09T15:00:00Z',
              value: 60.5,
            },
            {
              date: '2023-06-09T18:00:00Z',
              value: 53.4,
            },
            {
              date: '2023-06-09T21:00:00Z',
              value: 61.1,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 76.7,
            },
            {
              date: '2023-06-10T03:00:00Z',
              value: 33.3,
            },
            {
              date: '2023-06-10T06:00:00Z',
              value: 22.8,
            },
            {
              date: '2023-06-10T09:00:00Z',
              value: 54,
            },
            {
              date: '2023-06-10T12:00:00Z',
              value: 61.3,
            },
            {
              date: '2023-06-10T15:00:00Z',
              value: 52.5,
            },
            {
              date: '2023-06-10T18:00:00Z',
              value: 72.9,
            },
            {
              date: '2023-06-10T21:00:00Z',
              value: 13,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 52.3,
            },
            {
              date: '2023-06-11T03:00:00Z',
              value: 59.7,
            },
            {
              date: '2023-06-11T06:00:00Z',
              value: 53.9,
            },
            {
              date: '2023-06-11T09:00:00Z',
              value: 56.6,
            },
            {
              date: '2023-06-11T12:00:00Z',
              value: 51.7,
            },
            {
              date: '2023-06-11T15:00:00Z',
              value: 38.1,
            },
            {
              date: '2023-06-11T18:00:00Z',
              value: 40.1,
            },
            {
              date: '2023-06-11T21:00:00Z',
              value: 22.6,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 43.3,
            },
            {
              date: '2023-06-12T03:00:00Z',
              value: 60.3,
            },
            {
              date: '2023-06-12T06:00:00Z',
              value: 57.2,
            },
            {
              date: '2023-06-12T09:00:00Z',
              value: 68.4,
            },
            {
              date: '2023-06-12T12:00:00Z',
              value: 62.6,
            },
            {
              date: '2023-06-12T15:00:00Z',
              value: 61.7,
            },
            {
              date: '2023-06-12T18:00:00Z',
              value: 60.8,
            },
            {
              date: '2023-06-12T21:00:00Z',
              value: 65.9,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 70.7,
            },
            {
              date: '2023-06-13T03:00:00Z',
              value: 66.4,
            },
            {
              date: '2023-06-13T06:00:00Z',
              value: 61.7,
            },
            {
              date: '2023-06-13T09:00:00Z',
              value: 70.1,
            },
            {
              date: '2023-06-13T12:00:00Z',
              value: 78.8,
            },
            {
              date: '2023-06-13T15:00:00Z',
              value: 89.7,
            },
            {
              date: '2023-06-13T18:00:00Z',
              value: 99,
            },
            {
              date: '2023-06-13T21:00:00Z',
              value: 99.5,
            },
          ],
        },
      ],
    },
    {
      parameter: 'visibility:km',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 20.5,
            },
            {
              date: '2023-06-07T03:00:00Z',
              value: 25.9,
            },
            {
              date: '2023-06-07T06:00:00Z',
              value: 28.6,
            },
            {
              date: '2023-06-07T09:00:00Z',
              value: 26.3,
            },
            {
              date: '2023-06-07T12:00:00Z',
              value: 26.5,
            },
            {
              date: '2023-06-07T15:00:00Z',
              value: 22.3,
            },
            {
              date: '2023-06-07T18:00:00Z',
              value: 18,
            },
            {
              date: '2023-06-07T21:00:00Z',
              value: 19.2,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 19.8,
            },
            {
              date: '2023-06-08T03:00:00Z',
              value: 24.6,
            },
            {
              date: '2023-06-08T06:00:00Z',
              value: 28,
            },
            {
              date: '2023-06-08T09:00:00Z',
              value: 22,
            },
            {
              date: '2023-06-08T12:00:00Z',
              value: 26.1,
            },
            {
              date: '2023-06-08T15:00:00Z',
              value: 22.2,
            },
            {
              date: '2023-06-08T18:00:00Z',
              value: 20.7,
            },
            {
              date: '2023-06-08T21:00:00Z',
              value: 20.6,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 17.9,
            },
            {
              date: '2023-06-09T03:00:00Z',
              value: 24.6,
            },
            {
              date: '2023-06-09T06:00:00Z',
              value: 28.1,
            },
            {
              date: '2023-06-09T09:00:00Z',
              value: 15.2,
            },
            {
              date: '2023-06-09T12:00:00Z',
              value: 26.5,
            },
            {
              date: '2023-06-09T15:00:00Z',
              value: 23,
            },
            {
              date: '2023-06-09T18:00:00Z',
              value: 21.3,
            },
            {
              date: '2023-06-09T21:00:00Z',
              value: 21,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 13.2,
            },
            {
              date: '2023-06-10T03:00:00Z',
              value: 24.6,
            },
            {
              date: '2023-06-10T06:00:00Z',
              value: 27.7,
            },
            {
              date: '2023-06-10T09:00:00Z',
              value: 27.8,
            },
            {
              date: '2023-06-10T12:00:00Z',
              value: 26.6,
            },
            {
              date: '2023-06-10T15:00:00Z',
              value: 22.9,
            },
            {
              date: '2023-06-10T18:00:00Z',
              value: 18.9,
            },
            {
              date: '2023-06-10T21:00:00Z',
              value: 18.6,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 14.1,
            },
            {
              date: '2023-06-11T03:00:00Z',
              value: 23.2,
            },
            {
              date: '2023-06-11T06:00:00Z',
              value: 28.3,
            },
            {
              date: '2023-06-11T09:00:00Z',
              value: 28.3,
            },
            {
              date: '2023-06-11T12:00:00Z',
              value: 26.7,
            },
            {
              date: '2023-06-11T15:00:00Z',
              value: 23.1,
            },
            {
              date: '2023-06-11T18:00:00Z',
              value: 14.3,
            },
            {
              date: '2023-06-11T21:00:00Z',
              value: 16,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 13.1,
            },
            {
              date: '2023-06-12T03:00:00Z',
              value: 24.6,
            },
            {
              date: '2023-06-12T06:00:00Z',
              value: 28.5,
            },
            {
              date: '2023-06-12T09:00:00Z',
              value: 25.6,
            },
            {
              date: '2023-06-12T12:00:00Z',
              value: 26.4,
            },
            {
              date: '2023-06-12T15:00:00Z',
              value: 23.4,
            },
            {
              date: '2023-06-12T18:00:00Z',
              value: 20.4,
            },
            {
              date: '2023-06-12T21:00:00Z',
              value: 18.9,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 17.4,
            },
            {
              date: '2023-06-13T03:00:00Z',
              value: 22.8,
            },
            {
              date: '2023-06-13T06:00:00Z',
              value: 28.1,
            },
            {
              date: '2023-06-13T09:00:00Z',
              value: 26.8,
            },
            {
              date: '2023-06-13T12:00:00Z',
              value: 25.4,
            },
            {
              date: '2023-06-13T15:00:00Z',
              value: 20,
            },
            {
              date: '2023-06-13T18:00:00Z',
              value: 14.7,
            },
            {
              date: '2023-06-13T21:00:00Z',
              value: 17,
            },
          ],
        },
      ],
    },
    {
      parameter: 'relative_humidity_2m:p',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 85.5,
            },
            {
              date: '2023-06-07T03:00:00Z',
              value: 64.9,
            },
            {
              date: '2023-06-07T06:00:00Z',
              value: 43.8,
            },
            {
              date: '2023-06-07T09:00:00Z',
              value: 50,
            },
            {
              date: '2023-06-07T12:00:00Z',
              value: 57.2,
            },
            {
              date: '2023-06-07T15:00:00Z',
              value: 78.7,
            },
            {
              date: '2023-06-07T18:00:00Z',
              value: 86,
            },
            {
              date: '2023-06-07T21:00:00Z',
              value: 89.3,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 88.3,
            },
            {
              date: '2023-06-08T03:00:00Z',
              value: 69.7,
            },
            {
              date: '2023-06-08T06:00:00Z',
              value: 45.7,
            },
            {
              date: '2023-06-08T09:00:00Z',
              value: 46.1,
            },
            {
              date: '2023-06-08T12:00:00Z',
              value: 63.5,
            },
            {
              date: '2023-06-08T15:00:00Z',
              value: 81.6,
            },
            {
              date: '2023-06-08T18:00:00Z',
              value: 86.6,
            },
            {
              date: '2023-06-08T21:00:00Z',
              value: 87.8,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 93,
            },
            {
              date: '2023-06-09T03:00:00Z',
              value: 68.6,
            },
            {
              date: '2023-06-09T06:00:00Z',
              value: 47.6,
            },
            {
              date: '2023-06-09T09:00:00Z',
              value: 52,
            },
            {
              date: '2023-06-09T12:00:00Z',
              value: 63.3,
            },
            {
              date: '2023-06-09T15:00:00Z',
              value: 81.6,
            },
            {
              date: '2023-06-09T18:00:00Z',
              value: 85,
            },
            {
              date: '2023-06-09T21:00:00Z',
              value: 83.8,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 85,
            },
            {
              date: '2023-06-10T03:00:00Z',
              value: 70.2,
            },
            {
              date: '2023-06-10T06:00:00Z',
              value: 49,
            },
            {
              date: '2023-06-10T09:00:00Z',
              value: 48.1,
            },
            {
              date: '2023-06-10T12:00:00Z',
              value: 63,
            },
            {
              date: '2023-06-10T15:00:00Z',
              value: 80.1,
            },
            {
              date: '2023-06-10T18:00:00Z',
              value: 87,
            },
            {
              date: '2023-06-10T21:00:00Z',
              value: 89.8,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 89.8,
            },
            {
              date: '2023-06-11T03:00:00Z',
              value: 69.9,
            },
            {
              date: '2023-06-11T06:00:00Z',
              value: 46.4,
            },
            {
              date: '2023-06-11T09:00:00Z',
              value: 47.2,
            },
            {
              date: '2023-06-11T12:00:00Z',
              value: 60.6,
            },
            {
              date: '2023-06-11T15:00:00Z',
              value: 78.6,
            },
            {
              date: '2023-06-11T18:00:00Z',
              value: 89.3,
            },
            {
              date: '2023-06-11T21:00:00Z',
              value: 90.3,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 90.7,
            },
            {
              date: '2023-06-12T03:00:00Z',
              value: 71.4,
            },
            {
              date: '2023-06-12T06:00:00Z',
              value: 48.2,
            },
            {
              date: '2023-06-12T09:00:00Z',
              value: 62.5,
            },
            {
              date: '2023-06-12T12:00:00Z',
              value: 66.1,
            },
            {
              date: '2023-06-12T15:00:00Z',
              value: 75.8,
            },
            {
              date: '2023-06-12T18:00:00Z',
              value: 87.1,
            },
            {
              date: '2023-06-12T21:00:00Z',
              value: 86.4,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 85.7,
            },
            {
              date: '2023-06-13T03:00:00Z',
              value: 67.2,
            },
            {
              date: '2023-06-13T06:00:00Z',
              value: 53,
            },
            {
              date: '2023-06-13T09:00:00Z',
              value: 60.4,
            },
            {
              date: '2023-06-13T12:00:00Z',
              value: 68.9,
            },
            {
              date: '2023-06-13T15:00:00Z',
              value: 78.7,
            },
            {
              date: '2023-06-13T18:00:00Z',
              value: 90,
            },
            {
              date: '2023-06-13T21:00:00Z',
              value: 88.8,
            },
          ],
        },
      ],
    },
    {
      parameter: 'prob_precip_1h:p',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T03:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T06:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T09:00:00Z',
              value: 13.7,
            },
            {
              date: '2023-06-07T12:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T15:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T18:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-07T21:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T03:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T06:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T09:00:00Z',
              value: 4.4,
            },
            {
              date: '2023-06-08T12:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T15:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T18:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T21:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T03:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T06:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T09:00:00Z',
              value: 26.1,
            },
            {
              date: '2023-06-09T12:00:00Z',
              value: 14.7,
            },
            {
              date: '2023-06-09T15:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T18:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T21:00:00Z',
              value: 2.5,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T03:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T06:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T09:00:00Z',
              value: 3,
            },
            {
              date: '2023-06-10T12:00:00Z',
              value: 5.1,
            },
            {
              date: '2023-06-10T15:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T18:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T21:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T03:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T06:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T09:00:00Z',
              value: 4.4,
            },
            {
              date: '2023-06-11T12:00:00Z',
              value: 5.3,
            },
            {
              date: '2023-06-11T15:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T18:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T21:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T03:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T06:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T09:00:00Z',
              value: 65.3,
            },
            {
              date: '2023-06-12T12:00:00Z',
              value: 15.9,
            },
            {
              date: '2023-06-12T15:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T18:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T21:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T03:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T06:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T09:00:00Z',
              value: 36.1,
            },
            {
              date: '2023-06-13T12:00:00Z',
              value: 40.5,
            },
            {
              date: '2023-06-13T15:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T18:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T21:00:00Z',
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export let weather_24H = {
  version: '3.0',
  user: 'kg_dronos',
  dateGenerated: '2023-06-07T05:25:29Z',
  status: 'OK',
  data: [
    {
      parameter: 't_2m:C',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 24,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 23.4,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 22.9,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 23.8,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 23.2,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 23.8,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 24,
            },
          ],
        },
      ],
    },
    {
      parameter: 'uv:idx',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 0,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 0,
            },
          ],
        },
      ],
    },
    {
      parameter: 'wind_speed_10m:kmh',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 11.8,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 11.3,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 8.7,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 11.1,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 10.7,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 10,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 11.1,
            },
          ],
        },
      ],
    },
    {
      parameter: 'wind_dir_10m:d',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 208.7,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 221.4,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 215.5,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 228.3,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 218.6,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 215.9,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 216.5,
            },
          ],
        },
      ],
    },
    {
      parameter: 'effective_cloud_cover:p',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 48.2,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 76.9,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 32.5,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 76.7,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 52.3,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 43.3,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 70.7,
            },
          ],
        },
      ],
    },
    {
      parameter: 'visibility:km',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 20.5,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 19.8,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 17.9,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 13.2,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 14.1,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 13.1,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 17.4,
            },
          ],
        },
      ],
    },
    {
      parameter: 'relative_humidity_2m:p',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 85.5,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 88.3,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 93,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 85,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 89.8,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 90.7,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 85.7,
            },
          ],
        },
      ],
    },
    {
      parameter: 'prob_precip_1h:p',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-08T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-09T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-10T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-11T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-12T00:00:00Z',
              value: 1,
            },
            {
              date: '2023-06-13T00:00:00Z',
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export let weather_footer = {
  version: '3.0',
  user: 'kg_dronos',
  dateGenerated: '2023-06-07T05:25:28Z',
  status: 'OK',
  data: [
    {
      parameter: 't_2m:C',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T05:25:28Z',
              value: 32.9,
            },
          ],
        },
      ],
    },
    {
      parameter: 'wind_speed_2m:kmh',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T05:25:28Z',
              value: 12.3,
            },
          ],
        },
      ],
    },
    {
      parameter: 'effective_cloud_cover:p',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T05:25:28Z',
              value: 5.2,
            },
          ],
        },
      ],
    },
    {
      parameter: 'relative_humidity_2m:p',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T05:25:28Z',
              value: 47.2,
            },
          ],
        },
      ],
    },
  ],
};

export let weather_box = {
  version: '3.0',
  user: 'kg_dronos',
  dateGenerated: '2023-06-07T05:25:28Z',
  status: 'OK',
  data: [
    {
      parameter: 'sunrise:sql',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: 'Wednesday 7th June, 10:55:28 am',
              value: '5:57 AM',
            },
          ],
        },
      ],
    },
    {
      parameter: 'sunset:sql',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T05:25:28Z',
              value: '6:42 PM',
            },
          ],
        },
      ],
    },
    {
      parameter: 'is_rain_30min:idx',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T05:25:28Z',
              value: 0,
            },
          ],
        },
      ],
    },
    {
      parameter: 'wind_dir_10m:d',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T05:25:28Z',
              value: 231.9,
            },
          ],
        },
      ],
    },
    {
      parameter: 'wind_gusts_100m_3h:kmh',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T05:25:28Z',
              value: 29.2,
            },
          ],
        },
      ],
    },
    {
      parameter: 'relative_humidity_20m:p',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T05:25:28Z',
              value: 48.8,
            },
          ],
        },
      ],
    },
    {
      parameter: 'visibility:km',
      coordinates: [
        {
          lat: 11.092137,
          lon: 76.997168,
          dates: [
            {
              date: '2023-06-07T05:25:28Z',
              value: 28.1,
            },
          ],
        },
      ],
    },
  ],
};
