<div id="bottomMenu" [ngStyle]="!footer.show ? {'display': 'none'} : {'display': 'block'}">
  <div class="bottom_menu">
    <div class="bottom_menu_left">
      <div class="weather_box" (click)="onWeatherTap(1)" [ngClass]="{ weatherTap_active: weatherTap == 1 }" [ngStyle]="footer.children[0].show ? {'display': 'block'} : {'display': 'none'}">
        <img [src]="'/footer/image.png' | assetUrl" alt="image" />
        <span>Elevation: 300m</span>
      </div>
      <div class="weather_box" (click)="onWeatherTap(2)" [ngClass]="{ weatherTap_active: weatherTap == 2 }" [ngStyle]="footer.children[1].show ? {'display': 'block'} : {'display': 'none'}">
        <img [src]="'/footer/sun.png' | assetUrl" alt="sun" />
        <span
          >{{ currentWeatherFooterData?.data[0]?.coordinates[0]?.dates[0]?.value.toString() | slice : 0 : 4 }}°C |
          {{ (currentWeatherFooterData?.data[0]?.coordinates[0]?.dates[0]?.value * 1.8 + 32).toString() | slice : 0 : 4 }}°F
        </span>
      </div>
      <div class="weather_box" (click)="onWeatherTap(3)" [ngClass]="{ weatherTap_active: weatherTap == 3 }" [ngStyle]="footer.children[2].show ? {'display': 'block'} : {'display': 'none'}">
        <img [src]="'/footer/wind.png' | assetUrl" alt="wind" />
        <span> Wind: {{ currentWeatherFooterData?.data[1]?.coordinates[0]?.dates[0]?.value + "km/h" }}</span>
      </div>
      <div class="weather_box" (click)="onWeatherTap(4)" [ngClass]="{ weatherTap_active: weatherTap == 4 }" [ngStyle]="footer.children[3].show ? {'display': 'block'} : {'display': 'none'}">
        <img [src]="'/footer/cloud-fog.png' | assetUrl" alt="cloud-fog" />
        <span>Cloud Cover: {{ currentWeatherFooterData?.data[2]?.coordinates[0]?.dates[0]?.value + "%" }}</span>
      </div>
      <div class="weather_box" (click)="onWeatherTap(5)" [ngClass]="{ weatherTap_active: weatherTap == 5 }" [ngStyle]="footer.children[4].show ? {'display': 'block'} : {'display': 'none'}">
        <img [src]="'/footer/drop.png' | assetUrl" alt="drop" />
        <span>Moisture: {{ currentWeatherFooterData?.data[3]?.coordinates[0]?.dates[0]?.value }}</span>
      </div>
    </div>
    <div class="bottom_menu_right">
      <div class="weather_box" [ngStyle]="footer.children[5].show ? {'display': 'block'} : {'display': 'none'}">
        <img [src]="'/footer/location.png' | assetUrl" alt="location" />
        <span id="location">27.2046° N, 77.4977° E</span>
      </div>
      <div class="weather_box" [ngStyle]="footer.children[6].show ? {'display': 'block'} : {'display': 'none'}">
        <span id="locationName">No location recorded</span>
      </div>
      <div [ngStyle]="footer.children[7].show ? {'display': 'flex'} : {'display': 'none'}">
        <div class="weather_box">
          <img [src]="'/footer/Subtract.png' | assetUrl" alt="Subtract" />
        </div>
        <div class="weather_box"><span id="mapScale">30</span><span>KM</span></div>
      </div>
      <div class="weather_box" [ngStyle]="footer.children[8].show ? {'display': 'block'} : {'display': 'none'}">
        <img [src]="'/footer/airspace-informatics.svg' | assetUrl" alt="airspace-informatics" />
        <span id="location">Airspace Informatics</span>
      </div>
    </div>
  </div>
  <div id="weather_info" class="weather_info" *ngIf="weatherTap != 0">
    <div class="weather_info_left">
      <ng-container *ngIf="weatherTap != 1">
        <app-weather-table [weatherDataList]="weatherDataList" [weatherLabel]="weatherLabel" (sendHours)="getHours($event)"></app-weather-table>
      </ng-container>
    </div>
    <div class="weather_info_right">
      <div class="weather_summary">
        <div class="weather_summary_head">
          <div class="weather_summary_head_left">
            <p>{{ currentWeatherBoxData?.data[0]?.coordinates[0]?.dates[0]?.date }}</p>
            <p>Gloomy weather ahead</p>
          </div>
          <div class="weather_summary_head_right">
            <img [src]="'/master-weather/weather-summary/cloudy.svg' | assetUrl" alt="cloudy" />
            <p>{{ currentWeatherFooterData?.data[0]?.coordinates[0]?.dates[0]?.value + "°" }}</p>
          </div>
        </div>
        <div class="weather_summary_content">
          <div class="weather_summary_content_box">
            <div class="weather_summary_content_box_top">
              <img [src]="'/master-weather/weather-summary/sun-fog.svg' | assetUrl" alt="sun-fog" />
              <p>SUNRISE</p>
            </div>
            <p class="weather_summary_content_box_middle">
              {{ currentWeatherBoxData?.data[0]?.coordinates[0]?.dates[0]?.value }}
            </p>
            <p class="weather_summary_content_box_bottom">Sunset {{ currentWeatherBoxData?.data[1]?.coordinates[0]?.dates[0]?.value }}</p>
          </div>
          <div class="weather_summary_content_box">
            <div class="weather_summary_content_box_top">
              <img [src]="'/master-weather/weather-summary/drop.svg' | assetUrl" alt="drop" />
              <p>RAINFALL</p>
            </div>
            <p class="weather_summary_content_box_middle">{{ currentWeatherBoxData?.data[2]?.coordinates[0]?.dates[0]?.value }} MM</p>
            <p class="weather_summary_content_box_bottom">7 mm rainfall expected in the next 24 hr</p>
          </div>
          <div class="weather_summary_content_box">
            <div class="weather_summary_content_box_top">
              <img [src]="'/master-weather/weather-summary/phosphor.svg' | assetUrl" alt="phosphor" />
              <p>FEELS LIKE</p>
            </div>
            <p class="weather_summary_content_box_middle">28 °C</p>
            <p class="weather_summary_content_box_bottom">Humidity making it feels warmer</p>
          </div>
          <div class="weather_summary_content_box">
            <div class="weather_summary_content_box_top">
              <img [src]="'/master-weather/weather-summary/wind-power.svg' | assetUrl" alt="wind-power" />
              <p>WIND</p>
            </div>
            <p class="weather_summary_content_box_middle">7:26</p>
            <p class="weather_summary_content_box_bottom">Sunset 19:27</p>
          </div>
          <div class="weather_summary_content_box">
            <div class="weather_summary_content_box_top">
              <img [src]="'/master-weather/weather-summary/wind.svg' | assetUrl" alt="wind" />
              <p>GUSTS</p>
            </div>
            <p class="weather_summary_content_box_middle">
              {{ currentWeatherBoxData?.data[4]?.coordinates[0]?.dates[0]?.value.toString() | slice : 0 : 2 }} KM/H
            </p>
            <p class="weather_summary_content_box_bottom">Moderate wind that may affect drone stability</p>
          </div>
          <div class="weather_summary_content_box">
            <div class="weather_summary_content_box_top">
              <img [src]="'/master-weather/weather-summary/wind-2.svg' | assetUrl" alt="wind-2" />
              <p>HUMIDITY</p>
            </div>
            <p class="weather_summary_content_box_middle">{{ currentWeatherBoxData?.data[5]?.coordinates[0]?.dates[0]?.value }}%</p>
            <p class="weather_summary_content_box_bottom">The dew point is 23° right now</p>
          </div>
          <div class="weather_summary_content_box">
            <div class="weather_summary_content_box_top">
              <img [src]="'/master-weather/weather-summary/frame.svg' | assetUrl" alt="frame" />
              <p>VISIBILITY</p>
            </div>
            <p class="weather_summary_content_box_middle">{{ currentWeatherBoxData?.data[6]?.coordinates[0]?.dates[0]?.value }}</p>
            <p class="weather_summary_content_box_bottom">It’s perfectly clear right now</p>
          </div>
          <div class="weather_summary_content_box">
            <div class="weather_summary_content_box_top">
              <img [src]="'/master-weather/weather-summary/maskgroup.svg' | assetUrl" alt="maskgroup" />
              <p>KP INDEX</p>
            </div>
            <p class="weather_summary_content_box_middle">2.61 nT</p>
            <p class="weather_summary_content_box_bottom">Quiet geomagnetic conditions</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
