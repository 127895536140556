<div [ngClass]="hideMissionSection ? 'mission-holder' : ''">
  <div [ngClass]="!hidemission ? (loadMissionDetail ? 'mission-wrapper upload-dataset-holder' : 'mission-wrapper') : (hidemission ? 'd-none' : 'mission-wrapper')">
    <div class="mission-bg">
      <img class="mission-img" src="{{hasSiteImg ? sitePlaceholderImage : '/images/default_tower.png' | assetUrl}}" alt="Site Image"/>
    </div>
    <button class="btn close" (click)="initHideMission()">
      <img [src]="'/images/close.svg' | assetUrl" alt="close" />
    </button>
    <div class="mission-holder-wrapper">
      <div class="mission-inner">
        <h3 class="title">
          {{missionSiteInfo.siteName}} 
        </h3>
        <p class="measurement">
          <img [src]="'/images/globe.svg' | assetUrl" alt="Globe" class="measurement-icon"/>
          {{missionSiteInfo.siteLatitude}}, {{missionSiteInfo.siteLongitude}}
        </p>
        <span class="badge badge-default">Amplitel</span>
        <span class="badge badge-default">{{missionSiteInfo.industryType}}</span>
        <span class="badge badge-default">{{missionSiteAsset.length}}</span>
      </div>
      <div *ngIf="(loadMissionDetail && !loadUploadDataset) || backToCreateMission">
        <app-mission-detail [missionSiteID]="getSiteID" [getCreateMissionForm1Data]="createMissionFormData" [updateMissionForm1Data]="updateMissionFormData" (uploadDatasetComponent)="initUploadDatasetComponent($event)" [isMissionCreatedCheck]="isMissionCreated" [missionObjectiveList]="missionObjectiveList" [isDetailUpdated]="isDetailUpdated"></app-mission-detail>
      </div>
      <ng-template [ngIf]="loadUploadDataset">
        <app-upload-new-dataset #uploadNewDatasetView (hideMissionSection)="initHideMissionSection($event)" (hideMission)="hideMission($event)" (initBackToParentComponent)="backToParentComp($event)" [isHidemission]="hidemission" [isDetailUpdated]="isDetailUpdated"></app-upload-new-dataset>
      </ng-template>
      <form [formGroup]="UploadDatasetForm" (ngSubmit)="onSumbitDataset()">
        <ng-template [ngIf]="!loadMissionDetail && !loadUploadDataset">
        <div class="form-holder">
          <div class="form-group">
            <label class="label" for="missionProject">Select Project<em class="required">*</em></label>
            <select id="missionProject" class="form-control mission-field" formControlName="missionProject" placeholder="Choose Mission Project" required (change)="changeMissionProject($event)">
              <option  selected value="">Choose Project</option>
              <option *ngFor="let missionProject of missionProjects.records" value="{{missionProject._id}}">{{missionProject.title}}</option>
            </select>
            <div *ngIf="!projectRequired || UploadDatasetForm.controls['missionProject'].invalid && (UploadDatasetForm.controls['missionProject'].dirty || UploadDatasetForm.controls['missionProject'].touched)" class="error-msg">
              Project Name is required
            </div>   
          </div>
          <div class="form-group">
            <label class="label" for="missionAsset">Select Asset<em class="required">*</em></label>
            <select id="missionAsset" class="form-control mission-field" formControlName="missionAsset" placeholder="Choose Mission Asset" required (change)="changeMissionAsset($event)">
              <option  selected value="">Choose Asset</option>
              <option *ngFor="let missionAsset of missionSiteAsset" value="{{missionAsset.id}}">{{missionAsset.assetName}}</option>
            </select>
            <div *ngIf="!assetRequired || UploadDatasetForm.controls['missionAsset'].invalid && (UploadDatasetForm.controls['missionAsset'].dirty || UploadDatasetForm.controls['missionAsset'].touched)" class="error-msg">
              Asset Name is required
            </div>
          </div>
          <div class="btn-holder">
            <button type="submit" class="btn btn-outline" [disabled]="UploadDatasetForm.invalid">Upload Dataset</button>
            <button type="button" class="btn btn-default" (click)="validateCanvasBtn()">canvas</button>
          </div>
        </div>
        </ng-template>
      </form>
    </div>
  </div>
</div>
<ng-template [ngIf]="isShowMissionConfirmation">
  <div class="modal fade show mission-confirm-modal" id="closeMissionConfirmation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="img-holder">
            <img [src]="'/images/warning.png' | assetUrl" alt="warning image" />
          </div>
          <div class="content">
            <h2 class="heading">Cancel the process?</h2>
            <p class="info">All the changes made will be deleted.</p>
          </div>
        </div>
        <div class="btn-holder">
          <button type="button" class="btn btn-default" (click)="closeMission()">Yes</button>
          <button type="button" class="btn btn-danger" (click)="stayAliveMission()">No</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>