import { Component } from '@angular/core';

@Component({
  selector: 'app-sample',
  templateUrl: './view-mission.component.html',
  styleUrls: ['./view-mission.component.scss']
})
export class ViewMissionComponent {
  activeTab: number = 1;
  showModal = false;

  openModal() {
    this.showModal = true;
  }
}