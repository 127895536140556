import { Component, OnInit } from '@angular/core';
import { GlobeComponent } from '../globe/globe.component';

@Component({
  selector: 'app-contextbutton',
  templateUrl: './contextbutton.component.html',
  styleUrls: ['./contextbutton.component.scss'],
})
export class ContextbuttonComponent implements OnInit {
  public permissionData: string = '';
  isExpanded = false;
  fileToUpload: File | null = null;
  document: any;
  uploadFileInput = document.getElementById('upload-file');

  constructor(public globe: GlobeComponent) {}
  ngAfterViewInit() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  ngOnInit(): void {
    // outside click hide dropdown
    const userUtm: any = localStorage.getItem('user-data');
    const userPermissionData = JSON.parse(userUtm);
    this.permissionData = userPermissionData?.member[0]?.role?.permissions || '';
    const button_container = document.querySelector('.button-container') as HTMLDivElement;
    document.onclick = (event) => {
      if (button_container) {
        (<Element>event.target).closest('.button-container') ? '' : button_container.classList.remove('active');
      }
    };
  }

  enableTooltip(event: any) {
    $(event.target).tooltip('enable');
  }
  public isUserRolePresent(): boolean {
    return this.permissionData.includes('missions.create');
  }
  triggerCenter() {
    this.clearuploadfile();
    this.globe.removeObjective();
    this.globe.getPin();
    this.globe.missionMethod = 'pin';
    this.globe.createMission = true;
    this.globe.removeAllPopups();
  }

  triggerDraw() {
    this.clearuploadfile();
    this.globe.removeObjective();
    this.globe.drawPolygon();
  }

  triggerDrawLine() {
    this.clearuploadfile();
    this.globe.removeObjective();
    this.globe.removeAllPopups();
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  getExpandedClass() {
    return this.isExpanded ? 'show' : '';
  }

  triggerInputUpload() {
    var uploadFile = window.document.getElementById('upload-file')!.click();
  }
  handleFileInput(files: any) {
    this.globe.removeObjective();
    this.fileToUpload = files.target.files[0];
    this.globe.fileUploaded(files.target.files[0]);
    this.globe.missionMethod = 'upload';
    this.globe.createMission = true;
  }

  missionDetails() {
    const customEvent = new CustomEvent('dronos:utm:missionFloating', { detail: { gridFlowFlag: true } })
    window.dispatchEvent(customEvent)
  }

  clearuploadfile() {
    const uploadFileInput = document.getElementById('upload-file') as HTMLInputElement;
    if (uploadFileInput) {
      uploadFileInput.value = '';
    }

  }
}
