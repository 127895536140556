import { Injectable } from '@angular/core';
const red5pro = require('red5pro-webrtc-sdk')


@Injectable({
  providedIn: 'root'
})
export class Red5proService {
  activeRed5StreamId = 'test';
  stream: any;
  subscriberEventHandler:any;
  invalidNameEventHandler:any;
  liveIsAvailable:boolean = false;

  constructor() { }


  async red5proInit(streamId:string) {
    try {
      let red5StreamId = "rdd";
      if (streamId == "RDD-RED5") {
        red5StreamId = "rdd";
      } else {
        red5StreamId = streamId;
      }
      
      this.activeRed5StreamId = red5StreamId;
      const subscriber = new red5pro.RTCSubscriber();

      const invalidNameEventHandler = (e:any) => {
        console.log("INVLID NAME E: ", e.type);
        if (e.type == "Subscribe.InvalidName") {
          this.destroySubscriber();
          this.retryInitSubscriber();
        }
      };

      this.invalidNameEventHandler = invalidNameEventHandler;

      subscriber.on("*", invalidNameEventHandler);

      await subscriber.init({
        protocol: 'wss',
        host: 'red5.vertikaliti.com',
        app: 'live',
        streamName: this.activeRed5StreamId,
        rtcConfiguration: {
          iceServers: [{ urls: 'stun:stun2.l.google.com:19302' }],
          iceCandidatePoolSize: 2,
          bundlePolicy: 'max-bundle'
        }, // See https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/RTCPeerConnection#RTCConfiguration_dictionary
        mediaElementId: "red5pro-subscriber",
        subscriptionId: this.activeRed5StreamId + Math.floor(Math.random() * 0x10000).toString(16),
        maintainConnectionOnSubscribeErrors: true,
        videoEncoding: "H264"
      });

      await subscriber.subscribe();

      subscriber.off("*", invalidNameEventHandler);

      this.activeRed5Subscriber = subscriber;
      this.activeRed5Subscriber.mute();

      const subscriberEventHandler = (e:any) => {
        if (e.type != "Subscribe.Time.Update") {

          console.log("RED5 EVENT: ", e.type);

          if (e.type == "Subscribe.Play.Unpublish") {
            this.activeRed5Subscriber.pause();
            this.liveIsAvailable = false;
          }

          if (e.type == "Subscribe.Playback.Change") {
            console.log("RED5 STATE: ", e.data.state);
            if (e.data.state == "Playback.AVAILABLE" || e.data.state == "Playback.PAUSED") {
              this.activeRed5Subscriber.play();
            } else {
              this.retryInitSubscriber();
            }
          }

          if (e.type == "Subscribe.Metadata") {
            window.clearInterval(this.checkLiveInterval);
            this.activeRed5Subscriber.play();
            this.liveIsAvailable = true;
          }

          if (e.type == "Subscribe.Connection.Closed") {
            this.destroySubscriber();
            this.retryInitSubscriber();
          }
        }
      };

      this.subscriberEventHandler = subscriberEventHandler;
      subscriber.on('*', subscriberEventHandler);

    } catch (e) {
      this.liveIsAvailable = false;
      // An error occured in establishing a subscriber session.
    }
  }

  retryInitSubscriber () {
    window.clearInterval(this.checkLiveInterval);
    this.checkLiveInterval = window.setInterval(() => {
      console.log("check live running..");
      this.red5proInit(this.activeRed5StreamId);
    }, 2000);
  }
  checkLiveInterval = 0;
  // activeRed5StreamId:any;
	activeRed5Subscriber:any;
  destroySubscriber () {
    window.clearInterval(this.checkLiveInterval);
    if (this.activeRed5Subscriber) {
      this.activeRed5Subscriber.off("*", this.subscriberEventHandler);
      this.activeRed5Subscriber.off("*", this.invalidNameEventHandler);
      this.activeRed5Subscriber.unsubscribe();
      this.activeRed5Subscriber = null;
    }
  }





}
