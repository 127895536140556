<!--Generate Stream URL-->
<ng-template #generateStreamUrlModal let-modal>
  <div class="generate-modal-content">
    <div class="modal-header">
      <ng-template [ngIf]="generateStreamUrlPopUpScreen === 'confirm-generate-url-screen'">
        <h4 class="modal-title">PLEASE SELECT AN OPTION FROM BELOW</h4>
      </ng-template>
      <ng-template [ngIf]="generateStreamUrlPopUpScreen === 'copy-url-screen'">
        <h4 class="modal-title">COPY LINK CODE</h4>
      </ng-template>
      <button [disabled]="disableGenerateUrlBtn" type="button" class="close-btn" (click)="clearGenerateUrlPopUp()">
        <span>×</span>
      </button>
    </div>
    <div class="modal-body p-3 ">
      <ng-template [ngIf]="generateStreamUrlPopUpScreen === 'confirm-generate-url-screen'">
        <p class="confirm-text">Do you require AI Annotation for this mission?</p>
        <div class="confirm-action-section">
          <button [disabled]="disableGenerateUrlBtn" (click)="initGenerateUrlRequest(false)" type="button"
            class="no confirm-action-btn">No</button>
          <button [disabled]="disableGenerateUrlBtn" (click)="initGenerateUrlRequest(true)" type="button"
            class="yes confirm-action-btn">Yes</button>
        </div>
      </ng-template>
      <ng-template [ngIf]="generateStreamUrlPopUpScreen === 'copy-url-screen'">
        <div id="copy-url" class="url-section">
          <span class="stream-publish-url">{{ streamPublishUrl }} </span>
        </div>
        <div class="confirm-action-section">
          <button type="button" class="no confirm-action-btn" (click)="clearGenerateUrlPopUp()">Close</button>
          <button (click)="copyUrl()" type="button" class="yes confirm-action-btn">Copy</button>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
<!--END OF Generate Stream URL-->