<table>
    <thead>
      <tr>
        <th>Serial Number</th>
        <th>Brand</th>
        <th>Model</th>
        <th>Category</th>
        <th>Status</th>
        <th>Utilized Hours</th>
        <th>Flight Date</th>
        <th style="width: 7%;"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of payloadList" (click)="payloadDetails(item._id)">
        <td>{{ item.serialNumber }}</td>
        <td>{{ item.catalogId.maker }}</td>
        <td>{{ item.catalogId.model }}</td>
        <td>{{ item.catalogId.type }}</td>
        <td [ngStyle]="getStatusStyle(item.status)">{{ item.catalogId.status }}</td>
        <td>{{ item.catalogId.utilizedhours }}</td>
        <td>{{ item.catalogId.flightDate }}
  
        </td>
        <td style="width: 7%;">
            <a style="z-index: 1;"    (click)="deletePayload(item._id)"><img [src]="'/images/more_vert_24px.svg' | assetUrl" style="max-width: 100px;" class="edit"></a> 
     
           </td>
      </tr>
    </tbody>
  </table>
  
  <div class="d-flex justify-content-between align-items-center mt-3 d-flex-container">
    <p class="txt-12 color-txt-mission">Showing {{ paginationRange }}</p>
    <nav aria-label="Page navigation">
        <ul class="pagination ">
            <li class="page-item" [ngClass]="{ 'disabled': currentPage === 1 }">
                <a class="page-link" aria-label="Previous" (click)="goToPreviousPage()">
                    <img [src]="'/images/chevron_left_24px.png' | assetUrl" style="max-width: 100px;">
                </a>
            </li>
            <li class="page-item" *ngFor="let page of pages" [ngClass]="{ 'active': currentPage === page }"
                (click)="changePage(page)">
                <a class="page-link"><span>{{ page }}</span></a>
            </li>
            <li class="page-item" [ngClass]="{ 'disabled': currentPage === pages.length }">
                <a class="page-link" aria-label="Next" (click)="goToNextPage()">
                    <img [src]="'/images/chevron_right_24px.png' | assetUrl" style="max-width: 100px;">
                </a>
            </li>
        </ul>
    </nav>
  </div>