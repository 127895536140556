<!DOCTYPE html>
<html>

<head>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@100;400&display=swap" rel="stylesheet">
  <script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script>
</head>

<body>
  <div class="grid-container">
    <app-navbar></app-navbar>
    <div class="form">
      <h1 class="title">PILOT DETAILS</h1>
      <div class="detail-content">
        <p>Serial Number: {{ pilotDetails[0].serial_number}}</p>
        <p>Brand: {{ pilotDetails[0].brand_id}}</p>
        <p>Model: {{ pilotDetails[0].model_id}}</p>
        <p>UAS Type: {{ pilotDetails[0].uas_type}}</p>
        <p>Weight: {{ pilotDetails[0].weight}} KG</p>
        <p>Length: {{ pilotDetails[0].length}} meter</p>
        <p>Wingspan: {{ pilotDetails[0].wingspan}} meter</p>
        <p>Power Source: {{ pilotDetails[0].power_source}}</p>
        <p>Max Flight Duration: {{ pilotDetails[0].max_flight_duration}}</p>
        <p>Max Speed: {{ pilotDetails[0].max_speed}}</p>
        <p>Max Height Capable: {{ pilotDetails[0].max_height_capable}}</p>
        <p>Max Distance Capable: {{ pilotDetails[0].max_distance_capable}}</p>
        <p>Frequencies: {{ pilotDetails[0].frequencies}}</p>
        <p>Documents: {{ pilotDetails[0].documents}}</p>
        <p>Registered at: {{ pilotDetails[0].created_at}}</p>
      </div>
    </div>
  </div>
</body>

</html>
