import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { state } from '@app/utility';
import { ApiService } from 'src/app/api-permit.service';
@Component({
  selector: 'app-utm-issue-permit',
  templateUrl: './issue-permit.component.html',
  styleUrls: ['./issue-permit.component.scss'],
})
export class IssuePermitComponent implements OnInit{

  @Output() permitUrls = new EventEmitter<string[]>();
  
  public permitId: any = ''
  fileSelected: any = null;
  filesize: any;
  imageName: any;
  AWS_S3_BUCKET = 'sts1-bucket';
  AWS_KEY = 'workspace-64cb4b211a9deaa71709965f';
  workspaceid = state.getUserInfo().member[0].workspace._id.toString();
  sts: any;
  permitUrl: any = []
  constructor(private aModalService: NgbActiveModal,
    private api: ApiService,) {}


  ngOnInit(){
    this.getSTSCredential();
  }

  async getSTSCredential() {
    // this.api.getSTSCredential().subscribe((sts) => {
    // this.sts = sts.credentials;
    //   console.log('STS Credentials', this.sts);
    // });
    this.sts = {
      accessKeyId: 'AKIAQSHXYQXJU5DM7AFG',

      secretAccessKey: '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',

      region: 'ap-southeast-1',

      s3Bucket: 'dronos-utm',

      s3Prefix: `permit-${this.permitId}`,
    };

  }

  
  closeModal() {
    this.aModalService.close();
  }

  confirmUpload() {
    const result = {
      status: 'confirm',
      data: this.permitUrl
    }
    this.aModalService.close(result);
  }

  async handleSendFile() {
    await this.s3_upload(this.fileSelected);
    // await this.s3_retrive(this.fileSelected);
  }

  onChangeFile(event: any) {
    this.filesize = event.target.files[0].size;
    this.imageName = event.target.files[0].name;
    this.fileSelected = event.target.files[0];
    this.handleSendFile();
  }

  async s3_upload(file: File) {
    const client = new S3Client({
      region: this.sts.region,
      credentials: {
        accessKeyId: this.sts.accessKeyId,
        secretAccessKey: this.sts.secretAccessKey,
        sessionToken: this.sts.sessionToken,
      },
    });

    const putCommand = new PutObjectCommand({
      Bucket: this.sts.s3Bucket,
      Key: this.sts.s3Prefix + '/' + file.name,
      Body: file,
      // ACL: 'public-read',
      ContentType: file.type,
    });

    client.send(putCommand, (err: any, data: any) => {
      if (err) {
        return false;
      }

      // this.savetoDb(data.ETag);
      this.permitUrl.push(`https://${this.sts.s3Bucket}.s3.ap-southeast-1.amazonaws.com/${this.sts.s3Prefix}/${file.name}`)

      return true;
    });
  }
}
