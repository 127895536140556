import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment.service'; 
import { IUser, initializeUser } from '../models/user.model';
import { state } from '@app/utility';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl() + '/account/users';
const userSignUpUrl = `${apiUrl}/signup`;
const userProfileUrl = `${apiUrl}/profile`;
const allPilotsUrl = `${apiUrl}/pilot`;
const workspaceId = state.getUserInfo().member[0].workspace._id.toString();
const headers =  new HttpHeaders({
  Authorization: 'Bearer ' + environmentService.getToken(),
  'Content-Type': 'application/json',
  workspaceId: workspaceId,
  });

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {}

  create(data: IUser): Observable<IUser> {
    return this.http.post<IUser>(userSignUpUrl, data, { headers : headers });
  }

  getProfile(): Observable<IUser> {
    return this.http.get<IUser>(userProfileUrl, { headers : headers });
  }

  getAll(): Observable<IUser> {
    return this.http.get<IUser>(apiUrl, { headers : headers });
  }

  getAllPilot(): Observable<IUser> {
    return this.http.get<IUser>(allPilotsUrl, { headers : headers });
  }

  get(id: string): Observable<IUser> {
    return this.http.get<IUser>(`${apiUrl}/${id}`, { headers: headers }).pipe(
      map((userData: IUser) => {
        // Initialize the retrieved data using initializeUser function
        const initializedUser: IUser = {
          ...initializeUser(), // Use spread operator to copy default values
          ...userData, // Copy the retrieved data over the initialized values
        };
        return initializedUser;
      })
    );
  }

  update(id: string, data: IUser): Observable<IUser> {
    return this.http.put<IUser>(`${apiUrl}/${id}`, data);
  }

  delete(id: string): Observable<IUser> {
    return this.http.delete<IUser>(`${apiUrl}/${id}`, { headers : headers });
  }
}
