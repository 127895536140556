<div>
    <div class="drop-pin-container">
        <div class="header">
            <div class="d-flex align-items-center"> 
                <img [src]="'/icon/polygon.svg' | assetUrl"/>
                <span class="text-header">MISSION AREA</span>
            </div>
            <img [src]="'/images/close.svg' | assetUrl" (click)="removeArea('remove')" style="cursor: pointer;"/>
        </div>
        <div class="vertical-line"></div>
        <span class="title-font">Dimension</span>
        <div class="d-flex justify-content-between" style="width: 100%;">
            <div class="d-flex align-items-center">
                <span class="content-font">Altitude</span>
            </div>
            <div class="custom-input-pin">
                <input class="custom-input" type="number" [(ngModel)]="altitude" (ngModelChange)="altitudeData($event)" style="color: #ffffff;">
                <label class="custom-label">m</label>
            </div>
            
        </div>
        <div class="d-flex justify-content-between" style="width: 100%;">
            <div class="d-flex align-items-center">
                <span class="content-font" id="warning">Adherence to the legal altitude limit of 120 meters is crucial. Exceeding this threshold may lead to potential rejection.</span>
                <img [src]="'/icon/Info.svg' | assetUrl" style="margin-left: 5px;"/>
            </div>
            
        </div>
        <div class="d-flex justify-content-between align-items-center" style="width: 100%;" *ngIf="modalState == 'pin'">
                <span class="content-font" >Radius</span>
            <div class="custom-input-pin">
                <input class="custom-input" type="number" [(ngModel)]="radius" (ngModelChange)="radiusData($event)" min="0" style="color: #ffffff;">
                <label class="custom-label">m</label>
            </div>
        </div>
        <div class="vertical-line"></div>
        <span class="title-font">Size</span>
        <div class="d-flex justify-content-between align-items-center" style="width: 100%;"*ngIf="modalState != 'pin'">
            <span class="content-font">Distance</span>
        <div class="custom-input-pin">
            <input class="custom-input" type="number" [(ngModel)]="distance" disabled>
            <label class="custom-label" style="color: rgba(255, 255, 255, 0.4) !important">m</label>
        </div>
    </div>
        <div class="d-flex justify-content-between align-items-center" style="width: 100%;">
                <span class="content-font">Area</span>
            <div class="custom-input-pin">
                <input class="custom-input" type="number" [(ngModel)]="area" disabled>
                <label class="custom-label" style="color: rgba(255, 255, 255, 0.4) !important">m<sup style="top: 0.5em !important;">2</sup></label>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center" style="width: 100%;">
                <span class="content-font">Volume</span>
            <div class="custom-input-pin">
                <input class="custom-input" type="number" [(ngModel)]="volume" disabled>
                <label class="custom-label" style="color: rgba(255, 255, 255, 0.4) !important">m <sup style="top: 0.5em !important;">3</sup></label>
            </div>
        </div>
        <div class="confirm-btn" (click)="confirm()" *ngIf="!edit">Confirm</div>
        <div class="confirm-btn" (click)="editArea()" *ngIf="edit">Edit</div>
    </div>
</div>
