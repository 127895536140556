import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-utm-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent {
  @Input() title: string = ''
  @Input() description: string = ''
  @Input() cancelText: string = ''
  @Input() proceedText: string = ''
  @Output() cancelClick = new EventEmitter()
  @Output() proceedClick = new EventEmitter()

  cancelClicked() {
    this.cancelClick.emit('true')
  }
  proceedClicked() {
    this.proceedClick.emit('true')
  }
}
