import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { assetUrl } from 'src/single-spa/asset-url';
import { GlobeComponent } from '../globe/globe.component';
import { MapdataService } from '../services/mapdata.service';

import { Router } from '@angular/router';
import { ScheduleService } from '../services/schedule.service';
import { MissionService } from '../services/mission.service';
import { map } from 'rxjs';
import { IMissionObjective } from '../models/mission-objective.model';

@Component({
  selector: 'app-objective',
  templateUrl: './objective.component.html',
  styleUrls: ['./objective.component.scss'],
})
export class ObjectiveComponent implements OnInit {
  @Output() isDoneLoad = new EventEmitter<string>();
  constructor(
    public globe: GlobeComponent,
    private mapDataservice: MapdataService,
    private scheduleSer: ScheduleService,
    private router: Router,
    private missionService: MissionService,
  ) {}

  public objectives$ = this.missionService.getObjective({ pageLimit: '20' });
  public mappedObjectives$ = this.objectives$.pipe(
    map((objectives) =>
      objectives.records.map((objective: IMissionObjective) => {
        return {
          id: objective._id,
          name: objective.name,
          category: 'Infrastructure',
          output: objective.output,
          gif: objective.pictureId ?? assetUrl('/images/no-image.jpg'),
        };
      }),
    ),
  );

  public newCheckPolyPass: any;

  ngOnInit(): void {}

  getBackgroundStyle(objective: { gif: any }) {
    return {
      background: `url(${objective.gif}), lightgray 50% / cover no-repeat`,
      'background-size': '105% 105%',
      'background-repeat': 'no-repeat',
      'background-position': 'center',
    };
  }

  objectiveClicked(objective: any) {
    this.isDoneLoad.emit('false');
    this.sendObjectiveToSchedule(objective);

    const area = this.globe.getMissionArea();
    const center = this.globe.getMissionCentroid();
    const linePath = this.globe.getLinePath();
    const altitudeVal = this.globe.altitude;

    const PassData = {
      objective: objective,
      area: area,
      center: center,
      line: linePath,
      altitude: altitudeVal,
    };

    this.mapDataservice.addnewArr(PassData);
    setTimeout(() => {
      // this.isDoneLoad.emit("true")
      this.router.navigate(['/schedule']);
    }, 1000);
  }
  sendObjectiveToSchedule(objective: any) {
    this.scheduleSer.missionTitleNumberSubject.next(objective);
  }
}
