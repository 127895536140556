<!DOCTYPE html>
<html>

<head>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@100;400&display=swap" rel="stylesheet">
  <script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script>
</head>

<body>
  <div class="grid-container">
    <app-navbar></app-navbar>
    <div class="content">
      <h1>Pilots</h1>

      <table class="table">
        <tr>
          <th>No.</th>
          <th>Fullname</th>
          <th>Email</th>
          <th>Registration Date</th>
          <th>Action</th>
        </tr>
        <tr *ngFor="let pilot of pilots; let i = index">
          <td>{{ i+1 }}.</td>
          <td>{{ pilot.fullName }}</td>
          <td>{{ pilot.email }}</td>
          <td>{{ pilot.created_at | date: 'medium' }}</td>
          <td>
            <a href="/pilot/{{i +1}}" class="link">
              <span>View</span>
            </a>
            <a href="/pilot/{{i +1}}/edit" class="link">
              <span>Edit</span>
            </a>
            <a href="/pilot/{{i +1}}/edit" class="link">
              <span>Delete</span>
            </a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</body>

</html>
