import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-drone-detail',
  templateUrl: './drone-detail.component.html',
  styleUrls: ['./drone-detail.component.scss']
})
export class DroneDetailComponent {

  droneDetails: any[] = [];

  constructor(private route: ActivatedRoute, private http: HttpClient) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'bearer MQ.mK0nB6_hMJ26_fECXHa5bhRhz74B17jP-ORUfZoe6CzpsBqTvfCAS81ynnS-'
      })
    };

    // get drone details
    this.http.get('http://127.0.0.1:3333/api/drones/'+id, httpOptions).subscribe((response: any) => {
      this.droneDetails = response;
    }, (error: any) => {
      console.log('Error while fetching drone details', error);
    });
  }
}
