import { Component, OnInit } from '@angular/core';
import { WeatherService } from '../services/weather.service';
import moment from 'moment';

// sample weather data using external ts file
import { weather_1H, weather_3H, weather_24H, weather_footer, weather_box } from '../../assets/master-weather/sample-weather-data';

@Component({
  selector: 'app-master-weather',
  templateUrl: './master-weather.component.html',
  styleUrls: ['./master-weather.component.scss'],
})
export class MasterWeatherComponent implements OnInit {
  weatherDataList: any;
  weatherLabel: any;
  weatherTap: any = 0;
  sunCoverage: any = [];

  // sample data for weather table
  sunLabel = [
    {
      day: 'Day',
      hours: 'Hours',
      fog: 'Temperature',
      visibility: 'UV Index',
    },
  ];
  windLabel = [
    {
      day: 'Day',
      hours: 'Hours',
      fog: 'Wind Speed ',
      visibility: 'Wind Direction',
    },
  ];
  cloudLabel = [
    {
      day: 'Day',
      hours: 'Hours',
      fog: 'Fog',
      visibility: 'Visibility',
    },
  ];
  moistureLabel = [
    {
      day: 'Day',
      hours: 'Hours',
      fog: 'Rel Humidity',
      visibility: 'Precipitation',
    },
  ];
  // sunCoverage = [
  //   {
  //     day: 'Monday, 27th February',
  //     hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //     icon: ['sun', 'sun2', 'sun3', 'sun', 'sun', 'sun2', 'sun', 'sun3', 'sun', 'sun3', 'sun', 'sun2'],
  //     fog: ['32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°'],
  //     visibility: [1, 2, 3, 4, 5, 0, 0, 3, 4, 5, 2, 4],
  //   },
  //   {
  //     day: 'Tuesday, 27th February',
  //     hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //     icon: ['sun', 'sun2', 'sun3', 'sun', 'sun', 'sun2', 'sun', 'sun3', 'sun', 'sun3', 'sun', 'sun2'],
  //     fog: ['32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°'],
  //     visibility: [1, 2, 3, 4, 5, 0, 0, 3, 4, 5, 2, 4],
  //   },
  //   {
  //     day: 'Wednesday, 27th February',
  //     hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //     icon: ['sun', 'sun2', 'sun3', 'sun', 'sun', 'sun2', 'sun', 'sun3', 'sun', 'sun3', 'sun', 'sun2'],
  //     fog: ['32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°'],
  //     visibility: [1, 2, 3, 4, 5, 0, 0, 3, 4, 5, 2, 4],
  //   },
  //   {
  //     day: 'Thursday, 27th February',
  //     hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //     icon: ['sun', 'sun2', 'sun3', 'sun', 'sun', 'sun2', 'sun', 'sun3', 'sun', 'sun3', 'sun', 'sun2'],
  //     fog: ['32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°'],
  //     visibility: [1, 2, 3, 4, 5, 0, 0, 3, 4, 5, 2, 4],
  //   },
  //   {
  //     day: 'Friday, 27th February',
  //     hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //     icon: ['sun', 'sun2', 'sun3', 'sun', 'sun', 'sun2', 'sun', 'sun3', 'sun', 'sun3', 'sun', 'sun2'],
  //     fog: ['32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°'],
  //     visibility: [1, 2, 3, 4, 5, 0, 0, 3, 4, 5, 2, 4],
  //   },
  //   {
  //     day: 'Saturday, 27th February',
  //     hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //     icon: ['sun', 'sun2', 'sun3', 'sun', 'sun', 'sun2', 'sun', 'sun3', 'sun', 'sun3', 'sun', 'sun2'],
  //     fog: ['32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°'],
  //     visibility: [1, 2, 3, 4, 5, 0, 0, 3, 4, 5, 2, 4],
  //   },
  //   {
  //     day: 'Sunday, 27th February',
  //     hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //     icon: ['sun', 'sun2', 'sun3', 'sun', 'sun', 'sun2', 'sun', 'sun3', 'sun', 'sun3', 'sun', 'sun2'],
  //     fog: ['32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°'],
  //     visibility: [1, 2, 3, 4, 5, 0, 0, 3, 4, 5, 2, 4],
  //   },
  // ];
  // windCoverage = [
  //    {
  //       day: 'Monday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: ['wind', 'wind2', 'wind', 'wind3', 'wind', 'wind2', 'wind', 'wind3', 'wind', 'wind2', 'wind', 'wind3'],
  //       mainValue: [1, 2, 3, 4, 5, 6, 3, 4, 5, 6, 7, 8],
  //       subValue: ['N', 'E', 'W', 'NE', 'S', 'EW', 'W', 'NE', 'S', 'EW', 'E', 'W'],
  //    },
  //    {
  //       day: 'Tuesday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: ['wind', 'wind2', 'wind', 'wind3', 'wind', 'wind2', 'wind', 'wind3', 'wind', 'wind2', 'wind', 'wind3'],
  //       mainValue: [1, 2, 3, 4, 5, 6, 3, 4, 5, 6, 7, 8],
  //       subValue: ['N', 'E', 'W', 'NE', 'S', 'EW', 'W', 'NE', 'S', 'EW', 'E', 'W'],
  //    },
  //    {
  //       day: 'Wednesday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: ['wind', 'wind2', 'wind', 'wind3', 'wind', 'wind2', 'wind', 'wind3', 'wind', 'wind2', 'wind', 'wind3'],
  //       mainValue: [1, 2, 3, 4, 5, 6, 3, 4, 5, 6, 7, 8],
  //       subValue: ['N', 'E', 'W', 'NE', 'S', 'EW', 'W', 'NE', 'S', 'EW', 'E', 'W'],
  //    },
  //    {
  //       day: 'Thursday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: ['wind', 'wind2', 'wind', 'wind3', 'wind', 'wind2', 'wind', 'wind3', 'wind', 'wind2', 'wind', 'wind3'],
  //       mainValue: [1, 2, 3, 4, 5, 6, 3, 4, 5, 6, 7, 8],
  //       subValue: ['N', 'E', 'W', 'NE', 'S', 'EW', 'W', 'NE', 'S', 'EW', 'E', 'W'],
  //    },
  //    {
  //       day: 'Friday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: ['wind', 'wind2', 'wind', 'wind3', 'wind', 'wind2', 'wind', 'wind3', 'wind', 'wind2', 'wind', 'wind3'],
  //       mainValue: [1, 2, 3, 4, 5, 6, 3, 4, 5, 6, 7, 8],
  //       subValue: ['N', 'E', 'W', 'NE', 'S', 'EW', 'W', 'NE', 'S', 'EW', 'E', 'W'],
  //    },
  //    {
  //       day: 'Saturday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: ['wind', 'wind2', 'wind', 'wind3', 'wind', 'wind2', 'wind', 'wind3', 'wind', 'wind2', 'wind', 'wind3'],
  //       mainValue: [1, 2, 3, 4, 5, 6, 3, 4, 5, 6, 7, 8],
  //       subValue: ['N', 'E', 'W', 'NE', 'S', 'EW', 'W', 'NE', 'S', 'EW', 'E', 'W'],
  //    },
  //    {
  //       day: 'Sunday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: ['wind', 'wind2', 'wind', 'wind3', 'wind', 'wind2', 'wind', 'wind3', 'wind', 'wind2', 'wind', 'wind3'],
  //       mainValue: [1, 2, 3, 4, 5, 6, 3, 4, 5, 6, 7, 8],
  //       subValue: ['N', 'E', 'W', 'NE', 'S', 'EW', 'W', 'NE', 'S', 'EW', 'E', 'W'],
  //    },
  // ];
  // cloudCoverage = [
  //    {
  //       day: 'Monday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: ['cloud', 'cloud2', 'cloud3', 'cloud', 'cloud3', 'cloud', 'cloud2', 'cloud', 'cloud3', 'cloud', 'cloud2', 'cloud'],
  //       mainValue: ['80%', '39%', '97%', '30%', '20%', '54%', '23%', '23%', '45%', '76%', '68%', '97%'],
  //       subValue: [5, 12, 3, 4, 5, 16, 7, 18, 9, 10, 11, 12],
  //    },
  //    {
  //       day: 'Tuesday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: ['cloud', 'cloud2', 'cloud3', 'cloud', 'cloud3', 'cloud', 'cloud2', 'cloud', 'cloud3', 'cloud', 'cloud2', 'cloud'],
  //       mainValue: ['80%', '39%', '97%', '30%', '20%', '54%', '23%', '23%', '45%', '76%', '68%', '97%'],
  //       subValue: [5, 12, 3, 4, 5, 16, 7, 18, 9, 10, 11, 12],
  //    },
  //    {
  //       day: 'Wednesday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: ['cloud', 'cloud2', 'cloud3', 'cloud', 'cloud3', 'cloud', 'cloud2', 'cloud', 'cloud3', 'cloud', 'cloud2', 'cloud'],
  //       mainValue: ['80%', '39%', '97%', '30%', '20%', '54%', '23%', '23%', '45%', '76%', '68%', '97%'],
  //       subValue: [5, 12, 3, 4, 5, 16, 7, 18, 9, 10, 11, 12],
  //    },
  //    {
  //       day: 'Thursday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: ['cloud', 'cloud2', 'cloud3', 'cloud', 'cloud3', 'cloud', 'cloud2', 'cloud', 'cloud3', 'cloud', 'cloud2', 'cloud'],
  //       mainValue: ['80%', '39%', '97%', '30%', '20%', '54%', '23%', '23%', '45%', '76%', '68%', '97%'],
  //       subValue: [5, 12, 3, 4, 5, 16, 7, 18, 9, 10, 11, 12],
  //    },
  //    {
  //       day: 'Friday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: ['cloud', 'cloud2', 'cloud3', 'cloud', 'cloud3', 'cloud', 'cloud2', 'cloud', 'cloud3', 'cloud', 'cloud2', 'cloud'],
  //       mainValue: ['80%', '39%', '97%', '30%', '20%', '54%', '23%', '23%', '45%', '76%', '68%', '97%'],
  //       subValue: [5, 12, 3, 4, 5, 16, 7, 18, 9, 10, 11, 12],
  //    },
  //    {
  //       day: 'Saturday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: ['cloud', 'cloud2', 'cloud3', 'cloud', 'cloud3', 'cloud', 'cloud2', 'cloud', 'cloud3', 'cloud', 'cloud2', 'cloud'],
  //       mainValue: ['80%', '39%', '97%', '30%', '20%', '54%', '23%', '23%', '45%', '76%', '68%', '97%'],
  //       subValue: [5, 12, 3, 4, 5, 16, 7, 18, 9, 10, 11, 12],
  //    },
  //    {
  //       day: 'Sunday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: ['cloud', 'cloud2', 'cloud3', 'cloud', 'cloud3', 'cloud', 'cloud2', 'cloud', 'cloud3', 'cloud', 'cloud2', 'cloud'],
  //       mainValue: ['80%', '39%', '97%', '30%', '20%', '54%', '23%', '23%', '45%', '76%', '68%', '97%'],
  //       subValue: [5, 12, 3, 4, 5, 16, 7, 18, 9, 10, 11, 12],
  //    },
  // ];
  // moistureCoverage = [
  //    {
  //       day: 'Monday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: [
  //          'moisture',
  //          'moisture2',
  //          'moisture3',
  //          'moisture',
  //          'moisture3',
  //          'moisture',
  //          'moisture2',
  //          'moisture',
  //          'moisture3',
  //          'moisture',
  //          'moisture2',
  //          'moisture',
  //       ],
  //       mainValue: ['23%', '23%', '34%', '34%', '56%', '56%', '34%', '78%', '75%', '97%', '26%', '28%'],
  //       subValue: ['64%', '47%', '95%', '38%', '37%', '28%', '74%', '48%', '3%', '2%', '74%', '28%'],
  //    },
  //    {
  //       day: 'Tuesday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: [
  //          'moisture',
  //          'moisture2',
  //          'moisture3',
  //          'moisture',
  //          'moisture3',
  //          'moisture',
  //          'moisture2',
  //          'moisture',
  //          'moisture3',
  //          'moisture',
  //          'moisture2',
  //          'moisture',
  //       ],
  //       mainValue: ['23%', '23%', '34%', '34%', '56%', '56%', '34%', '78%', '75%', '97%', '26%', '28%'],
  //       subValue: ['64%', '47%', '95%', '38%', '37%', '28%', '74%', '48%', '3%', '2%', '74%', '28%'],
  //    },
  //    {
  //       day: 'Wednesday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: [
  //          'moisture',
  //          'moisture2',
  //          'moisture3',
  //          'moisture',
  //          'moisture3',
  //          'moisture',
  //          'moisture2',
  //          'moisture',
  //          'moisture3',
  //          'moisture',
  //          'moisture2',
  //          'moisture',
  //       ],
  //       mainValue: ['23%', '23%', '34%', '34%', '56%', '56%', '34%', '78%', '75%', '97%', '26%', '28%'],
  //       subValue: ['64%', '47%', '95%', '38%', '37%', '28%', '74%', '48%', '3%', '2%', '74%', '28%'],
  //    },
  //    {
  //       day: 'Thursday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: [
  //          'moisture',
  //          'moisture2',
  //          'moisture3',
  //          'moisture',
  //          'moisture3',
  //          'moisture',
  //          'moisture2',
  //          'moisture',
  //          'moisture3',
  //          'moisture',
  //          'moisture2',
  //          'moisture',
  //       ],
  //       mainValue: ['23%', '23%', '34%', '34%', '56%', '56%', '34%', '78%', '75%', '97%', '26%', '28%'],
  //       subValue: ['64%', '47%', '95%', '38%', '37%', '28%', '74%', '48%', '3%', '2%', '74%', '28%'],
  //    },
  //    {
  //       day: 'Friday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: [
  //          'moisture',
  //          'moisture2',
  //          'moisture3',
  //          'moisture',
  //          'moisture3',
  //          'moisture',
  //          'moisture2',
  //          'moisture',
  //          'moisture3',
  //          'moisture',
  //          'moisture2',
  //          'moisture',
  //       ],
  //       mainValue: ['23%', '23%', '34%', '34%', '56%', '56%', '34%', '78%', '75%', '97%', '26%', '28%'],
  //       subValue: ['64%', '47%', '95%', '38%', '37%', '28%', '74%', '48%', '3%', '2%', '74%', '28%'],
  //    },
  //    {
  //       day: 'Saturday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: [
  //          'moisture',
  //          'moisture2',
  //          'moisture3',
  //          'moisture',
  //          'moisture3',
  //          'moisture',
  //          'moisture2',
  //          'moisture',
  //          'moisture3',
  //          'moisture',
  //          'moisture2',
  //          'moisture',
  //       ],
  //       mainValue: ['23%', '23%', '34%', '34%', '56%', '56%', '34%', '78%', '75%', '97%', '26%', '28%'],
  //       subValue: ['64%', '47%', '95%', '38%', '37%', '28%', '74%', '48%', '3%', '2%', '74%', '28%'],
  //    },
  //    {
  //       day: 'Sunday, 27th February',
  //       hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  //       icon: [
  //          'moisture',
  //          'moisture2',
  //          'moisture3',
  //          'moisture',
  //          'moisture3',
  //          'moisture',
  //          'moisture2',
  //          'moisture',
  //          'moisture3',
  //          'moisture',
  //          'moisture2',
  //          'moisture',
  //       ],
  //       mainValue: ['23%', '23%', '34%', '34%', '56%', '56%', '34%', '78%', '75%', '97%', '26%', '28%'],
  //       subValue: ['64%', '47%', '95%', '38%', '37%', '28%', '74%', '48%', '3%', '2%', '74%', '28%'],
  //    },
  // ];

  getOrdinal(day: any) {
    if (day % 10 === 1 && day !== 11) {
        return day + "st";
    } else if (day % 10 === 2 && day !== 12) {
        return day + "nd";
    } else if (day % 10 === 3 && day !== 13) {
        return day + "rd";
    } else {
        return day + "th";
    }
}

  getCurrentDate() {
    const currentDate = new Date();

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const dayName = days[currentDate.getDay()];


const dayWithOrdinal = this.getOrdinal(currentDate.getDate());

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const monthName = months[currentDate.getMonth()];

return `${dayName} ${dayWithOrdinal} ${monthName}`;

  }

  

  onWeatherTap(val: number) {
    if (this.weatherTap == val) {
      this.weatherTap = 0;
    } else {
      this.weatherTap = val;

      if (this.weatherTap == 1) {
        this.weatherDataList = null;
        this.weatherLabel = null;
      }
      if (this.weatherTap == 2) {
        // this.hours == 1 ?
        this.arrstIndex = 0;
        this.getHours(this.hours);

        // : this.hours == 3 ? this.getHours(this.hours) : this.getHours(this.hours);

        // this.weatherDataList = this.sunCoverage;
        // this.weatherLabel = this.sunLabel;
      }
      if (this.weatherTap == 3) {
        this.arrstIndex = 2;
        this.getHours(this.hours);

        // this.weatherDataList = this.windCoverage;
        // this.weatherLabel = this.windLabel;
      }
      if (this.weatherTap == 4) {
        this.arrstIndex = 4;
        this.getHours(this.hours);

        // this.weatherDataList = this.cloudCoverage;
        // this.weatherLabel = this.cloudLabel;
      }
      if (this.weatherTap == 5) {
        this.arrstIndex = 6;
        this.getHours(this.hours);

        // this.weatherDataList = this.moistureCoverage;
        // this.weatherLabel = this.moistureLabel;
      }
    }
  }

  ///////////////////////////////////////////////////////////////

  // sample weather data from external file
  fullWeatherData_1Hours: any = weather_1H;
  fullWeatherData_3Hours: any = weather_3H;
  fullWeatherData_24Hours: any = weather_24H;

  currentWeatherBoxData: any = weather_box;
  currentWeatherFooterData: any = weather_footer;

  ////////////////////////////////////////////////////////////

  // fullWeatherData_1Hours: any;
  // fullWeatherData_3Hours: any;
  // fullWeatherData_24Hours: any;

  dateWiseFullWeatherData_1Hours: any = [];
  dateWiseFullWeatherData_3Hours: any = [];
  dateWiseFullWeatherData_24Hours: any = [];

  // currentWeatherBoxData: any;
  // currentWeatherFooterData: any;

  arrstIndex: any = 0;

  constructor(private weather: WeatherService) {}
  weatherArray: any = [];

  location!: string;
  token!: string;
  hours: any = 3;

  footer = {
    show: true,
    children: [
      // left
      { name: 'elevation', show: true },
      { name: 'celcius', show: true },
      { name: 'wind', show: true },
      { name: 'cloud', show: true },
      { name: 'moisture', show: true },
      // right
      { name: 'latlng', show: true },
      { name: 'location', show: true },
      { name: 'scale', show: false },
      { name: 'airspace', show: true },
    ],
  }

  ngOnInit(): void {
    // this.userLocation();

    this.handleWeatherPanel();

    let apiHours1 = 1;
    let apiHours3 = 3;
    let apiHours24 = 24;
    this.dayWiseSplit(this.fullWeatherData_3Hours, apiHours3);
    this.dayWiseSplit(this.fullWeatherData_1Hours, apiHours1);
    this.dayWiseSplit(this.fullWeatherData_24Hours, apiHours24);

    this.listenFooterPreferences()
  }

  listenFooterPreferences() {
    window.addEventListener("footer:preferences", (event: any) => {
      const detail = event.detail;
      this.footer = detail

      let root = document.documentElement;

      if (!this.footer.show) {
        root.style.setProperty('--footer-height', '0px')
      } else {
        const footerContainer = document.querySelector('.bottom_menu')

        root.style.setProperty('--footer-height', `${footerContainer?.clientHeight}px`)
      }
    });
  }

  handleWeatherPanel() {
    // document.addEventListener('click', (event: any) => {
    //   const bottomMenu = document.getElementById('bottomMenu');

    //   if (!bottomMenu?.contains(event.target)) {
    //     this.weatherTap = 0;
    //   }
    // });

    document.addEventListener('click', (event: any) => {
      (<Element>event.target).closest('#bottomMenu') ? '' : (this.weatherTap = 0);
    });
  }

  userLocation() {
    this.weather.getCurrentLocation().subscribe({
      next: (data: { coords: { latitude: any; longitude: any; }; }) => {
        this.location = `${data.coords.latitude},${data.coords.longitude}`;
      },
      error: (err: { code: number; }) => {
        if (err.code == 1) {
          if (confirm('Need to access your Location, to Show weather infermation!')) {
            var link = document.createElement('a');
            link.href = 'https://support.google.com/chrome/answer/142065?hl=en';
            link.target = '_blank';
            link.click();
          } else {
            alert('Location permission denied!');
          }
        }
      },
      complete: () => this.userToken(),
    });
  }

  userToken() {
    this.weather.getToken().subscribe({
      next: (data: any) => {
        localStorage.setItem('weatherToken', data.access_token);
        this.token = localStorage.getItem('weatherToken')!;
      },
      error: (err: any) => {
        console.log('Weather Token Error', err);
      },
      complete: () => {
        this.currentData();
        this.fullWather();
      },
    });
  }

  fullWather() {
    this.weather.weatherData_3Hours(this.location, this.token).subscribe({
      next: (val: any) => {
        this.printData('full Weather Data 3Hours', val);
        this.fullWeatherData_3Hours = val;
      },
      error: (err: any) => {
        console.log('Weather Date Error', err);
      },
      complete: () => {
        let apiHours = 3;
        this.dayWiseSplit(this.fullWeatherData_3Hours, apiHours);
        this.weather_1hours();
      },
    });
  }

  weather_1hours() {
    this.weather.weatherData_1Hours(this.location, this.token).subscribe({
      next: (val: any) => {
        // this.printData('full Weather Data 1Hours', val);
        this.fullWeatherData_1Hours = val;
      },
      error: (err: any) => {
        console.log('Weather Date Error', err);
      },
      complete: () => {
        let apiHours = 1;
        this.dayWiseSplit(this.fullWeatherData_1Hours, apiHours);
        this.weather_24hours();
      },
    });
  }

  weather_24hours() {
    this.weather.weatherData_24Hours(this.location, this.token).subscribe({
      next: (val: any) => {
        // this.printData('full Weather Data_24Hours', val);
        this.fullWeatherData_24Hours = val;
      },
      error: (err: any) => {
        console.log('Weather Date Error', err);
      },
      complete: () => {
        let apiHours = 24;
        this.dayWiseSplit(this.fullWeatherData_24Hours, apiHours);
      },
    });
  }

  currentData() {
    this.weather.currentWeatherFooter(this.location, this.token).subscribe({
      next: (data: any) => {
        this.currentWeatherFooterData = data;
        this.printData('Current Weather Footer Date', this.currentWeatherFooterData);
      },
      error: (err: any) => {
        console.log('Current Date Error', err);
      },
    });

    this.weather.currentWeatherBox(this.location, this.token).subscribe({
      next: (data: any) => {
        this.currentWeatherBoxData = data;

        this.currentWeatherBoxData.data[0].coordinates[0].dates[0].date = moment(this.currentWeatherBoxData?.data[0]?.coordinates[0]?.dates[0]?.date).format(
          'dddd Do MMMM, h:mm:ss a'
        );

        this.currentWeatherBoxData.data[0].coordinates[0].dates[0].value = moment(this.currentWeatherBoxData?.data[0]?.coordinates[0]?.dates[0]?.value).format(
          'LT'
        );

        this.currentWeatherBoxData.data[1].coordinates[0].dates[0].value = moment(this.currentWeatherBoxData?.data[1]?.coordinates[0]?.dates[0]?.value).format(
          'LT'
        );

        this.printData('Current Weather Box Date', this.currentWeatherBoxData);
      },
      error: (err: any) => {
        console.log('Current Date Error', err);
      },
    });
  }

  dayWiseSplit(val: any, apiHours: any) {
    for (let i = 0; i < val.data.length; i++) {
      let tempArray = [];

      for (let j = 0; j < val.data[i].coordinates[0].dates.length; j = j + 24 / apiHours) {
        tempArray.push(val.data[i].coordinates[0].dates.slice(j, j + 24 / apiHours));
      }

      let filteredWeatherData = {
        weatherName: val.data[i].parameter,
        data: tempArray,
      };

      apiHours == 1
        ? this.dateWiseFullWeatherData_1Hours.push(filteredWeatherData)
        : apiHours == 3
        ? this.dateWiseFullWeatherData_3Hours.push(filteredWeatherData)
        : this.dateWiseFullWeatherData_24Hours.push(filteredWeatherData);
    }

    // apiHours == 1
    //   ? console.log(
    //       'Date Wise Full Weather Data 1Hours',
    //       this.dateWiseFullWeatherData_1Hours
    //     )
    //   : apiHours == 3
    //   ? console.log(
    //       'Date Wise Full Weather Data 3Hours',
    //       this.dateWiseFullWeatherData_3Hours
    //     )
    //   : console.log(
    //       'Date Wise Full Weather Data 24Hours',
    //       this.dateWiseFullWeatherData_24Hours
    //     );
  }

  getSunData(val: any, arrayIndex: any) {
    this.weatherArray = [];

    let hours: any = [];
    let day = '';
    let icon: any = [];
    let mainValue: any = [];
    let subValue: any = [];

    val[arrayIndex].data.map((val: any) => {
      day = '';
      hours = [];
      icon = [];
      mainValue = [];
      subValue = [];

      val.map((weatherVal: any, index: any) => {
        day == '' ? (day = moment(weatherVal.date.slice(0, 10)).format('dddd Do MMMM')) : day;
        index == 0 ? hours.push(index) : this.hours == 1 ? hours.push(index + 1) : this.hours == 3 ? hours.push(index * this.hours) : hours.push(index); // hours.push(index + 1);

        if (this.weatherTap == 2) {
          weatherVal.value < 1 ? icon.push('sun') : weatherVal.value > 2 ? icon.push('sun2') : icon.push('sun3');
          mainValue.push(weatherVal.value + ' °');
        }
        if (this.weatherTap == 3) {
          weatherVal.value < 30 ? icon.push('wind') : weatherVal.value > 60 ? icon.push('wind2') : icon.push('wind3');
          mainValue.push(weatherVal.value);
        }
        if (this.weatherTap == 4) {
          weatherVal.value < 30 ? icon.push('cloud') : weatherVal.value > 60 ? icon.push('cloud2') : icon.push('cloud3');
          mainValue.push(weatherVal.value + ' %');
        }
        if (this.weatherTap == 5) {
          weatherVal.value < 30 ? icon.push('moisture') : weatherVal.value > 60 ? icon.push('moisture2') : icon.push('moisture3');
          mainValue.push(weatherVal.value + ' %');
        }
      });

      let sunCoverage = {
        day,
        hours,
        icon,
        mainValue,
        subValue,
      };

      this.sunCoverage.push(sunCoverage);
    });

    val[arrayIndex + 1].data.map((val: any, i: any) => {
      subValue = [];

      val.map((subVal: any, index: any) => {
        if (this.weatherTap == 2) subValue.push(subVal.value);
        if (this.weatherTap == 3) {
          if (subVal.value >= 10 && subVal.value < 80) subValue.push('NE');
          if (subVal.value >= 80 && subVal.value < 100) subValue.push('E');
          if (subVal.value >= 100 && subVal.value < 170) subValue.push('SE');
          if (subVal.value >= 170 && subVal.value < 190) subValue.push('S');
          if (subVal.value >= 190 && subVal.value < 260) subValue.push('SW');
          if (subVal.value >= 260 && subVal.value < 280) subValue.push('W');
          if (subVal.value >= 280 && subVal.value < 350) subValue.push('NW');
          if (subVal.value >= 350 || subVal.value < 10) subValue.push('N');
        }
        if (this.weatherTap == 4) subValue.push(subVal.value);
        if (this.weatherTap == 5) subValue.push(subVal.value + '%');
      });

      this.sunCoverage[i].subValue = subValue;
    });

    this.weatherDataList = this.sunCoverage;
    if (this.weatherTap == 2) this.weatherLabel = this.sunLabel;
    if (this.weatherTap == 3) this.weatherLabel = this.windLabel;
    if (this.weatherTap == 4) this.weatherLabel = this.cloudLabel;
    if (this.weatherTap == 5) this.weatherLabel = this.moistureLabel;
  }

  getHours(hoursBtn: any) {
    this.hours = hoursBtn;
    this.weatherDataList = [];
    this.sunCoverage = [];

    hoursBtn == 1
      ? this.getSunData(this.dateWiseFullWeatherData_1Hours, this.arrstIndex)
      : hoursBtn == 3
      ? this.getSunData(this.dateWiseFullWeatherData_3Hours, this.arrstIndex)
      : this.getSunData(this.dateWiseFullWeatherData_24Hours, this.arrstIndex);
  }

  printData(label: string, ...data: any) {
    console.log(`%c=== ${label} ===`, 'color:green;font-size: 15px', ...data);
  }
}
