import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EnvironmentService } from  'src/app/services/environment.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// date interval 7 days
const dateInterval = 'todayT00:00:00Z--today+6DT23:00:00Z';

const sunApi = 't_2m:C,uv:idx';
const windApi = 'wind_speed_10m:kmh,wind_dir_10m:d';
const cloudApi = 'effective_cloud_cover:p,visibility:km'; //,is_fog_1h:idx
const moistureApi = 'relative_humidity_2m:p,prob_precip_1h:p'; //dew_point_100m:C,

// current data box
const sunRise = 'sunrise:sql';
const sunSet = 'sunset:sql';
const rainFall = 'is_rain_30min:idx';
const windDirection = 'wind_dir_10m:d';
const windGusts = 'wind_gusts_100m_3h:kmh';
const relativeHumidity = 'relative_humidity_20m:p';
const visibility = 'visibility:km';

//  current data footer
const temperature = 't_2m:C';
const windpeed = 'wind_speed_2m:kmh';
const cloudCover = 'effective_cloud_cover:p';
const moisture = 'relative_humidity_2m:p';

const environmentService = new EnvironmentService();

// meteomatics user name and password
const username = environmentService.getMeteomaticUser();
const password = environmentService.getMeteomatickey();
const headers = new HttpHeaders().set(
  'Authorization',
  'Basic ' + btoa(username + ':' + password),
);

@Injectable({
  providedIn: 'root',
})
export class WeatherService {

  constructor(private http: HttpClient) {}

  // get current location from user
  getCurrentLocation(): Observable<any> {
    return new Observable((data) => {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          data.next(location);
          data.complete();
        },
        (error) => {
          data.error(error);
        },
      );
    });
  }

  // weather meteomatics Authorization token generate
  getToken() {
    return this.http.get('https://login.meteomatics.com/api/v1/token', {
      headers: headers,
    });
  }

  // get full weather infermation from meteomatics database

  // weatherData(date: string, location: string, hours: any, token: string) {

  //    return this.http.get(
  //       `https://api.meteomatics.com/${date}:PT${hours}H/${sunApi},${windApi},${cloudApi},${moistureApi}/${location}/json?access_token=${token}`
  //    );
  // }

  weatherData_1Hours(location: string, token: string) {
    return this.http.get(
      `https://api.meteomatics.com/${dateInterval}:PT1H/${sunApi},${windApi},${cloudApi},${moistureApi}/${location}/json?access_token=${token}`,
    );
  }

  weatherData_3Hours(location: string, token: string) {
    return this.http.get(
      `https://api.meteomatics.com/${dateInterval}:PT3H/${sunApi},${windApi},${cloudApi},${moistureApi}/${location}/json?access_token=${token}`,
    );
  }

  weatherData_24Hours(location: string, token: string) {
    return this.http.get(
      `https://api.meteomatics.com/${dateInterval}:PT24H/${sunApi},${windApi},${cloudApi},${moistureApi}/${location}/json?access_token=${token}`,
    );
  }

  currentWeatherBox(location: string, token: string) {
    return this.http.get(
      `https://api.meteomatics.com/now/${sunRise},${sunSet},${rainFall},${windDirection},${windGusts},${relativeHumidity},${visibility}/${location}/json?access_token=${token}`,
    );
  }

  // windy services //

  // windy services end //

  currentWeatherFooter(location: string, token: string) {
    return this.http.get(
      `https://api.meteomatics.com/now/${temperature},${windpeed},${cloudCover},${moisture}/${location}/json?access_token=${token}`,
    );
  }
}
