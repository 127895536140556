import { Component, ComponentFactoryResolver, Input, ViewContainerRef, EventEmitter, Output } from '@angular/core';
import { state } from '@app/utility';
import { ApiService } from '../../api-permit.service';
import { Router } from '@angular/router'
import { ApplicationTrackerComponent } from '../../application-tracker/application-tracker.component';
import { ViewReceiptComponent } from 'src/app/modal-contents/view-receipt/view-receipt.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermitService } from 'src/app/services/permit.service';
import { IPermit } from 'src/app/models/permit.model';
import { IPermitMission } from 'src/app/models/permit-mission.model';

type PermitExtend = IPermit & {
  fullname: string;
  email: string;
  progress: any;
  permit_status: string;
  approvedTotal: any;
}
interface UserRole {
  Applicant: boolean,
  Authority: boolean,
  HumanValidator: boolean,
  Other: boolean
,
}

@Component({
  selector: 'app-utm-list-all',
  templateUrl: './list-all.component.html',
  styleUrls: ['./list-all.component.scss'],
})
export class ListAllComponent {
  handleClick(_t18: PermitExtend) {
    throw new Error('Method not implemented.');
  }
  @Input() permitList: any = [];
  @Output() refresh = new EventEmitter<any>();

  totalFees: number = 0;
  showDropdown = false;
  itemsPerPage = 5;
  currentPage = 1;
  currentIdx = null;
  clickedIdx = null;
  isExpand = false;
  confirmApproveModal = false;
  rejectModal = false;
  allColumns: any = [];
  missionHeader: any = ['ID', 'Mission Name', 'Objective', 'Date Create', 'Mission Area', 'Mission Status'];
  permitIds: any;
  rejectRemark: string = '';
  typeof: any;
  allClickedRows: number[] = [];
  allRoles: UserRole = {
    Applicant: false,
    Authority: false,
    HumanValidator: false,
    Other: false,
  }

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private apiService: ApiService,
    private modalService: NgbModal,
    private permitService: PermitService,
  ) { }

  ngAfterViewInit() {
    $('[data-toggle="tooltip"]').tooltip();
  }
  ngOnInit() {
    const role = state.getUserInfo().member[0].role.name;
    this.defineRoles(role);
  }

  onHoverCard(event: any) {
    const tooltip = document.getElementById('tooltip');

      const x = event.clientX;
      const y = event.clientY;

      if (tooltip != null) {
        tooltip.style.left = `${x - 40}px`;
        tooltip.style.top = `${y - 300}px`;
        tooltip.style.display = 'block';
      }

  }


  defineRoles(role: string): void {
    this.allColumns = [];
    switch (role) {
      case 'Owner':
      case 'Admin':
        this.allRoles.Applicant = true;
        this.allColumns.push('Application ID', 'Status', 'Permit Type', 'Applicant', 'Date Applied', 'Fees', 'Permit Status');
        break;
      case 'Authority Admin':
        this.allRoles.Authority = true;
        this.allColumns.push('Application ID', 'Permit Type', 'Applicant', 'Date Applied', 'Payment Status', 'Approval Progress');
        break;
      case 'Human Validator':
        this.allRoles.HumanValidator = true
        this.allColumns.push('Application ID', 'Permit Type', 'Applicant', 'Date Applied', 'Payment', 'Fees', 'Payment Proof', 'Permit Status');
        break;
      default:
        this.allRoles.Other = true;
    }
  }

  getPaymentClass(permit: any) {
    let classStatus = '';
    switch (this.convertPaymentStatus(permit.paymentStatus, permit)) {
      case 'PENDING':
        classStatus = 'badge-pending';
        break;
      case 'VERIFIED':
        classStatus = 'badge-success';
        break;
      case 'RETURNED':
        classStatus = 'badge-returned';
        break;
      default:
        classStatus = 'view-receipt';
    }
    return classStatus;
  }

  onPaymentClick(event: Event, permit: any) {
    event.stopPropagation();
    if (!permit.paymentApprovalInfo && typeof permit.paymentApprovalInfo !== 'object') {
      this.viewReceipt(permit._id, permit.paymentInfo.receipt);
    }
  }

  tracker(id: any) {
    this.router.navigate(['application-tracker', id]);
  }

  loadPermitApplicationComponent(id: any) {
    this.viewContainerRef.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ApplicationTrackerComponent);
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    componentRef.instance.id = id;
    this.router.navigate(['application-tracker']);
  }

  redirectToSummary(mission: any, permitId: string, applicationId: string) {
    this.apiService.setMissionPermitId(mission, permitId, applicationId);
    this.router.navigate(['/mission-summary']);
  }

  checkStatus(data: IPermitMission[]) {
    return data.every((item) => item.permitStatus === 'APPROVED') ? 'APPROVED' : 'PENDING';
  }

  checkTotalApproved(permit: any) {
    const totalApproved = permit.missions.filter((item: { permitStatus: string }) => item.permitStatus == 'APPROVED').length;
    return `${totalApproved} / ${permit.missions.length}`;
  }

  redirectToAppSummary(allMissions: any) {
    const allMissionId = allMissions.map((item: { missionId: { _id: any } }) => item.missionId._id).toString();
    this.router.navigate(['/permit'], { queryParams: { checkedMissions: allMissionId } });
  }

  convertPaymentStatus(status: string, permit: any) {
    const approvalInfo = permit.paymentApprovalInfo
    if (status != 'PAID') {
      return 'PENDING';
    } else if (approvalInfo && typeof approvalInfo === 'object') {
      return approvalInfo.paymentApprovalStatus;
    } else {
      return 'View Receipt';
    }
  }

  viewReceipt(id: string, filename: string) {
    this.permitIds = id;
    const modalRef = this.modalService.open(ViewReceiptComponent, {
      centered: true,
      backdrop: 'static',
      size: 'md',
    });

    modalRef.componentInstance.fileName = filename;
    modalRef.componentInstance.permitId = id;

    modalRef.result.then((result) => {
      if (result == 'confirm') {
        this.confirmApproveModal = true;
        this.rejectModal = false;
      } else if (result == 'rejected'){
        this.rejectModal = true;
        this.confirmApproveModal = false;
      } else {
        this.rejectModal = false;
        this.confirmApproveModal = false;
      }
    });
  }

  approveRejectService(result: any) {
    const isApprove = result == 'confirm'
    const body = {
      paymentApprovalStatus: isApprove ? 'VERIFIED' : 'RETURNED',
      remarks: isApprove ? '' : this.rejectRemark,
    };

    this.permitService.paymentApproval(this.permitIds, body).subscribe((data) => {
      this.permitIds = null;
      this.refresh.emit('refreshList');
    });

    this.confirmApproveModal = false;
    this.rejectModal = false;
  }

  cancelConfirm() {
    this.confirmApproveModal = false;
    this.rejectModal = false;
  }

  returnStatusColor(status: string) {
    if (status == 'APPROVED') {
      return 'badge-success';
    } else if (status == 'PENDING' || status == 'PENDING APPROVAL' || status == 'IN REVIEW' || status == 'INTERNAL REQUEST') {
      return 'badge-pending';
    } else if (status == 'REJECTED') {
      return 'badge-rejected';
    } else {
      return 'circle-draft color-draft';
    }
  }

  getPaymentStatus(status: string) {
    if (status == 'COMPLETED' || status == 'PAID') {
      return 'badge-success';
    } else {
      return 'badge-rejected';
    }
  }

  formatDate(date: string) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const dateObj = new Date(date);

    return `${months[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
  }

  formatTime(date: string) {
    const dateObj = new Date(date);

    let hours = dateObj.getHours();
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');

    const amOrPm = hours < 12 ? 'AM' : 'PM';
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes} ${amOrPm}`;
  }

  onHover(idx: any) {
    this.currentIdx = idx;
  }

  onLeave() {
    if (!this.isExpand) {
      this.currentIdx = null;
      const tooltip = document.getElementById('tooltip')
      if ( tooltip != null) {
        tooltip.style.display = 'none';
      }
    }
  }

  openMission(idx: any) {
    this.clickedIdx = idx;
    this.currentIdx = null;

    let index = this.allClickedRows.indexOf(idx);
    if (index !== -1) {
      this.allClickedRows.splice(index, 1);
    } else {
      this.allClickedRows.push(idx);
    }
  }

  get paginatedPermits() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.permitList.slice(startIndex, endIndex);
  }

  get paginationRange(): string {
    const startRange = (this.currentPage - 1) * this.itemsPerPage + 1;
    const endRange = Math.min(this.currentPage * this.itemsPerPage, this.permitList.length);
    return `${startRange} - ${endRange} of ${this.permitList.length} records`;
  }

  get pages(): number[] {
    const pageCount = Math.ceil(this.permitList.length / this.itemsPerPage);
    return Array.from({ length: pageCount }, (_, i) => i + 1);
  }

  changePage(pageNumber: number): void {
    this.currentPage = pageNumber;
  }

  goToNextPage(): void {
    this.currentIdx = null;
    this.clickedIdx = null;
    if (this.currentPage < this.pages.length) {
      this.currentPage++;
    }
  }

  goToPreviousPage(): void {
    this.currentIdx = null;
    this.clickedIdx = null;
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
}
