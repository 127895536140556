import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'formatDateTime',
})
export class FormatDateTimePipe implements PipeTransform {
  transform(date: string): string {
    const formattedDate = moment(date).format('MM/DD/YYYY');
    const formattedTime = moment(date).utc().format('hh:mm A');

    return `${formattedDate} ${formattedTime}`;
  }
}
