import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { DroneService } from './drone.service';
import { PayloadService } from './payload.service';
import { MissionService } from './mission.service';
import { IMission } from '../models/mission.model';
import { IPagination } from '../models/pagination.model';
import { MemberService } from './member.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  private updatedTextSubject = new BehaviorSubject<string>('');
  updatedText$ = this.updatedTextSubject.asObservable();

  AllScheduleChecked = new Subject<boolean>();

  selectedOperatorDronePayloadList = new Subject<any>();
  sendSelectedOperatorDronePayloadList = new Subject<any>();

  missionPostData$ = new BehaviorSubject<any>('');

  // editMissionData = new BehaviorSubject<any>('');
  editMissionRepeatDays = new BehaviorSubject<any>('');

  allDayCheckbox = new BehaviorSubject<any>('');
  allDayCheckboxToChangeTime = new BehaviorSubject<any>('');

  missionTitleNumber: number = 0;
  missionTitleNumberSubject = new BehaviorSubject<any>('');

  scheduleDateList = new BehaviorSubject<any>('');

  missionDate = new BehaviorSubject<any>('');

  scheduleTotalTimeCalculate = new BehaviorSubject<any>('');
  PreviousRepeatDayChange = new BehaviorSubject<any>('');

  editMissionData$ = new Subject<IMission>();

  allDayToggleDisable$ = new BehaviorSubject<any>('');
  allDayToggleGetIndex$ = new BehaviorSubject<any>({ index: 0 });
  private eventHandlers: Record<string, (event: Event) => void> = {};

  constructor(
    private droneService: DroneService,
    private payloadService: PayloadService,
    private missionService: MissionService,
    private memberService: MemberService,
  ) {}

  closeDropdown(className: string) {
    const dropdowns = document.querySelector(className) as HTMLDivElement;

    const clickHandler = (event: Event) => {
      if (!(event.target as HTMLDivElement).closest(className)) {
        dropdowns && dropdowns.classList.remove('dropdown_show');
      }
    };
    document.addEventListener('click', clickHandler);
    this.eventHandlers[className] = clickHandler;
  }

  removeEventListener(className: string) {
    const clickHandler = this.eventHandlers[className];

    if (clickHandler) {
      document.removeEventListener('click', clickHandler);
      delete this.eventHandlers[className];
    }
  }

  onGetAllOperator() {
    // return this.memberService.getAll({ role: '64d04bfe27ff533bb768583f', pageLimit: '1000' });
    return this.memberService.getAll({ role: '6566b582da2a140491611fbe', pageLimit: '1000' });
  }

  getMissionName(updatedText: string) {
    this.updatedTextSubject.next(updatedText);
  }

  getAllOperatorDronePayloadMissionData() {
    const queryParams: IPagination = { pageLimit: '1000' };

    return forkJoin({
      operator: this.onGetAllOperator(),
      drone: this.droneService.getAll(queryParams),
      payload: this.payloadService.getAll(queryParams),
      missionList: this.missionService.getAll(queryParams),
    });
  }
}
