import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BG_TRANSPARENT, MISSION_STATUS_COLORS } from 'src/app/constants/create-mission.const';
import { Checkbox } from 'src/app/shared/interface/checklist-dropdown.interface';

@Component({
  selector: 'app-utm-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrl: './multi-select-dropdown.component.scss',
})
export class MultiSelectDropdownComponent {
  @Input() list: Checkbox[] = [];
  @Input() placeholder: string = 'Select'

  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();

  checkedList: Checkbox[] = [];
  currentSelected: string = this.placeholder;
  showDropDown = false;
  missionStatusColors = MISSION_STATUS_COLORS;
  bgTransparent = BG_TRANSPARENT;

  constructor() {
    this.checkedList = [];
  }

  getSelectedValue(item: Checkbox) {
    if (item.checked) {
      this.checkedList.push(item);
    } else {
      const index = this.checkedList.indexOf(item);
      this.checkedList.splice(index, 1);
    }

    this.currentSelected = this.checkedList.map(list => list.name).join(', ');

    //share checked list
    this.shareCheckedlist(this.checkedList);

    //share individual selected item
    this.shareIndividualStatus(item);
  }

  shareCheckedlist(items: Checkbox[]) {
    this.shareCheckedList.emit(items);
  }

  shareIndividualStatus(item: Checkbox) {
    this.shareIndividualCheckedList.emit(item);
  }
}
