import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-utm-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss']
})
export class ViewPdfComponent {
  public pdfUrl: any;
  // pdfUrl!: SafeResourceUrl;
  constructor(private aModalService: NgbActiveModal){}
  close() {
    this.aModalService.close();
  }
  // pdfUrl: SafeResourceUrl;
  // @ViewChild('pdfModal') pdfModal;

  // constructor(private modalService: NgbModal, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    // Set the URL of the PDF
    // const url = 'https://example.com/path-to-your/sample.pdf';
  }
}
