<div class="reject-modal" >

    <div class="reject-modal-content text-center">

        <img class="" [src]="'/icon/Reject.png' | assetUrl">

        <p class="confirm-title">{{ title }}</p>

        <div class="confirm-desc">
            {{ description }}
        </div>

        <div class="receipt-modal-buttons">

            <a (click)="cancelClicked()" class="receipt-modal-button cancel">{{ cancelText }}</a>

            <a (click)="proceedClicked()" class="receipt-modal-button reject">{{ proceedText }}</a>

        </div>

    </div>

</div>