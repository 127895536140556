import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'setScheduleCheckMark',
})
export class SetScheduleCheckMarkPipe implements PipeTransform {
  transform(selectedScheduleId: string[], currentScheduleId: string, type: string): boolean {
    return selectedScheduleId.includes(currentScheduleId);
  }
}
