<div class="navigation_toolbar_container">
  <div class="navigation_control">
    <div class="navigation_3d navigation_control_box">
      <img [src]="icon2d ? ('/navigation-toolbar/2D.svg' | assetUrl) : ('/navigation-toolbar/3D.svg' | assetUrl)" alt="3D" (click)="tilt()" />
      <!-- <img *ngIf="!icon2d" [src]="'/navigation-toolbar/3D.svg' | assetUrl" alt="3D" (click)="tilt()" /> -->
      <!-- <img *ngIf="icon2d" [src]="'/navigation-toolbar/2D.svg' | assetUrl" alt="2D" (click)="tilt()" /> -->
    </div>
    <div class="navigation_zoom navigation_control_box">
      <img [src]="'/navigation-toolbar/zoom-in.svg' | assetUrl" alt="zoom-in" (click)="zoomIn()" />
      <div class="zoom_border"></div>
      <img [src]="'/navigation-toolbar/zoom-out.svg' | assetUrl" alt="zoom-out" (click)="zoomOut()" />
    </div>
    <div class="navigation_icon navigation_control_box">
      <img [src]="'/navigation-toolbar/navigator.svg' | assetUrl" alt="navigator" (click)="center()" />
    </div>
  </div>
  <!-- <div class="navigation_mark">
    <p class="navigation_mark_icon">&#63;</p>
  </div> -->
</div>
