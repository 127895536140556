import { Pipe, PipeTransform } from '@angular/core';
import { IDrone } from 'src/app/models/drone.model';
import { IMember } from 'src/app/models/member.model';
import { IPayload } from 'src/app/models/payload.model';
export interface SelectedInventory {
  operator: IMember[];
  drone: IDrone[];
  payload: IPayload[];
}
@Pipe({
  name: 'inventoryBackgroundColor',
})
export class InventoryBackgroundColorPipe implements PipeTransform {
  // transform(selectedScheduleInventory: string[], selectedScheduleInventoryId: string): boolean {
  //   return selectedScheduleInventory.includes(selectedScheduleInventoryId);
  // }
  transform(selectedScheduleInventory: any, selectedScheduleCount: string, type: string, data: IMember | IDrone | IPayload): boolean {
    let val: boolean = false;
    if (selectedScheduleInventory && Object.keys(selectedScheduleInventory).length) {
      const scheduleCount = selectedScheduleCount.replace(/\s/g, '');
      if (selectedScheduleInventory[scheduleCount]) {
        const inventoryType = selectedScheduleInventory[scheduleCount][type];
        if (inventoryType) {
          val = inventoryType.includes(data);
        }
      }
    }

    return val;
  }
}
