import { Component } from '@angular/core';
import { GlobeComponent } from '../globe/globe.component';

@Component({
  selector: 'app-navigation-toolbar',
  templateUrl: './navigation-toolbar.component.html',
  styleUrls: ['./navigation-toolbar.component.scss'],
})
export class NavigationToolbarComponent {
  icon2d: boolean = false;
  constructor(public globe: GlobeComponent) {}

  zoomIn() {
    this.globe.map.zoomIn();
  }

  zoomOut() {
    this.globe.map.zoomOut();
  }

  tilt() {
    var currentPitch = this.globe.map.getPitch();
    var newPitch;

    if (currentPitch === 0) {
      newPitch = 90;
      this.icon2d = true
    } else {
      newPitch = 0;
      this.icon2d = false
    }

    this.globe.map.easeTo({
      pitch: newPitch,
      duration: 500,
    });
  }

  center() {
    this.globe.map.resetNorth();
  }
}
