import { Component, OnInit } from '@angular/core';
import { MapdataService } from '../services/mapdata.service';
import { GlobeComponent } from '../globe/globe.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LiveService } from '../live.service';
import { MissionService } from '../services/mission.service';
import { IMissionFilter, IMission } from '../models/mission.model';
import * as singleSpa from 'single-spa';
import * as APP_CONSTANTS from '../constants/app-constants';
import mapboxgl from 'mapbox-gl';
import { MissionDetailsComponent } from '../modal-contents/mission-details/mission-details.component';
import { TelemetryService } from '../services/telemetry.service';
import { DroneService } from '../services/drone.service';
import { PayloadService } from '../services/payload.service';
import { ScheduleService } from '../services/schedule.service';
import { StreamVideoDetail } from 'src/app/interfaces/video-stream-detail.interface';
import { VideoService } from '../services/video.service';
import { LiveStreamVideoData, LiveStreamVideoResponse } from '../interfaces/video-stream-api-response.interface';
import { BG_TRANSPARENT, MISSION_STATUS_COLORS, NOTIFY_MSG } from '../constants/create-mission.const';
import { MESSAGES } from '..//constants/message.const';
import { ActivatedRoute } from '@angular/router';
import { LIVE_STREAM_PAGE_LIMIT, ORDER_BY_DESC } from '../constants/app-constants';
import { JwtTokenResponse } from '../interfaces/live-stream-jwt-token-response.interface';
import { GenerateStreamUrlResponse } from '../modal-contents/generate-url/generate-url/generate-url.component';
import { Checkbox } from '../shared/interface/checklist-dropdown.interface';
@Component({
  selector: 'app-utm-telemetry',
  templateUrl: './telemetry.component.html',
  styleUrls: ['./telemetry.component.scss'],
})
export class TelemetryComponent implements OnInit {
  public isShowToaster: boolean = false;
  public toasterClass: string = '';
  public toasterMsg: string = '';
  //shared componnet modal video detail
  public modalVideoStreamDetail!: StreamVideoDetail;
  //end of shared componnet modal video detail
  public selectedVideoStreamMissionId: string = '';
  public missionIds: string[] = [];
  public tempMissionIds: string[] = [];
  public liveStreamDetails: LiveStreamVideoData[] = [];

  fileToUpload: File | null = null;
  document: any;
  uploadFileInput = document.getElementById('upload-file');

  public selectedGenerateUrlStreamId: string = '';

  on_nav_tap: any = 'mission';
  on_mapstyle_tap = 1;
  selectedMissionId: string = '';
  currentIdx: number | null = null;
  // sites page asset filter select value
  industry: any;
  asset_health: any;
  project: any;
  keyValue: any;
  missionList: any;
  missionIncoming: any;
  missionActive: any;
  missionCompleted: any;
  MissionKeyPass: any;
  permissionData: string = '';
  selectedMission: string = '';
  APP_CONSTANTS = APP_CONSTANTS;
  cursor = '';
  isSearch: boolean = false;
  isFilter: boolean = false;
  isStatus: boolean = false;
  isObjective: boolean = false;
  isSort: boolean = false;
  selectedSortOption: string = 'createdAt';
  search = '';
  isAscending: boolean = false;
  sortOrder = 'DESC';
  filterStatus: Checkbox[] = [];
  filterObjective: Checkbox[] = [];
  isNewMission: boolean = false;
  isZoomedIn: boolean = false;
  showMore: boolean = false;
  prevId: string = '';
  sortingList: any = [
    {
      checked: false,
      value: 'name',
      name: 'Mission Name',
    },
    {
      checked: false,
      value: 'createdAt',
      name: 'Date Created',
    },
    {
      checked: false,
      value: 'updatedAt',
      name: 'Date Updated',
    },
  ];
  statusList: Checkbox[] = [];
  objectiveList: Checkbox[] = [];
  dronePilotPayloadCheck = false;

  missionStatusColors = MISSION_STATUS_COLORS;
  bgTransparent = BG_TRANSPARENT;

  constructor(
    public mapdataservice: MapdataService,
    public globe: GlobeComponent,
    private modalService: NgbModal,
    private liveAdd: LiveService,
    // private missionListn: ScheduleService,
    private missionService: MissionService, // public content: ContextbuttonComponent,
    private telemetryService: TelemetryService,
    private droneService: DroneService,
    private payloadService: PayloadService,
    private schedule: ScheduleService,
    private videoService: VideoService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    const livesteamUrl = this.activatedRoute.snapshot.paramMap.get('livestream');
    const missionId = this.activatedRoute.snapshot.paramMap.get('missionId');
    if (livesteamUrl === 'livestream') {
      this.initLiveStream(missionId ?? '', '');
    }
    this.getObjective();
    this.getStatus();
    this.getmission();
    const userUtm: any = localStorage.getItem('user-data');
    const userPermissionData = JSON.parse(userUtm);
    this.permissionData = userPermissionData?.member[0]?.role?.permissions || '';

    window.addEventListener('dronos:utm:mission-click-map', (e: any) => {
      const missionId = e.detail.missionId;
      this.selectedMissionId = missionId;

      const container = document.getElementById('scroll-cont');
      const card = document.getElementById(`card-${missionId}`);

      const containerRect = container!.getBoundingClientRect();
      const cardRect = card!.getBoundingClientRect();

      const offset = cardRect.top - containerRect.top;
      container!.scrollTop = offset;
    });
    // this.fetchDronePilotPayload()
    //clear video stream
    window.addEventListener('dronos:utm:video-payer', (event: any) => {
      if (event.detail.clearVideo) {
        this.clearVideoStreaming('video-close');
      }
    });
    //end of clear video stream
  }

  public isUserRolePresent(): boolean {
    return this.permissionData.includes('data.streaming.live');
  }
  // clear selected data on sites page asset filter popup box
  onClearAll() {
    this.industry = null;
    this.project = null;
    this.asset_health = null;
  }

  redirectToUploadDataset(missionDetails: IMission) {
    const customEvent = new CustomEvent('dronos:utm:missionFloating', { detail: { gridFlowFlag: true, missionDetails } });
    window.dispatchEvent(customEvent);
  }

  // common for all dropdown
  dropdown(event: any, targetElement: string = 'div.dropdownBox'): void {
    event.target.parentElement.closest(targetElement).classList.toggle('active');
  }

  tabClosed() {
    this.globe.isLivestream = false;
    if (this.globe.map.getSource('mission')) {
      this.globe.removeAreaBuffer();
    }
    this.globe.removeMissionMarkers();
    this.clearVideoStreaming('tab-close');
  }

  sideBarClicked(icon: any) {
    this.on_nav_tap = icon;
  }

  triggerUp(event: any) {
    this.globe.flytoMission(event);
  }

  missionDetails() {
    const customEvent = new CustomEvent('dronos:utm:missionFloating', { detail: { gridFlowFlag: true } });
    window.dispatchEvent(customEvent);
  }

  viewMissionDetails(event: Event, missionId: string) {
    this.selectedMissionId = missionId;

    // view mission details modal
    event.stopPropagation();

    const modalRef = this.modalService.open(MissionDetailsComponent, {
      centered: true,
      windowClass: 'mission-details',
      backdropClass: 'mission-details-static',
      backdrop: 'static',
      size: 'xl',
    });
    // modalRef.componentInstance.missionDetails = this.originalMission.find((item: any) => (item._id = missionId));
    modalRef.componentInstance.missionId = missionId;
  }
  //check mission has video stream
  getMissionVideoDetail(missionIds: string[]) {
    const videoStatusUpdatedMissionIds: string[] = [];
    if (missionIds.length > 0) {
      const queryParams = {
        sortOrder: ORDER_BY_DESC,
        pageLimit: LIVE_STREAM_PAGE_LIMIT,
      };
      this.missionIds = [...this.tempMissionIds];
      this.tempMissionIds = [];
      this.videoService.getLiveStreamMissionByIds(queryParams).subscribe((result: LiveStreamVideoResponse) => {
        if (result.records.length > 0) {
          this.liveStreamDetails = [...result.records];
          result.records.forEach((record: LiveStreamVideoData) => {
            const missionId: string = record.missionId;
            const missionIndex = this.missionIds.indexOf(missionId);
            const isStatusUpdated = videoStatusUpdatedMissionIds.indexOf(missionId);
            if (missionIndex !== -1 && isStatusUpdated === -1) {
              videoStatusUpdatedMissionIds.push(missionId);
              this.updateMissionVideoStreamStatus(missionIndex, record.droneStreamStatus);
            }
          });
        }
      });
    }
  }
  //end
  //update mission video stream status
  updateMissionVideoStreamStatus(missionIndex: number, status: string) {
    this.missionList[missionIndex].videoStreamStatus = status;
  }
  //end of update mission video stream status
  initLiveStream(id: string, missionId: string | undefined) {
    if (this.selectedVideoStreamMissionId === '') {
      this.videoService.getStreamDetailByMissionId(id).subscribe(
        (result: LiveStreamVideoResponse) => {
          if (result.records.length > 0) {
            //init video palyer function
            const liveStreamVideoDetail: LiveStreamVideoData = result.records[0];
            this.videoService.getStreamVideoToken(liveStreamVideoDetail._id).subscribe((result: JwtTokenResponse) => {
              const jwtToken = result.token;
              window.dispatchEvent(new CustomEvent('dronos:sidebar-toggle', { detail: { toggle_type: false } }));
              const streamUrl = liveStreamVideoDetail.droneStreamUrl;
              this.modalVideoStreamDetail = {
                id: missionId ? missionId : MESSAGES.NO_MISSION_ID_FOUND,
                type: this.selectedVideoStreamMissionId === '' ? 'start' : 'destroy-start',
                streamURL: streamUrl + '/',
                liveStream: true,
                mediaServer: 'mediaMTX',
                token: jwtToken,
              };
              this.selectedVideoStreamMissionId = id;
              const missionIndex = this.missionIds.indexOf(id);
              if (missionIndex !== -1) {
                this.updateMissionVideoStreamStatus(missionIndex, liveStreamVideoDetail.droneStreamStatus);
              }
              window.dispatchEvent(new CustomEvent('dronos:utm:video', { detail: this.modalVideoStreamDetail }));
            });
            //end init video palyer function
          } else {
            this.initToaster(NOTIFY_MSG.STREAM_MISSION_FAILED, NOTIFY_MSG.ERROR_CLASS, true);
          }
        },
        (error) => {
          this.initToaster(NOTIFY_MSG.STREAM_MISSION_FAILED, NOTIFY_MSG.ERROR_CLASS, true);
        },
      );
    } else {
      this.initToaster(NOTIFY_MSG.CLEAR_STREAM, NOTIFY_MSG.ERROR_CLASS, false);
    }
  }

  initToaster(toasterMessage: string, toasterClass: string, closeDashboardMenu: boolean) {
    if (closeDashboardMenu) {
      window.dispatchEvent(new CustomEvent('dronos:sidebar-toggle', { detail: { toggle_type: false } }));
    }
    this.isShowToaster = true;
    this.toasterMsg = toasterMessage;
    this.toasterClass = toasterClass;
    this.clearToaster(closeDashboardMenu);
  }

  clearToaster(openDashboardMenu: boolean) {
    setTimeout(() => {
      this.isShowToaster = false;
      if (openDashboardMenu) {
        window.dispatchEvent(new CustomEvent('dronos:sidebar-toggle', { detail: { toggle_type: true } }));
      }
    }, 3000);
  }
  clearVideoStreaming(type: 'tab-close' | 'video-close') {
    this.modalVideoStreamDetail = {
      type: 'destroy',
    };
    window.dispatchEvent(new CustomEvent('dronos:utm:video', { detail: this.modalVideoStreamDetail }));
    this.selectedVideoStreamMissionId = '';
    window.dispatchEvent(new CustomEvent('dronos:sidebar-toggle', { detail: { toggle_type: true } }));
  }

  createNewMission(type: string) {
    this.globe.missionPanel = true;
    this.isNewMission = true;
    switch (type) {
      case 'pin':
        // sini
        this.triggerCenter();
        break;
      case 'polygon':
        // sini
        this.triggerDraw();
        break;
      case 'upload':
        this.triggerInputUpload();
      // sini
    }
  }

  triggerCenter() {
    if (this.globe.map.getSource('mission')) {
      this.globe.removeAreaBuffer();
    }
    this.clearuploadfile();
    this.globe.removeObjective();
    this.globe.getPin();
    this.globe.missionMethod = 'pin';
    this.globe.createMission = true;
  }

  triggerDraw() {
    if (this.globe.map.getSource('mission')) {
      this.globe.removeAreaBuffer();
    }
    this.clearuploadfile();
    this.globe.removeObjective();
    this.globe.drawPolygon();
  }
  enableTooltip(event: any) {
    $(event.target).tooltip('enable');
  }
  handleFileInput(files: any) {
    this.globe.removeObjective();
    this.fileToUpload = files.target.files[0];
    this.globe.fileUploaded(files.target.files[0]);
    this.globe.missionMethod = 'upload';
    this.globe.createMission = true;
  }

  triggerInputUpload() {
    if (this.globe.map.getSource('mission')) {
      this.globe.removeAreaBuffer();
    }
    const uploadFile = window.document.getElementById('upload-file')!.click();
  }

  clearuploadfile() {
    const uploadFileInput = document.getElementById('upload-file') as HTMLInputElement;
    if (uploadFileInput) {
      uploadFileInput.value = '';
    }
  }

  triggerComplete(event: any) {
    const passComplete = event;
    this.globe.weatherOnOff();
    this.liveAdd.triggerPlayback(passComplete);
    this.globe.flytoMissionEnded(event);
  }

  onMissionClick(mission: any, event: any) {
    this.selectedMissionId = mission._id;
    this.showMore = false;
    if (this.selectedMissionId == mission._id && this.prevId != mission._id) {
      this.isZoomedIn = true;
      event.preventDefault();
      if (mission.area) {
        const coordinate = new mapboxgl.LngLat(mission.area.geoCoordinate.longitude, mission.area.geoCoordinate.latitude);
        this.globe.map.flyTo({
          center: coordinate,
          zoom: 14,
        });
        if (this.globe.map.getSource('mission')) {
          this.globe.removeAreaBuffer();
        }
        this.prevId = mission._id;
        this.globe.drawAreaBuffer(mission.area.coordinate);
      }

      const customEvent = new CustomEvent('dronos:utm:mission-click', { detail: { missionId: this.selectedMissionId } });
      window.dispatchEvent(customEvent);
    } else {
      this.isZoomedIn = false;
      this.prevId = '';
      this.selectedMissionId = '';
      const coordinate = new mapboxgl.LngLat(mission.area.geoCoordinate.longitude, mission.area.geoCoordinate.latitude);
      this.globe.map.flyTo({
        center: coordinate,
        zoom: 5,
      });
    }
  }

  sortChange() {
    this.globe.removeMissionMarkers();
    this.getmission();
  }

  onSwitchChange() {
    this.globe.removeMissionMarkers();
    if (this.isAscending == true) {
      this.sortOrder = 'ASC';
    } else {
      this.sortOrder = 'DESC';
    }
    this.getmission();
  }

  onFilterStatusChanged(items: Checkbox[]) {
    this.globe.removeMissionMarkers();
    this.filterStatus = items;
    this.getmission();
  }

  onFilterObjectiveChanged(items: Checkbox[]) {
    this.globe.removeMissionMarkers();
    this.filterObjective = items;
    this.getmission();
  }

  showMoreSection(id: string, event: any) {
    this.selectedMissionId = id;

    event.stopPropagation();

    this.showMore = !this.showMore;
    // if (this.selectedMissionId == id) {
    //   this.selectedMissionId = null;
    // } else {
    //   this.selectedMission = id;
    // }
  }

  onOptionClick(appName: string) {
    singleSpa.navigateToUrl(appName);
  }

  getObjective() {
    this.missionService.getObjective().subscribe((data) => {
      this.objectiveList = data.records.map((status) => ({
        checked: false,
        value: status._id,
        name: status.name,
      }));
    });
  }

  getStatus() {
    this.missionService.getStatusList().subscribe((data) => {
      this.statusList = data.map((status) => ({
        checked: false,
        value: status,
        name: status,
      }));
    });
  }

  getmission() {
    const queryParams: IMissionFilter = {
      pageLimit: '10',
      search: this.search,
      sortBy: this.selectedSortOption,
      sortOrder: this.sortOrder,
      status: this.filterStatus.map((status) => status.name),
      objective: this.filterObjective.map((objective) => objective.value)
    };

    this.missionService.getAll(queryParams).subscribe((data) => {
      // Your data object
      const mission = data.records;
      this.cursor = data.meta.nextCursor;
      // Iterate through the missions
      for (const missionItem of mission) {
        //check mission id is already pushed
        if (this.missionIds.indexOf(missionItem._id) === -1) this.tempMissionIds.push(missionItem._id);
        //end of check mission id is already pushed
        if (missionItem.area) {
          this.globe.markMission(missionItem.area.geoCoordinate?.latitude, missionItem.area.geoCoordinate?.longitude, missionItem._id);
        }
      }

      this.missionList = data.records;
      this.globe.currentTelemetryMissions = this.missionList;
      this.getMissionVideoDetail(this.tempMissionIds);
    });
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      const queryParams: IMissionFilter = {
        pageLimit: '10',
        search: this.search,
        nextCursor: this.cursor,
        sortBy: this.selectedSortOption,
        sortOrder: this.sortOrder,
        status: this.filterStatus.flatMap((status) => status.name),
        objective: this.filterObjective.map((objective) => objective.value),
      };

      if (this.cursor) {
        this.missionService.getAll(queryParams).subscribe((data) => {
          this.cursor = data.meta.nextCursor;
          this.missionList = [...this.missionList, ...data.records];
          const newMission = data.records;
          for (const missionItem of newMission) {
            //check mission id is already pushed
            if (this.missionIds.indexOf(missionItem._id) === -1) this.tempMissionIds.push(missionItem._id);
            //end of check mission id is already pushed
            if (missionItem.area) {
              this.globe.markMission(missionItem.area.geoCoordinate?.latitude, missionItem.area.geoCoordinate?.longitude, missionItem.trackingId);
            }
          }
          this.getMissionVideoDetail(this.tempMissionIds);
        });
      }
    }
  }

  formatDate(date: string) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const dateObj = new Date(date);

    return `${months[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
  }

  formatTime(date: string) {
    const dateObj = new Date(date);

    let hours = dateObj.getHours();
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const meridiem = hours < 12 ? 'AM' : 'PM';

    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes} ${meridiem}`;
  }

  onInputChange() {
    if (this.globe.map.getSource('mission')) {
      this.globe.removeAreaBuffer();
    }
    this.globe.removeMissionMarkers();
    this.getmission();
  }

  clearSort() {
    this.isAscending = true;
    this.sortOrder = 'ASC';
    this.selectedSortOption = 'createdAt';
    this.getmission();
  }

  callOrtho() {
    this.telemetryService.getData('65ae11cb5d174088c6b34485').subscribe((val) => {
      console.log('=========++++++++++', val);
    });
  }

  passMissionId() {
    // this.telemetryService.getMissionId()
  }

  async fetchDronePilotPayload() {
    try {
      const droneResponse = await this.droneService.getAll().toPromise();
      const drone = droneResponse?.records || [];

      const payloadResponse = await this.payloadService.getAll().toPromise();
      const payload = payloadResponse?.records || [];

      const pilotResponse = await this.schedule.onGetAllOperator().toPromise();
      const pilot = (pilotResponse as any)?.records || [];

      // Now that you have all the data, you can process it.
      this.processDronePayload(drone, payload, pilot);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  processDronePayload(droneData: any, payloadData: any, pilotData: any) {
    if (droneData.length !== 0 && payloadData.length !== 0 && pilotData !== 0) {
      this.dronePilotPayloadCheck = true;
    } else {
      this.dronePilotPayloadCheck = false;
    }
  }

  initGenerateStreamUrl(id: string) {
    this.selectedGenerateUrlStreamId = id;
  }

  manageGenerateUrlResponse(event: GenerateStreamUrlResponse) {
    if (event.showToaster && event.message && event.toasterClass) {
      if (event.closeRightSideDashboard) {
        this.initToaster(event.message, event.toasterClass, event.closeRightSideDashboard);
      } else {
        this.initToaster(event.message, event.toasterClass, false);
      }
    }
    if (event.clearCurrentStreamId) {
      this.selectedGenerateUrlStreamId = '';
    }
  }

  onMouseEnter() {
    this.showMore = false;
  }
}
