import { Component, OnInit } from '@angular/core';
import { IDrone } from 'src/app/models/drone.model';
import { IFlight } from 'src/app/models/flight';
import { IMission } from 'src/app/models/mission.model';
import { IPayload } from 'src/app/models/payload.model';
import { DroneService } from 'src/app/services/drone.service';
import { FlightService } from 'src/app/services/flight.service';
import { MissionService } from 'src/app/services/mission.service';
import { PayloadService } from 'src/app/services/payload.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  public missions: any

  constructor(private missionService: FlightService){
    
  }

  ngOnInit(){
    this.getAll()
    // this.create()
    // this.delete()
  }

  getAll(){
    this.missionService.getAll(1).subscribe( (data) => {
      console.log(data)
    }) 
  }

  create(){
    var flight: IFlight = {
      "missionId": 1,
      "name": "Test Mission Flight",
      "flightPattern": "waypoint",
      "finishAction": "hover",
      "coordinateDetail": [
        {
            "latitude": "Test latitude",
            "longitude": "Test longitude",
            "speed": 0,
            "altitude": 0,
            "gimbalPitch": 0,
            "action": "Test action"
        },
        {
            "latitude": "Test latitude1",
            "longitude": "Test longitude1",
            "speed": 0,
            "altitude": 0,
            "gimbalPitch": 0,
            "action": "Test action1"
        }
      ]
    }

    this.missionService.create(flight).subscribe( (data) => {
      console.log(data)
    }) 
  }

  delete(){
    this.missionService.delete(1).subscribe( (data) => {
      console.log(data)
    }) 
  }
}
