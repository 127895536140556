import { Component, OnInit } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import GeocodeReverse from '@mapbox/mapbox-sdk/services/geocoding';
import { MapdataService } from '../services/mapdata.service';
import { kml } from '@tmcw/togeojson';
import JSZip from 'jszip';
import * as turf from '@turf/turf';
import { state } from '@app/utility';
import * as THREE from 'three';
import $ from 'jquery';
import { LivestreamsComponent } from '../livestreams/livestreams.component';
import { MqttserviceService } from '../mqttservice.service';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { assetUrl } from 'src/single-spa/asset-url';
import { ApiService } from '../api-permit.service';
import { RoleType } from '../enums/roleType.enum';
import { environment } from 'src/environments/environment';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { MissionService } from '../services/mission.service';
import { MapService } from '../map.service';

// const AWS = require('aws-sdk');
// import * as AWS from 'aws-sdk'

import { ActivatedRoute } from '@angular/router';
// import DjiGeozones from 'ol-dji-geozones';

interface CapturedSources {
  [key: string]: mapboxgl.AnySourceData; // Adjust the type accordingly
}

export interface CompassData {
  batteries_capacity: number;
  wind_speed: number;
  vertical_speed: number;
  height: number;
  attitude_pitch: number;
  attitude_head: number;
  attitude_roll: number;
}

@Component({
  selector: 'app-globe',
  templateUrl: './globe.component.html',
  styleUrls: ['./globe.component.scss', '../../../node_modules/@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'],
  providers: [LivestreamsComponent],
})
export class GlobeComponent implements OnInit {
  readonly RoleType = RoleType;
  missionPanel: boolean = true;
  myLineChart: any;
  lineData: any;
  isDrawLine: boolean = false;
  createMission = false;
  airspaceInformatics = false;
  missionMethod: any;
  featureProperties: any;
  completeProfileToken: any;
  compassOn = false;
  compassData!: CompassData;
  WeatherOn = false;
  closeResult = '';
  transPoly = [] as any;
  mapData: any;
  marker: mapboxgl.Marker = new mapboxgl.Marker({
    draggable: true,
    anchor: 'bottom', // Set the anchor point to the bottom center
  });
  centroid: any;
  circleLayerId: any;
  customLayer: any;
  // poiLayer: any;
  classA = 'CLASS_A.kmz';
  classB = 'CLASS_B.kmz';
  classC = 'CLASS_C.kmz';
  WMR = 'WM(R).kmz';
  WMP = 'WM(P).kmz';
  WMD = 'WM(D).kmz';
  Djigeozone = 'https://dronos-utm.s3.ap-southeast-1.amazonaws.com/DJIGEOZONE.geojson';
  kmzData = 'https://dronos-utm.s3.ap-southeast-1.amazonaws.com/malaysia.sua.kmz';
  layerStyle: string[] = [];
  r: any;
  capturedLayers: mapboxgl.AnyLayer[] = [];
  sourceIds: any;
  capturedSources: CapturedSources = {};
  message!: string;
  incomingTopic: string = '';
  incomingPayload: string = '';
  role = '';
  dronePathSource!: mapboxgl.GeoJSONSource;
  dronePathCoordinates = [] as any;
  _data!: mapboxgl.GeoJSONSource;
  contextmenu = false;
  contextmenuX = 0;
  contextmenuY = 0;
  dronePath: any;
  radius: number = 500;
  altitude: number = 120;
  missionLayer: any;
  isLivestream: any = false;
  missionAreaArea: any;
  missionAreaVolume: any;
  dronePilotPayloadCheck = false;
  airspaceOn = true;
  currentEnv: string = '';
  currentTelemetryMissions: any = []; // temporary nad
  private missionMarker: mapboxgl.Marker[] = [];
  private readonly AWS_S3_BUCKET_NAME = 'your-s3-bucket-name';
  private readonly AWS_REGION = 'your-region';
  missionAreaDistance: any;
  // private s3Client!: S3Client;
  coordinates = [] as any;
  markers = [] as any;
  distanceMarker: any;
  labelMarker: any;
  constructor(
    public mapdataservice: MapdataService,
    private api: ApiService,

    public livestreams: LivestreamsComponent,

    private mqttservice: MqttserviceService,
    // s3Client = new S3Client({ region: this.AWS_REGION });
    private missionService: MissionService,
    private mapService: MapService,
    private activatedRoute: ActivatedRoute,
  ) {
    // NexusObject;Nexus
    this.currentEnv = environment.name;
  }
  map!: mapboxgl.Map;
  // loader!: GLTFLoader;
  drawline!: MapboxDraw;
  // camera!: THREE.Camera;
  // renderer!: THREE.WebGLRenderer;
  // scene!: THREE.Scene;

  draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      polygon: true,
      trash: false,
    },
    defaultMode: 'draw_polygon',
    styles: [
      // Customize the line color
      {
        id: 'gl-draw-line',
        type: 'line',
        paint: {
          'line-color': '#aaaaaa', // Set line color to red
          'line-width': 3,
        },
      },
      // Customize the marker color
      {
        id: 'gl-draw-marker',
        type: 'circle',
        filter: ['all', ['!=', 'meta', 'midpoint'], ['==', '$type', 'Point']],
        paint: {
          'circle-color': '#016064', // Set marker color to green
          'circle-radius': 8,
        },
      },
      {
        id: 'gl-draw-marker-inner',
        type: 'circle',
        filter: ['all', ['!=', 'meta', 'midpoint'], ['==', '$type', 'Point']],
        paint: {
          'circle-color': '#fefefe',
          'circle-radius': 4,
        },
      },
    ],
  });

  center: any;
  dataCenter2: any;
  geojsonLayers: any;

  showDiv = {
    previous: false,
    current: false,
    next: false,
  };

  footerLoaded: boolean = false;
  scalePrecision: number = 3;
  coordinateTimeout: any;
  id = 'uuyuy';
  ortho = {
    external_url: 'https://aerodyne-halle.s3.eu-central-1.amazonaws.com/HALLE/04_DELIVERY/04_HOSTING/01_MAPTILER/01_ORTHO_MAPTILER/Halle/openlayers.html',
    layer_type: 'ortho',
    layer_name: 'Ortho',
    layer_date: '2021-10-06',
    id: '615d232430ee0b02b26cf26d',
  };

  isLoad: boolean = false;
  orthoMinzoom = 15;

  ngOnInit() {
    const livestream = this.activatedRoute.snapshot.paramMap?.get('livestream');
    if (livestream === 'livestream') {
      this.isLivestream = true;
      return;
    }
    (mapboxgl as any).accessToken = 'pk.eyJ1IjoiaW50dWl0aXZlYmFkZ2VyIiwiYSI6ImNrOXZpeXZiNzBkN3Iza29saHhjOWo2OGwifQ.QOMbmOzi8OhYw6NYyDqBRQ';

    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/intuitivebadger/clnbqvtnk03la01pjfg65g3r3',
      // style: 'mapbox://styles/mapbox/streets-v11',

      zoom: 2,
      center: [-90, 40],
      attributionControl: false,
    });

    this.mapService.setMapInstance(this.map);
    this.removeAllPopups();

    // UNDAN trigger (uncomment ni chu)
    this.arrangeUndan();

    this.map.on('draw.create', () => {
      this.map.getCanvas().style.cursor = 'crosshair';
    });

    //draw update for upload kml and draw polygon
    this.map.on('draw.update', (e) => {
      this.clearLayer();
      if (this.marker) {
        this.marker.remove();
        this.clearLayer();
      }
      this.clearLayer();

      var elevation = this.altitude;
      var feature = e.features[0];

      this.transPoly.push(feature.geometry.coordinates[0]);

      this.map.addLayer({
        id: 'area',
        type: 'fill-extrusion',
        source: {
          type: 'geojson',
          data: feature,
        },
        paint: {
          'fill-extrusion-color': '#009688',
          'fill-extrusion-opacity': 0.5,
          'fill-extrusion-height': elevation,
        },
      });

      this.missionAreaArea = turf.area(feature).toFixed(2);
      this.missionAreaVolume = (this.missionAreaArea * this.altitude).toFixed(2);

      // Calculate buffer polygons
      var polygonBuffer1 = turf.buffer(feature, 100, { units: 'meters' });
      var polygonBuffer2 = turf.buffer(feature, 200, { units: 'meters' });
      var polygonBuffer3 = turf.buffer(feature, 300, { units: 'meters' });

      // Convert buffer polygons to line strings
      var lineBuffer1 = turf.polygonToLineString(polygonBuffer1);
      var lineBuffer2 = turf.polygonToLineString(polygonBuffer2);
      var lineBuffer3 = turf.polygonToLineString(polygonBuffer3);

      // Add buffer layers
      this.map.addLayer({
        id: 'buffer1',
        type: 'line',
        source: {
          type: 'geojson',
          data: lineBuffer1,
        },
        paint: {
          'line-color': '#FFD54F',
          'line-width': 1,
          'line-dasharray': [3, 2, 2, 3],
        },
      });

      this.map.addLayer({
        id: 'buffer2',
        type: 'line',
        source: {
          type: 'geojson',
          data: lineBuffer2,
        },
        paint: {
          'line-color': '#FF0000',
          'line-width': 1,
          'line-dasharray': [3, 2, 2, 3],
        },
      });

      this.map.addLayer({
        id: 'buffer3',
        type: 'line',
        source: {
          type: 'geojson',
          data: lineBuffer3,
        },
        paint: {
          'line-color': '#2196F3',
          'line-width': 1,
          'line-dasharray': [3, 2, 2, 3],
        },
      });

      if (this.marker) {
        this.marker.remove();
      }

      if (!this.isDrawLine) {
        // Calculate the centroid of the polygon
        var centroid = turf.centroid(feature);

        const combinedPolygons = turf.union(polygonBuffer1, polygonBuffer2, polygonBuffer3);

        const bbox = turf.bbox(combinedPolygons);

        this.centroid = centroid.geometry.coordinates;

        this.map.addSource(`centroid`, {
          type: 'geojson',
          data: centroid,
        });

        fetch(assetUrl('globe/dropPin.svg'))
          .then((response) => response.blob())
          .then((iconBlob) => {
            const iconUrl = URL.createObjectURL(iconBlob);

            const image = new Image();
            image.onload = () => {
              if (!this.map.hasImage('custom-icon')) {
                this.map.addImage('custom-icon', image);
              }
              this.map.addLayer({
                id: `centroid`,
                type: 'symbol',
                source: `centroid`,
                layout: {
                  'icon-image': 'custom-icon',
                  'icon-size': 1,
                  'icon-anchor': 'bottom',
                },
              });
            };

            image.src = iconUrl;
          });

        this.map.fitBounds(
          [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[3]],
          ],
          {
            pitch: 70,
            padding: 20,
          },
        );
      }
    });

    // djiGeozones.once('init',(data:any)=>{})

    // djiGeozones.on('error', (err) => {
    //   alert('An error occurred: ' + err.message);
    //   console.error(err);
    // });

    this.getNotamNew();
    this.addOrtho();
    // this.mapdataservice.addOrtho();

    // this.livestreams.mqttTelemetry();

    const geocodingClient = GeocodeReverse({
      accessToken: mapboxgl.accessToken,
    });

    this.map.setProjection('globe');

    const secondsPerRevolution = this.altitude;
    const maxSpinZoom = 5;
    const slowSpinZoom = 3;

    let userInteracting = false;
    let spinEnabled = true;

    const spinGlobe = () => {
      const zoom = this.map.getZoom();

      if (spinEnabled && !userInteracting && zoom < maxSpinZoom) {
        let distancePerSecond = 360 / secondsPerRevolution;
        if (zoom > slowSpinZoom) {
          const zoomDif = (maxSpinZoom - zoom) / (maxSpinZoom - slowSpinZoom);
          distancePerSecond *= zoomDif;
        }
        const center = this.map.getCenter();
        center.lng -= distancePerSecond;
        this.map.easeTo({ center, duration: 1000, easing: (n) => n });
      }
    };

    window.addEventListener('dronos:utm:fly-to', (event: any) => {
      const lat = event.detail.coordinate.lat;
      const lng = event.detail.coordinate.lng;
      const zoom = event.detail.zoom;
      this.map.flyTo({
        center: [lng, lat],
        zoom: zoom !== undefined ? zoom : 20,
      });
    });

    window.addEventListener('dronos:utm:sidebar-click', (event: any) => {
      const iconClicked = event.detail.icon;
      if (iconClicked == 'mission') {
        this.isLivestream = true;
        this.airspaceOn = false;
      } else {
        this.isLivestream = false;
      }
    });

    this.getFooterCallback();

    this.map.on('mousedown', () => {
      userInteracting = true;
    });

    this.map.on('wheel', () => {
      userInteracting = true;
    });

    const modelOrigin = [101.650149, 2.900591] as mapboxgl.LngLatLike;
    const modelAltitude = 0;
    const modelRotate = [Math.PI / 2, 0, 0];

    const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);

    const modelTransform = {
      translateX: modelAsMercatorCoordinate.x,
      translateY: modelAsMercatorCoordinate.y,
      translateZ: modelAsMercatorCoordinate.z as number,
      rotateX: modelRotate[0],
      rotateY: modelRotate[1],
      rotateZ: modelRotate[2],
      scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
    };

    this.customLayer = {
      id: '3d-model-2',
      type: 'custom',
      renderingMode: '3d',
      onAdd: function (map: mapboxgl.Map, gl: WebGLRenderingContext) {
        this.camera = new THREE.PerspectiveCamera();
        this.scene = new THREE.Scene();

        const directionalLight = new THREE.DirectionalLight(0xffffff);
        directionalLight.position.set(0, -70, 100).normalize();
        this.scene.add(directionalLight);

        const directionalLight2 = new THREE.DirectionalLight(0xffffff);
        directionalLight2.position.set(0, 70, 100).normalize();
        this.scene.add(directionalLight2);

        const loader = new GLTFLoader();
        loader.load(
          "'/Low.glb' | assetUrl", // assets/Low.glb create pipe url //
          (gltf) => {
            this.scene.add(gltf.scene);
          },
        );
        map = map;

        this.renderer = new THREE.WebGLRenderer({
          canvas: map.getCanvas(),
          context: gl,
          antialias: true,
        });

        this.renderer.autoClear = false;
      },
      render: function (gl: WebGLRenderingContext, matrix: any) {
        const rotationX = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(1, 0, 0), modelTransform.rotateX);
        const rotationY = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 1, 0), modelTransform.rotateY);
        const rotationZ = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 0, 1), modelTransform.rotateZ);

        const m = new THREE.Matrix4().fromArray(matrix);
        const l = new THREE.Matrix4()
          .makeTranslation(modelTransform.translateX, modelTransform.translateY, modelTransform.translateZ)
          .scale(new THREE.Vector3(modelTransform.scale, -modelTransform.scale, modelTransform.scale))
          .multiply(rotationX)
          .multiply(rotationY)
          .multiply(rotationZ);

        this.camera.projectionMatrix = m.multiply(l);
        this.renderer.state.reset();
        this.renderer.render(this.scene, this.camera);
        // this.map.triggerRepaint();
      },
    };

    this.map.on('zoomend', () => {
      // console.log('jalan dulu');
      // setTimeout(() => {
      //   console.log("start");
      // this.map.addLayer(this.customLayer);
      // }, 10000);
    });

    this.map.on('load', () => {
      state.setMapbox(this.map, mapboxgl);
      const customEvent = new CustomEvent('dronos:utm:mapbox-loaded');
      // const poiLayers = ['poi-label', 'transit-label'];
      window.dispatchEvent(customEvent);
      this.map.resize();
      // this.map.addLayer(this.poiLayer);
      this.loadDataKmz(this.layerStyle);
    });

    // this.map.once('click', (evt) => {
    //   this.map.flyTo({
    //     center: [108.261004, 4.921666],
    //     zoom: 5.5,
    //   });
    // });

    this.map.on('move', () => {
      if (this.airspaceOn == true) {
        if (this.map.getZoom() <= 6) this.airspaceInformatics = false;
        if (this.map.getZoom() >= 6) {
          const visibleFeatures = this.map.queryRenderedFeatures(undefined, { layers: this.layerStyle });
          const dup = new Set();
          const filteredAirSpace = visibleFeatures.filter((el) => {
            const verticalName = el.properties?.['name'];
            const duplicate = dup.has(verticalName);
            dup.add(verticalName);
            return !duplicate;
          });

          this.getLayerInfo(filteredAirSpace);
        }
      }
    });

    this.loadDataKmz2(this.kmzData);
    // this.loadDataKmz(this.Djigeozone);

    // recheck source url

    //   // function onDragEnd() {
    //   //   const lngLat = marker.getLngLat();
    //   //   console.log('test');
    //   // }

    //   this.getLayerInfo(evt);

    //   var createGeoJSONCircle = (center: [number, number], radius: number) => {
    //     const options = { steps: 64, units: 'miles', properties: {} };
    //     const coordinates = [];
    //     for (let i = 0; i < options.steps; i++) {
    //       const angle = (i / options.steps) * Math.PI * 2;
    //       const dx = radius * Math.cos(angle);
    //       const dy = radius * Math.sin(angle);
    //       const point = [center[0] + dx, center[1] + dy];
    //       coordinates.push(point);
    //     }
    //     coordinates.push(coordinates[0]);
    //     return {
    //       type: 'Feature',
    //       geometry: {
    //         type: 'Polygon',
    //         coordinates: [coordinates],
    //       },
    //       properties: options.properties,
    //     };
    //   };
    // });

    // add a click event listener to the main button
    // mainButton.addEventListener('click', function() {
    //   // toggle the visibility of the expanded buttons
    //   expandedButtons.forEach(function(button) {
    //     button.classList.toggle('visible');
    //   });
    // });

    this.map.on('mouseup', () => {
      userInteracting = false;
      spinGlobe();
    });

    this.map.on('dragend', () => {
      userInteracting = false;
      spinGlobe();
    });

    this.map.on('pitchend', () => {
      userInteracting = false;
      spinGlobe();
    });

    this.map.on('rotateend', () => {
      userInteracting = false;
      spinGlobe();
    });

    this.map.on('moveend', () => {
      spinGlobe();
    });

    // document.getElementById('btn-spin').addEventListener('click', (e) => {
    //   spinEnabled = !spinEnabled;
    //   if (spinEnabled) {
    //     spinGlobe();
    //     e.target.innerHTML = 'Pause rotation';
    //   } else {
    //     this.map.stop();
    //     e.target.innerHTML = 'Start rotation';
    //   }
    // });

    spinGlobe();

    this.addLayer();

    this.map.doubleClickZoom.disable();

    this.map.on('dblclick', (e) => {
      // Toggle the display of #objectiveBar
      // $('#objectiveBar').css('display', $('#objectiveBar').css('display') === 'block' ? 'none' : 'block');
    });

    //move get center
    this.map.on('mousemove', async (e) => {
      var dataCenter2 = e.lngLat.wrap();
      clearTimeout(this.coordinateTimeout);
      if (this.map.getZoom() > 2.6) {
        if (this.footerLoaded) {
          const dmsLatitude = this.decimalToDMS(dataCenter2.lat);
          const dmsLongitude = this.decimalToDMS(dataCenter2.lng);
          // if (document.getElementById('locationName')) {
          //   document.getElementById('location')!.innerHTML = [dmsLatitude, dmsLongitude].toString();
          // }

          const coordinate = {
            lat: dataCenter2.lat,
            lng: dataCenter2.lng,
          };

          this.shareCoordinate(coordinate); // footer - share component

          const geocode = await geocodingClient
            .reverseGeocode({
              query: [e.lngLat.lng, e.lngLat.lat],
              types: ['region', 'poi'],
            })
            .send();

          try {
            // Code that might throw an error
            const result = geocode.body.features[0].place_name; // Divide by zero to intentionally throw an error
            // if (document.getElementById('locationName')) {
            //   document.getElementById('locationName')!.innerHTML = result.length > 40 ? `${result.slice(0, 35)}...` : result;
            // }
          } catch (error) {
            // Code to handle the error
            // if (document.getElementById('locationName')) {
            //   document.getElementById('locationName')!.innerHTML = 'No location recorded';
            // }
          }
        }
      } else {
        // document.getElementById('location')!.innerHTML = 'Zoom in to get Cooordinate';
        // document.getElementById('locationName')!.innerHTML = 'Zoom in to get Location';
      }
    });

    this.map.on('style.load', () => {
      // Insert the layer beneath any symbol layer.
      const layers = this.map.getStyle().layers;
      // const labelLayerId = layers.find((layer) => layer.type === 'symbol' && layer.layout['text-field']).id;

      // The 'building' layer in the Mapbox Streets
      // vector tileset contains building height data
      // from OpenStreetMap.
      this.map.addLayer(
        {
          id: 'add-3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          minzoom: 15,
          paint: {
            'fill-extrusion-color': '#aaa',

            // Use an 'interpolate' expression to
            // add a smooth transition effect to
            // the buildings as the user zooms in.
            'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
            'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
            'fill-extrusion-opacity': 0.6,
          },
        },
        // labelLayerId
      );
    });

    this.map.on('load', () => {
      //LAYER BNYK2 RESTRICT
      //LAYER MALAYSIA
    });

    this.map.on('drag', () => {
      //error catch
      // GET HAZARD AND AIRSPCE
    });

    //get zoom
    this.map.on('zoom', () => {
      // Get the current zoom level of the map
      var zoom = this.map.getZoom();
      var center = this.map.getCenter().lat;

      // Convert the zoom level to scale in kilometers
      var scale = (156543.03392 * Math.cos((center * Math.PI) / 180)) / Math.pow(2, zoom);
      var scale_km = scale / 1000;

      // Log the scale in kilometers to the console
      // console.log('Scale in kilometers:', scale_km);
      // if (this.footerLoaded) {
      //   document.getElementById('map-scale')!.innerHTML = scale_km.toPrecision(this.scalePrecision);
      // }
    });

    this.map.on('zoomend', () => {
      // this.getAirspace();
      // this.getgroundHazard();
    });

    ////////////////////////////////////KEYBORD CONTROL FUNCTION//////////////////////////
    // pixels the map pans when using keyboard arrow keys
    const deltaDistance = 100;

    // degrees the map rotates when using keyboard arrow keys
    const deltaDegrees = 25;

    // degrees the map tilts when using keyboard T and G keys
    const deltaPitch = 10;

    const mapdiv = document.getElementById('map');

    function easing(t: number) {
      return t * (2 - t);
    }

    const panMapBy = (deltaX: number, deltaY: number) => {
      this.map.panBy([deltaX, deltaY], {
        easing: easing,
      });
    };

    const rotateMapBy = (degrees: number) => {
      this.map.easeTo({
        bearing: this.map.getBearing() + degrees,
        easing: easing,
      });
    };

    const tiltMapBy = (degrees: number) => {
      this.map.easeTo({
        pitch: this.map.getPitch() + degrees,
        easing: easing,
      });
    };

    const zoomin = () => {
      this.map.zoomIn();
    };

    const zoomout = () => {
      this.map.zoomOut();
    };

    mapdiv!.addEventListener('keydown', function (e) {
      // e.preventDefault();

      switch (e.which) {
        case 87: // w
          panMapBy(0, -deltaDistance);
          break;
        case 83: // s
          panMapBy(0, deltaDistance);
          break;
        case 65: // a
          panMapBy(-deltaDistance, 0);
          break;
        case 68: // d
          panMapBy(deltaDistance, 0);
          break;
        case 37: // left
          rotateMapBy(-deltaDegrees);
          break;
        case 39: // right
          rotateMapBy(deltaDegrees);
          break;
        case 38: // up
          tiltMapBy(deltaPitch);
          break;
        case 40: // down
          tiltMapBy(-deltaPitch);
          break;
        case 69: // e
          zoomin();
          break;
        case 81: // q
          zoomout();
          break;
      }
    });
    ////////////////////////////////////KEYBORD CONTROL FUNCTION END//////////////////////////
    this.completeProfileToken = localStorage.getItem('profileCompleted');
    this.role = state.getUserInfo().member[0].role.name;
  }

  finishLoad(val: string) {
    if (val == 'false') {
      this.isLoad = true;
    } else {
      this.isLoad = false;
    }
  }

  shareCoordinate(coordinate: any) {
    this.coordinateTimeout = setTimeout(() => {
      const customEvent = new CustomEvent('dronos:utm:change-coordinate', { detail: { coordinate } });
      window.dispatchEvent(customEvent);
    }, 500);
  }

  compassOnOff() {
    this.compassOn = !this.compassOn;
  }

  weatherOnOff() {
    this.WeatherOn = !this.WeatherOn;
  }

  getNotamNew() {
    this.mapdataservice.getnotam2().subscribe((e: any) => {
      console.log('======= notam ====', e);
    });
  }

  // getNotamData(): void {
  //   this.mapdataservice.getNotam().subscribe(
  //     (data) => {
  //       // Handle the data received from the API
  //       console.log("notam",data);
  //     },
  //     (error) => {
  //       // Handle the error, if any
  //       console.error(error);
  //     }
  //   );
  // }
  //activates the menu with the coordinates

  // loadGLBFile(url: string, onSuccess: (gltf: THREE.GLTF) => void) {
  //   fetch(url)
  //     .then(response => response.arrayBuffer())
  //     .then(buffer => {
  //       const gltf = this.loader.parse(buffer);
  //       onSuccess(gltf);
  //     });
  // }

  //mark all mission for mission marker//
  markMission(lat: any, lng: any, missionid: string) {
    const dataCenter = {
      lng: lng,
      lat: lat,
    };
    var missionMark = document.createElement('div');
    missionMark.className = 'mission-marker';
    missionMark.style.cursor = 'pointer';
    fetch(assetUrl('globe/dropPin.svg'))
      .then((response) => response.text())
      .then((svgText) => {
        missionMark.innerHTML = svgText;
      });

    const marker = new mapboxgl.Marker({
      element: missionMark,
      anchor: 'bottom',
    })
      .setLngLat([dataCenter.lng, dataCenter.lat])
      .addTo(this.map);
    this.missionMarker.push(marker);

    missionMark.addEventListener('click', () => {
      const area = this.currentTelemetryMissions.filter(
        (item: any) => item.area.geoCoordinate.latitude == dataCenter.lat && item.area.geoCoordinate.longitude == dataCenter.lng,
      );
      const coordinate = new mapboxgl.LngLat(dataCenter.lng, dataCenter.lat);

      this.map.flyTo({
        center: coordinate,
        zoom: 14,
      });
      if (this.map.getSource('mission')) {
        this.removeAreaBuffer();
      }
      this.drawAreaBuffer(area[0].area.coordinate);

      const customEvent = new CustomEvent('dronos:utm:mission-click-map', { detail: { missionId: missionid } });
      window.dispatchEvent(customEvent);
    });
    // this.selectedMissionId = this.selectedMissionId == mission?._id ? null : mission._id;
  }

  //remove all marker for mission
  removeMissionMarkers() {
    this.missionMarker.forEach((marker) => marker.remove());
    this.missionMarker = [];
  }

  //draw area for mission
  drawAreaBuffer(area: any) {
    const elevation = 120;
    const bufferDistance1 = 0.1;
    const bufferDistance2 = 0.2;
    const bufferDistance3 = 0.3;
    const convertedCoordinates = area.map((coord: { longitude: string; latitude: string }) => [parseFloat(coord.longitude), parseFloat(coord.latitude)]);

    const polygonFeature: any = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [convertedCoordinates],
      },
    };

    const bufferedPolygon1 = turf.buffer(polygonFeature, bufferDistance1, { units: 'kilometers' });
    const bufferedPolygon2 = turf.buffer(polygonFeature, bufferDistance2, { units: 'kilometers' });
    const bufferedPolygon3 = turf.buffer(polygonFeature, bufferDistance3, { units: 'kilometers' });

    const combinedPolygons = turf.union(bufferedPolygon1, bufferedPolygon2, bufferedPolygon3);

    const bbox = turf.bbox(combinedPolygons);

    this.map.addSource(`mission-buffer1`, {
      type: 'geojson',
      data: bufferedPolygon1,
    });

    this.map.addLayer({
      id: `mission-buffer1`,
      type: 'line',
      source: `mission-buffer1`,
      paint: {
        'line-color': '#FFD54F',
        'line-width': 1,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    this.map.addSource(`mission-buffer2`, {
      type: 'geojson',
      data: bufferedPolygon2,
    });

    this.map.addLayer({
      id: `mission-buffer2`,
      type: 'line',
      source: `mission-buffer2`,
      paint: {
        'line-color': '#FF0000',
        'line-width': 1,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    this.map.addSource(`mission-buffer3`, {
      type: 'geojson',
      data: bufferedPolygon3,
    });

    this.map.addLayer({
      id: `mission-buffer3`,
      type: 'line',
      source: `mission-buffer3`,
      paint: {
        'line-color': '#2196F3',
        'line-width': 1,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    this.map.addSource('mission', {
      type: 'geojson',
      data: polygonFeature,
    });

    this.map.addLayer({
      id: 'mission',
      type: 'fill-extrusion',
      source: 'mission',
      paint: {
        'fill-extrusion-color': '#009688',
        'fill-extrusion-opacity': 0.5,
        'fill-extrusion-height': elevation,
      },
    });

    this.map.fitBounds(
      [
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],
      ],
      {
        pitch: 70,
        padding: 20,
      },
    );
  }

  //remove mission area
  removeAreaBuffer() {
    this.map.removeLayer('mission');
    this.map.removeSource('mission');
    this.map.removeLayer('mission-buffer3');
    this.map.removeSource('mission-buffer3');
    this.map.removeLayer('mission-buffer2');
    this.map.removeSource('mission-buffer2');
    this.map.removeLayer('mission-buffer1');
    this.map.removeSource('mission-buffer1');
  }

  // Function to create a tooltip element
  createTooltip(coordinates: any) {
    const tooltip = document.createElement('div');
    tooltip.id = 'confirm-draw';
    tooltip.style.position = 'absolute';
    tooltip.style.zIndex = '5';
    // tooltip.style.left = coordinates[0] + 'px';
    // tooltip.style.top = coordinates[1] + 'px';
    tooltip.style.backgroundColor = 'red';
    // tooltip.textContent = 'test';

    document.body.appendChild(tooltip);
    this.updateTooltip(coordinates);
  }

  updateTooltip(e: any) {
    let tooltip = document.getElementById('confirm-draw');
    if (!tooltip) return;

    const content = `Double click to confirm area`;

    tooltip.innerHTML = content;
    tooltip.style.left = e.point.x + 15 + 'px';
    tooltip.style.top = e.point.y - 20 + 'px';
    tooltip.style.borderRadius = '1.432px';
    tooltip.style.background = 'var(--transparent-50-black, rgba(0, 0, 0, 0.50))';
    tooltip.style.backdropFilter = 'blur(2.5px)';
    tooltip.style.padding = '4px 6px';
    tooltip.style.gap = '8px';
    tooltip.style.color = 'var(--shades-white, #FFF)';
    tooltip.style.fontFamily = 'Barlow';
    tooltip.style.fontSize = '12px';
    tooltip.style.fontWeight = '500';
    tooltip.style.fontStyle = 'normal';
  }

  // Function to remove the tooltip element
  removeTooltip() {
    let tooltip = document.getElementById('confirm-draw');
    if (tooltip && tooltip.parentNode) {
      tooltip.parentNode.removeChild(tooltip);
    }
  }

  //show mission objective
  showObjective() {
    // if (this.map.getLayer('area') ) {
    if ($('#objectiveBar').css('display') === 'none') {
      $('#objectiveBar').css('display', 'block');
    } else {
      $('#objectiveBar').css('display', 'none');
    }
    // }
  }

  removeObjective() {
    $('#objectiveBar').css('display', 'none');
  }
  //************************** Drop pin function **********************************/
  getPin() {
    this.isDrawLine = false;
    this.clearDraw();
    this.clearLayer();
    if (this.marker) {
      this.marker.remove();
      this.clearLayer();
    }

    var dataCenter = this.map.getCenter();
    var PinCenter = {
      lng: dataCenter.lng,
      lat: dataCenter.lat,
    };

    var centroid = document.createElement('div');
    centroid.className = 'custom-marker';

    // Fetch the SVG file from the asset folder
    fetch(assetUrl('globe/dropPin.svg'))
      .then((response) => response.text())
      .then((svgText) => {
        centroid.innerHTML = svgText;

        const marker = new mapboxgl.Marker({
          element: centroid,
          draggable: true,
          anchor: 'bottom', // Set the anchor point to the bottom center
        })
          .setLngLat(dataCenter)
          .addTo(this.map);

        const onDragEnd = () => {
          const lngLat = marker.getLngLat();
          const PinCenter = {
            lng: lngLat.lng,
            lat: lngLat.lat,
          };
          this.removeCircle();
          this.setCircle(PinCenter);
        };

        marker.on('dragend', onDragEnd);

        this.setCircle(PinCenter);
        // Store the new marker
        this.marker = marker;
      });
  }

  setCircle(center: { lng: any; lat: any }) {
    const radius = this.radius; // Set the radius of the circle in meters
    const steps = 64; // Set the number of steps in the circle (higher = smoother circle)
    var elevation = this.altitude; // Set the elevation in meters

    // Create a circular polygon using turf.circle()
    const circle = turf.circle([center.lng, center.lat], radius, {
      steps,
      units: 'meters',
    });

    const buffer1 = turf.circle([center.lng, center.lat], radius + 100, {
      steps,
      units: 'meters',
    });

    const buffer2 = turf.circle([center.lng, center.lat], radius + 200, {
      steps,
      units: 'meters',
    });

    const buffer3 = turf.circle([center.lng, center.lat], radius + 300, {
      steps,
      units: 'meters',
    });

    const combinedPolygons = turf.union(buffer1, buffer2, buffer3);
    const bbox = turf.bbox(combinedPolygons);

    this.map.fitBounds(
      [
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],
      ],
      {
        padding: 20,
        pitch: 70,
      },
    );

    // Extrude the circle to create a 3D polygon with elevation
    const coordinates3D = circle.geometry.coordinates.map((ring: any) => ring.map((coord: any) => [coord[0], coord[1], elevation]));

    this.transPoly.push(coordinates3D[0]);
    this.missionAreaArea = (Math.PI * Math.pow(radius, 2)).toFixed(2);
    this.missionAreaVolume = (Math.PI * Math.pow(radius, 2) * this.altitude).toFixed(2);

    // Create a 3D polygon GeoJSON object
    const polygon3D: GeoJSON.Feature<GeoJSON.Polygon, any> = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: coordinates3D,
      },
      properties: {},
    };

    // Create a circle layer using the 3D polygon GeoJSON object
    this.map.addLayer({
      id: 'area',
      type: 'fill-extrusion',
      source: {
        type: 'geojson',
        data: polygon3D,
      },
      paint: {
        'fill-extrusion-color': '#009688',
        'fill-extrusion-opacity': 0.5,
        'fill-extrusion-height': elevation,
      },
    });

    this.map.addLayer({
      id: 'buffer1',
      type: 'line',
      source: {
        type: 'geojson',
        data: buffer1,
      },
      paint: {
        'line-color': '#FFD54F',
        'line-width': 1,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    this.map.addLayer({
      id: 'buffer2',
      type: 'line',
      source: {
        type: 'geojson',
        data: buffer2,
      },
      paint: {
        'line-color': '#FF0000',
        'line-width': 1,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    this.map.addLayer({
      id: 'buffer3',
      type: 'line',
      source: {
        type: 'geojson',
        data: buffer3,
      },
      paint: {
        'line-color': '#2196F3',
        'line-width': 1,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    // Store the layer ID so we can remove it later
    this.circleLayerId = 'area';
    this.centroid = turf.centroid(circle).geometry.coordinates;
  }

  //remove circle layer
  removeCircle() {
    if (this.map.getLayer('area')) {
      this.map.removeLayer('area');
      this.map.removeSource('area');
      this.map.removeLayer('buffer1');
      this.map.removeSource('buffer1');
      this.map.removeLayer('buffer2');
      this.map.removeSource('buffer2');
      this.map.removeLayer('buffer3');
      this.map.removeSource('buffer3');
    }
  }

  //************************** Drop pin function end **********************************/
  //************************** Update map layer **********************************/
  refreshMapLayers() {
    this.map.setPaintProperty('area', 'fill-extrusion-height', this.altitude);
    if (this.missionMethod == 'pin') {
      const lngLat = this.marker.getLngLat();
      const PinCenter = {
        lng: lngLat.lng,
        lat: lngLat.lat,
      };
      this.removeCircle();
      this.setCircle(PinCenter);
    } else {
      this.setAreaVolume();
    }
  }
  setAreaVolume() {
    const features = this.map.queryRenderedFeatures({ layers: ['area'] } as any);
    if (features.length > 0) {
      const turfFeatures = turf.featureCollection(features);
      this.missionAreaArea = turf.area(turfFeatures).toFixed(2);
      this.missionAreaVolume = (this.missionAreaArea * this.altitude).toFixed(2);
    } else {
      // console.log('No features found in the specified layer.');
    }
  }
  //************************** Update map layer **********************************/
  //************************** Draw polygon function **********************************/
  drawPolygon() {
    this.isDrawLine = false;
    this.clearDraw();
    this.clearLayer();
    this.removeAllPopups();

    if (this.marker) {
      this.marker.remove();
      this.clearLayer();
    }
    this.clearDraw();
    this.clearMapListeners();

    // Add the draw control to the map
    this.map.addControl(this.draw);
    // Create a new polygon feature programmatically
    var polygonFeature = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [[]], // Add coordinates here if needed
      },
    };
    this.map.on('click', this.mapClickListener);

    // Trigger the draw.create event with the new polygon feature
    this.map.fire('draw.create', { features: [polygonFeature] });

    this.map.on('draw.create', (e) => {
      this.clearLayer();
      if (this.marker) {
        this.marker.remove();
        this.clearLayer();
      }

      var elevation = this.altitude;
      var feature = e.features[0];

      this.transPoly.push(feature.geometry.coordinates[0]);
      this.clearLayer();

      this.map.addLayer({
        id: 'area',
        type: 'fill-extrusion',
        source: {
          type: 'geojson',
          data: feature,
        },
        paint: {
          'fill-extrusion-color': '#009688',
          'fill-extrusion-opacity': 0.5,
          'fill-extrusion-height': elevation,
        },
      });

      const coordinates = feature.geometry.coordinates[0];
      const isMultipleCoordinates = coordinates.length > 1;

      this.missionAreaArea = turf.area(feature).toFixed(2);
      this.missionAreaVolume = (this.missionAreaArea * this.altitude).toFixed(2);

      if (isMultipleCoordinates) {
        this.missionAreaDistance = turf.length(turf.lineString(coordinates), { units: 'meters' }).toFixed(2);
        this.calculateMidpoint(coordinates.slice(-2));
        this.clearMapListeners();
      } else {
        this.missionAreaDistance = '0.00';
      }

      // Calculate buffer polygons
      if (feature.geometry.coordinates[0].length !== 0) {
        var polygonBuffer1 = turf.buffer(feature, 100, { units: 'meters' });
        var polygonBuffer2 = turf.buffer(feature, 200, { units: 'meters' });
        var polygonBuffer3 = turf.buffer(feature, 300, { units: 'meters' });

        // Convert buffer polygons to line strings
        var lineBuffer1 = turf.polygonToLineString(polygonBuffer1);
        var lineBuffer2 = turf.polygonToLineString(polygonBuffer2);
        var lineBuffer3 = turf.polygonToLineString(polygonBuffer3);

        // Add buffer layers
        this.map.addLayer({
          id: 'buffer1',
          type: 'line',
          source: {
            type: 'geojson',
            data: lineBuffer1,
          },
          paint: {
            'line-color': '#FFD54F',
            'line-width': 1,
            'line-dasharray': [3, 2, 2, 3],
          },
        });

        this.map.addLayer({
          id: 'buffer2',
          type: 'line',
          source: {
            type: 'geojson',
            data: lineBuffer2,
          },
          paint: {
            'line-color': '#FF0000',
            'line-width': 1,
            'line-dasharray': [3, 2, 2, 3],
          },
        });

        this.map.addLayer({
          id: 'buffer3',
          type: 'line',
          source: {
            type: 'geojson',
            data: lineBuffer3,
          },
          paint: {
            'line-color': '#2196F3',
            'line-width': 1,
            'line-dasharray': [3, 2, 2, 3],
          },
        });

        // Calculate the centroid of the polygon
        var centroid = turf.centroid(feature);

        const combinedPolygons = turf.union(polygonBuffer1, polygonBuffer2, polygonBuffer3);

        const bbox = turf.bbox(combinedPolygons);

        this.map.addSource(`centroid`, {
          type: 'geojson',
          data: centroid,
        });

        this.centroid = centroid.geometry.coordinates;

        fetch(assetUrl('globe/dropPin.svg'))
          .then((response) => response.blob())
          .then((iconBlob) => {
            const iconUrl = URL.createObjectURL(iconBlob);

            const image = new Image();
            image.onload = () => {
              if (!this.map.hasImage('custom-icon')) {
                this.map.addImage('custom-icon', image);
              }
              if (!this.map.getLayer('centroid')) {
                this.map.addLayer({
                  id: `centroid`,
                  type: 'symbol',
                  source: `centroid`,
                  layout: {
                    'icon-image': 'custom-icon',
                    'icon-size': 1,
                    'icon-anchor': 'bottom',
                  },
                });
              }
            };

            image.src = iconUrl;
          });
        this.map.fitBounds(
          [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[3]],
          ],
          {
            pitch: 70,
            padding: 20,
          },
        );
      }
      this.missionMethod = 'draw';
      this.createMission = true;
    });
  }

  clearMapListeners() {
    this.map.off('click', this.mapClickListener);
    this.map.off('mousemove', this.mapMoveListener);
    this.coordinates = [];
    if (this.distanceMarker) {
      this.distanceMarker.remove();
      this.distanceMarker = null;
    }
  }

  mapMoveListener = (event: any) => {
    const currentCoordinate = [event.lngLat.lng, event.lngLat.lat];
    const line = turf.lineString([...this.coordinates, currentCoordinate]);
    const length = turf.length(line, { units: 'meters' });

    if (!this.distanceMarker) {
      const labelDiv = this.createLabelDiv();
      this.distanceMarker = new mapboxgl.Marker(labelDiv).setLngLat([event.lngLat.lng, event.lngLat.lat]).addTo(this.map);
    }
    this.distanceMarker.getElement().innerHTML = `<span>${length.toFixed(2)} m</span>`;
    const markerOffset = [0, -30];
    this.distanceMarker.setLngLat([event.lngLat.lng, event.lngLat.lat]).setOffset(markerOffset);
  };

  mapClickListener = (event: any) => {
    this.map.on('mousemove', this.mapMoveListener);
    this.coordinates.push([event.lngLat.lng, event.lngLat.lat]);
    this.calculateMidpoint();
  };

  calculateMidpoint(lastTwoCoordinates: any[] = []) {
    if (lastTwoCoordinates.length < 2 && this.coordinates.length < 2) {
      return;
    }
    const [coordinate1, coordinate2] = lastTwoCoordinates.length > 1 ? lastTwoCoordinates : this.coordinates.slice(0, 2);
    const { midpoint, length } = this.calculateMidpointValues(coordinate1, coordinate2);
    const labelMarker = this.addMarker(midpoint, length);
    this.markers.push(labelMarker);
    this.coordinates.shift();
  }

  addMarker(midpoint: turf.helpers.Feature<turf.helpers.Point, turf.helpers.Properties>, length: number) {
    const labelDiv = this.createLabelDiv();
    labelDiv.innerHTML = `<span>${length.toFixed(2)}&nbsp;&nbsp;m</span>`;
    const [longitude, latitude] = midpoint.geometry.coordinates;
    return new mapboxgl.Marker(labelDiv).setLngLat([longitude, latitude]).addTo(this.map);
  }

  calculateMidpointValues(coord1: turf.Position, coord2: turf.Position) {
    const midpoint = turf.midpoint(coord1, coord2);
    const line = turf.lineString([coord1, coord2]);
    const length = turf.length(line, { units: 'meters' });

    return { midpoint, length };
  }

  createLabelDiv(): HTMLDivElement {
    const labelDiv = document.createElement('div');
    labelDiv.style.maxHeight = '50px';
    labelDiv.style.color = '#fff';
    labelDiv.style.background = 'rgba(0, 0, 0, 0.6)';
    labelDiv.style.borderRadius = '3px';
    labelDiv.style.padding = '3px 7px';
    return labelDiv;
  }

  removeAllPopups() {
    for (const marker of this.markers) {
      marker.remove();
    }
    this.markers = [];
    this.clearMapListeners();
  }

  //************************** Draw polygon function end **********************************/
  //************************** Draw line function **********************************/
  // drawLine() {
  //   this.isDrawLine = true;
  //   this.clearDraw();
  //   this.clearLayer();

  //   if (this.marker) {
  //     this.marker.remove();
  //     this.clearLayer();
  //   }

  //   this.map.addControl(this.draw);

  //   this.map.getCanvas().style.cursor = 'crosshair';
  //   this.map.on('draw.create', (event) => {
  //     if (this.isDrawLine) {
  //       this.clearLayer()
  //       console.log('siniiii 123')
  //       console.log('Line coordinates:', event.features);
  //       this.lineData = event.features;

  //       var feature = event.features[0];

  //       this.map.addLayer({
  //         id: 'line-layer',
  //         type: 'line',
  //         source: {
  //           type: 'geojson',
  //           data: feature,
  //         },
  //         paint: {
  //           'line-color': '#009688',
  //           'line-opacity': 0.5,
  //           'line-width': 2, // Adjust line width as needed
  //         },
  //       });

  //       this.missionMethod = 'line';
  //       this.removeObjective();

  //       // const points:any = []
  //       this.map.on('click', (e) => {
  //         if (this.isDrawLine) {
  //           // console.log('tengok click', e.lngLat, event.features[0].geometry.coordinates)
  //           const lineCoordinates = event.features[0].geometry.coordinates;
  //           // console.log('pastu', lineCoordinates)
  //           const isSimilar = lineCoordinates.some((coords: any) => this.areCoordinatesSimilar(coords, e.lngLat));
  //           // console.log('ssssss', isSimilar)
  //           if (isSimilar) {
  //             this.createMission = true;
  //             // setTimeout(() => {
  //             //   this.updateElevationProfile();
  //             // }, 3000);
  //             this.showObjective()
  //           } else {
  //             this.createMission = false;
  //             this.removeObjective();
  //           }
  //         }
  //       })
  //     }

  //   });
  // }

  // updateElevationProfile() {

  //   const chartEl: any = document.getElementById('chart-canvas')

  //   this.myLineChart = new Chart(chartEl, {
  //     type: 'line',
  //     data: {
  //       labels: [], // Provide your labels here
  //       datasets: []
  //     },
  //     options: {
  //       plugins: {
  //         legend: {
  //           display: false
  //         },
  //         title: {
  //           display: true,
  //           align: 'start',
  //           text: 'Elevation (m)'
  //         }
  //       },
  //       maintainAspectRatio: false,
  //       responsive: true,
  //       scales: {
  //         x: {
  //           min: 0,
  //           grid: {
  //             display: false
  //           }
  //         },
  //         y: {
  //           min: 0,
  //           grid: {
  //             display: false
  //           }
  //         }
  //       },
  //       elements: {
  //         point: {
  //           radius: 0
  //         }
  //       },
  //       layout: {
  //         padding: {
  //           top: 6,
  //           right: 20,
  //           bottom: -10,
  //           left: 20
  //         }
  //       }
  //     }
  //   });

  //   const featureProper: any = {
  //     type: "FeatureCollection",
  //     features: this.lineData

  //   }
  //   // split the line into 1m segments
  //   const chunks = turf.lineChunk(featureProper, 1, { units: 'meters' }).features;

  //   // get the elevation for the leading coordinate of each segment
  //   const elevations = [
  //     ...chunks.map((feature) => {
  //       return this.map.queryTerrainElevation(
  //         feature.geometry.coordinates[0] as [number, number]
  //       );
  //     }),
  //     // do not forget the last coordinate
  //     this.map.queryTerrainElevation(
  //       chunks[chunks.length - 1].geometry.coordinates[1] as [number, number]
  //     )
  //   ];
  //   console.log('sini elevations', this.myLineChart)
  //   // add dummy labels
  //   this.myLineChart.data.labels = elevations.map(() => '');
  //   this.myLineChart.data.datasets[0] = {
  //     data: elevations,
  //     fill: false,
  //     tension: 0.4
  //   };

  //   // console.log('chart exist tak, ', document.getElementById('chart-canvas'))
  //   // console.log('tengok line chart', this.myLineChart.data)
  //   this.myLineChart.update();
  // }

  areCoordinatesSimilar(coords1: any, coords2: any) {
    // Round coordinates to two decimal points for comparison
    const roundedCoords1 = coords1.map((coord: any) => coord.toFixed(2));
    const roundedCoords2 = [coords2.lng.toFixed(2), coords2.lat.toFixed(2)];

    // Check if the rounded coordinates match
    return JSON.stringify(roundedCoords1) === JSON.stringify(roundedCoords2);
  }
  //************************** Draw line function end **********************************/

  //************************** upload kml function **********************************/

  //check existing layer
  fileUploaded(file: any) {
    this.isDrawLine = false;
    if (this.map.getLayer('upload')) {
      this.map.removeLayer('upload');
    }
    if (this.map.getSource('upload')) {
      this.map.removeSource('upload');
    }
    this.clearDraw();
    this.clearLayer();
    if (this.marker) {
      this.marker.remove();
      this.clearLayer();
    }
    // Load the new file
    this.loadFile(file);
  }

  //load uploaded file and parse
  loadFile(file: any) {
    const reader = new FileReader();
    reader.onload = () => {
      const fileContents = reader.result as string;
      let jsonData;

      // Parse file based on file type
      if (file.type === 'application/json' || file.name.endsWith('.json')) {
        jsonData = JSON.parse(fileContents);
        this.loadOnMap(jsonData);
      } else if (
        file.type === 'application/vnd.google-earth.kml+xml' ||
        file.type === 'application/vnd.google-earth.kmz' ||
        file.name.endsWith('.kml') ||
        file.name.endsWith('.kmz')
      ) {
        if (file.name.endsWith('.kmz')) {
          // Extract KML file from KMZ archive
          const zip = new JSZip();
          zip.loadAsync(fileContents).then((zip) => {
            const kmlFile = Object.values(zip.files).find((f) => f.name.toLowerCase().endsWith('.kml'));
            if (!kmlFile) {
              // console.error('KMZ file does not contain a KML file');
              return;
            }
            kmlFile.async('string').then((kmlContents) => {
              const kmlDom = new DOMParser().parseFromString(kmlContents, 'application/xml');
              jsonData = kml(kmlDom);
              this.loadOnMap(jsonData);
            });
          });
        } else {
          // Parse KML file directly
          const kmlDom = new DOMParser().parseFromString(fileContents, 'application/xml');
          jsonData = kml(kmlDom);
          this.loadOnMap(jsonData);
        }
      } else {
        // console.error('Unsupported file type');
        return;
      }
    };
    reader.readAsBinaryString(file);
  }

  //load on map
  //load on map
  loadOnMap(e: any) {
    this.clearDraw();
    this.clearLayer();
    if (this.marker) {
      this.marker.remove();
      this.clearLayer();
    }

    var elevation = this.altitude;

    // Convert LineString to Polygon if any
    const convertedFeatures = e.features.map((feature: { geometry: { type: string; coordinates: any } }) => {
      if (feature.geometry.type === 'LineString') {
        let coordinates = feature.geometry.coordinates;
        // Check if the first and last positions are equivalent
        if (coordinates.length >= 2 && !arePositionsEqual(coordinates[0], coordinates[coordinates.length - 1])) {
          // Add the first position as the last position to close the ring
          coordinates.push(coordinates[0]);
        }
        const polygon = turf.polygon([coordinates]);
        return {
          ...feature,
          geometry: polygon.geometry,
        };
      }
      return feature;
    });

    let smallestPolygon: any = null;
    let smallestArea = Infinity;

    convertedFeatures.forEach((feature: { geometry: { type: string; coordinates: any[] } }) => {
      if (feature.geometry.type === 'Polygon') {
        const area = calculatePolygonArea(feature.geometry.coordinates);
        if (area < smallestArea) {
          smallestArea = area;
          smallestPolygon = feature;
        }
      } else if (feature.geometry.type === 'MultiPolygon') {
        feature.geometry.coordinates.forEach((polygonCoordinates: any) => {
          const area = calculatePolygonArea(polygonCoordinates);
          if (area < smallestArea) {
            smallestArea = area;
            smallestPolygon = feature;
          }
        });
      }
    });

    function calculatePolygonArea(coordinates: turf.helpers.Position[][]) {
      // Assuming coordinates are in [longitude, latitude] format (for example, GeoJSON)
      const polygon = turf.polygon(coordinates);
      return turf.area(polygon);
    }

    function arePositionsEqual(position1: any[], position2: any[]) {
      return position1[0] === position2[0] && position1[1] === position2[1];
    }

    this.map.addControl(this.draw);

    this.draw.add(smallestPolygon);
    this.draw.changeMode('simple_select');
    this.transPoly.push(smallestPolygon.geometry.coordinates[0]);

    this.map.addLayer({
      id: 'area',
      type: 'fill-extrusion',
      source: {
        type: 'geojson',
        data: smallestPolygon,
      },
      paint: {
        'fill-extrusion-color': '#009688',
        'fill-extrusion-opacity': 0.5,
        'fill-extrusion-height': elevation,
      },
    });

    this.missionAreaArea = turf.area(smallestPolygon).toFixed(2);
    this.missionAreaVolume = (this.missionAreaArea * this.altitude).toFixed(2);

    // Calculate buffer polygons
    var polygonBuffer1 = turf.buffer(smallestPolygon, 100, { units: 'meters' });
    var polygonBuffer2 = turf.buffer(smallestPolygon, 200, { units: 'meters' });
    var polygonBuffer3 = turf.buffer(smallestPolygon, 300, { units: 'meters' });

    // Convert buffer polygons to line strings
    var lineBuffer1 = turf.polygonToLineString(polygonBuffer1);
    var lineBuffer2 = turf.polygonToLineString(polygonBuffer2);
    var lineBuffer3 = turf.polygonToLineString(polygonBuffer3);

    // this.map.setLayoutProperty('buffer1', 'visibility', 'none');
    // this.map.setLayoutProperty('buffer2', 'visibility', 'none');
    // this.map.setLayoutProperty('buffer3', 'visibility', 'none');

    // Add buffer layers
    this.map.addLayer({
      id: 'buffer1',
      type: 'line',
      source: {
        type: 'geojson',
        data: lineBuffer1,
      },
      paint: {
        'line-color': '#FFD54F',
        'line-width': 1,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    this.map.addLayer({
      id: 'buffer2',
      type: 'line',
      source: {
        type: 'geojson',
        data: lineBuffer2,
      },
      paint: {
        'line-color': '#FF0000',
        'line-width': 1,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    this.map.addLayer({
      id: 'buffer3',
      type: 'line',
      source: {
        type: 'geojson',
        data: lineBuffer3,
      },
      paint: {
        'line-color': '#2196F3',
        'line-width': 1,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    // Calculate the centroid of the polygon
    var centroid = turf.centroid(smallestPolygon);

    const combinedPolygons = turf.union(polygonBuffer1, polygonBuffer2, polygonBuffer3);

    const bbox = turf.bbox(combinedPolygons);

    this.centroid = centroid.geometry.coordinates;

    this.map.addSource(`centroid`, {
      type: 'geojson',
      data: centroid,
    });

    fetch(assetUrl('globe/dropPin.svg'))
      .then((response) => response.blob())
      .then((iconBlob) => {
        const iconUrl = URL.createObjectURL(iconBlob);

        const image = new Image();
        image.onload = () => {
          if (!this.map.hasImage('custom-icon')) {
            this.map.addImage('custom-icon', image);
          }
          this.map.addLayer({
            id: `centroid`,
            type: 'symbol',
            source: `centroid`,
            layout: {
              'icon-image': 'custom-icon',
              'icon-size': 1,
              'icon-anchor': 'bottom',
            },
          });
        };

        image.src = iconUrl;
      });

    this.map.fitBounds(
      [
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],
      ],
      {
        pitch: 70,
        padding: 20,
      },
    );
  }

  //************************** Upload kml function end**********************************/

  //************************** Clear all editable layer function*********************************/
  clearLayer() {
    if (this.map.getLayer('area')) {
      this.map.removeLayer('area');
    }
    if (this.map.getSource('area')) {
      this.map.removeSource('area');
    }
    if (this.map.getLayer('buffer1')) {
      this.map.removeLayer('buffer1');
    }
    if (this.map.getSource('buffer1')) {
      this.map.removeSource('buffer1');
    }
    if (this.map.getLayer('buffer2')) {
      this.map.removeLayer('buffer2');
    }
    if (this.map.getSource('buffer2')) {
      this.map.removeSource('buffer2');
    }
    if (this.map.getLayer('buffer3')) {
      this.map.removeLayer('buffer3');
    }
    if (this.map.getSource('buffer3')) {
      this.map.removeSource('buffer3');
    }
    if (this.map.getLayer('centroid')) {
      this.map.removeLayer('centroid');
    }
    if (this.map.getSource('centroid')) {
      this.map.removeSource('centroid');
    }
    if (this.map.getLayer('centroid')) {
      this.map.removeLayer('centroid');
    }
    if (this.map.getSource('centroid')) {
      this.map.removeSource('centroid');
    }
    if (this.marker) {
      this.marker.remove();
    }
  }
  clearDraw() {
    const hasDrawControl = this.map.hasControl(this.draw);
    if (hasDrawControl) {
      if (this.map.getSource('area')) {
        this.clearLayer();
      }
      this.map.removeControl(this.draw);
    }
  }
  //************************** Clear all editable layer function end**********************************/

  decimalToDMS(decimal: number): string {
    const degrees = Math.floor(decimal);
    const minutes = Math.floor((decimal - degrees) * 60);
    const seconds = ((decimal - degrees - minutes / 60) * 3600).toFixed(2);
    return `${degrees}° ${Math.abs(minutes)}' ${Number(seconds)}" ${decimal < 0 ? 'S' : 'N'}`;
  }

  addOrtho() {
    // Ensure map is initialized
    if (!this.map) {
      console.error('Map is not initialized.');
      return;
    }

    // Check if the ortho layer already exists
    if (this.map.getLayer('orthographic-layer')) {
      console.warn('Orthographic layer already added.');
      return;
    }

    // Define the URL of the ortho layer
    const urlWithoutExtension =
      'https://aerodyne-halle.s3.eu-central-1.amazonaws.com/HALLE/04_DELIVERY/04_HOSTING/01_MAPTILER/01_ORTHO_MAPTILER/Halle/openlayers.html';
    const tilesURL = urlWithoutExtension.replace(/(?=\w+\.\w{3,4}$).+/, '{z}/{x}/{y}.png');
    const halleBounds = [11.948129486313775, 51.457127989859885, 11.989467645980852, 51.46577283000741];

    // Add the ortho layer to the map
    this.map.on('load', () => {
      this.map.addLayer({
        id: 'orthographic-layer',
        type: 'raster',
        source: {
          type: 'raster',
          tiles: [tilesURL],
          tileSize: 256,
          minzoom: this.orthoMinzoom,
          bounds: halleBounds,
        },
      });
    });
  }

  //add layer
  addLayer() {
    this.map.once('style.load', () => {
      if (!this.map.getSource('mapbox-dem')) {
        this.map.addSource('mapbox-dem', {
          type: 'raster-dem',
          url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
          tileSize: 512,
          maxzoom: 14,
        });
      }
      if (!this.map.getLayer('terrain-data')) {
        this.map.addLayer({
          id: 'terrain-data',
          type: 'hillshade',
          source: 'mapbox-dem',
          paint: {
            'hillshade-exaggeration': 1,
          },
        });
      }
      // add the DEM source as a terrain layer with exaggerated height
      this.map.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
      this.map.setLayoutProperty('terrain-data', 'visibility', 'none');
    });

    // this.getAirspace();
  }

  //set style
  setStyle(name: string) {
    this.map.setStyle(name);
    for (const item of this.layerStyle) {
      this.loadDataKmz(item);
    }
  }

  getLayerInfo(e: any) {
    if (e.length != 0) {
      const layerInfo = e;

      if (!layerInfo.length) {
        return;
      }
      this.featureProperties = layerInfo;
      var feature = layerInfo;
      // Access the properties of the clicked feature
      var featureProperties = feature.properties;

      feature.forEach((singleFeature: { properties: { name: any } }) => {
        // Access the name property of the current feature
        const name = singleFeature.properties.name;
        // this.featureProperties = name;
        // this.airspaceInformatics = true;

        // Emit the featureProperties to the child component
        this.airSpaceInformatics(feature);

        // Display the name
        //send name to airspaceInformatics components
      });

      // this.featureProperties = feature.properties;
      this.airspaceInformatics = true;
    } else {
      this.airspaceInformatics = false;
    }
  }

  getgroundHazard() {
    var boundHazard = this.map.getBounds();

    this.mapdataservice.getGroundData(boundHazard).subscribe((data3: any) => {
      // console.log('DATA3', data3);
    });
  }

  //wafi undan
  async arrangeUndan() {
    const url = 'https://dronos-utm.s3.ap-southeast-1.amazonaws.com/3D_waypoint.txt';

    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.text();
      const geoJSONData = this.convertToGeoJSON(data);

      if (geoJSONData) {
        // Add GeoJSON source
        this.map.addSource('waypoint', {
          type: 'geojson',
          data: geoJSONData,
        });

        // Add Line Layer
        this.map.addLayer({
          id: 'waypoint-line',
          type: 'line',
          source: 'waypoint',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#FF0000', // Replace with your desired line color
            'line-width': 2, // Replace with your desired line width
          },
        });

        // Add Marker Layer
        this.map.addLayer({
          id: 'waypoint-markers',
          type: 'circle',
          source: 'waypoint',
          paint: {
            'circle-color': '#00FF00', // Replace with your desired marker color
            'circle-radius': 6, // Replace with your desired marker radius
          },
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  convertToGeoJSON(data: string) {
    const rows = data.split('\n');
    const header = rows[0].split(',');
    const totalpoint = header.length / 2;
    const coordinate = rows[1].split(',');
    const lat = coordinate.slice(0, totalpoint);
    const lng = coordinate.slice(totalpoint);
    const mergecoor = [];

    // Add coordinates to the LineString
    for (let i = 0; i < totalpoint; i++) {
      mergecoor.push([Number(lng[i]), Number(lat[i])]);
    }

    const geojson: mapboxgl.GeoJSONSourceOptions['data'] = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: mergecoor,
      },
      properties: {},
    };

    this.convertObjToString(mergecoor);

    return geojson;
  }

  async convertObjToString(geojson: any) {
    const inputCoordinates = geojson;

    const outputFormat = inputCoordinates.map(([long, lat]: [number, number]) => ({
      lat,
      long,
    }));

    const newObject = JSON.stringify(outputFormat, null, 2);

    const params = {
      Bucket: 'dronos-utm',
      Key: 'NewUkm.json',
      Body: newObject,
      ContentType: 'application/json',
    };

    const s3Client = new S3Client({
      region: environment.region,
      credentials: {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
      },
    });

    const s3Command = new PutObjectCommand(params);

    try {
      const result = await s3Client.send(s3Command);
      console.log('Upload successful', result);
    } catch (error) {
      console.error('Upload error', error);
    }
  }

  //function for getting mission area data //
  getMissionArea() {
    var area = this.map.querySourceFeatures('area'); // tiles issue maxzoom
    // console.log('issue 2', area);
    // condition ?? draw.selectionchange == true
    let newData = this.transPoly;
    let nedPoly = newData[newData.length - 1];
    // console.log('Correct ??', nedPoly);
    // if(newData.length >= 1){
    //   console.log(newData.length - 1);

    // }
    var passParam = {
      coordinates: [nedPoly],
      type: 'polygon',
    };

    // console.log(passParam);

    var coordinate = passParam;
    // console.log('1st area', coordinate);
    return coordinate;
  }

  getLinePath() {
    var line = this.map.getSource('line-layer') as any;
    if (line) {
      // console.log('NADDD', line._data);

      var passParam = {
        coordinates: [line._data.geometry.coordinates],
        type: 'line',
      };

      return passParam;
    } else {
      return null;
    }
  }

  getMissionCentroid() {
    return this.centroid;
  }
  //function for getting mission area data end //
  // async getAirspace() {
  //   // this.map.on('zoomend', async () => {
  //   let airspace: any[] = [];
  //   const boundMap = this.map.getBounds();
  //   console.log(
  //     boundMap.getEast(),
  //     boundMap.getNorth(),
  //     boundMap.getSouth(),
  //     boundMap.getWest()
  //   );

  //   try {
  //     const data = await firstValueFrom(
  //       this.mapdataservice.getMapData(boundMap)
  //     );
  //     console.log('ALTITUDE ANGEL', data);
  //     airspace.push(data);
  //   } catch (error) {
  //     console.error(error);
  //   }

  //   console.log(airspace[0]);
  //   //error sini need to do if bfore trigging
  //   for (const source of airspace[0].features) {
  //     // console.log(source);

  //     const sourceData = [
  //       {
  //         id: source.id,
  //         data: source.features,
  //       },
  //     ];

  //     console.log(source);

  //     if (
  //       source.properties.altitudeCeiling &&
  //       source.properties.altitudeFloor
  //     ) {

  //       // error handle same source for load layer
  //       let sourceRe = this.map.getSource(source.id);

  //       if (sourceRe) {
  //         this.map.removeSource(source.id);
  //       }

  //       // source = new mapboxgl.GeoJSONSource({
  //       //   id: source,
  //       //   data: {
  //       //     // Your GeoJSON data here
  //       //   },
  //       // });

  //       // this.map.addSource(sourceID, source);
  //       this.map.addSource(source.id, {
  //         type: 'geojson',
  //         data: source,
  //       });

  //       this.map.addLayer({
  //         id: source.id,
  //         type: 'fill-extrusion',
  //         source: source.id,
  //         paint: {
  //           'fill-extrusion-color': source.properties.fillColor,

  //           'fill-extrusion-height': source.properties.altitudeCeiling.meters,

  //           'fill-extrusion-base': source.properties.altitudeFloor.meters,

  //           'fill-extrusion-opacity': 0.5,
  //         },
  //       });
  //     } else {
  //       this.map.addSource(source.id, {
  //         type: 'geojson',
  //         data: source,
  //       });

  //       this.map.addLayer({
  //         id: source.id,
  //         type: 'fill',
  //         source: source.id,
  //         paint: {
  //           'fill-color': source.properties.fillColor,

  //           'fill-opacity': 0.5,
  //         },
  //       });

  //       // If the source doesn't have altitudeCeiling and altitudeFloor properties, add a different type of layer or skip it
  //       console.log(
  //         'Source with id: ' +
  //         source.id +
  //         " doesn't have altitudeCeiling and altitudeFloor properties."
  //       );
  //     }
  //   }
  //   // });
  // }

  /***********AIR SPACE GIS DATA */

  loadgeozone() {
    let data = '';
    const dta = [];
    let data2: any[] = []; // Declare data2 as an array of any type initially

    fetch(this.Djigeozone)
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        data2 = myJson.areas; // Assign the value of myJson to data2
        // console.log('======== dta', data2);

        // data2.forEach((areas: any) => {
        //   console.log(areas.sub_areas);
        // });
      })
      .catch(function (error) {
        console.error('Error fetching GeoJSON:', error);
      });
    setTimeout(() => {
      // console.log('outsdide', data2);
    }, 100);

    // Now you can access data2 outside of this block
    data2.forEach((areas) => {
      console.log('areas', areas);
    });
  }

  async loadDataKmz2(file2: any) {
    try {
      const response = await fetch(file2);
      const kmzData = await response.arrayBuffer();

      const zip = await JSZip.loadAsync(kmzData);
      const kmlFile = Object.values(zip.files).find((f) => f.name.toLowerCase().endsWith('.kml'));

      if (!kmlFile) {
        // console.error('KMZ file does not contain a KML file');
        return;
      }

      const kmlContents = await kmlFile.async('text');
      const kmlDom = new DOMParser().parseFromString(kmlContents, 'application/xml');
      const jsonData = kml(kmlDom);

      var result = jsonData.features.filter((jsonData) => jsonData.geometry?.type === 'Polygon'); //filter for point data

      const polygonFiltered = {
        type: 'FeatureCollection',
        features: result,
      };
    } catch (error) {
      // console.error('Error loading KMZ file:', error);
    }
    this.loadgeozone();
  }

  async loadDataKmz(file2: any) {
    try {
      const response = await fetch('https://dronos-utm.s3.ap-southeast-1.amazonaws.com/' + file2);
      const kmzData = await response.arrayBuffer();

      const zip = await JSZip.loadAsync(kmzData);
      const kmlFile = Object.values(zip.files).find((f) => f.name.toLowerCase().endsWith('.kml'));

      if (!kmlFile) {
        // console.error('KMZ file does not contain a KML file');
        return;
      }

      const kmlContents = await kmlFile.async('text');
      const kmlDom = new DOMParser().parseFromString(kmlContents, 'application/xml');
      const defaultColor = 'rgba(0, 0, 0, 0)'; // Replace 'yourDefaultColor' with an actual color value

      const jsonData: any = kml(kmlDom);

      if (this.map.getLayer(file2)) {
        this.map.removeLayer(file2);
        this.map.removeSource(file2);
        const elementToRemove = file2;
        const newArray = this.layerStyle.filter((item) => item !== elementToRemove);
        this.layerStyle = newArray;
      } else {
        if (!this.layerStyle.includes(file2)) {
          this.layerStyle.push(file2);
        }
        this.map.addSource(file2, {
          type: 'geojson',
          data: jsonData,
        });
        var fillColor;

        switch (file2) {
          case 'CLASS_A.kmz':
            fillColor = '#5CEF85';
            break;
          case 'CLASS_B.kmz':
            fillColor = '#8CCAFB';
            break;
          case 'CLASS_C.kmz':
            fillColor = '#3C44FF';
            break;
          case 'WM(D).kmz':
            fillColor = '#FFFF05';
            break;
          case 'WM(P).kmz':
            fillColor = '#D19721';
            break;
          case 'WM(R).kmz':
            fillColor = '#FF0033';
            break;
          default:
            fillColor = defaultColor;
            break;
        }

        if (fillColor) {
          this.map.addLayer({
            id: file2,
            type: 'fill',
            source: file2,
            paint: {
              'fill-color': fillColor,
              'fill-opacity': 0.3,
            },
          });
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  // airspace hide and show end//

  /***************** AIRSPACE GIS DATA END */

  //toggle terrain
  toggleTerrain() {
    const layerId = 'terrain-data';
    const isLayerVisible = this.map.getLayoutProperty(layerId, 'visibility') === 'visible';
    const layerExists = this.map.getLayer(layerId) !== undefined;
    if (layerExists) {
      if (isLayerVisible) {
        this.map.setLayoutProperty(layerId, 'visibility', 'none');
      } else {
        this.map.setLayoutProperty(layerId, 'visibility', 'visible');
      }
    } else {
      this.map.addLayer({
        id: 'terrain-data',
        type: 'hillshade',
        source: 'mapbox-dem',
        paint: {
          'hillshade-exaggeration': 1,
        },
      });
      // add the DEM source as a terrain layer with exaggerated height
      this.map.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
      this.map.setLayoutProperty('terrain-data', 'visibility', 'none');
    }
  }

  loadPathTele(e: any) {
    this.map.addSource(e.properties.topic, {
      type: 'geojson',
      data: e,
    });

    this.map.addLayer({
      id: e.properties.topic,
      type: 'line',
      source: e.properties.topic,

      paint: {
        'line-color': 'grey',
        'line-width': 3,
        'line-opacity': 0.5,
      },
    });
  }

  airSpaceInformatics(val: boolean) {
    this.airspaceInformatics = val;
  }

  flytoMissionEnded(data: any) {
    const telemetryPath: any = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [],
          },
        },
      ],
    };

    var elevation = this.altitude;
    const bufferDistance1 = 0.1;
    const bufferDistance2 = 0.2;
    const bufferDistance3 = 0.3;
    const convertedCoordinates = data.area.coordinate.map((coord: { longitude: string; latitude: string }) => [
      parseFloat(coord.latitude),
      parseFloat(coord.longitude),
    ]);

    const polygonFeature: any = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [convertedCoordinates],
      },
    };

    const center: any = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [data.area.geoCoordinate.longitude, data.area.geoCoordinate.latitude],
      },
    };

    const bufferedPolygon1 = turf.buffer(polygonFeature, bufferDistance1, { units: 'kilometers' });
    const bufferedPolygon2 = turf.buffer(polygonFeature, bufferDistance2, { units: 'kilometers' });
    const bufferedPolygon3 = turf.buffer(polygonFeature, bufferDistance3, { units: 'kilometers' });

    this.map.addSource(`${data.missionId}-buffer1`, {
      type: 'geojson',
      data: bufferedPolygon1,
    });

    this.map.addLayer({
      id: `${data.missionId}-buffer1`,
      type: 'line',
      source: `${data.missionId}-buffer1`,
      paint: {
        'line-color': '#2196F3',
        'line-width': 3,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    this.map.addSource(`${data.missionId}-buffer2`, {
      type: 'geojson',
      data: bufferedPolygon2,
    });

    this.map.addLayer({
      id: `${data.missionId}-buffer2`,
      type: 'line',
      source: `${data.missionId}-buffer2`,
      paint: {
        'line-color': '#FF0000',
        'line-width': 3,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    this.map.addSource(`${data.missionId}-buffer3`, {
      type: 'geojson',
      data: bufferedPolygon3,
    });

    this.map.addLayer({
      id: `${data.missionId}-buffer3`,
      type: 'line',
      source: `${data.missionId}-buffer3`,
      paint: {
        'line-color': '#FFD54F',
        'line-width': 3,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    this.map.addSource(data.missionId, {
      type: 'geojson',
      data: polygonFeature,
    });

    this.map.addLayer({
      id: data.missionId,
      type: 'fill-extrusion',
      source: data.missionId,
      paint: {
        'fill-extrusion-color': '#009688',
        'fill-extrusion-opacity': 0.5,
        'fill-extrusion-height': elevation,
      },
    });

    this.map.addSource(`${data.missionId}-centroid-point`, {
      type: 'geojson',
      data: center,
    });

    fetch(assetUrl('globe/dropPin.svg'))
      .then((response) => response.blob())
      .then((iconBlob) => {
        const iconUrl = URL.createObjectURL(iconBlob);

        const image = new Image();
        image.onload = () => {
          this.map.addImage('custom-icon', image);
          this.map.addLayer({
            id: `${data.missionId}-centroid-point`,
            type: 'symbol',
            source: `${data.missionId}-centroid-point`,
            layout: {
              'icon-image': 'custom-icon',
              'icon-size': 1,
              'icon-anchor': 'bottom',
            },
          });
        };

        image.src = iconUrl;
      });

    this.map.flyTo({
      center: [data.area.geoCoordinate.longitude, data.area.geoCoordinate.latitude],
      zoom: 14,
    });

    // telemetry
  }

  flytoMission(data: any) {
    const telemetryPath: any = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [],
          },
        },
      ],
    };

    var elevation = this.altitude;
    const bufferDistance1 = 0.1;
    const bufferDistance2 = 0.2;
    const bufferDistance3 = 0.3;
    const convertedCoordinates = data.area.coordinate.map((coord: { longitude: string; latitude: string }) => [
      parseFloat(coord.latitude),
      parseFloat(coord.longitude),
    ]);

    const polygonFeature: any = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [convertedCoordinates],
      },
    };

    const center: any = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [data.area.geoCoordinate.longitude, data.area.geoCoordinate.latitude],
      },
    };

    const bufferedPolygon1 = turf.buffer(polygonFeature, bufferDistance1, { units: 'kilometers' });
    const bufferedPolygon2 = turf.buffer(polygonFeature, bufferDistance2, { units: 'kilometers' });
    const bufferedPolygon3 = turf.buffer(polygonFeature, bufferDistance3, { units: 'kilometers' });

    this.map.addSource(`${data.trackingId}-buffer1`, {
      type: 'geojson',
      data: bufferedPolygon1,
    });

    this.map.addLayer({
      id: `${data.trackingId}-buffer1`,
      type: 'line',
      source: `${data.trackingId}-buffer1`,
      paint: {
        'line-color': '#2196F3',
        'line-width': 3,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    this.map.addSource(`${data.trackingId}-buffer2`, {
      type: 'geojson',
      data: bufferedPolygon2,
    });

    this.map.addLayer({
      id: `${data.trackingId}-buffer2`,
      type: 'line',
      source: `${data.trackingId}-buffer2`,
      paint: {
        'line-color': '#FF0000',
        'line-width': 3,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    this.map.addSource(`${data.trackingId}-buffer3`, {
      type: 'geojson',
      data: bufferedPolygon3,
    });

    this.map.addLayer({
      id: `${data.trackingId}-buffer3`,
      type: 'line',
      source: `${data.trackingId}-buffer3`,
      paint: {
        'line-color': '#FFD54F',
        'line-width': 3,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    this.map.addSource(data.trackingId, {
      type: 'geojson',
      data: polygonFeature,
    });

    this.map.addLayer({
      id: data.trackingId,
      type: 'fill-extrusion',
      source: data.trackingId,
      paint: {
        'fill-extrusion-color': '#009688',
        'fill-extrusion-opacity': 0.5,
        'fill-extrusion-height': elevation,
      },
    });

    this.map.addSource(`${data.trackingId}-centroid-point`, {
      type: 'geojson',
      data: center,
    });

    fetch(assetUrl('globe/dropPin.svg'))
      .then((response) => response.blob())
      .then((iconBlob) => {
        const iconUrl = URL.createObjectURL(iconBlob);

        const image = new Image();
        image.onload = () => {
          this.map.addImage('custom-icon', image);
          this.map.addLayer({
            id: `${data.trackingId}-centroid-point`,
            type: 'symbol',
            source: `${data.trackingId}-centroid-point`,
            layout: {
              'icon-image': 'custom-icon',
              'icon-size': 1,
              'icon-anchor': 'bottom',
            },
          });
        };

        image.src = iconUrl;
      });

    this.map.flyTo({
      center: [data.area.geoCoordinate.longitude, data.area.geoCoordinate.latitude],
      zoom: 14,
    });

    // telemetry
    const airplaneMarker = document.createElement('div');
    airplaneMarker.className = 'marker';
    let Sn = [] as any;
    const searchDroneSn = data.scheduleIds[0].droneList[0]; // NI NAD CHECK BALIK

    this.mqttservice.getDroneSn(searchDroneSn).subscribe((r) => {
      Sn.push(r);
    });

    setTimeout(() => {
      for (const sn2 of Sn) {
        for (const key in sn2) {
          if (sn2.hasOwnProperty(key)) {
            const value = sn2[key];
            // console.log(`${key}: ${value}`);
          }
        }
      }
    }, 100);

    // var airplaneMark = new mapboxgl.Marker(airplaneMarker,{
    //     rotation: 0,
    //     scale: 0.5
    // }).setLngLat([0, 0]).addTo(this.map);
    //add marker here
    setTimeout(() => {
      const srnNumber = [];

      for (const sn2 of Sn) {
        srnNumber.push(sn2.serialNumber);
      }

      // this.mqttservice.disconnect()
      this.mqttservice.subscribeToTopic(`thing/product/1581F5FKD232T00D2C8B/osd`);
      this.mqttservice.setCallback((topic: string, payload: string) => {
        this.incomingTopic = topic;
        this.incomingPayload = payload;

        const data = JSON.parse(payload);
        this.compassData = {
          batteries_capacity: data.data.battery.batteries[0].capacity_percent,
          wind_speed: data.data.wind_speed.toFixed(2),
          vertical_speed: data.data.vertical_speed.toFixed(2),
          height: data.data.height.toFixed(2),
          attitude_pitch: data.data.attitude_pitch.toFixed(2),
          attitude_head: data.data.attitude_head.toFixed(2),
          attitude_roll: data.data.attitude_roll.toFixed(2),
        };

        const latLng = [data.data.longitude, data.data.latitude] as [number, number];

        telemetryPath.features[0].geometry.coordinates.push(latLng);

        //   const updatedData = {
        //     type: 'FeatureCollection',
        //     features: [telemetryPath.features[0]],
        //   };

        //   // Replace the data source for the waypoint markers
        //  const source1= this.map.getSource('path') as mapboxgl.GeoJSONSource;
        //  source1.setData(updatedData as any)

        if (!this.map.getLayer('path')) {
          this.map.addSource('path', {
            type: 'geojson',
            data: telemetryPath,
          });
          this.map.addLayer({
            id: 'path',
            type: 'line',
            source: 'path',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#888',
              'line-width': 3,
            },
          });

          // this.map.addLayer({
          //   id: 'waypoint-markers',
          //   type: 'symbol',
          //   source: 'path', // Use the same source as the path
          //   layout: {
          //     'icon-image': 'waypoint-marker', // Use your custom marker symbol name
          //     'icon-allow-overlap': true,
          //   },
          //   paint: {},
          // });
        } else {
          const sourceId = 'path';

          // Remove existing layer and source
          this.map.removeLayer(sourceId);
          this.map.removeSource(sourceId);

          // Add new source and layer
          this.map.addSource(sourceId, {
            type: 'geojson',
            data: telemetryPath,
          });
          this.map.addLayer({
            id: 'path',
            type: 'line',
            source: sourceId,
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#00f0ff',
              'line-width': 8,
            },
          });

          const newCoordinate = telemetryPath.features[0].geometry.coordinates.slice(-1)[0];
          const heading: any = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: newCoordinate,
            },
          };

          if (this.map.getLayer('heading')) {
            this.map.removeLayer('heading');
            this.map.removeSource('heading');
            this.map.addSource(`heading`, {
              type: 'geojson',
              data: heading,
            });
            fetch(assetUrl('globe/dronePin.png'))
              .then((response) => response.blob())
              .then((iconBlob) => {
                const iconUrl = URL.createObjectURL(iconBlob);

                const image = new Image();
                image.onload = () => {
                  this.map.addLayer({
                    id: `heading`,
                    type: 'symbol',
                    source: `heading`,
                    layout: {
                      'icon-image': 'heading-icon',
                      'icon-size': 1,
                      'icon-anchor': 'bottom',
                    },
                  });
                };

                image.src = iconUrl;
              });
          } else if (!this.map.getLayer('heading')) {
            this.map.addSource(`heading`, {
              type: 'geojson',
              data: heading,
            });
            fetch(assetUrl('globe/dronePin.png'))
              .then((response) => response.blob())
              .then((iconBlob) => {
                const iconUrl = URL.createObjectURL(iconBlob);

                const image = new Image();
                image.onload = () => {
                  this.map.addImage('heading-icon', image);
                  this.map.addLayer({
                    id: `heading`,
                    type: 'symbol',
                    source: `heading`,
                    layout: {
                      'icon-image': 'heading-icon',
                      'icon-size': 1,
                      'icon-anchor': 'bottom',
                    },
                  });
                };

                image.src = iconUrl;
              });
          }
          this.map.panTo(newCoordinate);
        }

        // const source = this.map.getSource('trace') as mapboxgl.GeoJSONSource;
        // const currentData = source['_data'] as any; // Get the current data
        // currentData.features.push(newCoordinate);

        // source.setData(currentData); // Update the data
        // this.map.panTo(newCoordinate.geometry.coordinates);
        // this.addnewLine(latLng);
      });
    }, 2000);
  }

  addnewLine(e: any) {}

  getFooterCallback() {
    const customEvent = new CustomEvent('dronos:utm:footer-call');
    window.dispatchEvent(customEvent);

    window.addEventListener('dronos:utm:footer-answer', () => {
      this.footerLoaded = true;
    });
  }
}
