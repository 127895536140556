<div class="missionDetail-wrapper">
    <h3 class="title">Uploading new dataset</h3>
    <div class="progress-holder">
        <ul class="progress-list">
            <li class="active"><span class="bullet-step">Mission Detail</span></li>
            <li><span class="bullet-step">Upload Dataset</span></li>
        </ul>
    </div>
    <div class="form-holder">
        <form [formGroup]="createMissionForm" (ngSubmit)="onSumbitInfo()">
            <div class="form-group">
                <label for="missionId" class="label">Mission ID</label>
                <input class="form-control" [disabled]='isDisabled' autocomplete="off" placeholder="Mission ID" id="missionId" #missionID>
            </div>
            <div class="form-group">
                <label class="label" for="missionObj">Mission Objective<em class="required">*</em></label>
                <select id="missionObj" class="form-control mission-field" formControlName="missionObj" placeholder="Choose Mission Objective" (change)="changeMissionObjective($event)">
                    <option selected value="">Choose Mission Objective</option>
                    <option *ngFor="let mObj of missionObjectiveRecord" value="{{mObj._id}}">{{mObj.name}}</option>
                </select>
                <div *ngIf="createMissionForm.controls['missionObj'].invalid && (createMissionForm.controls['missionObj'].dirty || createMissionForm.controls['missionObj'].touched)" class="error-msg">
                    Objective Name is required
                </div>
            </div>
            <div class="btn-holder">
                <button type="button" class="btn btn-outline" (click)="initUploadDatasetComponent(false)">
                  Cancel
                </button>
                <button type="submit" [disabled]="createMissionForm.invalid || isNextClick" class="btn btn-default">Next</button>
            </div>
        </form>
    </div>
</div>
<ng-template [ngIf]="isShowToaster">
    <app-utm-toaster-custom [toasterClass]="toasterClass" [toasterMsg]="toasterMsg"></app-utm-toaster-custom>
</ng-template>