import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { state } from '@app/utility';
import { EnvironmentService } from 'src/app/services/environment.service';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl();

@Injectable({
  providedIn: 'root',
})

export class ApiService {
  API_SERVER: string = apiUrl + "/";
  workspace = state.getUserInfo().member[0].workspace._id.toString();
  bearerToken = state.getDronosToken().value;

  // token
  private mission: any;
  private permitId: any;
  private applicationId: any;
  constructor(private http: HttpClient) {
  }

  setMissionPermitId(mission: any, permitId: string, applicationId: string) {
    this.mission = mission
    this.permitId = permitId
    this.applicationId = applicationId
  }

  getMissionPermitId() {
    const allId = {
      mission: this.mission,
      permitId: this.permitId,
      applicationId: this.applicationId
    }
    return allId
  }


}
