import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment.service';
import { ICreateMission, IMission, IMissionFilter } from '../models/mission.model';
import { state } from '@app/utility';
import { IPagination } from '../models/pagination.model';
import { IMissionObjective } from '../models/mission-objective.model';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl() + '/mission/missions';
const missionStatusListUrl = `${apiUrl}/mission-status`;
const missionBoundaryTypeUrl = `${apiUrl}/mission-boundary-type`;
const statusUrl = `${apiUrl}/mission-status`;
const objectiveUrl = `${environmentService.getApiUrl()}/mission/mission-objective`;
// const workspaceId = state.getUserInfo().member[0].workspace._id.toString();
const workspaceId = state.getUserInfo().activeWorkspaceId;
const headers = new HttpHeaders({
  Authorization: 'Bearer ' + environmentService.getToken(),
  workspaceId: workspaceId,
  'Content-Type': 'application/json',
});

@Injectable({
  providedIn: 'root',
})
export class MissionService {
  constructor(private http: HttpClient) {}

  getMissionStatusList(): Observable<string[]> {
    return this.http.get<string[]>(missionStatusListUrl, { headers: headers });
  }

  getStatusList(): Observable<string[]> {
    return this.http.get<string[]>(statusUrl, {headers: headers});
  }

  getObjective(queryParams?: IPagination): Observable<{ records: IMissionObjective[]; meta: { nextCursor: string; prevCursor: string } }> {
    return this.http.get<{ records: IMissionObjective[]; meta: { nextCursor: string; prevCursor: string } }>(objectiveUrl, { headers: headers , params: { ...queryParams },});
  }

  getBoundaryType(): Observable<string[]> {
    return this.http.get<string[]>(missionBoundaryTypeUrl, { headers: headers });
  }

  create(data: ICreateMission): Observable<{ data: IMission; isOverlap: boolean; message: string }> {
    return this.http.post<{ data: IMission; isOverlap: boolean; message: string }>(`${apiUrl}`, data, { headers: headers });
  }

  getAll(queryParams?: IMissionFilter): Observable<{ records: IMission[]; meta: { nextCursor: string; prevCursor: string } }> {
    return this.http.get<{ records: IMission[]; meta: { nextCursor: string; prevCursor: string } }>(`${apiUrl}`, {
      headers: headers,
      params: { ...queryParams },
    });
  }

  get(id: string): Observable<IMission> {
    return this.http.get<IMission>(`${apiUrl}/${id}`, { headers: headers });
  }

  update(id: string, data: Partial<IMission>): Observable<IMission> {
    return this.http.patch<IMission>(`${apiUrl}/${id}`, data);
  }

  delete(id: string): Observable<IMission> {
    return this.http.delete<IMission>(`${apiUrl}/${id}`, { headers: headers });
  }
}
