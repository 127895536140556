<div>
    <div class="modal-card">
        <img [src]="'/icon/Warning-alt.svg' | assetUrl" class="img-size" *ngIf="state == 'alert'"/>
        <img [src]="'/icon/Warning-remove.svg' | assetUrl" class="img-size" *ngIf="state == 'remove'"/>
        <span class="modal-title">{{ state == 'alert' ? 'Maximum Altitude Reached' : 'Remove Area' }}</span>
        <span class="modal-desc">{{ state == 'alert' ? 'Adherence to the legal altitude limit of 120 meters is crucial. Exceeding this threshold may lead to potential rejection.' : 'Are you sure you want to remove this area? You won’t be able to revert this action.' }}</span>
        <div class="d-flex" style="gap: 20px">
            <span class="modal-desc" (click)="closeModal()" style="cursor: pointer; align-self: center;">Cancel</span>
            <div [ngClass]="state == 'alert' ? 'proceed-btn' : 'remove-btn' " (click)="confirm( state == 'alert' ? 'alert' : 'remove')">{{ state == 'alert' ? 'Proceed' : 'Confirm' }}</div>
        </div>
    </div>
</div>