import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  getApiUrl(): string {
    return environment.apiHost;
  }

  getToken() {
    return environment.token;
  }

  getAccessToken() {
    return environment.mapbox.accessToken;
  }

  getAAToken() {
    return environment.AAToken;
  }

  getAAUrl() {
    return environment.AAURL;
  }

  isProduction(): boolean {
    return environment.production;
  }

  getMeteomaticUser() {
    return environment.MeteomaticUser;
  }

  getMeteomatickey() {
    return environment.Meteomatickey;
  }
}
