import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-utm-toaster-custom',
  templateUrl: './toaster-custom.component.html',
  styleUrls: ['./toaster-custom.component.scss']
})
export class ToasterCustomComponent {
  @Input() toasterClass!:string;
  @Input() toasterMsg!:string;
}
