<!DOCTYPE html>
<html>

<head>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@100;400&display=swap" rel="stylesheet">
  <script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script>
</head>

<body>
  <div class="grid-container">
    <app-navbar></app-navbar>
    <div class="content">
      <h1>Heyy, this is a Sample Page</h1>
      <p>You can modify from here!</p>
    </div>
  </div>
</body>

</html>
