import { Component,OnInit } from '@angular/core';
import { LiveService } from '../live.service';
// import {RTCSubscriber} from 'red5pro-webrtc-sdk'
// import * as mqtt from "mqtt"
// import * as socket from 'socket.io-client'

// import * as RTCSubscriber from 'red5pro-webrtc-sdk'
// const { io } = require("socket.io-client");


const red5pro = require('red5pro-webrtc-sdk')

@Component({
  selector: 'app-utm-livestreams',
  templateUrl: './livestreams.component.html',
  styleUrls: ['./livestreams.component.scss'],
  providers:[LivestreamsComponent]
})
export class LivestreamsComponent implements OnInit {
  videoList: any[] = [];
  activeRed5Subscriber: any;
  subscriberEventHandler: any;
  checkLiveInterval: any;
  liveIsAvailable: boolean = false;

  constructor(private liveAdd:LiveService){
    this.liveAdd.addVideoEvent.subscribe((data:any)=>{
      this.addVideo(data)
    })
  }

  ngOnDestroy() {
    this.destroySubscriber();
    // this.liveAdd.addVideoEvent?.unsubscribe();

  }

  addVideo(data:any) {
    const videoElementId = 'red5pro-subscriber-' + this.videoList.length;
    const newVideo = {
      id: videoElementId,
      streamId: data // Set the stream ID here
    };
    this.videoList.push(newVideo);
    this.initializeRed5Pro(newVideo);
  }

  initializeRed5Pro(video: any) {
    const subscriber = new red5pro.RTCSubscriber();

    try {
      let red5StreamId = video.streamId;
      this.activeRed5Subscriber = subscriber;
      this.activeRed5Subscriber.mute();

      // Your existing initialization code...
      const config = {
        protocol: 'wss',
        host: 'red5-utm.dronos.ai',
        app: 'live',
        streamName: red5StreamId,
        rtcConfiguration: {
          iceServers: [{ urls: 'stun:stun2.l.google.com:19302' }],
          iceCandidatePoolSize: 2,
          bundlePolicy: 'max-bundle'
        },
        mediaElementId: video.id,
        subscriptionId: red5StreamId + Math.floor(Math.random() * 0x10000).toString(16),
        maintainConnectionOnSubscribeErrors: true,
        videoEncoding: 'H264'
      };

      // Subscribe to the stream
      subscriber.init(config)
        .then(() => subscriber.subscribe())
        .catch((error: any) => {
          console.error('Could not subscribe:', error);
          // Handle error here
        });

      const subscriberEventHandler = (e: any) => {
        if (e.type != 'Subscribe.Time.Update') {
          // Your existing event handling logic...
        }
      };

      this.subscriberEventHandler = subscriberEventHandler;
      subscriber.on('*', subscriberEventHandler);
    } catch (e) {
      this.liveIsAvailable = false;
      // An error occurred in establishing a subscriber session.
    }
  }

  destroySubscriber() {
    window.clearInterval(this.checkLiveInterval);
    if (this.activeRed5Subscriber) {
      this.activeRed5Subscriber.off('*', this.subscriberEventHandler);
      this.activeRed5Subscriber?.unsubscribe();
      this.activeRed5Subscriber = null;
    }
  }

  closeVideo(index: number): void {
    if (this.videoList[index]) {
      this.destroySubscriberForVideo(this.videoList[index]);
      this.videoList.splice(index, 1);
    }
  }

  // Function to destroy a subscriber for a specific video
  destroySubscriberForVideo(video: any): void {
    if (this.activeRed5Subscriber && this.activeRed5Subscriber.mediaElementId === video.id) {
      this.activeRed5Subscriber.off('*', this.subscriberEventHandler);
      this.activeRed5Subscriber?.unsubscribe();
      this.activeRed5Subscriber = null;
    }}


  ngOnInit():void {

    
    // this.mqttTelemetry();
    

  }

  testInject(){
  }

  // socketioConn(){
  //   const socket2 = io("wss://mqtt-wss.aerodyne.group:8086/mqtt");

  //   socket2.on('connect',()=>{
  //     console.log("listennnnnnn");
      
  //     console.log("sod",socket2.id);
      
  //   })


  // }

  // mqttTelemetry(){
  //   const client = mqtt.connect('ws://mqtt-wss.aerodyne.group:8083/mqtt',{
  //     username: '',
  //     password: '',
  //   });

  //   client.on('connect',()=>{
  //     // client.subscribe('thing/product/1581F5FHB22A70020AD8/osd')
      
  //   })

  // }


 


}
