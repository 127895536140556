import { Component, OnInit, SimpleChanges } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { MapdataService } from '../services/mapdata.service';
import { MissionDetailsComponent } from '../modal-contents/mission-details/mission-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MissionService } from '../services/mission.service';
import { IMission, IMissionCoordinate } from '../models/mission.model';
import { IDrone } from '../models/drone.model';
import { IPayload } from '../models/payload.model';
import { IUser, IUserPilotInfo } from '../models/user.model';

type MissionExtend = IMission & {
  startDate: string;
  endDate: string;
  checked: boolean;
  fee: number;
  missionArea?: IMissionCoordinate[];
  centroid?: {};
};

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss'],
})
export class MissionComponent implements OnInit {
  missions: MissionExtend[] = [];
  specificMission: any;
  drones: IDrone[] = [];
  payloads: IPayload[] = [];
  operators: IUser[] = [];
  checkedMissions: string[] = [];
  pilotInformation: IUserPilotInfo[] = [];
  fee: number = 0;
  showSpinner = true;
  searchMission: string = '';
  originalMission: any = [];
  currentIdx: number | null = null;

  constructor(
    private router: Router,
    private http: HttpClient,
    private mapDataService: MapdataService,
    private modalService: NgbModal,
    private missionService: MissionService,
  ) {}

  searchMissions(stringToFind: string) {
    const normalizedSearchString = stringToFind.toLowerCase();
    if (stringToFind.length == 0) {
      this.missions = this.originalMission;
    } else {
      this.missions = this.originalMission.filter((item: { [s: string]: unknown } | ArrayLike<unknown>) => {
        return Object.values(item).some((value) =>
          // typeof value === 'string' && value.includes(stringToFind)
          String(value).toLowerCase().includes(normalizedSearchString),
        );
      });
    }
  }

  ngOnInit() {
    this.mapDataService.initializeMap('map-content', 101.651993, 2.901133, 9);
    this.fetchMissions();
    this.mapDataService.addOrtho()
  }

  fetchMissions() {
    this.missionService.getAll().subscribe((data) => {
      //filter not applied mission
      const filteredRecords = data.records.filter((record) => {
        return !record.permitInfo.permitId;
      });

      this.missions = filteredRecords.map((record) => ({
        ...record,
        checked: false,
        fee: record.permitInfo.permitFees,
        missionArea: record.area.coordinate,
        centroid: record.area.geoCoordinate,
      })) as MissionExtend[];

      this.originalMission = this.missions;
      this.showSpinner = false;
    });
  }

  formatTime(time: number): string {
    const hours = Math.floor(time / 100);
    const minutes = time % 100;
    const amPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${amPm}`;
    return formattedTime;
  }

  showModal = false;

  activeTab = 1;

  showMissionContainer = true;
  showPermitContainer = false;
  showTabMission = true;
  showTabOperator = false;
  showTabDrone = false;
  showCoordinate = false;

  map2 = false;
  map!: mapboxgl.Map;

  openModal(missionId: string) {
    const modalRef = this.modalService.open(MissionDetailsComponent, {
      centered: true,
      windowClass: 'mission-details',
      backdropClass: 'mission-details-static',
      backdrop: 'static',
      size: 'xl',
    });
    // modalRef.componentInstance.missionDetails = this.originalMission.find((item: any) => (item._id = missionId));
    modalRef.componentInstance.missionId = missionId;
  }

  closeModal() {
    this.showModal = false;
  }

  openTabMission() {
    this.showTabMission = true;
    this.showTabOperator = false;
    this.showTabDrone = false;
    this.activeTab = 1;
  }

  openTabOperator() {
    this.showTabMission = false;
    this.showTabOperator = true;
    this.showTabDrone = false;
    this.activeTab = 2;
  }

  openTabDrone() {
    this.showTabMission = false;
    this.showTabOperator = false;
    this.showTabDrone = true;
    this.activeTab = 3;
  }

  //show coordinate

  openCoordinateModal(center: any, area: any, id: string) {
    this.showCoordinate = true;
    setTimeout(() => {
      this.mapDataService.initializeMap('showmap', center.longitude, center.latitude, 9);
      setTimeout(() => {
        this.mapDataService.drawAreaBuffer(area.location, center, id);
      }, 1000);
    }, 1000);
  }

  closeCoordinateModal() {
    this.showCoordinate = false;
  }

  redirectToPermit(checkedMissions: string) {
    this.router.navigate(['/permit'], { queryParams: { checkedMissions: checkedMissions } });
  }

  //set checked mission
  setCheckedMissions(): string[] {
    return this.missions.filter((mission) => mission.checked === true).map((mission) => mission._id);
  }

  getCheckedMissions() {
    const checkedMissions = this.setCheckedMissions();
    if (checkedMissions.length != 0) {
      this.redirectToPermit(checkedMissions.toString());
    }
  }

  handleCheckboxChange(mission: MissionExtend) {
    if (mission.checked) {
      this.fee = this.fee + mission.fee;
      this.mapDataService.drawAreaBuffer(mission.missionArea, mission.centroid, mission.trackingId);
    } else {
      this.fee = this.fee - mission.fee;
      this.mapDataService.removeAreaBuffer(mission.trackingId);
    }
  }

  getPilotInformation(pilotInformation: undefined | IUserPilotInfo[]): IUserPilotInfo[] {
    return pilotInformation as IUserPilotInfo[];
  }
}
