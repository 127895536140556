import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { assetUrl } from 'src/single-spa/asset-url';
// import { WebsocketService } from '../websocket.service';
// import { PahoService } from '../paho.service';
import { BasixService } from '../basix.service';
import { ScheduleService } from '../services/schedule.service';
import { MqttserviceService } from '../mqttservice.service';
import { CompassData, GlobeComponent } from '../globe/globe.component';
import { MissionService } from '../services/mission.service';
import { IMissionFilter } from '../models/mission.model';
// const mqtt = require("mqtt");

@Component({
  selector: 'app-utm-telecompass',
  templateUrl: './telecompass.component.html',
  styleUrls: ['./telecompass.component.scss'],
})
export class TelecompassComponent implements OnInit {
  @Input('compassData') compassData!: CompassData;
  // messages: string[] = [];
  // compassData = {
  //   attitude_head: 136.1,
  //   attitude_pitch: 4.5,
  //   attitude_roll: -1.6,
  //   batteries_capacity: 40,
  //   height: 19.29,
  //   vertical_speed: 23,
  //   wind_direction: 46,
  //   wind_speed: 12,
  // };
  // constructor(private websocketService: WebsocketService) {}

  // ngOnInit() {
  //   this.websocketService.getMessageStream().subscribe(message => {
  //     this.messages.push(message);
  //   });
  // }

  // ngOnDestroy() {
  //   this.websocketService.closeConnection();
  // }

  // subscribeToTopic() {
  //   this.websocketService.subscribeToTopic('your-topic-name');
  // }

  // closeConnection() {
  //   this.websocketService.closeConnection();
  // }

  message!: string;
  incomingTopic: string = '';
  incomingPayload: string = '';

  constructor(private mqttService: MqttserviceService, private basix: BasixService, private missionService: MissionService, private golbe: GlobeComponent) {}

  ngOnInit() {
    setTimeout(() => {
      // this.sendMessage()
      // this.mqttService.subscribeToTopic('thing/product/1581F5FHB22A70020AD8/osd')
      // this.mqttService.setCallback((topic: string, payload: string) => {
      //   this.incomingTopic = topic;
      //   this.incomingPayload = payload;
      // });
    }, 5000);

    const queryParams:IMissionFilter = { pageLimit: '1000' }

    this.missionService.getAll(queryParams).subscribe((data) => {
      console.log('data', data.records);
    });

    // this.basix.subscribe('presence')
    // this.mqttService.setCallback(this.handleMessage.bind(this));
    this.mqttService.connect();
  }

  startTele() {
    this.mqttService.subscribeToTopic('thing/product/1581F5FHB22A70020AD8/osd');
    this.mqttService.setCallback((topic: string, payload: string) => {
      this.incomingTopic = topic;
      this.incomingPayload = payload;
 
      const data = JSON.parse(payload.toString());
    });
  }

  ngOnDestroy(): void {
    this.mqttService.disconnect();
  }

  sendMessage(): void {
    const topic = 'thing/product/1581F5FHB22A70020AD8/osd';
    const message = 'Hello from Angular MQTT';
    this.mqttService.sendMessage(topic, message);
  }

  private handleMessage(message: string): void {
    this.message = message;
  }

  close() {
    this.golbe.compassOn = false;
    this.golbe.airspaceOn = true;
  }
}
