<!DOCTYPE html>
<html>

<head>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@100;400&display=swap" rel="stylesheet">
  <script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script>
</head>

<body>
  <div class="grid-container">
    <app-navbar></app-navbar>
    <div class="content">

      <div class="form">
        <h1 class="title">CREATE DRONE</h1>
        <form (ngSubmit)="onSubmit()">

          <div class="input-group">
            <p class="label" for="serialNumber">Serial Number:</p>
            <input type="text" id="serialNumber" name="serialNumber" [(ngModel)]="serialNumber" class="input" required>
          </div>

          <div class="input-group">
            <p class="label" for="brandId">Brand ID:</p>
            <select id="brandId" name="brandId" [(ngModel)]="brandId" class="dropdown" required>
              <option value="1">DJI</option>
              <option value="2">VGG2454</option>
            </select>
          </div>

          <div class="input-group">
            <p class="label" for="modelId">Model ID:</p>
            <select id="brandId" name="modelId" [(ngModel)]="modelId" class="dropdown" required>
              <option value="1">Mavic 2</option>
              <option value="2">Mavic 2 pro</option>
              <option value="3">Inspire</option>
              <option value="4">Spark</option>
            </select>
          </div>

          <div class="input-group">
            <p class="label" for="uas_type">UAS Type:</p>
            <input type="text" id="uas_type" name="uas_type" [(ngModel)]="uas_type" class="input" required>
          </div>

          <div class="input-group">
            <p class="label" for="weight">Weight:</p>
            <input type="number" id="weight" name="weight" [(ngModel)]="weight" class="input" required>
          </div>

          <div class="input-group">
            <p class="label" for="length">Length:</p>
            <input type="number" id="length" name="length" [(ngModel)]="length" class="input" required>
          </div>

          <div class="input-group">
            <p class="label" for="wingspan">Wingspan:</p>
            <input type="number" id="wingspan" name="wingspan" [(ngModel)]="wingspan" class="input" required>
          </div>

          <div class="input-group">
            <p class="label" for="powerSource">Power Source:</p>
            <input type="text" id="powerSource" name="powerSource" [(ngModel)]="powerSource" class="input" required>
          </div>

          <div class="input-group">
            <p class="label" for="maxFlightDuration">Max Flight Duration:</p>
            <input type="number" id="maxFlightDuration" name="maxFlightDuration" [(ngModel)]="maxFlightDuration"
              class="input" required>
          </div>

          <div class="input-group">
            <p class="label" for="maxSpeed">Max Speed:</p>
            <input type="number" id="maxSpeed" name="maxSpeed" [(ngModel)]="maxSpeed" class="input" required>
          </div>

          <div class="input-group">
            <p class="label" for="maxHeightCapable">Max Height Capable:</p>
            <input type="number" id="maxHeightCapable" name="maxHeightCapable" [(ngModel)]="maxHeightCapable"
              class="input" required>
          </div>

          <div class="input-group">
            <p class="label" for="maxDistanceCapable">Max Distance Capable:</p>
            <input type="number" id="maxDistanceCapable" name="maxDistanceCapable" [(ngModel)]="maxDistanceCapable"
              class="input" required>
          </div>

          <div class="input-group">
            <p class="label" for="frequencies">Frequencies:</p>
            <input class="input" type="number" id="frequencies" name="frequencies" [(ngModel)]="frequencies" required>
          </div>

          <div class="input-group">
            <p class="label" for="powerOutout">Power Output:</p>
            <input class="input" type="number" id="powerOutout" name="powerOutout" [(ngModel)]="powerOutout" required>
          </div>

          <div class="input-group">
            <p class="label" for="documents">Documents:</p>
            <input class="input" type="text" id="documents" name="documents" [(ngModel)]="documents" required>
          </div>

          <button class="btn" type="submit">Register Drone</button>
        </form>
      </div>
    </div>
  </div>
</body>

</html>
