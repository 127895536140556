import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-weather-table',
  templateUrl: './weather-table.component.html',
  styleUrls: ['./weather-table.component.scss'],
})
export class WeatherTableComponent {
  @Input() weatherDataList: any;
  @Input() weatherLabel: any;
  @Output() sendHours = new EventEmitter<string>();
  weatherHours = 3;
  getHours(val: any) {
    this.sendHours.emit(val);
  }
}
