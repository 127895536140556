<div class="main-payment-container">
    <div class="container-fluid mt-10 m-20">
        <div class="row">
            <div class="col-3 mt-10">
                <div class="left-body">
                    <div class="header">
                        <div class="d-flex flex-fill align-items-center">
                            <!-- Image on the left -->
                            <div class="me-3">
                                <img [src]="'/images/arrow_back_24px.svg' | assetUrl" style="max-width: 100px;"
                                    style="cursor: pointer;">
                            </div>
                            <!-- Text on the right -->
                            <div class="" style="margin-top: -3px;">
                                <p class="header-txt">BACK</p>
                            </div>
                        </div>
                    </div>
                    <div class="notification">
                        <p>All notifications</p>

                        <div class="d-flex align-items-center">
                            <!-- Image on the left -->
                            <div>
                                <!-- Checkbox -->
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" id="mySwitch" name="darkmode"
                                        value="yes">
                                </div>
                            </div>

                            <!-- Text in the middle -->
                            <div class="flex-fill">
                                <p class="m-0">Unread only</p>
                            </div>

                            <!-- "Mark all as read" text at the end -->
                            <div class="all-read">
                                <p class="m-0">Mark all as read</p>
                            </div>
                        </div>


                    </div>

                    <div class="noti-body">

                        <!-- card -->
                        <div class="card">
                            <div class="row">
                                <!-- Column 1 -->
                                <div class="col-md-1">
                                    <span class="dot"></span>
                                </div>

                                <!-- Column 2 -->
                                <div class="col-md-1">
                                    <img [src]="'/icon/aerodyne.png' | assetUrl" style="max-width: 100px;">
                                </div>

                                <!-- Column 3 -->
                                <div class="col-md-10 ">
                                    <p class="head">Payment Received</p>
                                    <p class="sub">The fee for Application #AP000023003 has been paid by Aerodyne Group.
                                    </p>
                                    <span class="time">a second ago 4:42 PM </span>
                                </div>
                            </div>
                        </div>
                        <div class="card active">
                            <div class="row">
                                <!-- Column 1 -->
                                <div class="col-md-1">
                                    <span class="dot"></span>
                                </div>

                                <!-- Column 2 -->
                                <div class="col-md-1">
                                    <img [src]="'/icon/aerodyne.png' | assetUrl" style="max-width: 100px;">
                                </div>

                                <!-- Column 3 -->
                                <div class="col-md-10 ">
                                    <p class="head">New Application</p>
                                    <p class="sub">New Application #AP000023003 awaiting for the approval.
                                    </p>
                                    <span class="time">a second ago 4:42 PM </span>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="row">
                                <!-- Column 1 -->
                                <div class="col-md-1">
                                    <span class="dot"></span>
                                </div>

                                <!-- Column 2 -->
                                <div class="col-md-1">
                                    <img [src]="'/icon/aerodyne.png' | assetUrl" style="max-width: 100px;">
                                </div>

                                <!-- Column 3 -->
                                <div class="col-md-10 ">
                                    <p class="head">Payment Received</p>
                                    <p class="sub">The fee for Application #AP000023003 has been paid by Aerodyne Group.
                                    </p>
                                    <span class="time">30 minutes ago 5:42 PM </span>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="row">
                                <!-- Column 1 -->
                                <div class="col-md-1">
                                    <span class="dot"></span>
                                </div>

                                <!-- Column 2 -->
                                <div class="col-md-1">
                                    <img [src]="'/icon/aerodyne.png' | assetUrl" style="max-width: 100px;">
                                </div>

                                <!-- Column 3 -->
                                <div class="col-md-10 ">
                                    <p class="head">Payment Received</p>
                                    <p class="sub">The fee for Application #AP000023003 has been paid by Aerodyne Group.
                                    </p>
                                    <span class="time">a second ago 4:42 PM </span>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="row">
                                <!-- Column 1 -->
                                <div class="col-md-1">
                                    <span class="dot"></span>
                                </div>

                                <!-- Column 2 -->
                                <div class="col-md-1">
                                    <img [src]="'/icon/aerodyne.png' | assetUrl" style="max-width: 100px;">
                                </div>

                                <!-- Column 3 -->
                                <div class="col-md-10 ">
                                    <p class="head">Payment Received</p>
                                    <p class="sub">The fee for Application #AP000023003 has been paid by Aerodyne Group.
                                    </p>
                                    <span class="time">a second ago 4:42 PM </span>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="row">
                                <!-- Column 1 -->
                                <div class="col-md-1">
                                    <span class="dot"></span>
                                </div>

                                <!-- Column 2 -->
                                <div class="col-md-1">
                                    <img [src]="'/icon/aerodyne.png' | assetUrl" style="max-width: 100px;">
                                </div>

                                <!-- Column 3 -->
                                <div class="col-md-10 ">
                                    <p class="head">Payment Received</p>
                                    <p class="sub">The fee for Application #AP000023003 has been paid by Aerodyne Group.
                                    </p>
                                    <span class="time">a second ago 4:42 PM </span>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="row">
                                <!-- Column 1 -->
                                <div class="col-md-1">
                                    <span class="dot"></span>
                                </div>

                                <!-- Column 2 -->
                                <div class="col-md-1">
                                    <img [src]="'/icon/aerodyne.png' | assetUrl" style="max-width: 100px;">
                                </div>

                                <!-- Column 3 -->
                                <div class="col-md-10 ">
                                    <p class="head">Payment Received</p>
                                    <p class="sub">The fee for Application #AP000023003 has been paid by Aerodyne Group.
                                    </p>
                                    <span class="time">a second ago 4:42 PM </span>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="row">
                                <!-- Column 1 -->
                                <div class="col-md-1">
                                    <span class="dot"></span>
                                </div>

                                <!-- Column 2 -->
                                <div class="col-md-1">
                                    <img [src]="'/icon/aerodyne.png' | assetUrl" style="max-width: 100px;">
                                </div>

                                <!-- Column 3 -->
                                <div class="col-md-10 ">
                                    <p class="head">Payment Received</p>
                                    <p class="sub">The fee for Application #AP000023003 has been paid by Aerodyne Group.
                                    </p>
                                    <span class="time">a second ago 4:42 PM </span>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="row">
                                <!-- Column 1 -->
                                <div class="col-md-1">
                                    <span class="dot"></span>
                                </div>

                                <!-- Column 2 -->
                                <div class="col-md-1">
                                    <img [src]="'/icon/aerodyne.png' | assetUrl" style="max-width: 100px;">
                                </div>

                                <!-- Column 3 -->
                                <div class="col-md-10 ">
                                    <p class="head">Payment Received</p>
                                    <p class="sub">The fee for Application #AP000023003 has been paid by Aerodyne Group.
                                    </p>
                                    <span class="time">a second ago 4:42 PM </span>
                                </div>
                            </div>
                        </div>

                    </div>






                </div>
            </div>
            <div class="col-9 mt-10">
                <div class="right-body">
                    <div class="header">
                        <div class="d-flex align-items-center">
                            <!-- Image on the left -->
                            <div class="me-3">
                                <img [src]="'/icon/aerodyne-green.png' | assetUrl" style="max-width: 100px;"
                                    style="cursor: pointer;">
                            </div>
                            <!-- Text in the middle -->
                            <div class="flex-fill">
                                <p class="header-txt">Notification Details</p>
                            </div>
                            <!-- "Search Status" image at the end -->
                            <div>
                                <img [src]="'/icon/search-status.png' | assetUrl">
                            </div>
                        </div>

                    </div>

                    <!-- card -->
                    <div class="max-right">
                        <div class="card">
                            <div class="row">
                                <div class="col-md-1">
                                    <img [src]="'/icon/aerodyne.png' | assetUrl" style="max-width: 100px;">
                                </div>
                                <div class="col-md-9">
                                    <p class="name">Mukmin bin Aminuddin<span> a second ago 4:42 PM</span></p>
                                    <p class="details">New Application #AP000023003</p>

                                    <div class="row txt-details">
                                        <div class="col-md-6">
                                            <p>New Application #AP000023003 awaiting for the approval.</p>
                                            <p>The following is a detailed description for a permit application for the
                                                construction of a new residential building in a suburban area. The
                                                purpose of this application is to obtain the necessary permissions and
                                                approvals from the local authorities to proceed with the proposed
                                                project. The proposed residential building is a three-story structure
                                                with a total floor area of approximately 5,000 square feet. It is
                                                designed to accommodate six residential units, each consisting of two
                                                bedrooms, a living room, a kitchen, and a bathroom. The building will be
                                                constructed using high-quality materials and will adhere to all relevant
                                                building codes and regulations.</p>
                                        </div>
                                        <div class="col-md-6">
                                            <!-- call map component -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="row">
                                <div class="col-md-1">
                                    <img [src]="'/icon/aerodyne.png' | assetUrl" style="max-width: 100px;">
                                </div>
                                <div class="col-md-9">
                                    <p class="name">Mukmin bin Aminuddin<span> a second ago 4:42 PM</span></p>
                                    <p class="details">New Application #AP000023003</p>

                                    <div class="row txt-details">
                                        <div class="col-md-6">
                                            <p>New Application #AP000023003 awaiting for the approval.</p>
                                            <p>The following is a detailed description for a permit application for the
                                                construction of a new residential building in a suburban area. The
                                                purpose of this application is to obtain the necessary permissions and
                                                approvals from the local authorities to proceed with the proposed
                                                project. The proposed residential building is a three-story structure
                                                with a total floor area of approximately 5,000 square feet. It is
                                                designed to accommodate six residential units, each consisting of two
                                                bedrooms, a living room, a kitchen, and a bathroom. The building will be
                                                constructed using high-quality materials and will adhere to all relevant
                                                building codes and regulations.</p>
                                        </div>
                                        <div class="col-md-6">
                                            <!-- call map component -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="row">
                                <div class="col-md-1">
                                    <img [src]="'/icon/aerodyne.png' | assetUrl" style="max-width: 100px;">
                                </div>
                                <div class="col-md-9">
                                    <p class="name">Mukmin bin Aminuddin<span> a second ago 4:42 PM</span></p>
                                    <p class="details">New Application #AP000023003</p>

                                    <div class="row txt-details">
                                        <div class="col-md-6">
                                            <p>New Application #AP000023003 awaiting for the approval.</p>
                                            <p>The following is a detailed description for a permit application for the
                                                construction of a new residential building in a suburban area. The
                                                purpose of this application is to obtain the necessary permissions and
                                                approvals from the local authorities to proceed with the proposed
                                                project. The proposed residential building is a three-story structure
                                                with a total floor area of approximately 5,000 square feet. It is
                                                designed to accommodate six residential units, each consisting of two
                                                bedrooms, a living room, a kitchen, and a bathroom. The building will be
                                                constructed using high-quality materials and will adhere to all relevant
                                                building codes and regulations.</p>
                                        </div>
                                        <div class="col-md-6">
                                            <!-- call map component -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="row">
                                <div class="col-md-1">
                                    <img [src]="'/icon/aerodyne.png' | assetUrl" style="max-width: 100px;">
                                </div>
                                <div class="col-md-9">
                                    <p class="name">Mukmin bin Aminuddin<span> a second ago 4:42 PM</span></p>
                                    <p class="details">New Application #AP000023003</p>

                                    <div class="row txt-details">
                                        <div class="col-md-6">
                                            <p>New Application #AP000023003 awaiting for the approval.</p>
                                            <p>The following is a detailed description for a permit application for the
                                                construction of a new residential building in a suburban area. The
                                                purpose of this application is to obtain the necessary permissions and
                                                approvals from the local authorities to proceed with the proposed
                                                project. The proposed residential building is a three-story structure
                                                with a total floor area of approximately 5,000 square feet. It is
                                                designed to accommodate six residential units, each consisting of two
                                                bedrooms, a living room, a kitchen, and a bathroom. The building will be
                                                constructed using high-quality materials and will adhere to all relevant
                                                building codes and regulations.</p>
                                        </div>
                                        <div class="col-md-6">
                                            <!-- call map component -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>