<div class="main-payment-container">
    <div class="container-fluid mt-10 m-20">
        <!-- <div class="d-flex justify-content-between mt-10 mb-10"> -->
            <!-- Content aligned to the left -->
            <div>
                <div class="ml-3 left-header">
                    <p class="header-txt mt-10">PAYMENT APPROVAL</p>
                    <span>Track, manage and approve the payment.</span>
                </div>
            </div>
            <div class="row mt-10 mb-10" style="margin-top: 30px;">
                <!-- Content aligned to the left -->
                <div class="col-12">
                    <div class="tab-content" style="width: 100%;">
                        <ng-container>
                            <app-utm-list-all [permitList]="permitList" (refresh)="getAllPermits()"></app-utm-list-all>
                        </ng-container>
                    </div>
                </div>
            </div>

        <!-- </div> -->
    </div>
</div>
