import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateWidth',
})
export class CalculateWidthPipe implements PipeTransform {
  transform(value: number): string {
    return `calc((78.8vw / 20) * ${value})`;
  }
}
