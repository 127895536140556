import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-utm-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {

  showToast = false;
  toastTimeout: any;
  @Input() message = '';
  @Input() state = '';

  displayToast() {
    this.showToast = true;
    this.toastTimeout = setTimeout(() => {
      this.closeToast();
    }, 3000);  // The toast will hide after 3 seconds (3000ms)
  }

  closeToast() {
    this.showToast = false;
    if (this.toastTimeout) {
      clearTimeout(this.toastTimeout);
    }
  }
}
