import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobeComponent } from './globe/globe.component';
import { ProfileComponent } from './profile/profile.component';
import { MissionComponent } from './mission/mission.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SampleComponent } from './sample/sample.component';
import { PilotIndexComponent } from './pilot-management/pilot-index/pilot-index.component';
import { DroneIndexComponent } from './drone-management/drone-index/drone-index.component';
import { PilotCreateEditComponent } from './pilot-management/pilot-create-edit/pilot-create-edit.component';
import { PilotDetailComponent } from './pilot-management/pilot-detail/pilot-detail.component';
import { DroneCreateEditComponent } from './drone-management/drone-create-edit/drone-create-edit.component';
import { DroneDetailComponent } from './drone-management/drone-detail/drone-detail.component';
import { ContextmenuComponent } from './contextmenu/contextmenu.component';
import { ContextbuttonComponent } from './contextbutton/contextbutton.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MasterWeatherComponent } from './master-weather/master-weather.component';
import { ElavationComponent } from './master-weather/elavation/elavation.component';
import { WeatherTableComponent } from './master-weather/weather-table/weather-table.component';
import { AirspaceInformaticsComponent } from './airspace-informatics/airspace-informatics.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { NavigationToolbarComponent } from './navigation-toolbar/navigation-toolbar.component';
import { ViewMissionComponent } from './view-mission/view-mission.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ObjectiveComponent } from './objective/objective.component';
import { LivestreamsComponent } from './livestreams/livestreams.component';
import { MapSettingComponent } from './map-setting/map-setting.component';
import { httpCustomInterceptor } from 'fe-micro-share-components';
import { CompleteProfileComponent } from './Components/complete-profile-alert/complete-profile.component';
import { CreateMissionComponent } from './Components/create-mission/create-mission.component';
import { MissionDetailComponent } from './Components/mission-detail/mission-detail.component';
import { ToasterCustomComponent } from './Components/toaster-custom/toaster-custom.component';
import { UploadFilesViewComponent } from './Components/upload-files-view/upload-files-view.component';
import { UploadNewDatasetComponent } from './Components/upload-new-dataset/upload-new-dataset.component';
import { TelemetryComponent } from './telemetry/telemetry.component';
import { DeletePermitComponent } from './modal-contents/delete-permit/delete-permit.component';
import { ViewPermitComponent } from './modal-contents/view-permit/view-permit.component';
import { ModalLiveComponent } from './modal-contents/modal-live/modal-live.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { PermitComponent } from './permit/permit.component';

import { PermitApplicationsComponent } from './permit-applications/permit-applications.component';
import { ListAllComponent } from './permit-applications/list-all/list-all.component';

import { TelecompassComponent } from './telecompass/telecompass.component';
import { TerraweatherComponent } from './terraweather/terraweather.component';
import { NotificationComponent } from './notification/notification.component';
import { ApplicationTrackerComponent } from './application-tracker/application-tracker.component';
import { MissionListComponent } from './application-tracker/mission-list/mission-list.component';
import { AttachmentComponent } from './application-tracker/attachment/attachment.component';
import { PaymentApprovalComponent } from './payment-approval/payment-approval.component';
import { ViewReceiptComponent } from './modal-contents/view-receipt/view-receipt.component';
// import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { IssuePermitComponent } from './modal-contents/issue-permit/issue-permit.component';
import { EnvironmentService } from 'src/app/services/environment.service';
import { ToastComponent } from './toast/toast.component';

import { UploadProgressbarComponent } from './Components/upload-progressbar/upload-progressbar.component';
import { MissionDetailsComponent } from './modal-contents/mission-details/mission-details.component';
import { DropPinComponent } from './drop-pin/drop-pin.component';
import { MaximumAltitudeComponent } from './modal-contents/maximum-altitude/maximum-altitude.component';
import { InventoryComponent } from './inventory/inventory.component';
import { ViewPdfComponent } from './modal-contents/view-pdf/view-pdf.component';
import { SafeUrlPipe } from './safe-url.pipe';
import { DroneListComponent } from './inventory/drone-list/drone-list.component';
import { PayloadListComponent } from './inventory/payload-list/payload-list.component';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { AddDroneComponent } from './modal-contents/add-drone/add-drone.component';
import { AddPayloadComponent } from './modal-contents/add-payload/add-payload.component';
import { DroneDetailsComponent } from './modal-contents/drone-details/drone-details.component';
import { PayloadDetailsComponent } from './modal-contents/payload-details/payload-details.component';
import { DeleteDroneComponent } from './modal-contents/delete-drone/delete-drone.component';
import { DeletePayloadComponent } from './modal-contents/delete-payload/delete-payload.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { MissionSummaryComponent } from './mission-summary/mission-summary.component';
import { SharedModule } from './shared/shared.module';
import { WarningModalComponent } from './modules/user-schedule/warning-modal/warning-modal.component';
import { NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GenerateUrlComponent } from './modal-contents/generate-url/generate-url/generate-url.component';
import { MultiSelectDropdownComponent } from './Components/multi-select-dropdown/multi-select-dropdown.component';

// import { _getUserRole } from './state-management/permit/permit.reducer'


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    GlobeComponent,
    ProfileComponent,
    MissionComponent,
    NavbarComponent,
    PilotIndexComponent,
    PilotCreateEditComponent,
    PilotDetailComponent,
    DroneIndexComponent,
    DroneCreateEditComponent,
    DroneDetailComponent,
    SampleComponent,
    ContextmenuComponent,
    ContextbuttonComponent,
    MasterWeatherComponent,
    ElavationComponent,
    WeatherTableComponent,
    AirspaceInformaticsComponent,
    EmptyRouteComponent,
    CreateMissionComponent,
    UploadNewDatasetComponent,
    MissionDetailComponent,
    NavigationToolbarComponent,
    UploadFilesViewComponent,
    ViewMissionComponent,
    ToasterCustomComponent,
    CompleteProfileComponent,
    ObjectiveComponent,
    LivestreamsComponent,
    MapSettingComponent,
    TelemetryComponent,
    DeletePermitComponent,
    ViewPermitComponent,
    ModalLiveComponent,
    InvoiceComponent,
    PermitApplicationsComponent,
    ListAllComponent,
    TelecompassComponent,
    TerraweatherComponent,
    NotificationComponent,
    ApplicationTrackerComponent,
    MissionListComponent,
    AttachmentComponent,
    PaymentApprovalComponent,
    MissionSummaryComponent,
    ViewReceiptComponent,
    IssuePermitComponent,
    ToastComponent,
    UploadProgressbarComponent,
    MissionDetailsComponent,
    DropPinComponent,
    MaximumAltitudeComponent,
    InventoryComponent,
    ViewPdfComponent,
    SafeUrlPipe,
    DroneListComponent,
    PayloadListComponent,
    AddDroneComponent,
    AddPayloadComponent,
    DroneDetailsComponent,
    PayloadDetailsComponent,
    DeleteDroneComponent,
    DeletePayloadComponent,
    VideoPlayerComponent,
    WarningModalComponent,
    PermitComponent,
    GenerateUrlComponent,
    MultiSelectDropdownComponent,
    // ProgressToastComponent,
    // Toast2Component,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxPaginationModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    // ToastrModule.forRoot(),
    NgxDropzoneModule,
    NgbTooltipModule,
    NgbModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: httpCustomInterceptor,
      multi: true,
    },
    EnvironmentService,
  ],
  bootstrap: [AppComponent],
  // exports: [ContextbuttonComponent, GlobeComponent]
})
export class AppModule {
  testVar: string = 'test'
}
