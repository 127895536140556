import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appInlineEditable]',
})
export class InlineEditableDirective {
  @Output() changeTitle: EventEmitter<string> = new EventEmitter<string>();
  currentTitle!: string;
  constructor(private elementRef: ElementRef) {}

  @HostListener('dblclick') onClick() {
    this.currentTitle = this.elementRef.nativeElement.innerText;
    this.elementRef.nativeElement.contentEditable = 'true';
    this.elementRef.nativeElement.focus();

    // document.execCommand('selectAll');

    var selection = window.getSelection()!;
    var range = document.createRange();
    range.selectNodeContents(this.elementRef.nativeElement);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  @HostListener('blur') onBlur() {
    const updatedText = this.elementRef.nativeElement.innerText;
    this.elementRef.nativeElement.contentEditable = 'false';
    (!updatedText || updatedText == 'MISSION UNTITLED') && (this.elementRef.nativeElement.innerText = this.currentTitle);
    updatedText && updatedText != 'MISSION UNTITLED' && this.changeTitle.emit(updatedText);
  }
}
