import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'calculateSlot',
})
export class CalculateSlotPipe implements PipeTransform {
  transform(slot: any, timeObj: any, type: string = 'display'): any {
    if (type === 'startIndex') {
      return slot.startTime === timeObj.time ? timeObj?.value : '';
    } else {
      const startTime = moment(slot.startTime, 'h:mm A');
      const endTime = moment(slot.endTime, 'h:mm A');

      const timeDifferenceInMilliseconds = endTime.diff(startTime);
      let timeDifferenceInHours = moment.duration(timeDifferenceInMilliseconds).asHours();
      const timeDifference = moment.duration(endTime.diff(startTime));

      if (timeDifferenceInHours < 0) {
        timeDifferenceInHours = 24 - -timeDifferenceInHours;
      }

      return timeDifferenceInHours * 2;
    }
  }
}
