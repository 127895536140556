<div>
    <div oncontextmenu="return false;">
        <div id="map"></div>

        <div *ngIf="contextmenu == true">
            <app-contextmenu [x]="contextmenuX" [y]="contextmenuY" (click)="showDiv.previous = !showDiv.previous"></app-contextmenu>
        </div>
    </div>
    <app-mission *ngIf="showDiv.previous"></app-mission>
    <app-utm-telecompass *ngIf="compassOn" [compassData]="compassData"></app-utm-telecompass>
    <app-utm-livestreams></app-utm-livestreams>
    <app-utm-video-player></app-utm-video-player>
    <app-utm-terraweather *ngIf="WeatherOn"></app-utm-terraweather>
    <!-- <app-site-card></app-site-card> -->
    <ng-template #mission let-modal>
        <div class="modal-header"></div>
        <div class="modal-body"></div>
        <div class="modal-footer"></div>
    </ng-template>
    <ng-container>
        <app-utm-map-setting></app-utm-map-setting>
        <app-utm-telemetry *ngIf="isLivestream"></app-utm-telemetry>
    </ng-container>
    <!-- <app-contextbutton class="contextbutton_selector"></app-contextbutton> -->

    <app-navigation-toolbar class="navigation_toolbar"></app-navigation-toolbar>

    <!-- <app-create-mission></app-create-mission> -->
    <div id="objectiveBar">
        <app-objective (isDoneLoad)="finishLoad($event)"></app-objective>
    </div>

    <ng-container class="altitude" *ngIf="createMission">
        <app-utm-drop-pin [modalState]="this.missionMethod" [distance]="this.missionAreaDistance" [area]="this.missionAreaArea" [volume]="this.missionAreaVolume"></app-utm-drop-pin>
    </ng-container>

    <ng-container class="app-airspace-informatics" *ngIf="airspaceInformatics">
        <app-airspace-informatics (airSpaceInformatics)="airSpaceInformatics($event)" [featureProperties]="featureProperties"></app-airspace-informatics>
    </ng-container>
</div>

<div *ngIf="isLoad" class="loading-bg">
    <div class="spinner-container d-flex">
        <div class="spinner-grow spinner-icon" role="status"></div>
        <span class="ml-3" style="color: white">Redirecting to Scheduler...</span>
    </div>
</div>