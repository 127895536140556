import { Component } from '@angular/core';
import { VideoService } from '../video.service';
import { LiveService } from '../live.service';
import { GlobeComponent } from '../globe/globe.component';
import { IMissionResponse } from '../shared/interface/mission.interface';
import { DroneService } from '../services/drone.service';
import { forkJoin, tap } from 'rxjs';
import { IDrone } from '../models/drone.model';

@Component({
  selector: 'app-utm-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  providers: [VideoPlayerComponent],
})
export class VideoPlayerComponent {
  videoSource = '';
  componentVisible = false; // Initialize to false

  filteredData: any[] = []; // Store the filtered and aggregated data
  droneSN: string = ''; // Define droneSN as a class property
  droneDate: string = ''; // Define droneDate as a class property

  constructor(private video: VideoService, private playback: LiveService, private globe: GlobeComponent, private droneSer: DroneService) {
    this.playback.addVideoPlaybackEvent.subscribe((e: any) => {
      this.triggerPlay(e);
    });
  }

  triggerPlay(e: IMissionResponse) {
    // this.droneSN = e.schedules[0].drones[0].droneId.serialNumber; // Assign droneSN to the class property
    const droneDataApi = e.scheduleIds[0].droneList.map((data) => this.droneSer.get(data));
    let droneList: IDrone[];
    forkJoin(droneDataApi)
      .pipe(
        tap((data) => {
          droneList = data;
          this.droneSN = data[0]?.serialNumber || '';
          this.pathMapTele(this.droneSN);
        }),
      )
      .subscribe();
    // this.droneSN = droneList[0]?.serialNumber; // Assign droneSN to the class property
    // this.droneDate = e.schedules[0].startDate; // Assign droneDate to the class property
    this.droneDate = e.scheduleIds[0].startDate;
    // this.pathMapTele(this.droneSN);

    this.componentVisible = true;

    this.video.getVideoList().subscribe((data: any) => {
      // const missionId = e.missionId;
      const missionId = e.scheduleIds[0].missionId;
      const matchingVideo = data.find((videoItem: any) => videoItem.url.includes(missionId));

      if (matchingVideo) {
        this.videoSource = matchingVideo.url;
      } else {
        console.log(`No video found for missionId: ${missionId}`);
      }
    });

    const videoList = this.video.getVideoList();
  }

  pathMapTele(e: any) {
    this.video.getTelemtryPath(e).subscribe((i: any) => {
      this.filteredData = this.filterAndAggregate(i);
    });
  }

  filterAndAggregate(data: any[]): any[] {
    // Filter and aggregate data based on your criteria
    const filteredData = data.filter((item) => {
      return (
        item.topic.includes(this.droneSN) && // Access droneSN using this keyword
        new Date(item.createdAt) >= new Date(this.droneDate) // Access droneDate using this keyword
      );
    });

    // Modify this part based on your aggregation logic
    const aggregatedData = filteredData.map((item) => ({
      type: 'Feature',
      properties: {
        topic: item.topic,
        createdAt: item.createdAt,
      },
      geometry: {
        type: 'Point',
        coordinates: [item.payload.longitude, item.payload.latitude],
      },
    }));

    const aggregatedData2 = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: filteredData.map((item) => [item.payload.longitude, item.payload.latitude]),
      },
      properties: {
        topic: filteredData[0]?.topic || '', // You can use the topic from the first item in filteredData
        createdAt: filteredData[0]?.createdAt, // You can use the createdAt from the first item in filteredData
      },
    };

    this.globe.loadPathTele(aggregatedData2);
    return aggregatedData;
  }
}
