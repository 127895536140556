import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DroneService } from 'src/app/services/drone.service';

@Component({
  selector: 'app-utm-delete-drone',
  templateUrl: './delete-drone.component.html',
  styleUrls: ['./delete-drone.component.scss'],
})
export class DeleteDroneComponent {
  @Input() id: any;
  constructor(private aModalService: NgbActiveModal, private droneService: DroneService) {}

  ngOnInit() {
  }

  closeModal() {
    this.aModalService.close();
  }

  confirmDelete(id: any) {
    this.droneService.delete(id).subscribe(
      (response) => {
        console.log('Drone deleted:', response);
      },
      (error) => {
        console.error('Error deleting drone:', error);
      },
    );

    this.aModalService.close('confirm');
  }
}
