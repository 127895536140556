import { Injectable } from '@angular/core';
// import * as mqtt from 'mqtt';
// import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BasixService {
  private uploadedUrls: string[] = [];

  urlPush=[]

  setUploadedUrls(urls: string[]) {
    this.uploadedUrls = urls;
  }

  getUploadedUrls() {
    return this.uploadedUrls;
  }
 

}
