<div class="map_setting_container" [ngClass]="[on_nav_tap === 'mapsetting' ? 'display-block' : 'display-none']">
  <div class="setting">
    <div class="setting_head">
      <p>map settings</p>
      <img [src]="'/sidebar/arrow-left.svg' | assetUrl" alt="arrow-left" (click)="on_nav_tap = 0" />
    </div>
    <hr class="setting_bottom_border" />

    <div class="map_tap_container">
      <div class="map_tap">
        <p (click)="on_mapstyle_tap = 1" [ngClass]="{ map_tap_active: on_mapstyle_tap == 1 }">Layers</p>
        <p (click)="on_mapstyle_tap = 3" [ngClass]="{ map_tap_active: on_mapstyle_tap == 3 }">Style</p>
      </div>
      <div class="sites_search_box"></div>
      <div class="map_tap_content">
        <div class="map_layer" [ngClass]="[on_mapstyle_tap == 1 ? 'display-block' : 'display-none']">>
          <div class="map_layer_content" style="height: 75vh; overflow: auto;">
            <div class="airspace_box dropdownBox">
              <div class="airspace_head" (click)="dropdown($event)">
                <img [src]="'/sidebar/arrow-right.svg' | assetUrl" alt="arrow-right" />
                <h6>AIRSPACE</h6>
              </div>
              <div class="airspace_dropdown">
                <div class="airspace_sub_head_item_container_out">
                  <div class="airspace_sub_head_item">
                     <p><svg xmlns="http://www.w3.org/2000/svg" width="4" height="16" viewBox="0 0 4 16" fill="none">
                      <path d="M0 2C0 0.89543 0.895431 0 2 0C3.10457 0 4 0.89543 4 2V14C4 15.1046 3.10457 16 2 16C0.895431 16 0 15.1046 0 14V2Z" fill="#5CEF85"/>
                    </svg>Class A</p>
                    <img [src]="'/sidebar/eye-slash.png' | assetUrl" id="CLASS_A.kmz" alt="eye" (click)="onChangeEyeIcon($event)" />
                  </div>
                  <div class="airspace_sub_head_item">
                    <p><svg xmlns="http://www.w3.org/2000/svg" width="4" height="16" viewBox="0 0 4 16" fill="none">
                      <path d="M0 2C0 0.89543 0.895431 0 2 0C3.10457 0 4 0.89543 4 2V14C4 15.1046 3.10457 16 2 16C0.895431 16 0 15.1046 0 14V2Z" fill="#8CCAFB"/>
                    </svg>Class B</p>
                    <img [src]="'/sidebar/eye-slash.png' | assetUrl" id="CLASS_B.kmz" alt="eye" (click)="onChangeEyeIcon($event)" />
                  </div>
                  <div class="airspace_sub_head_item">
                    <p><svg xmlns="http://www.w3.org/2000/svg" width="4" height="16" viewBox="0 0 4 16" fill="none">
                      <path d="M0 2C0 0.89543 0.895431 0 2 0C3.10457 0 4 0.89543 4 2V14C4 15.1046 3.10457 16 2 16C0.895431 16 0 15.1046 0 14V2Z" fill="#3C44FF"/>
                    </svg>Class C</p>
                    <img [src]="'/sidebar/eye-slash.png' | assetUrl" id="CLASS_C.kmz" alt="eye" (click)="onChangeEyeIcon($event)" />
                  </div>
                  <div class="airspace_sub_head_item">
                    <p><svg xmlns="http://www.w3.org/2000/svg" width="4" height="16" viewBox="0 0 4 16" fill="none">
                      <path d="M0 2C0 0.89543 0.895431 0 2 0C3.10457 0 4 0.89543 4 2V14C4 15.1046 3.10457 16 2 16C0.895431 16 0 15.1046 0 14V2Z" fill="#FFFF05"/>
                    </svg>Danger Area</p>
                    <img [src]="'/sidebar/eye-slash.png' | assetUrl" id="WM(D).kmz" alt="eye" (click)="onChangeEyeIcon($event)" />
                  </div>
                  <div class="airspace_sub_head_item">
                    <p><svg xmlns="http://www.w3.org/2000/svg" width="4" height="16" viewBox="0 0 4 16" fill="none">
                      <path d="M0 2C0 0.89543 0.895431 0 2 0C3.10457 0 4 0.89543 4 2V14C4 15.1046 3.10457 16 2 16C0.895431 16 0 15.1046 0 14V2Z" fill="#D19721"/>
                    </svg>Prohibited Area</p>
                    <img [src]="'/sidebar/eye-slash.png' | assetUrl" id="WM(P).kmz" alt="eye" (click)="onChangeEyeIcon($event)" />
                  </div>
                  <div class="airspace_sub_head_item">
                    <p><svg xmlns="http://www.w3.org/2000/svg" width="4" height="16" viewBox="0 0 4 16" fill="none">
                      <path d="M0 2C0 0.89543 0.895431 0 2 0C3.10457 0 4 0.89543 4 2V14C4 15.1046 3.10457 16 2 16C0.895431 16 0 15.1046 0 14V2Z" fill="#FF0033"/>
                    </svg>Restricted Area</p>
                    <img [src]="'/sidebar/eye-slash.png' | assetUrl" id="WM(R).kmz" alt="eye" (click)="onChangeEyeIcon($event)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="map_zone" [ngClass]="[on_mapstyle_tap == 2 ? 'display-block' : 'display-none']"></div>
        <div class="map_style" [ngClass]="[on_mapstyle_tap == 3 ? 'display-block' : 'display-none']">
          <div class="map_style_box">
            <p>MAP STYLE</p>
            <div class="map_list">
              <div class="map_img_box" (click)="OnClickStyle('lightMode')">
                <img [src]="'/sidebar/light-mode.png' | assetUrl" alt="light_mode" />
                <p>Light mode</p>
              </div>
              <div class="map_img_box" (click)="OnClickStyle('darkMode')">
                <img [src]="'/sidebar/dark-mode.png' | assetUrl" alt="dark_mode" />
                <p>Dark mode</p>
              </div>
              <div class="map_img_box" (click)="OnClickStyle('satelliteMode')">
                <img [src]="'/sidebar/satellite.png' | assetUrl" alt="satellite" />
                <p>Satellite</p>
              </div>
            </div>
          </div>
          <div class="map_details_box">
            <p>MAP DETAILS</p>
            <div>
              <div class="map_img_box" (click)="OnClickTerrain()">
                <img [src]="'/sidebar/satellite.png' | assetUrl" alt="terrain" />
                <p>Terrain</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
