import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment.service'; 
import { IMember, IMemberFilter } from '../models/member.model';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl() + '/account/members';
const headers = new HttpHeaders({
    Authorization: 'Bearer ' + environmentService.getToken(),
  });

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(private http: HttpClient) {}
  
  getAll(queryParams?: IMemberFilter): Observable<{ records: IMember[]; meta: { nextCursor: string; prevCursor: string } }> {
    return this.http.get<{ records: IMember[]; meta: { nextCursor: string; prevCursor: string } }>(apiUrl, { headers: headers, params: { ...queryParams } });
  }

  get(id: string): Observable<IMember> {
    return this.http.get<IMember>(`${apiUrl}/${id}`, { headers : headers });
  }

  update(id: string, data: Partial<IMember>): Observable<IMember> {
    return this.http.put<IMember>(`${apiUrl}/${id}`, data);
  }
}
