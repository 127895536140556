import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PayloadService } from 'src/app/services/payload.service';

@Component({
  selector: 'app-utm-delete-payload',
  templateUrl: './delete-payload.component.html',
  styleUrls: ['./delete-payload.component.scss'],
})
export class DeletePayloadComponent {
  @Input() id: any;
  constructor(private aModalService: NgbActiveModal, private payloadService: PayloadService) {}

  ngOnInit() {
  }

  closeModal() {
    this.aModalService.close();
  }

  confirmDelete(id: any) {
    this.payloadService.delete(id).subscribe(
      (response) => {
        console.log('Payload deleted:', response);
      },
      (error) => {
        console.error('Error deleting payload:', error);
      },
    );

    this.aModalService.close('confirm');
  }
}
