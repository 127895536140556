import { Component } from '@angular/core';

@Component({
  selector: 'app-utm-view-permit',
  templateUrl: './view-permit.component.html',
  styleUrls: ['./view-permit.component.scss']
})
export class ViewPermitComponent {

}
