<div class="header">
    <p>DRONE DETAILS</p>
    <!-- <img (click)="closeModal()" [src]="'/icon/icon_x.png' | assetUrl" alt="warning" class="icon-close"/> -->
</div>
<div class="body">
    <div class="row">
        <div class="col-md-6 d-flex">
            <div class="">
                <span class="badge badge-secondary">{{dronesData!.serialNumber}}</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="text-end" style="margin-top: 6px;">
                <img [src]="'/images/octicon_pencil-24.png' | assetUrl" style="margin-right: 5px;" />
                <span>Edit</span>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="row">
            <div class="col-md-6 text-box">
                <p>{{getDroneCatalog(dronesData!.catalogId)!.brand + ' ' + getDroneCatalog(dronesData!.catalogId)!.model}}</p>
                <span class="first">{{getDroneCatalog(dronesData!.catalogId)!.type}}</span>
                <span class="second">{{getDroneCatalog(dronesData!.catalogId)!.brand}} {{getDroneCatalog(dronesData!.catalogId)!.brand}}</span>
            </div>
            <div class="col-md-6">
                <div class="text-end">
                    <img [src]="'/images/drone.png' | assetUrl" style="margin-right: 5px;" class="image-drone" />

                </div>
            </div>
        </div>
    </div>

    <p class="head-section">Technical Specification</p>
    <hr>
    <div class="row body-section">
        <div class="col-6">
            <p>Main Gimbal</p>
            <span>DJI Gimbal</span>
        </div>
        <div class="col-6">
            <p>Sensor</p>
            <span>Zoom Camera</span>

        </div>
    </div>
    <div class="row body-section">
        <div class="col-6">
            <p>Weight</p>
            <span>678 g</span>
        </div>
        <div class="col-6">
            <p>Resolution</p>
            <span>17 um</span>

        </div>
    </div>
    <div class="row body-section">
        <p>Key Features</p>
    </div>
    <p class="head-section">Drone Compatibility</p>
    <hr>
    <p class="txt-drone">Camera</p>
    <div class="box-drone">
        <img [src]="'/images/drone-compat.png' | assetUrl" />
        <p>DJI M300 RTK</p>
    </div>
    <p (click)="deleteDrone()" class="txt-delete">Delete Drone</p>
</div>
<div class="footer">
    <button (click)="closeModal()" class=" btn-cancel">Cancel</button>
    <button type="submit" class=" btn-save">Update</button>
</div>