import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteDroneComponent } from '../delete-drone/delete-drone.component';
import { DroneService } from 'src/app/services/drone.service';
import { ICatalogDrone, IDrone } from 'src/app/models/drone.model';
import { getModel } from 'src/app/models/utils.mode';

@Component({
  selector: 'app-utm-drone-details',
  templateUrl: './drone-details.component.html',
  styleUrls: ['./drone-details.component.scss'],
})
export class DroneDetailsComponent {
  @Input() id: any;
  dronesData!: IDrone;
  constructor(private aModalService: NgbActiveModal, private modalService: NgbModal, private droneService: DroneService) {}

  ngOnInit() {
    this.getDronesById(this.id);
  }

  getDronesById(id: any) {
    this.droneService.get(id).subscribe(
      (response) => {
        this.dronesData = response;
      },
      (error) => {
        console.error('Error:', error);
      },
    );
  }

  closeModal() {
    this.aModalService.close();
  }

  deleteDrone() {
    const modalRef = this.modalService.open(DeleteDroneComponent, {
      centered: true,
      windowClass: 'add-new',
      backdropClass: 'add-new-static',
      backdrop: 'static',
    });
  }

  getDroneCatalog(catalogId: ICatalogDrone | string): ICatalogDrone | null {
    return getModel<ICatalogDrone>(catalogId);
  }
}
