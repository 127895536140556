<!DOCTYPE html>
<html>

<head>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@100;400&display=swap" rel="stylesheet">
  <script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script>
</head>

<body>
  <div class="grid-container">
    <app-navbar></app-navbar>
    <div class="form">
      <h1 class="title">DRONE DETAILS</h1>
      <div class="detail-content">
        <p>Serial Number: {{droneDetails.values}}</p>
      </div>
    </div>
  </div>
</body>

</html>
