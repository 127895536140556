import { Component } from '@angular/core';
import { weather_1H } from 'src/assets/master-weather/sample-weather-data';
@Component({
  selector: 'app-utm-terraweather',
  templateUrl: './terraweather.component.html',
  styleUrls: ['./terraweather.component.scss'],
})
export class TerraweatherComponent {
  weatherDataList = [
    {
      day: 'Monday, 27th February',
      hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      icon: ['sun', 'sun2', 'sun3', 'sun', 'sun', 'sun2', 'sun', 'sun3', 'sun', 'sun3', 'sun', 'sun2'],
      fog: ['32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°', '32°'],
      visibility: [1, 2, 3, 4, 5, 0, 0, 3, 4, 5, 2, 4],
    },
  ];
  weatherLabel = [
    {
      day: 'Day',
      hours: 'Hours',
      fog: 'Temperature',
      visibility: 'UV Index',
    },
  ];
  newWeatherData: any;
  ngOnInit() {
    const transformedData = this.weatherDataList[0].hours.map((hour, index) => ({
      hours: hour,
      icon: this.weatherDataList[0].icon[index],
      fog: this.weatherDataList[0].fog[index],
      visibility: this.weatherDataList[0].visibility[index],
    }));
    this.newWeatherData = transformedData;
  }
}
