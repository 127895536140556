import { AfterViewInit, Component } from '@angular/core';
import { Tooltip } from 'bootstrap';

@Component({
  selector: 'app-utm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  constructor() {
  }
  title = 'Aerodyne UTM';
  ngAfterViewInit(): void {
    setTimeout(() => {
      const tooltipTriggerList: any = document.querySelectorAll('[data-bs-toggle="utm-tooltip"]');
      const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
    }, 0);
    
  }
}
