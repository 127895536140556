<div class="col-12 slider">
  @for (objective of mappedObjectives$ | async; track objective) {
    <div class="objective_card" [ngStyle]="getBackgroundStyle(objective)" (click)="objectiveClicked(objective)">
      <div class="detail">
        <div class="detail_card">
          <div class="detail_title">
            <div class="name">{{ objective.name }}</div>
            <div class="tagrow">
              <div class="tags">
                @for (tag of objective.output; track tag) {
                  <span class="tag">{{ tag }}</span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</div>
