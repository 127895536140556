import { Component } from '@angular/core';
import { AddDroneComponent } from '../modal-contents/add-drone/add-drone.component';
import { AddPayloadComponent } from '../modal-contents/add-payload/add-payload.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-utm-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent {

  constructor(
    private modalService: NgbModal,
  ){}
   
  componentActive:any;

  selectedTab: string = 'drone';

  selectTab(tabName: string) {
    this.selectedTab = tabName;
  }

  addnew(activetab:any){
    this.componentActive = (activetab === "drone") ? AddDroneComponent : AddPayloadComponent;
    const modalRef = this.modalService.open(this.componentActive, {
      centered: true,
      windowClass: 'add-new',
      backdropClass: 'add-new-static',
      backdrop: 'static',
    });
    
  }

}
