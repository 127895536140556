<div [ngClass]="{'d-none': (!isCameraParamDone && !isDigitalTwinDone && !isDroneImgDone && !isWorkFlowStarted),'upload-progress-wrapper': (isCameraParamDone || isDigitalTwinDone || isDroneImgDone || isWorkFlowStarted)}">
  <ng-template [ngIf]="isCameraParamDone">
    <div class="upload-heading">
      <h3 class="title">Uploading Camera Parameters</h3>
      <span class="count">{{ cameraParamProgress.toFixed(0) }}%</span>
    </div>
    <div class="progress progress-holder">
      <div
        class="progress-bar show-progress"
        role="progressbar"
        [style.width]="cameraParamProgress.toFixed(0) + '%'"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="progressPercentage"
      ></div>
    </div>
  </ng-template>
  <ng-template [ngIf]="isDigitalTwinDone">
    <div class="upload-heading mt-3">
      <h3 class="title">Uploading Digital Twin</h3>
      <span class="count">{{ digitalTwinProgress.toFixed(0) }}%</span>
    </div>
    <div class="progress progress-holder">
      <div
        class="progress-bar show-progress"
        role="progressbar"
        [style.width]="digitalTwinProgress.toFixed(0) + '%'"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="progressPercentage"
      ></div>
    </div>
  </ng-template>
  <ng-template [ngIf]="isDroneImgDone">
    <div class="upload-heading mt-3">
      <h3 class="title">Uploading Drone Images</h3>
      <span class="count">{{ droneImgProgress.toFixed(0) }}%</span>
    </div>
    <div class="progress progress-holder">
      <div
        class="progress-bar show-progress"
        role="progressbar"
        [style.width]="droneImgProgress.toFixed(0) + '%'"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="progressPercentage"
      ></div>
    </div>
  </ng-template>
  <ng-template [ngIf]="isWorkFlowStarted">
    <div class="upload-heading">
      <h3 class="title">{{workflowSteps}}</h3>
      <span class="count">{{ workflowProgress.toFixed(0) }}%</span>
    </div>
    <div class="progress progress-holder">
      <div
        class="progress-bar show-progress"
        role="progressbar"
        [style.width]="workflowProgress.toFixed(0) + '%'"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="progressPercentage"
      ></div>
    </div>
  </ng-template>
</div>