import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError,catchError, BehaviorSubject} from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Routes } from "./routes";
import {ICreateMissionProject, ICreateMissionSiteData, ICreateMissionObjective, ICreateMissionPostData, ICreateMissionStatus} from '../models/create-mission-dataset.model';

@Injectable({
  providedIn: 'root'
})
export class CreateMissionService {
  missionSiteUrl?:string;
  missionObjectiveList:any;
  
  constructor(private httpClient: HttpClient) { }

  getMissionObjective() {
    return this.httpClient.get<ICreateMissionObjective[]>(Routes.MISSION_OBJ_URL).pipe(shareReplay());
  }

  getMissionProjects(): Observable<ICreateMissionProject> {
    return this.httpClient.get<ICreateMissionProject>(Routes.MISSION_PROJECT_URL).pipe(map(res => res));
  }

  getMissionSiteInfo(siteId:any): Observable<ICreateMissionSiteData[]> {
    this.missionSiteUrl = Routes.MISSION_SITE_URL+siteId;
    return this.httpClient.get<ICreateMissionSiteData[]>(this.missionSiteUrl).pipe(map(res => res));
  }

  postMissionData(data:any): Observable<ICreateMissionPostData[]> {
    return this.httpClient.post<ICreateMissionPostData[]>(Routes.CREATE_MISSION_URL,data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    )
  }

  updateMissionData(missionId:number,missionData:Object): Observable<any> {
    return this.httpClient.patch<any>(Routes.CREATE_MISSION_URL+"/"+missionId, missionData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    )
  }

  updateMissionStatus(missionId:number,missionStatus:any): Observable<ICreateMissionStatus[]> {
    return this.httpClient.patch<ICreateMissionStatus[]>(Routes.CREATE_MISSION_URL+"/"+missionId, missionStatus).pipe(
      catchError((err) => {
        return throwError(err);
      })
    )
  }

}
