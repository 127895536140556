import { Component } from '@angular/core';



// import { PaymentCardComponent } from 'src/app/modal-contents/payment-card/payment-card.component';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ObservableInput, forkJoin } from 'rxjs';
import { MissionService } from 'src/app/services/mission.service';
import { PermitService } from 'src/app/services/permit.service';

interface PermitData {
  mission: string;
  objective: string;
  createdDate: string;
  createdTime: string;
  applicant: string;
  permitType: string;
  fee:string;
  missionName:string;
}

@Component({
  selector: 'app-utm-mission-list',
  templateUrl: './mission-list.component.html',
  styleUrls: ['./mission-list.component.scss']
})
export class MissionListComponent {

  permitList: PermitData[] = [];
  totalFees: number = 0;
  showDropdown = false;
  itemsPerPage = 5;
  currentPage = 1;
  permitId = "";
  processingDay = 3;

  constructor(
    // private modalService: NgbModal,
    private route: ActivatedRoute,
    private http: HttpClient,
    private missionService: MissionService,
    private permitService: PermitService
  ) {
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      const checkedMissionsString = params.get('checkedMissions');
      if (!checkedMissionsString) {
        return;
      }

      const checkedMissions = checkedMissionsString.split(',');

      const apiRequests: ObservableInput<any>[] = [];

      for (const missionId of checkedMissions) {
        const apiRequest = this.missionService.get(missionId)
        apiRequests.push(apiRequest);
      }

      forkJoin(apiRequests).subscribe(
        (results) => {
          this.permitList = results.map((missionData: any) => {
            this.totalFees = this.totalFees + Number(missionData.permit.permitFees);
            this.permitId = missionData.permit.permit;
            return {
              mission: missionData.missionId,
              objective: missionData.objective.name,
              createdDate: new Date(missionData.createdAt).toDateString(),
              createdTime: new Date(missionData.createdAt).toTimeString(),
              applicant: missionData.applicant,
              permitType: missionData.permit.permitType.name,
              fee: missionData.permit.permitFees,
              missionName: missionData.name,
            };
          });
        },
        (error) => {
          console.error('Error occurred while fetching API data:', error);
        },
      );
    });
  }

  get paginatedPermits(): PermitData[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.permitList.slice(startIndex, endIndex);
  }

  get paginationRange(): string {
    const startRange = (this.currentPage - 1) * this.itemsPerPage + 1;
    const endRange = Math.min(this.currentPage * this.itemsPerPage, this.permitList.length);
    return `${startRange} - ${endRange} of ${this.permitList.length} records`;
  }

  get pages(): number[] {
    const pageCount = Math.ceil(this.permitList.length / this.itemsPerPage);
    return Array.from({ length: pageCount }, (_, i) => i + 1);
  }

  changePage(pageNumber: number): void {
    this.currentPage = pageNumber;
  }

  goToNextPage(): void {
    if (this.currentPage < this.pages.length) {
      this.currentPage++;
    }
  }

  goToPreviousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  createApplication(missionId: any) {
    const missionArray = missionId.split(',')
    const data = {
      missionIds: missionArray,
      workspaceId: '64cb47db1a9deaa71709962f',
    };

    return this.permitService.create(data);
  }

  submitApplication(){
    this.permitService.submission(this.permitId).subscribe((data) => {
      console.log(data);
    });
  }

  saveDraft(){
     this.route.queryParamMap.subscribe((params) => {
      const checkedMissions = params.get('checkedMissions');

      if (checkedMissions) {
        this.createApplication(checkedMissions).subscribe(
          (response) => {
            this.createApplication(response);
          },
          (error) => {
            console.error('Error:', error);
          }
        );
      }
    });
  }
}



