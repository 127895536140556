import { inject } from '@angular/core';
import { catchError, of } from 'rxjs';
import { ScheduleService } from 'src/app/services/schedule.service';

export const resolveOperatorDronePayloadMissionData = () => {
  return inject(ScheduleService)
    .getAllOperatorDronePayloadMissionData()
    .pipe(
      catchError((error) => {
        console.error('Error in resolver:', error);
        return of({ error });
      }),
    );
};
