<div class="main-payment-container">
    <div class="container-fluid mt-10 custom-aligment">
        <!-- table -->
        <div class="row txt-12">
            <!-- Header titles -->
            <div class="col-md-12">
                <div class="card th-card-header">
                    <div
                        class="card-body txt-12 color-txt-gray font-weight-normal card-body-th letter-spacing-4 d-flex align-items-center">
                        <div class="flex-container">
                            <div class="flex-item">Mission</div>
                            <div class="flex-item">Objective</div>
                            <div class="flex-item" style="width: 20%;">Created Date</div>
                            <div class="flex-item">Applicant</div>
                            <div class="flex-item">Permit Type</div>
                            <div class="flex-item">Status</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row txt-12">
            <!-- Header titles -->
            <div class="col-md-12">
                <div class="card th-card ">
                    <div
                        class="card-body txt-12 color-txt-gray font-weight-normal card-body-th letter-spacing-4 d-flex align-items-center">
                        <div class="flex-container">
                            <div class="flex-item">
                                <p class="mb-0">AP310523001</p>
                                <span>Gas Leak Detection</span>
                            </div>
                            <div class="flex-item">Hotspot  Identifcation</div>
                            <div class="flex-item">
                                <p class="mb-0">Nov 20, 2023 </p>
                                <span>07:45AM</span>
                            </div>
                            <div class="flex-item" style="width: 20%;">
                                <div style="display: flex; align-items: center;">
                                    <!-- Image -->
                                    <img [src]="'/images/Image.png' | assetUrl" style="max-width: 100px;">

                                    <!-- Text -->
                                    <div style="margin-left: 10px;">
                                        <p class="mb-0">James Johnson</p>
                                        <p class="color-txt-mission mb-0">james&#64;aerodyne.com</p>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-item">
                                <span>ATF</span>
                            </div>
                            <div class="flex-item">
                                <div class="circle-green color-success">
                                    Approved
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between align-items-center mt-3 d-flex-container">
            <p class="txt-12 color-txt-mission">Showing {{ paginationRange }}</p>
            <nav aria-label="Page navigation">
                <ul class="pagination ">
                    <li class="page-item" [ngClass]="{ 'disabled': currentPage === 1 }">
                        <a class="page-link" aria-label="Previous" (click)="goToPreviousPage()">
                            <img [src]="'/images/chevron_left_24px.png' | assetUrl" style="max-width: 100px;">
                        </a>
                    </li>
                    <li class="page-item" *ngFor="let page of pages" [ngClass]="{ 'active': currentPage === page }"
                        (click)="changePage(page)">
                        <a class="page-link"><span>{{ page }}</span></a>
                    </li>
                    <li class="page-item" [ngClass]="{ 'disabled': currentPage === pages.length }">
                        <a class="page-link" aria-label="Next" (click)="goToNextPage()">
                            <img [src]="'/images/chevron_right_24px.png' | assetUrl" style="max-width: 100px;">
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>