<div class="main-payment-container">
    <div class="container-fluid mt-10 m-20">
        <div class="d-flex justify-content-between mt-10 mb-10">
            <!-- Content aligned to the left -->
            <div>
                <div class="me-3 left-header">
                    <p class="header-txt mt-10">INVENTORY</p>
                    <span>Track, manage and forecast your resources </span>
                </div>
            </div>

            <div class="d-flex align-items-center">
               <!-- <img [src]="'/icon/search-status.png' | assetUrl"> -->
                <button class="btn-export">
                    <img [src]="'/icon/cloud.png' | assetUrl">
                    <span class="ml-10">Export</span>
                </button> 
             
            </div>
        </div>
        <div class="d-flex justify-content-between mt-10 mb-10">
            <div>
                <div class="tabs">
                    <div class="tab" (click)="selectTab('drone')" [ngClass]="{ 'active': selectedTab === 'drone' }">
                        <img [src]="'/images/drone-icon.svg' | assetUrl" style="margin-right: 5px;" /> <span>
                            Drone
                        </span></div>
                    <div class="tab" (click)="selectTab('payload')" [ngClass]="{ 'active': selectedTab === 'payload' }">
                        <img [src]="'/images/payload-icon.svg' | assetUrl" style="margin-right: 5px;" /> <span>
                            Payload
                        </span> </div>
                </div>


            </div>

            <div class="d-flex align-items-center">
                <!-- <img [src]="'/icon/search-status.png' | assetUrl">
                <button class="btn-export">
                    <img [src]="'/icon/cloud.png' | assetUrl">
                    <span class="ml-10">Export</span>
                </button> -->
                <button class="btn-new-application" (click)="addnew(selectedTab)">
                    <img [src]="'/icon/cloud.png' | assetUrl">
                    <span class="ml-10">Add New</span>
                </button>
            </div>
        </div>

        <div class="row">
            <div class="tab-content">
                <div *ngIf="selectedTab === 'drone'">
                    <app-utm-drone-list></app-utm-drone-list>
                </div>
                <div *ngIf="selectedTab === 'payload'">
                    <app-utm-payload-list></app-utm-payload-list>
                </div>

            </div>
        </div>

    </div>

</div>