import { Component, OnInit, ViewChild, Input, ElementRef, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NOTIFY_MSG } from 'src/app/constants/create-mission.const';
import { VideoService } from 'src/app/services/video.service';
import { JwtTokenResponse } from 'src/app/interfaces/live-stream-jwt-token-response.interface';
import { GenerateStreamUrlRequestInterface } from 'src/app/interfaces/generate-stream-url-request.interface';
import { GenerateLiveStreamUrlResponse } from 'src/app/interfaces/generate-stream-url-response.interface';
import { HttpErrorResponse } from '@angular/common/http';

export interface GenerateStreamUrlResponse {
  showToaster?: boolean;
  message?: string;
  toasterClass?: string;
  closeRightSideDashboard?: boolean;
  clearCurrentStreamId?: boolean
}
@Component({
  selector: 'app-generate-url',
  templateUrl: './generate-url.component.html',
  styleUrls: ['./generate-url.component.scss'],
})
export class GenerateUrlComponent implements OnInit {
  // Generaet stream url decleration 
  @ViewChild('generateStreamUrlModal') generateStreamUrlModal!: ElementRef
  public generateStreamUrlPopUpScreen: 'confirm-generate-url-screen' | 'copy-url-screen' = 'confirm-generate-url-screen'
  @Input() selectedGenerateUrlStreamId: string = "";
  public disableGenerateUrlBtn: boolean = false;
  public streamPublishUrl: string = "";
  @Output() responseDetail = new EventEmitter<GenerateStreamUrlResponse>();
  // end of Generaet stream url decleration 

  constructor(
    private modalService: NgbModal,
    private videoService: VideoService,
  ) { }

  ngOnInit(): void { }

  ngOnChanges(change: SimpleChanges) {
    if (change['selectedGenerateUrlStreamId']?.currentValue) {
      this.selectedGenerateUrlStreamId = change['selectedGenerateUrlStreamId'].currentValue;
      this.initGenerateStreamUrl()
    }
  }

  initGenerateStreamUrl() {
    this.generateStreamUrlPopUpScreen = 'confirm-generate-url-screen';
    this.modalService.open(this.generateStreamUrlModal, {
      backdrop: "static",
      centered: true,
      keyboard: false,
    })
  }

  initGenerateUrlRequest(requireAiAnnotation: boolean) {
    if (!this.disableGenerateUrlBtn) {
      this.disableGenerateUrlBtn = true;
      const payload: GenerateStreamUrlRequestInterface = {
        missionId: this.selectedGenerateUrlStreamId,
        requireAiAnnotation: requireAiAnnotation,
        isAiStreamAvailable: true,
        isDroneStreamAvailable: true,
      };
      this.videoService.createLiveStreamByMissionId(payload).subscribe({
        next: (result: GenerateLiveStreamUrlResponse) => {
          this.videoService.getStreamVideoToken(result._id).subscribe({
            next: (gwtTokenResponse: JwtTokenResponse) => {
              this.generateStreamUrlPopUpScreen = 'copy-url-screen';
              this.disableGenerateUrlBtn = false;
              const jwtToken = gwtTokenResponse.token
              this.streamPublishUrl = result.droneStreamPublishUrl + "?jwt=" + jwtToken;
            },
            error: (error: HttpErrorResponse) => {
              this.disableGenerateUrlBtn = false;
              this.responseDetail.emit({
                showToaster: true,
                message: NOTIFY_MSG.FAILURE_GENERATE_URL,
                toasterClass: NOTIFY_MSG.ERROR_CLASS,
                closeRightSideDashboard: true
              })
            }
          });
        },
        error: (error: HttpErrorResponse) => {
          this.disableGenerateUrlBtn = false;
          this.responseDetail.emit({
            showToaster: true,
            message: NOTIFY_MSG.FAILURE_GENERATE_URL,
            toasterClass: NOTIFY_MSG.ERROR_CLASS,
            closeRightSideDashboard: true
          })
        }
      });
    }
  }

  copyUrl() {
    const codeSnippet = document.getElementById('copy-url');
    if (codeSnippet) {
      const range = document.createRange();
      range.selectNode(codeSnippet);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      this.responseDetail.emit({
        showToaster: true,
        message: NOTIFY_MSG.SUCCESS_GENERATE_URL,
        toasterClass: NOTIFY_MSG.SUCCESS_CLASS,
        closeRightSideDashboard: true,
        clearCurrentStreamId: true
      })
      this.resetPopUpScreen();
      this.modalService.dismissAll();
    }
  }

  resetPopUpScreen() {
    this.selectedGenerateUrlStreamId = "";
  }

  clearGenerateUrlPopUp() {
    this.selectedGenerateUrlStreamId = "";
    this.responseDetail.emit({
      clearCurrentStreamId: true
    })
    this.modalService.dismissAll();
  }
}
