import { Component } from '@angular/core';
// import { PaymentCardComponent } from 'src/app/modal-contents/payment-card/payment-card.component';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { state } from '@app/utility';
import { ObservableInput, forkJoin, take } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeletePermitComponent } from 'src/app/modal-contents/delete-permit/delete-permit.component';
import { Location } from '@angular/common';
import * as mapboxgl from 'mapbox-gl';
import { Router } from '@angular/router';
import { EnvironmentService } from 'src/app/services/environment.service';
import { MissionDetailsComponent } from '../modal-contents/mission-details/mission-details.component';
import { MissionService } from '../services/mission.service';
import { ICatalogDrone, IDrone } from '../models/drone.model';
import { ICatalogPayload, IPayload } from '../models/payload.model';
import { IUser, IUserPilotInfo } from '../models/user.model';
import { PermitService } from '../services/permit.service';
import { getModel } from '../models/utils.mode';
import { UserService } from '../services/user.service';
import { IMission } from '../models/mission.model';
import { IPermitType } from '../models/permit-type.mode';
import { IMember } from '../models/member.model';

const environmentService = new EnvironmentService();
const apiHost = environmentService.getApiUrl();

interface PermitData {
  mission: string;
  objective: string;
  createdDate: string;
  createdTime: string;
  applicant: string;
  permitType: string;
  fee: number;
  missionName: string;
  applicantName: string;
  applicantEmail: string;
  applicantPic: string;
  permit: boolean;
  missionId: string;
  permitId: string;
}

interface ApplicationResponse {
  _id: string; // Adjust the type based on the actual data type of _id
  // Other properties that might be present in the response
}

@Component({
  selector: 'app-permit',
  templateUrl: './permit.component.html',
  styleUrls: ['./permit.component.scss'],
})
export class PermitComponent {
  permitList: PermitData[] = [];
  totalFees: number = 0;
  showDropdown = false;
  itemsPerPage = 5;
  currentPage = 1;
  permitId = '';
  statusPermit = '';
  processingDay = 3;
  showModal = false;
  // missions: Missions[] = [];
  specificMission: any;
  drones: IDrone[] = [];
  payloads: IPayload[] = [];
  operators: IUser[] = [];
  checkedMissions: string[] = [];
  pilotInformation: IUserPilotInfo[] = [];
  fee: number = 0;
  showTabMission = true;
  showTabOperator = false;
  showTabDrone = false;
  showCoordinate = false;
  activeTab = 1;
  map2 = false;
  map!: mapboxgl.Map;
  organizationIds = [];
  submitSpinner = false;
  showToast: boolean = false;
  toastrText: string = '';

  constructor(
    // private modalService: NgbModal,
    private route: ActivatedRoute,
    private http: HttpClient,
    private modalService: NgbModal,
    private location: Location,
    private router: Router,
    private missionService: MissionService,
    private permitService: PermitService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.getOrganizationIds();
    this.getAll();
  }

  getAll() {
    this.route.queryParamMap.subscribe((params) => {
      const checkedMissionsString = params.get('checkedMissions');
      if (!checkedMissionsString) {
        return;
      }

      const checkedMissions = checkedMissionsString.split(',');

      const apiRequests: ObservableInput<IMission>[] = [];

      for (const missionId of checkedMissions) {
        const apiRequest = this.missionService.get(missionId);
        apiRequests.push(apiRequest);
      }

      forkJoin(apiRequests).subscribe(
        (results) => {
          this.permitList = results.map((missionData) => {
            this.totalFees = this.totalFees + Number(missionData.permitInfo.permitFees);
            this.permitId = missionData.permitInfo.permitId!;
            this.statusPermit = missionData.status;

            // const applicantId = getModel<IUser>(missionData.applicantId!);
            const applicantId = getModel<IUser>('aaaaa'); // kejap
            const permitTypeId = getModel<IPermitType>(missionData.permitInfo.permitTypeId);

            const permitData: PermitData = {
              mission: missionData.trackingId,
              objective: missionData.objective.name,
              createdDate: new Date(missionData.createdAt!).toDateString(),
              createdTime: new Date(missionData.createdAt!).toTimeString(),
              applicant: '',
              permitType: permitTypeId?.name!,
              fee: missionData.permitInfo.permitFees,
              missionName: missionData.name,
              applicantName: applicantId?.firstName || '' + applicantId?.lastName,
              applicantEmail: applicantId?.email!,
              applicantPic: applicantId?.profilePicture!,
              permit: Object.keys(missionData.permitInfo).length === 3,
              missionId: missionData._id,
              permitId: missionData.permitInfo.permitId!,
            };

            return permitData;
          });
        },
        (error) => {
          console.error('Error occurred while fetching API data:', error);
        },
      );
    });
  }

  getOrganizationIds() {
    const url = apiHost + '/account/organisations';
    const bearerToken = state.getDronosToken().value;

    const headers = new HttpHeaders({
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    });

    this.http.get<any>(url, { headers: headers }).subscribe((data) => {
      const orgList = data.records;
      this.organizationIds = orgList.map((item: { _id: any }) => item._id);
    });
  }

  get paginatedPermits(): PermitData[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.permitList.slice(startIndex, endIndex);
  }

  get paginationRange(): string {
    const startRange = (this.currentPage - 1) * this.itemsPerPage + 1;
    const endRange = Math.min(this.currentPage * this.itemsPerPage, this.permitList.length);
    return `${startRange} - ${endRange} of ${this.permitList.length} records`;
  }

  get pages(): number[] {
    const pageCount = Math.ceil(this.permitList.length / this.itemsPerPage);
    return Array.from({ length: pageCount }, (_, i) => i + 1);
  }

  changePage(pageNumber: number): void {
    this.currentPage = pageNumber;
  }

  goToNextPage(): void {
    if (this.currentPage < this.pages.length) {
      this.currentPage++;
    }
  }

  goToPreviousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  createApplication(missionId: any) {
    const workspace = state.getUserInfo().member[0].workspace._id.toString();
    const missionArray = missionId.split(',');
    const data = {
      workspaceId: workspace,
      missionIds: missionArray,
    };

    return this.permitService.create(data);
  }

  async submitApplication() {
    this.submitSpinner = true;
    const allPermitsFalse = this.permitList.every((item) => item.permit === false);

    if (allPermitsFalse) {
      const params = await this.route.queryParamMap.pipe(take(1)).toPromise();

      if (params) {
        const checkedMissions = params.get('checkedMissions');

        if (checkedMissions) {
          const response = await this.createApplication(checkedMissions).toPromise();
          const typedResponse = response as ApplicationResponse;

          this.permitId = typedResponse._id;
        } else {
          alert('MISSION ALREADY SUBMITTED');
        }
      }
    }

    this.permitService.submission(this.permitId).subscribe(
      (data) => {
        this.toastrText = 'Your Application Has Been Successfully Submited';
        this.submitSpinner = false;
        setTimeout(() => {
          this.showToast = true;
        }, 300);
        this.showToast = false;
        setTimeout(() => this.router.navigate(['/permit-application']), 5100);
      },
      (error) => {
        if (confirm(error.error.message)) {
          this.router.navigate(['/mission']);
        }
      },
    );
  }

  saveDraft() {
    this.route.queryParamMap.subscribe((params) => {
      const checkedMissions = params.get('checkedMissions');

      if (checkedMissions) {
        this.createApplication(checkedMissions).subscribe(
          (response) => {
            this.toastrText = 'Your Application Has Been Successfully Drafted';
            this.submitSpinner = false;
            setTimeout(() => {
              this.showToast = true;
            }, 300);
            this.showToast = false;
            setTimeout(() => this.router.navigate(['/permit-application']), 5100);
            this.getAll();
          },
          (error) => {
            console.error('Error:', error);
          },
        );
      }
    });
  }

  back() {
    this.router.navigate(['/mission']);
  }

  deletePermit(permit: PermitData) {
    if (permit.permitId == undefined) {
      const idx = this.permitList.findIndex((item) => item.missionId == permit.missionId);
      this.permitList.splice(idx, 1);

      this.route.queryParamMap.subscribe((params) => {
        const checkedMissionsString = params.get('checkedMissions')?.split(',');

        // Assuming permit.missionId is defined somewhere in your code
        const idx1 = checkedMissionsString?.findIndex((item) => item == permit.missionId);

        if (idx1 !== undefined && idx1 !== -1) {
          checkedMissionsString?.splice(idx1, 1);
        }

        // Update the route with the modified checkedMissions parameter
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { checkedMissions: checkedMissionsString?.join(',') },
          // queryParamsHandling: 'merge' // preserve other existing query parameters
        });
        // this.getAll()
      });
    } else {
      const modalRef = this.modalService.open(DeletePermitComponent, {
        centered: true,
        windowClass: 'delete-permit',
        backdropClass: 'delete-permit-static',
        backdrop: 'static',
      });

      modalRef.result.then((result) => {
        if (result === 'confirm') {
          // Check mission status

          const allPermitsFalse = this.permitList.every((item) => item.permit === false);
          if (allPermitsFalse) {
            const data = {
              missionIds: [permit.missionId],
              add: false,
            };

            this.permitService.updateMissions(permit.permitId, data).subscribe(
              (response) => {
                // After successful update, remove the mission from the list
                const index = this.permitList.indexOf(permit);
                if (index !== -1) {
                  this.permitList.splice(index, 1);
                }
              },
              (error) => {
                console.error('Error updating mission:', error);
                // Handle error here
              },
            );
          }
          // Remove the mission from the list
          const index = this.permitList.indexOf(permit);
          if (index !== -1) {
            this.permitList.splice(index, 1);
          }
        }
      });
    }
  }

  openModal(missionId: string) {
    // this.fetchMissionById(missionId);
    // this.showModal = true;

    const modalRef = this.modalService.open(MissionDetailsComponent, {
      centered: true,
      windowClass: 'mission-details',
      backdropClass: 'mission-details-static',
      backdrop: 'static',
      size: 'xl',
    });

    modalRef.componentInstance.missionId = missionId;
  }

  closeModal() {
    this.showModal = false;
  }

  /*openModal(missionId: string) {
    this.fetchMissionById(missionId);
    this.showModal = true;
  }
  //fetchMissionById(missionId: string) {
  //  throw new Error('Method not implemented.');
  //}

  closeModal() {
    this.showModal = false;
  }
  */

  fetchOperatorById(operatorId: string) {
    this.operators = [];
    this.userService.get(operatorId).subscribe((data) => {
      // <-- Place the console.log here
      this.operators.push(data);
      // this.operators = operator;

      // ... continue with any other processing or operations using the 'operators' object ...
    });
  }

  formatTime(time: number): string {
    const hours = Math.floor(time / 100);
    const minutes = time % 100;
    const amPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${amPm}`;
    return formattedTime;
  }

  openTabMission() {
    this.showTabMission = true;
    this.showTabOperator = false;
    this.showTabDrone = false;
    this.activeTab = 1;
  }

  openTabOperator() {
    this.showTabMission = false;
    this.showTabOperator = true;
    this.showTabDrone = false;
    this.activeTab = 2;
  }

  openTabDrone() {
    this.showTabMission = false;
    this.showTabOperator = false;
    this.showTabDrone = true;
    this.activeTab = 3;
  }

  //show coordinate

  openCoordinateModal() {
    this.showCoordinate = true;

    setTimeout(() => {
      (mapboxgl as any).accessToken = 'pk.eyJ1IjoiaW50dWl0aXZlYmFkZ2VyIiwiYSI6ImNrOXZpeXZiNzBkN3Iza29saHhjOWo2OGwifQ.QOMbmOzi8OhYw6NYyDqBRQ';

      this.map = new mapboxgl.Map({
        container: 'showmap',
        style: 'mapbox://styles/intuitivebadger/clf659i6e003m01o9seb9u6l0',
        zoom: 5,
        center: [-90, 40],
        attributionControl: false,
      });
    }, 500);
  }

  closeCoordinateModal() {
    this.showCoordinate = false;
  }

  getDroneCatalog(catalogId: ICatalogDrone | string): ICatalogDrone | null {
    return getModel<ICatalogDrone>(catalogId);
  }

  getPayloadCatalog(catalogId: ICatalogPayload | string): ICatalogPayload | null {
    return getModel<ICatalogPayload>(catalogId);
  }

  getOperatorUser(userId: IUser | string): IUser | null {
    return getModel<IUser>(userId);
  }

  getPilotInformation(pilotInformation: undefined | IUserPilotInfo[]): IUserPilotInfo[] {
    return pilotInformation as IUserPilotInfo[];
  }

  isNotNull<T>(arg: T): arg is Exclude<T, null> {
    return arg !== null;
  }

  isDefined<Value>(value: Value | undefined | null): value is Value {
    return value !== null && value !== undefined;
  }

  // paymentCard(){
  //   this.modalService.open(PaymentCardComponent, {
  //     centered: true,
  //     windowClass: 'card-modal',
  //     backdropClass: 'card-modal-backdrop',
  //     backdrop: 'static',
  //   });
  // }
}
