import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragFileUploadDirective } from './directives/drag-file-upload.directive';
import { InlineEditableDirective } from './directives/inline-editable.directive';
import { TimeDifferencePipe } from './pipes/time-difference.pipe';
import { MatchingOperatorCountPipe } from './pipes/matching-operator-count.pipe';
import { DroneListCheckMarkPipe } from './pipes/drone-list-checkmark.pipe';
import { CalculateWidthPipe } from './pipes/calculate-width.pipe';
import { CalculateSlotPipe } from './pipes/calculate-slot.pipe';
import { CheckDateAndTimeIncludesPipe } from './pipes/check-date-and-time-includes.pipe';
import { InventoryBackgroundColorPipe } from './pipes/inventory-background-color.pipe';
import { SetScheduleCheckMarkPipe } from './pipes/set-schedule-check-mark.pipe';
import { FormatDateTimePipe } from './pipes/format-date-time.pipe';
import { RepeatModalSubmitValidationPipe } from './pipes/repeat-modal-submit-validation.pipe';

import { DroneSpecsRangePipe } from './pipes/drone-specs-range.pipe';
import { InventoryAvailableCheckPipe } from './pipes/inventory-available-check.pipe';
import { InventoryTitleCasePipe } from './pipes/inventory-title-case.pipe';
import { AssetUrlPipe } from './pipes/asset-url.pipe';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

const Modules = [CommonModule, NgSelectModule, FormsModule, ReactiveFormsModule, NgbAccordionModule];

const Pipes = [
  AssetUrlPipe,
  TimeDifferencePipe,
  MatchingOperatorCountPipe,
  DroneListCheckMarkPipe,
  CalculateWidthPipe,
  CalculateSlotPipe,
  CheckDateAndTimeIncludesPipe,
  InventoryBackgroundColorPipe,
  SetScheduleCheckMarkPipe,
  FormatDateTimePipe,
  RepeatModalSubmitValidationPipe,
  InventoryTitleCasePipe,
  DroneSpecsRangePipe,
  InventoryAvailableCheckPipe,
];

const Directives = [DragFileUploadDirective, InlineEditableDirective];

@NgModule({
  declarations: [...Pipes, ...Directives],
  imports: [...Modules],
  exports: [...Modules, ...Pipes, ...Directives],
})
export class SharedModule {}
