import { Component } from '@angular/core';
import { PermitService } from '../services/permit.service';
import { IPermit, IPermitFilter } from '../models/permit.model';
import { getModel, isModelPopulated } from '../models/utils.mode';
import { IMission } from '../models/mission.model';
import { IPermitMission } from '../models/permit-mission.model';
import { UserService } from '../services/user.service';
import { IUser } from '../models/user.model';

type PermitMissionExtend = IPermitMission & {
  location_area: string;
};

type PermitExtend = Omit<NonNullable<IPermit>, 'missions'> & {
  progress: any;
  fullname: string;
  email: string;
  permit_status: string;
  approvedTotal: any;
  missions: PermitMissionExtend[];
};

@Component({
  selector: 'app-utm-payment-approval',
  templateUrl: './payment-approval.component.html',
  styleUrls: ['./payment-approval.component.scss'],
})
export class PaymentApprovalComponent {
  constructor(
    // private modalService: NgbModal,
    private permitService: PermitService,
    private userService: UserService,
  ) {}

  permitList: PermitExtend[] = [];
  ngOnInit() {
    this.getAllPermits();
  }

  getAllPermits() {
    const queryParams: IPermitFilter = { pageLimit: '1000' };
    this.permitService.getAll(queryParams).subscribe((data: { records: any }) => {
      const permitList = data.records;
      this.permitList = permitList;

      this.getUsers();
      this.getPermitStatus();
      this.getArea();
    });
  }

  getArea() {
    const promises = []; // Collect all promises here

    this.permitList.forEach((missions) => {
      missions.missions.forEach((item) => {
        const points = getModel<IMission>(item.missionId)!.area.coordinate;
        let totalLatitude = 0;
        let totalLongitude = 0;

        points!.forEach((point) => {
          totalLatitude += parseFloat(point.latitude);
          totalLongitude += parseFloat(point.longitude);
        });

        const lat = totalLatitude / points!.length;
        const lng = totalLongitude / points!.length;

        // Push the promise into the array
        promises.push(
          this.findArea(lat, lng).then((data) => {
            if (data.city == undefined || data.city.includes('Throttled')) {
              item.location_area = 'No location found';
            } else {
              item.location_area = data.city;
            }
          }),
        );
      });
    });
  }

  async findArea(lat: any, lng: any) {
    try {
      const response = await fetch(`https://geocode.xyz/${lat},${lng}?geoit=json`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching location:', error);
      return null;
    }
  }

  getPermitStatus() {
    this.permitList.forEach((item) => {
      this.permitService.hasAllPermitIssued(item._id).subscribe((data: { allPermitIssued: any }) => {
        item.permit_status = data.allPermitIssued ? 'Permit Released' : 'N/A';
        // item.email = data.email
        // item.profile_pic = data.profilePicture
      });
    });
  }

  getUsers() {
    this.permitList.forEach((item) => {
      if (!isModelPopulated<IUser>(item.applicantId)) {
        this.userService.get(item.applicantId).subscribe((data) => {
          if (data) {
            item.fullname = data.firstName || '' + data.lastName;
            item.email = data.email || '';
            // item.profile_pic = data.profilePicture
          }
        });
      }
    });
  }
}
