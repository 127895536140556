import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(private http:HttpClient) { }

  getTelemtryPath(e:any){
    const url =`https://mqtt-mongo.dronos.ai/get-db/all`
  return this.http.get(url)
  }


  getVideoList(){
    const url = `https://red5.vertikaliti.com/file/dronos`;
    return this.http.get(url);
  }
}
