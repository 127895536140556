<div class="container_comp" style="
    width: 320px;
    height: 188.4px;
    background: rgba(34, 38, 49, 0.5);
    border-radius: 4px;
    overflow: hidden;
    backdrop-filter: blur(16.89px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;
    position: relative;
    top: 60px;
    margin-left: 79%;
  ">
    <div style="position: absolute; top: 8px; right: 8px; cursor: pointer">
        <img (click)="close()" [src]="'/images/close.svg' | assetUrl" alt="close" />
    </div>
    <div style="
      align-self: stretch;
      height: 188.4px;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 16px;
      padding-bottom: 16px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      display: flex;
    ">
        <div style="justify-content: center; align-items: flex-start; gap: 28.44px; display: inline-flex">
            <div style="justify-content: flex-start; align-items: center; gap: 7.11px; display: flex">
                <div style="width: 13.51px; height: 13.51px; position: relative">
                    <img [src]="'/icon/carbon_flight-schedule.svg' | assetUrl" />
                </div>
                <div style="text-align: center; color: #eef4f7; font-size: 12px; font-family: Barlow; font-weight: 500; word-wrap: break-word"></div>
            </div>
        </div>
        <div style="flex-direction: column; justify-content: flex-start; align-items: center; gap: 16px; display: flex">
            <div style="justify-content: center; align-items: center; gap: 8px; display: inline-flex">
                <div style="padding: 8px; flex-direction: column; justify-content: center; align-items: flex-end; gap: 12px; display: inline-flex">
                    <div style="width: 60px; justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                        <img [src]="'/icon/pharagraph-spacing.svg' | assetUrl" />
                        <div style="color: #eef4f7; font-size: 10px; font-family: Barlow; font-weight: 400; word-wrap: break-word">
                            {{ (compassData && compassData.height) || 0 }} m
                        </div>
                    </div>
                    <div style="justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                        <div style="width: 16px; height: 16px; position: relative">
                            <img [src]="'/icon/pharagraph-spacing2.svg' | assetUrl" />
                        </div>
                        <div style="color: #eef4f7; font-size: 10px; font-family: Barlow; font-weight: 400; word-wrap: break-word">
                            {{ (compassData && compassData.wind_speed) || 0 }} km/h
                        </div>
                    </div>
                    <div style="justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                        <div style="width: 16px; height: 16px; position: relative">
                            <img [src]="'/icon/pharagraph-spacing3.svg' | assetUrl" />
                        </div>
                        <div style="color: #eef4f7; font-size: 10px; font-family: Barlow; font-weight: 400; word-wrap: break-word">
                            {{ (compassData && compassData.vertical_speed) || 0 }} km/h
                        </div>
                    </div>
                </div>
                <div style="padding: 4px; border-radius: 180px; overflow: hidden; justify-content: center; align-items: center; gap: 10px; display: flex">
                    <img [src]="'/icon/Scales.svg' | assetUrl" />
                    <div style="flex-direction: column; justify-content: flex-start; align-items: center; display: inline-flex; position: fixed; padding-right: 10px;">
                        <div style="text-align: center; color: #00f0ff; font-size: 16px; font-family: Barlow; font-weight: 500; word-wrap: break-word">
                            {{ (compassData && compassData.attitude_head) || 0 }}°
                        </div>
                        <div style="text-align: center; color: #eef4f7; font-size: 16px; font-family: Barlow Semi Condensed; font-weight: 500; word-wrap: break-word">
                        </div>
                    </div>
                    <div style="width: 8.23px; height: 9.47px; transform: rotate(45.72deg); transform-origin: 0 0; background: #00f0ff; border-radius: 0.89px"></div>
                </div>
                <div style="padding: 8px; flex-direction: column; justify-content: center; align-items: flex-start; gap: 12px; display: inline-flex">
                    <div style="justify-content: flex-start; align-items: flex-start; gap: 8px; display: inline-flex">
                        <div style="width: 16px; height: 16px; position: relative">
                            <img [src]="'/icon/yaw.svg' | assetUrl" />
                        </div>
                        <div style="color: #eef4f7; font-size: 10px; font-family: Barlow; font-weight: 400; word-wrap: break-word">
                            {{ (compassData && compassData.attitude_roll) || 0 }}°
                        </div>
                    </div>
                    <div style="justify-content: flex-start; align-items: flex-start; gap: 8px; display: inline-flex">
                        <div style="width: 16px; height: 16px; position: relative">
                            <div style="width: 16px; height: 16px; left: 0px; top: 0px; position: absolute">
                                <img [src]="'/icon/pitch.svg' | assetUrl" />
                            </div>
                        </div>
                        <div style="color: #eef4f7; font-size: 10px; font-family: Barlow; font-weight: 400; word-wrap: break-word">
                            {{ (compassData && compassData.attitude_pitch) || 0 }}°
                        </div>
                    </div>
                    <div style="height: 16px; justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                        <div style="width: 14.22px; height: 14.22px; padding: 1px; justify-content: center; align-items: center; gap: 8px; display: flex">
                            <img [src]="'/icon/fa6-solid_rotate-left.svg' | assetUrl" />
                        </div>
                        <div style="color: #eef4f7; font-size: 10px; font-family: Barlow; font-weight: 400; word-wrap: break-word">
                            {{ (compassData && compassData.attitude_head) || 0 }}°
                        </div>
                    </div>
                </div>
            </div>
            <div style="justify-content: center; align-items: center; gap: 16px; display: inline-flex">
                <div style="height: 13px; justify-content: space-between; align-items: center; gap: 8px; display: flex">
                    <div style="width: 11.56px; height: 11.56px; position: relative">
                        <img [src]="'/icon/Group.svg' | assetUrl" />
                    </div>
                    <div style="color: #00f0ff; font-size: 10.67px; font-family: Barlow; font-weight: 500; line-height: 12.8px; word-wrap: break-word">
                        DJI Matrice 300
                    </div>
                    <div style="justify-content: flex-start; align-items: center; gap: 7.11px; display: flex">
                        <div style="justify-content: flex-start; align-items: center; gap: 14.22px; display: flex">
                            <div style="color: #66d6a6; font-size: 10.67px; font-family: Barlow; font-weight: 500; word-wrap: break-word">
                                {{ (compassData && compassData.batteries_capacity) || 0 }}%
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 41.22px; justify-content: flex-start; align-items: center; gap: 8px; display: flex">
                    <div style="width: 14.22px; height: 14.22px; position: relative">
                        <!-- <img [src]="'/icon/icon _Game console_.svg' | assetUrl" /> -->
                    </div>
                    <div style="color: #66d6a6; font-size: 10px; font-family: Barlow; font-weight: 500; word-wrap: break-word"></div>
                </div>
            </div>
        </div>
    </div>
</div>