<div class="container">
    <!-- icon x -->
    <div class="row">
        <div class="col">
            <p class="txt-header">ISSUE RECEIPT</p>
        </div>
        <div class="col text-right" style="text-align: right;">
            <img (click)="closeModal()" [src]="'/icon/icon_x.png' | assetUrl" alt="warning" class="icon-close" />
        </div>
    </div>

    <hr style="color: #ffffff">

    <p class="sub">Please upload the permit document in the provided field below.</p>

    <!-- two input for upload jupem permit and caam permit -->
    <p class="jupem-txt">JUPEM Permit</p>
    <div class="mb-3">
    
        <input class="form-control" type="file" id="formFile" (change)="onChangeFile($event)">
    </div>

    <p class="jupem-txt">Caam Permit</p>
    <div class="mb-3">
        <input class="form-control" type="file" id="formFile" (change)="onChangeFile($event)">
      </div>



    <div class="button-container" style="text-align: right;">
        <button type="save-draft" class="btn  mx-2 save-draft" (click)="closeModal()">Cancel</button>
        <button type="button btn-submit" class="btn btn-secondary btn-submit" (click)="confirmUpload()">Confirm</button>
    </div>

</div>