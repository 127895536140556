import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment.service';
import { state } from '@app/utility';
import { IPermitTracker, IPermitTrackerFilter } from '../models/permit-tracker.model';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl() + '/permits/permit-trackers';
const workspaceId = state.getUserInfo().member[0].workspace._id.toString();
const headers = new HttpHeaders({
  Authorization: 'Bearer ' + environmentService.getToken(),
  workspaceId: workspaceId,
  'Content-Type': 'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class PermitTrackerService {

  constructor(private http: HttpClient) {}

  getAll(queryParams?: IPermitTrackerFilter): Observable<{ records: IPermitTracker[]; meta: { nextCursor: string; prevCursor: string } }> {
    return this.http.get<{ records: IPermitTracker[]; meta: { nextCursor: string; prevCursor: string } }>(`${apiUrl}`, {
      headers: headers,
      params: { ...queryParams },
    });
  }

  get(id: string): Observable<IPermitTracker> {
    return this.http.get<IPermitTracker>(`${apiUrl}/${id}`, { headers: headers });
  }
}
