import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkDateAndTimeIncludes',
})
export class CheckDateAndTimeIncludesPipe implements PipeTransform {
  transform(slot: any, name: string): string {
    if (name == 'nextSchedule') {
      return this.nextSchedulePosition(slot);
    }
    if (name == 'operatorDronePayload') {
      return this.operatorDronePayloadWith(slot);
    }
    return '';
  }

  nextSchedulePosition(slotPosition: any): string {
    if (['8:00 AM', '6:00 PM'].includes(slotPosition.startTime) && ['6:00 PM', '4:00 AM'].includes(slotPosition.endTime)) {
      return '70.8vw';
    } else if (['8:00 AM', '6:00 PM'].includes(slotPosition.startTime) && ['5:30 PM', '3:30 AM'].includes(slotPosition.endTime)) {
      return '66.8vw';
    } else if (['8:30 AM', '6:30 PM'].includes(slotPosition.startTime) && ['6:00 PM', '4:00 AM'].includes(slotPosition.endTime)) {
      return '66.8vw';
    } else if (['8:30 AM', '6:30 PM'].includes(slotPosition.startTime) && ['5:30 PM', '3:30 AM'].includes(slotPosition.endTime)) {
      return '62.8vw';
    } else if (
      ['4:30 PM', '5:00 PM', '2:30 AM', '3:00 AM'].includes(slotPosition.startTime) ||
      ['5:30 PM', '6:00 PM', '3:30 AM', '4:00 AM'].includes(slotPosition.endTime)
    ) {
      return '-150px';
    } else {
      return '';
    }
  }
  operatorDronePayloadWith(scheduleEndTime: any): string {
    return ['8:00 AM', '8:30 AM', '6:00 PM', '6:30 PM'].includes(scheduleEndTime.startTime) &&
      ['5:30 PM', '6:00 PM', '3:30 AM', '4:00 AM'].includes(scheduleEndTime.endTime)
      ? '70%'
      : '95%';
  }
}
