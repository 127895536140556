import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pilot-detail',
  templateUrl: './pilot-detail.component.html',
  styleUrls: ['./pilot-detail.component.scss']
})
export class PilotDetailComponent {

  pilotDetails: any[] = [];

  constructor(private route: ActivatedRoute, private http: HttpClient) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'bearer MQ.mK0nB6_hMJ26_fECXHa5bhRhz74B17jP-ORUfZoe6CzpsBqTvfCAS81ynnS-'
      })
    };

    // get pilot details
    this.http.get('http://127.0.0.1:3333/api/pilots/1', httpOptions).subscribe((response: any) => {
      this.pilotDetails = response;
    }, (error: any) => {
      console.log('Error while fetching pilot details', error);
    });
  }

}
