import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private mapInstance: any | null = null;

  constructor() { }

  setMapInstance(map: any): void {
    this.mapInstance = map
  }

  getMapInstance() {
    return this.mapInstance
  }
}
