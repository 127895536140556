import { Component, Input, SimpleChanges } from '@angular/core';
import { MissionSharedService } from 'src/app/services/mission-shared.service';

@Component({
  selector: 'app-utm-upload-progressbar',
  templateUrl: './upload-progressbar.component.html',
  styleUrls: ['./upload-progressbar.component.scss']
})
export class UploadProgressbarComponent {
  @Input() isUploadStart?: boolean;
  @Input() isWorkFlowStarted?: boolean;
  @Input() isCameraParamDone?: boolean;
  @Input() isDigitalTwinDone?: boolean;
  @Input() isDroneImgDone?: boolean;
  @Input() cameraParamProgress!:number;
  @Input() digitalTwinProgress!:number;
  @Input() droneImgProgress!:number;
  @Input() workflowProgress!:number;
  @Input() workflowSteps!:string;
}
