<div class="weather_coverage">
    <div class="weather_coverage_head">
        <div class="weather_coverage_head_btn">
            <button (click)="getHours(1)" (click)="weatherHours = 1"
                [ngClass]="{ active: weatherHours == 1 }">Hourly</button>
            <button (click)="getHours(3)" (click)="weatherHours = 3" [ngClass]="{ active: weatherHours == 3 }">3
                Hour</button>
            <button (click)="getHours(24)" (click)="weatherHours = 24"
                [ngClass]="{ active: weatherHours == 24 }">Daily</button>
        </div>
    </div>
    <div class="weather_coverage_content">
        <div class="weather_coverage_content_left" *ngFor="let label of weatherLabel">
            <div>
                <p>{{ label.day }}</p>
                <p>{{ label.hours }}</p>
            </div>
            <div>
                <p>{{ label.fog }}</p>
                <p>{{ label.visibility }}</p>
            </div>
        </div>
        <div class="weather_coverage_content_right">
            <div class="weather_coverage_content_right_content" *ngFor="let weatherData of weatherDataList">
                <div>
                    <div class="weather_coverage_day">
                        <p>{{ weatherData.day }}</p>
                    </div>
                    <div class="weather_coverage_hours">
                        <p *ngFor="let hours of weatherData.hours">{{ hours }}</p>
                    </div>
                    <div class="weather_coverage_icon">
                        <img [src]="'/master-weather/' + icon + '.svg' | assetUrl" alt="{{icon}}"
                            *ngFor="let icon of weatherData.icon" />
                    </div>
                    <div class="weather_coverage_hours">
                        <p *ngFor="let main of weatherData.mainValue">{{ main }}</p>
                    </div>
                    <div class="weather_coverage_hours">
                        <p *ngFor="let sub of weatherData.subValue">{{ sub }}</p>
                    </div>
                </div>
                <div class="weather_coverage_content_right_content_border"></div>
            </div>
        </div>
    </div>
</div>