<!DOCTYPE html>
<html>

<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@100;400&display=swap" rel="stylesheet">
    <script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script>
</head>

<body>
    <div class="grid-container">
        <div class="tab">
            <h2 class="title">MISSION</h2>
            <div class="search-bar">
                <input type="text" placeholder="Search..." class="search-input">
                <button class="add-button">+</button>
            </div>
            <hr class="fine-line">
            <p class="desc">Here’s a list of missions that require permit application.</p>
            <ul class="card-list">
                <li class="tab-card">
                    <div class="wrapper">
                        <div class="card-info">
                            <input type="checkbox" class="checkbox">
                        </div>
                        <div class="card-info">
                            <p class="card-title">MSN0000100 : GAS LEAK DETECTION</p>
                            <p class="card-desc">Pengerang, Johor, Malaysia</p>
                            <p class="card-desc">Fees (RM) : 300</p>
                        </div>
                    </div>
                </li>
                <li class="tab-card">
                    <div class="wrapper">
                        <div class="card-info">
                            <input type="checkbox" class="checkbox">
                        </div>
                        <div class="card-info">
                            <p class="card-title">MSN0000100 : GAS LEAK DETECTION</p>
                            <p class="card-desc">Pengerang, Johor, Malaysia</p>
                            <p class="card-desc">Fees (RM) : 300</p>
                        </div>
                    </div>
                </li>
                <li class="tab-card">
                    <div class="wrapper">
                        <div class="card-info">
                            <input type="checkbox" class="checkbox">
                        </div>
                        <div class="card-info">
                            <p class="card-title">MSN0000100 : GAS LEAK DETECTION</p>
                            <p class="card-desc">Pengerang, Johor, Malaysia</p>
                            <p class="card-desc">Fees (RM) : 300</p>
                        </div>
                    </div>
                </li>
                <li class="tab-card-unselected">
                    <div class="wrapper">
                        <div class="card-info">
                            <input type="checkbox" class="checkbox">
                        </div>
                        <div class="card-info">
                            <p class="card-title">MSN0000100 : GAS LEAK DETECTION</p>
                            <p class="card-desc">Pengerang, Johor, Malaysia</p>
                            <p class="card-desc">Fees (RM) : 300</p>
                        </div>
                    </div>
                </li>
                <li class="tab-card-unselected">
                    <div class="wrapper">
                        <div class="card-info">
                            <input type="checkbox" class="checkbox">
                        </div>
                        <div class="card-info">
                            <p class="card-title">MSN0000100 : GAS LEAK DETECTION</p>
                            <p class="card-desc">Pengerang, Johor, Malaysia</p>
                            <p class="card-desc">Fees (RM) : 300</p>
                        </div>
                    </div>
                </li>
                <li class="tab-card-unselected">
                    <div class="wrapper">
                        <div class="card-info">
                            <input type="checkbox" class="checkbox">
                        </div>
                        <div class="card-info">
                            <p class="card-title">MSN0000100 : GAS LEAK DETECTION</p>
                            <p class="card-desc">Pengerang, Johor, Malaysia</p>
                            <p class="card-desc">Fees (RM) : 300</p>
                        </div>
                    </div>
                </li>
                <div class="button-container">
                    <button class="btn-cancel">Cancel</button>
                    <button class="btn-submit" (click)="openModal()">Submit</button>
                </div>

                <div class="modal-overlay" *ngIf="showModal">
                    <div class="modal-container">
                        <div class="modal-title">
                            <p class="tab-title-active">MISSION</p>
                            <p class="tab-title">OPERATOR</p>
                            <p class="tab-title">DRONE</p>
                        </div>
                        <hr class="fine-line">

                        <div class="modal-partition">
                            <div class="modal-row">
                                <div class="modal-text-group">
                                    <span class="modal-content-title">Mission ID</span>
                                    <span class="modal-content-subtitle">MSN0000100</span>
                                </div>
                                <div class="modal-text-group">
                                    <span class="modal-content-title">Mission Objective</span>
                                    <span class="modal-content-subtitle">Hotspot Identification</span>
                                </div>
                                <div class="modal-text-group">
                                    <span class="modal-content-title">Location</span>
                                    <span class="modal-content-subtitle">Sungai Kapal, Pengerang, Johor, Malaysia</span>
                                </div>
                                <div class="modal-text-group">
                                    <span class="modal-content-title">Buffer Zone</span>
                                    <span class="modal-content-subtitle">Included</span>
                                </div>
                            </div>
                            <div class="modal-row">
                                <div class="modal-text-group">
                                    <span class="modal-content-title">Mission Name</span>
                                    <span class="modal-content-subtitle">Gas Leak Detection</span>
                                </div>
                                <div class="modal-text-group">
                                    <span class="modal-content-title">Mission Area</span>
                                    <span class="modal-button">Show coordinates</span>
                                </div>
                                <div class="modal-text-group">
                                    <span class="modal-content-title">Geographic coordinate</span>
                                    <span class="modal-content-subtitle">27.2046° N, 77.4977° E</span>
                                </div>
                                <div class="modal-text-group">
                                    <span class="modal-content-title">Associate project</span>
                                    <span class="modal-content-subtitle">-</span>
                                </div>

                                <div class="modal-title-group">
                                    <span class="modal-content-title">Associate project</span>
                                    <span class="modal-content-subtitle">-</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ul>
        </div>
        <div class="content">
            <h1>Heyy, put map here</h1>
            <p>You can modify from here!</p>
        </div>
    </div>
</body>

</html>