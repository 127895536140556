import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { state } from '@app/utility';
import { EnvironmentService } from 'src/app/services/environment.service';
import { ICreateDrone, IDrone, IDroneFilter } from '../models/drone.model';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl() + '/inventory/drones';
const workspaceId = state.getUserInfo().member[0].workspace._id.toString();

const headers = new HttpHeaders({
  Authorization: 'Bearer ' + environmentService.getToken(),
  workspaceId: workspaceId,
  'Content-Type': 'application/json',
});

@Injectable({
  providedIn: 'root',
})
export class DroneService {
  constructor(private http: HttpClient) {}

  create(data: ICreateDrone): Observable<IDrone> {
    return this.http.post<IDrone>(`${apiUrl}`, data, { headers: headers });
  }

  getAll(queryParams?: IDroneFilter): Observable<{ records: IDrone[]; meta: { nextCursor: string; prevCursor: string } }> {
    return this.http.get<{ records: IDrone[]; meta: { nextCursor: string; prevCursor: string } }>(apiUrl, { headers: headers, params: { ...queryParams } });
  }

  get(id: string): Observable<IDrone> {
    return this.http.get<IDrone>(`${apiUrl}/${id}`, { headers: headers });
  }

  update(id: string, data: Partial<IDrone>): Observable<IDrone> {
    return this.http.put<IDrone>(`${apiUrl}/${id}`, data);
  }

  delete(id: string): Observable<IDrone> {
    return this.http.delete<IDrone>(`${apiUrl}/${id}`, { headers: headers });
  }
}
