<div class="header">
    <p>ADD NEW DRONE</p>


    <!-- <img (click)="closeModal()" [src]="'/icon/icon_x.png' | assetUrl" alt="warning" class="icon-close"/> -->



</div>
<div class="body">


    <div class="upload-container row">
        <div class="col-6">
            <div class="image-preview">
                <img *ngIf="selectedImage" [src]="selectedImage" alt="Uploaded Image" />
            </div>
        </div>
        <div class="col-6 upload-section">
            <button type="button" class="upload-button" (click)="fileInput.click()">Choose file</button>
            <input type="file" #fileInput (change)="onFileSelected($event)" accept="image/*" id="fileInput" style="display: none;" />
        </div>




    </div>


    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputEmail4">Serial Number
            </label>
            <input type="text" class="form-control" placeholder=" Enter serial number">
        </div>
        <div class="form-group col-md-6">
            <label for="inputPassword4">Nickname (if any)</label>
            <input type="text" class="form-control" placeholder="Enter nickname">
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputEmail4">Brand
            </label>
            <input type="text" class="form-control" placeholder=" Enter serial number">
        </div>
        <div class="form-group col-md-6">
            <label for="inputPassword4">Model</label>
            <input type="text" class="form-control" placeholder="Enter nickname">
        </div>
    </div>
    <div class="form-group">
        <label for="inputAddress">Category
        </label>
        <input type="text" class="form-control" id="inputAddress" placeholder="Select category">
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputEmail4">Size (Length x Width x Height)
            </label>
            <input type="text" class="form-control" placeholder="Enter size">
        </div>
        <div class="form-group col-md-6">
            <label for="inputPassword4">Max Flight Time (min)</label>
            <input type="text" class="form-control col-form-label-lg" placeholder="Enter max flight time">
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputEmail4">Max Take Off Weight (kg)
            </label>
            <input type="text" class="form-control" placeholder=" Enter max take off Weight">
        </div>
        <div class="form-group col-md-6">
            <label for="inputPassword4">Max Speed (m/s)</label>
            <input type="text" class="form-control" placeholder="Enter max speed">
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputEmail4">Max Payload (kg)
            </label>
            <input type="text" class="form-control" placeholder=" Enter max payload">
        </div>
        <div class="form-group col-md-6">
            <label for="inputPassword4">Battery (mAH)</label>
            <input type="text" class="form-control" placeholder="Enter battery">
        </div>
    </div>
</div>



<div class="footer">
    <button (click)="closeModal()"  class=" btn-cancel">Cancel</button>
    <button type="submit" class=" btn-save">Save</button>
</div>