<div class="main-payment-container">
    <div class="container-fluid mt-10 m-20">
        <div class="d-flex justify-content-between mt-10 mb-10">
            <!-- Content aligned to the left -->
            <div>
                <div class="me-3 left-header">
                    <p class="header-txt mt-10">PERMIT APPLICATION</p>
                    <span>Track, manage and forecast your resources. </span>
                </div>
            </div>

            <div class="d-flex align-items-center">
                <!-- <img [src]="'/icon/search-status.png' | assetUrl">
                <button class="btn-export">
                    <img [src]="'/icon/cloud.png' | assetUrl">
                    <span class="ml-10">Export</span>
                </button> -->
                <button class="btn-new-application" (click)="redirectToMissionList()" *ngIf="role == RoleType.Owner">
                    <img [src]="'/icon/cloud.png' | assetUrl">
                    <span class="ml-10">New Application</span>
                </button>
            </div>
        </div>

        <div class="d-flex justify-content-between mb-10" style="margin-top: 30px;">
            <!-- Content aligned to the left -->
            <div>
                <ul class="nav nav-tabs" style="width: 100%;">
                    <li class="nav-item">
                        <a class="nav-link" (click)="showTab('all')" [class.active]="activeTab === 'all'" style="border: 0px;">All
                            <span class="badge badge-pill badge-primary">{{ countAllStatus.all }}</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="role == RoleType.AuthorityAdmin || role == RoleType.Authority">
                        <a class="nav-link" (click)="showTab('pending')" [class.active]="activeTab === 'pending'" style="border: 0px;">Pending Approval
                            <span class="badge badge-pill badge-primary">{{ countAllStatus.pending }}</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="role == RoleType.Owner || role == RoleType.Admin ">
                        <a class="nav-link" (click)="showTab('drafts')"
                            [class.active]="activeTab === 'drafts'" style="border: 0px;">Drafts
                            <span class="badge badge-pill badge-primary">{{ countAllStatus.drafts }}</span></a>
                    </li>
                    <li class="nav-item" *ngIf="role == RoleType.Owner || role == RoleType.Admin">
                        <a class="nav-link" (click)="showTab('in-review')" [class.active]="activeTab === 'in-review'" style="border: 0px;">In
                            Review   <span class="badge badge-pill badge-primary">{{ countAllStatus.pending }}</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="showTab('completed')" style="border: 0px;"
                            [class.active]="activeTab === 'completed'">Completed   <span class="badge badge-pill badge-primary">{{ countAllStatus.completed }}</span></a>
                    </li>



                </ul>
            </div>
        </div>

        <div class="row mt-10 mb-10" style="margin-top: 30px;">
            <!-- Content aligned to the left -->
            <div class="col-12">
                <div class="tab-content" style="width: 100%;">
                    <ng-container>
                        <app-utm-list-all [permitList]="permitList"></app-utm-list-all>
                    </ng-container>
                </div>
            </div>
        </div>

    </div>
