import { IMember } from './member.model';
import { BaseModel } from './utils.mode';

export interface IUserPassport {
  passportNo: string;
  country: string;
  expiryDatePass: Date;
  passportAttachment: string;
}

export interface IUserCertificate {
  certificationType: string;
  certificateName: string;
  certificateNumber: string;
  issuedBy: string;
  issuedDate: Date;
  flightHours: number;
  expiryDate: Date;
  attachment: string[];
  addProperty?: object;
  urls: string[];
}

export interface IUserPilotInfo {
  flightHours: number;
  currency: number;
  lastFlownDate: string;
  competency: string[];
}

export interface IAccountLink {
  provider: string;
  username: string;
  otherField: Record<string, string>;
}

export type IUser =
  | (BaseModel & {
      profilePicture: string;
      firstName: string;
      lastName: string;
      username: string;
      passport: IUserPassport;
      nric: string;
      nricAttach: string;
      email: string;
      phoneNumber: string;
      country: string;
      city: string;
      socialLinks: string[];
      userCertificate: IUserCertificate[];
      pilotInformation: IUserPilotInfo[];
      accountLinks: IAccountLink[];
      addProperty: object;
      verified: boolean;
      member: IMember[];
    })
  // | null;

// Initialization function for IUserPassport
export const initializeUserPassport = (): IUserPassport => ({
  passportNo: '',
  country: '',
  expiryDatePass: new Date(),
  passportAttachment: '',
});

// Initialization function for IUserCertificate
export const initializeUserCertificate = (): IUserCertificate => ({
  certificationType: '',
  certificateName: '',
  certificateNumber: '',
  issuedBy: '',
  issuedDate: new Date(),
  flightHours: 0,
  expiryDate: new Date(),
  attachment: [],
  urls: []
});

// Initialization function for IUserPilotInfo
export const initializeUserPilotInfo = (): IUserPilotInfo => ({
  flightHours: 0,
  currency: 0,
  lastFlownDate: '',
  competency: [],
});

// Initialization function for IAccountLink
export const initializeAccountLink = (): IAccountLink => ({
  provider: '',
  username: '',
  otherField: {},
});

// Initialization function for IUser
export const initializeUser = (): IUser => ({
  _id: '',
  profilePicture: '',
  firstName: '',
  lastName: '',
  username: '',
  passport: initializeUserPassport(),
  nric: '',
  nricAttach: '',
  email: '',
  phoneNumber: '',
  country: '',
  city: '',
  socialLinks: [],
  userCertificate: [initializeUserCertificate()],
  pilotInformation: [initializeUserPilotInfo()],
  accountLinks: [initializeAccountLink()],
  addProperty: {},
  verified: false,
  member: [],
});

