import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-contextmenu',
  templateUrl: './contextmenu.component.html',
  styleUrls: ['./contextmenu.component.scss']
})
export class ContextmenuComponent {

  @Input() x=0;
  @Input() y=0;

  showMissionplan(){
    
    
  }
 
}
