import { Injectable } from '@angular/core';
import { EnvironmentService } from 'src/app/services/environment.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as mapboxgl from 'mapbox-gl';
import { Observable, BehaviorSubject } from 'rxjs';

import axios from 'axios';
import * as turf from '@turf/turf';
import { data } from 'jquery';
import { assetUrl } from 'src/single-spa/asset-url';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

// import DjiGeozone from 'ol-dji-geozones'
const paraUrl = '/v2/mapdata/geojson';

const environmentService = new EnvironmentService();
const accessToken = environmentService.getAccessToken();

@Injectable({
  providedIn: 'root',
})
export class MapdataService {
  private apiUrl = 'https://external-api.faa.gov/notamapi/v1/notams';
  private clientId = '57d4bcf59baa4c02a7e3eb4179fa5cbc';
  private clientSecret = 'C3dB1907CBDF47Dfa6E35faF0f07321e';
  private geozoneUrl = 'https://dronos-utm.s3.ap-southeast-1.amazonaws.com/DJIGEOZONE.geojson';

  map!: mapboxgl.Map;
  public elementsArray = [];
  public transferArray = new BehaviorSubject<any>([]);
  public elementsArraySubject = new BehaviorSubject<any>([]);
  private markers: { [name: string]: mapboxgl.Marker } = {};
  specCoordinates: any;
  isElevationGraph: boolean = false;
  id="uuyuy";
  ortho = {
    "external_url": "https://aerodyne-halle.s3.eu-central-1.amazonaws.com/HALLE/04_DELIVERY/04_HOSTING/01_MAPTILER/01_ORTHO_MAPTILER/Halle/openlayers.html",
    "layer_type": "ortho",
    "layer_name": "Ortho",
    "layer_date": "2021-10-06",
    "id": "615d232430ee0b02b26cf26d"
}
isMapLoaded$ = new BehaviorSubject(false);
isOrtho$ = new BehaviorSubject(false);


  notamJson = [
    {
      id: 'NOTAM_1_60713649',
      series: 'A',
      number: 'A1976/21',
      type: 'N',
      issued: '2021-06-04T14:07:00.000Z',
      affectedFIR: 'WMFC',
      selectionCode: 'QAFXX',
      traffic: 'IV',
      purpose: 'NBO',
      scope: 'E',
      minimumFL: '000',
      maximumFL: '999',
      location: 'WMFC',
      effectiveStart: '2023-12-12T14:30:00.000Z',
      effectiveEnd: '2023-12-15T18:00:00.000Z',
      text: 'WITH REGARD TO NOTAM A1948/21, A1949/21, A1950/21, A1951/21, \nA1952/21, A1953/21, A1954/21, A1955/21, A1956/21 AND A1957/21. NEW \nAIP SUP OF SIMILAR CONTENT WILL BE ISSUED WITH MNM CORRECTIONS AND \nSAME EFFECTIVE DATE. THE CORRECTIONS WILL BE HIGHLIGHTED. THIS \nMEASURE IS TAKEN BASED ON STAKEHOLDERS CONSULTATION AND THE INTEREST \nOF AIR SAFETY.',
      classification: 'INTL',
      accountId: 'WMKKYNYX',
      lastUpdated: '2021-06-04T14:08:00.000Z',
      icaoLocation: 'WMFC',
    },
    {
      id: 'NOTAM_1_60713650',
      series: 'B',
      number: 'A1976/21',
      type: 'N',
      issued: '2021-06-04T14:07:00.000Z',
      affectedFIR: 'WMFC',
      selectionCode: 'QAFXX',
      traffic: 'IV',
      purpose: 'NBO',
      scope: 'E',
      minimumFL: '000',
      maximumFL: '999',
      location: 'WMFC',
      effectiveStart: '2023-12-16T10:30:00.000Z',
      effectiveEnd: '2023-12-20T15:00:00.000Z',
      text: 'WITH REGARD TO NOTAM A1948/21, A1949/21, A1950/21, A1951/21, \nA1952/21, A1953/21, A1954/21, A1955/21, A1956/21 AND A1957/21. NEW \nAIP SUP OF SIMILAR CONTENT WILL BE ISSUED WITH MNM CORRECTIONS AND \nSAME EFFECTIVE DATE. THE CORRECTIONS WILL BE HIGHLIGHTED. THIS \nMEASURE IS TAKEN BASED ON STAKEHOLDERS CONSULTATION AND THE INTEREST \nOF AIR SAFETY.',
      classification: 'INTL',
      accountId: 'WMKKYNYX',
      lastUpdated: '2021-06-04T14:08:00.000Z',
      icaoLocation: 'WMFC',
    },
    {
      id: 'NOTAM_1_60713650',
      series: 'B',
      number: 'A1976/21',
      type: 'N',
      issued: '2021-06-04T14:07:00.000Z',
      affectedFIR: 'WMFC',
      selectionCode: 'QAFXX',
      traffic: 'IV',
      purpose: 'NBO',
      scope: 'E',
      minimumFL: '000',
      maximumFL: '999',
      location: 'WMFC',
      effectiveStart: '2023-12-23T10:30:00.000Z',
      effectiveEnd: '2023-12-27T15:30:00.000Z',
      text: 'WITH REGARD TO NOTAM A1948/21, A1949/21, A1950/21, A1951/21, \nA1952/21, A1953/21, A1954/21, A1955/21, A1956/21 AND A1957/21. NEW \nAIP SUP OF SIMILAR CONTENT WILL BE ISSUED WITH MNM CORRECTIONS AND \nSAME EFFECTIVE DATE. THE CORRECTIONS WILL BE HIGHLIGHTED. THIS \nMEASURE IS TAKEN BASED ON STAKEHOLDERS CONSULTATION AND THE INTEREST \nOF AIR SAFETY.',
      classification: 'INTL',
      accountId: 'WMKKYNYX',
      lastUpdated: '2021-06-04T14:08:00.000Z',
      icaoLocation: 'WMFC',
    },
    {
      id: 'NOTAM_1_60713650',
      series: 'B',
      number: 'A1976/21',
      type: 'N',
      issued: '2021-06-04T14:07:00.000Z',
      affectedFIR: 'WMFC',
      selectionCode: 'QAFXX',
      traffic: 'IV',
      purpose: 'NBO',
      scope: 'E',
      minimumFL: '000',
      maximumFL: '999',
      location: 'WMFC',
      effectiveStart: '2023-12-30T10:30:00.000Z',
      effectiveEnd: '2023-12-30T15:30:00.000Z',
      text: 'WITH REGARD TO NOTAM A1948/21, A1949/21, A1950/21, A1951/21, \nA1952/21, A1953/21, A1954/21, A1955/21, A1956/21 AND A1957/21. NEW \nAIP SUP OF SIMILAR CONTENT WILL BE ISSUED WITH MNM CORRECTIONS AND \nSAME EFFECTIVE DATE. THE CORRECTIONS WILL BE HIGHLIGHTED. THIS \nMEASURE IS TAKEN BASED ON STAKEHOLDERS CONSULTATION AND THE INTEREST \nOF AIR SAFETY.',
      classification: 'INTL',
      accountId: 'WMKKYNYX',
      lastUpdated: '2021-06-04T14:08:00.000Z',
      icaoLocation: 'WMFC',
    },
    {
      id: 'NOTAM_1_60713650',
      series: 'B',
      number: 'A1976/21',
      type: 'N',
      issued: '2021-06-04T14:07:00.000Z',
      affectedFIR: 'WMFC',
      selectionCode: 'QAFXX',
      traffic: 'IV',
      purpose: 'NBO',
      scope: 'E',
      minimumFL: '000',
      maximumFL: '999',
      location: 'WMFC',
      effectiveStart: '2023-12-29T10:30:00.000Z',
      effectiveEnd: '2023-12-29T12:30:00.000Z',
      text: 'WITH REGARD TO NOTAM A1948/21, A1949/21, A1950/21, A1951/21, \nA1952/21, A1953/21, A1954/21, A1955/21, A1956/21 AND A1957/21. NEW \nAIP SUP OF SIMILAR CONTENT WILL BE ISSUED WITH MNM CORRECTIONS AND \nSAME EFFECTIVE DATE. THE CORRECTIONS WILL BE HIGHLIGHTED. THIS \nMEASURE IS TAKEN BASED ON STAKEHOLDERS CONSULTATION AND THE INTEREST \nOF AIR SAFETY.',
      classification: 'INTL',
      accountId: 'WMKKYNYX',
      lastUpdated: '2021-06-04T14:08:00.000Z',
      icaoLocation: 'WMFC',
    },
    {
      id: 'NOTAM_1_60713650',
      series: 'B',
      number: 'A1976/21',
      type: 'N',
      issued: '2021-06-04T14:07:00.000Z',
      affectedFIR: 'WMFC',
      selectionCode: 'QAFXX',
      traffic: 'IV',
      purpose: 'NBO',
      scope: 'E',
      minimumFL: '000',
      maximumFL: '999',
      location: 'WMFC',
      effectiveStart: '2023-12-10T10:30:00.000Z',
      effectiveEnd: '2023-12-10T15:30:00.000Z',
      text: 'WITH REGARD TO NOTAM A1948/21, A1949/21, A1950/21, A1951/21, \nA1952/21, A1953/21, A1954/21, A1955/21, A1956/21 AND A1957/21. NEW \nAIP SUP OF SIMILAR CONTENT WILL BE ISSUED WITH MNM CORRECTIONS AND \nSAME EFFECTIVE DATE. THE CORRECTIONS WILL BE HIGHLIGHTED. THIS \nMEASURE IS TAKEN BASED ON STAKEHOLDERS CONSULTATION AND THE INTEREST \nOF AIR SAFETY.',
      classification: 'INTL',
      accountId: 'WMKKYNYX',
      lastUpdated: '2021-06-04T14:08:00.000Z',
      icaoLocation: 'WMFC',
    },
    {
      id: 'NOTAM_1_60713650',
      series: 'B',
      number: 'A1976/21',
      type: 'N',
      issued: '2021-06-04T14:07:00.000Z',
      affectedFIR: 'WMFC',
      selectionCode: 'QAFXX',
      traffic: 'IV',
      purpose: 'NBO',
      scope: 'E',
      minimumFL: '000',
      maximumFL: '999',
      location: 'WMFC',
      effectiveStart: '2023-12-04T10:30:00.000Z',
      effectiveEnd: '2023-12-05T15:30:00.000Z',
      text: 'WITH REGARD TO NOTAM A1948/21, A1949/21, A1950/21, A1951/21, \nA1952/21, A1953/21, A1954/21, A1955/21, A1956/21 AND A1957/21. NEW \nAIP SUP OF SIMILAR CONTENT WILL BE ISSUED WITH MNM CORRECTIONS AND \nSAME EFFECTIVE DATE. THE CORRECTIONS WILL BE HIGHLIGHTED. THIS \nMEASURE IS TAKEN BASED ON STAKEHOLDERS CONSULTATION AND THE INTEREST \nOF AIR SAFETY.',
      classification: 'INTL',
      accountId: 'WMKKYNYX',
      lastUpdated: '2021-06-04T14:08:00.000Z',
      icaoLocation: 'WMFC',
    },
  ];

  urlWithoutExtension = [{
    url:'https://aerodyne-masdar-data.s3.me-south-1.amazonaws.com/DELMA+ISLAND/Processed/Maptiler/DelmaIsland-Orthomosaic/Delma+Island/openlayers.html'}]


  draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      polygon: false,
      trash: false,
    },
    defaultMode: 'draw_line_string',
    styles: [
      // Customize the line color
      {
        id: 'gl-draw-line',
        type: 'line',
        paint: {
          'line-color': '#aaaaaa', // Set line color to red
          'line-width': 3,
        },
      },
      // Customize the marker color
      {
        id: 'gl-draw-marker',
        type: 'circle',
        filter: ['all', ['!=', 'meta', 'midpoint'], ['==', '$type', 'Point']],
        paint: {
          'circle-color': '#016064', // Set marker color to green
          'circle-radius': 8,
        },
      },
      {
        id: 'gl-draw-marker-inner',
        type: 'circle',
        filter: ['all', ['!=', 'meta', 'midpoint'], ['==', '$type', 'Point']],
        paint: {
          'circle-color': '#fefefe',
          'circle-radius': 4,
        },
      },
    ],
  });

  drawPolygon = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      polygon: true,
      trash: false,
    },
    defaultMode: 'draw_polygon',
    styles: [
      // Customize the line color
      {
        id: 'gl-draw-line',
        type: 'line',
        paint: {
          'line-color': '#aaaaaa', // Set line color to red
          'line-width': 3,
        },
      },
      // Customize the marker color
      {
        id: 'gl-draw-marker',
        type: 'circle',
        filter: ['all', ['!=', 'meta', 'midpoint'], ['==', '$type', 'Point']],
        paint: {
          'circle-color': '#016064', // Set marker color to green
          'circle-radius': 8,
        },
      },
      {
        id: 'gl-draw-marker-inner',
        type: 'circle',
        filter: ['all', ['!=', 'meta', 'midpoint'], ['==', '$type', 'Point']],
        paint: {
          'circle-color': '#fefefe',
          'circle-radius': 4,
        },
      },
    ],
  });

  marker: mapboxgl.Marker = new mapboxgl.Marker({
    draggable: false,
    anchor: 'bottom', // Set the anchor point to the bottom center
  });


  isDrawLine: boolean = false;
  lineData: any;
  createMission = false;
  graphLabels: Array<any> = [];
  graphElevationData: Array<any> = [];
  orthoMinzoom = 15

  constructor(private http: HttpClient) {
    (mapboxgl as any).accessToken = accessToken;
  }

  initializeMap(container: string, lng: number, lat: number, zoom: number): void {
    this.map = new mapboxgl.Map({
      container,
      style: 'mapbox://styles/intuitivebadger/clnbqvtnk03la01pjfg65g3r3',
      center: [lng, lat],
      zoom,
      pitch: 60,
      bearing: -60,
      attributionControl: true,
    });


    this.map.addControl(new mapboxgl.FullscreenControl());

    // kalau enlarge
    let canvas = this.map.getCanvasContainer().querySelector('.mapboxgl-canvas') as HTMLCanvasElement;
    // canvas.height = 100
    // canvas.width = 100

    this.map.resize();

  }

  turnOngeozone() { 
    console.log("Prop another")
  }

  checkMapLoad() {
    // if (this.map.on('load',() => {}) ) {

    // }
    const statusMap = this.map.on('load', (status) => {
      return true;
    });

    return statusMap;
  }

  loadScheduleMap() { }

  getMapData(data: any): Observable<any> {
    const dummy = null;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Authorization': environmentService.getAAToken()
    //   })
    // };

    // return this.http.get(environmentService.getAAUrl()+paraUrl+'?'+"n="+data._ne.lat+"&"+"e="+data._ne.lng+"&"+"s="+data._sw.lat+"&"+"w="+data._sw.lng,httpOptions)

    return null as any;
  }

  addnewArr(data: any) {
    const newArr = this.transferArray.getValue();
    newArr.push(data);
    this.transferArray.next(data);
  }

  objectiveMap(data: any) {
    this.passValue(data);
  }

  passValue(data2: any): Observable<any> {
    return data2;
  }

  // getNotamData() {
  //   const headers = {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //     client_id: this.clientId,
  //     client_secret: this.clientSecret,
  //   };
  //   return new Observable<any>((observer) => {
  //     axios
  //       .get(`${this.apiUrl}?icaoLocation=WMFC`, { headers })
  //       .then((response) => {
  //         observer.next(response.data);

  //         observer.complete();
  //       })
  //       .catch((error) => {
  //         observer.error(error);
  //       });
  //   });
  // }

  getnotam2() {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      client_id: this.clientId,
      client_secret: this.clientSecret,
    };

    return this.http.get(`${this.apiUrl}?icaoLocation=WMFC`, { headers });
  }

  //load orthos

  addOrtho() {
      // Ensure map is initialized
      if (!this.map) {
        console.error('Map is not initialized.');
        return;
      }
    
      // Check if the ortho layer already exists
      if (this.map.getLayer('orthographic-layer')) {
        console.warn('Orthographic layer already added.');
        return;
      }
    
      // Define the URL of the ortho layer
      const tilesURL = this.urlWithoutExtension[0].url.replace(/(?=\w+\.\w{3,4}$).+/, '{z}/{x}/{y}.png');
      const halleBounds = [11.948129486313775,51.457127989859885,11.989467645980852,51.46577283000741]
    
      // Add the ortho layer to the map
      this.map.on('load', () => {
        this.map.addLayer({
          id: 'orthographic-layer',
          type: 'raster',
          source: {
            type: 'raster',
            tiles: [tilesURL],
            tileSize: 256,
            minzoom: this.orthoMinzoom,
            bounds: halleBounds
          },
        });
      });
      
    
  }

  getGroundData(data: any): Observable<any> {
    const bBox = [data.getWest(), data.getSouth(), data.getEast(), data.getNorth()];

    const getBody = {
      type: 'Polygon',
      coordinates: [
        [
          [bBox[0], bBox[1]],
          [bBox[2], bBox[1]],
          [bBox[2], bBox[3]],
          [bBox[0], bBox[3]],
          [bBox[0], bBox[1]],
        ],
      ],
    };

    // const headers = new HttpHeaders({
    //   'Authorization': environmentService.getAAToken(),
    //   'Content-Type': 'application/json'
    // });

    // const url ='https://api.altitudeangel.com/v2/mapdata/geojson/groundhazards?iconformat=svg'
    // var resOut =[] as any

    // this.http.post(url, getBody, { headers }).subscribe({
    //   next: (response) => {
    //     resOut.push(response);
    //   },
    //   error: (error) => {
    //   },
    //   complete: () => {
    //   }
    // });

    return null as any;
  }

  //function to draw mission area and buffer
  drawAreaBuffer(location: any, centroid: any, id: string) {
    const elevation = 120;
    const bufferDistance1 = 0.1;
    const bufferDistance2 = 0.2;
    const bufferDistance3 = 0.3;
    // const convertedCoordinates = area.map((coord: { longitude: string; latitude: string }) => [parseFloat(coord.longitude), parseFloat(coord.latitude)]);

    const polygonFeature: any = {
      type: 'Feature',
      // geometry: {
      //   type: 'Polygon',
      //   coordinates: [convertedCoordinates],
      // },
      geometry: location,
    };

    const center: any = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [centroid.longitude, centroid.latitude],
      },
    };

    const bufferedPolygon1 = turf.buffer(polygonFeature, bufferDistance1, { units: 'kilometers' });
    const bufferedPolygon2 = turf.buffer(polygonFeature, bufferDistance2, { units: 'kilometers' });
    const bufferedPolygon3 = turf.buffer(polygonFeature, bufferDistance3, { units: 'kilometers' });

    const combinedPolygons = turf.union(bufferedPolygon1, bufferedPolygon2, bufferedPolygon3);

    const bbox = turf.bbox(combinedPolygons);

    this.map.addSource(`${id}-buffer1`, {
      type: 'geojson',
      data: bufferedPolygon1,
    });

    this.map.addLayer({
      id: `${id}-buffer1`,
      type: 'line',
      source: `${id}-buffer1`,
      paint: {
        'line-color': '#FFD54F',
        'line-width': 1,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    this.map.addSource(`${id}-buffer2`, {
      type: 'geojson',
      data: bufferedPolygon2,
    });

    this.map.addLayer({
      id: `${id}-buffer2`,
      type: 'line',
      source: `${id}-buffer2`,
      paint: {
        'line-color': '#FF0000',
        'line-width': 1,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    this.map.addSource(`${id}-buffer3`, {
      type: 'geojson',
      data: bufferedPolygon3,
    });

    this.map.addLayer({
      id: `${id}-buffer3`,
      type: 'line',
      source: `${id}-buffer3`,
      paint: {
        'line-color': '#2196F3',
        'line-width': 1,
        'line-dasharray': [3, 2, 2, 3],
      },
    });

    this.map.addSource(id, {
      type: 'geojson',
      data: polygonFeature,
    });

    this.map.addLayer({
      id: id,
      type: 'fill-extrusion',
      source: id,
      paint: {
        'fill-extrusion-color': '#009688',
        'fill-extrusion-opacity': 0.5,
        'fill-extrusion-height': elevation,
      },
    });

    this.map.addSource(`${id}-centroid-point`, {
      type: 'geojson',
      data: center,
    });

    fetch(assetUrl('globe/dropPin.svg'))
      .then((response) => response.blob())
      .then((iconBlob) => {
        const iconUrl = URL.createObjectURL(iconBlob);

        const image = new Image();
        image.onload = () => {
          this.map.addImage('custom-icon', image);
          this.map.addLayer({
            id: `${id}-centroid-point`,
            type: 'symbol',
            source: `${id}-centroid-point`,
            layout: {
              'icon-image': 'custom-icon',
              'icon-size': 1,
              'icon-anchor': 'bottom',
            },
          });
        };

        image.src = iconUrl;
      });

    this.map.fitBounds(
      [
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],
      ],
      {
        pitch: 70,
        padding: 20,
      },
    );

    // const bufferCoord = {
    //   buffer1: bufferedPolygon1.geometry.coordinates,
    //   buffer2: bufferedPolygon2.geometry.coordinates,
    //   buffer3: bufferedPolygon3.geometry.coordinates
    // }
    // return bufferedPolygon1;
  }

  removeAreaBuffer(id: string) {
    this.map.removeLayer(id);
    this.map.removeSource(id);
    this.map.removeLayer(id + '-buffer3');
    this.map.removeSource(id + '-buffer3');
    this.map.removeLayer(id + '-buffer2');
    this.map.removeSource(id + '-buffer2');
    this.map.removeLayer(id + '-buffer1');
    this.map.removeSource(id + '-buffer1');
    this.map.removeLayer(id + '-centroid-point');
    this.map.removeSource(id + '-centroid-point');
  }

  // OTHER DRAW FUNCTIONS
  clearDraw() {
    const hasDrawControl = this.map.hasControl(this.draw);
    const hasDrawPolygonControl = this.map.hasControl(this.drawPolygon)
    if (hasDrawControl || hasDrawPolygonControl) {
      if (this.map.getSource('area')) { // polygon draw control
        this.clearLayer();
        this.map.removeControl(this.drawPolygon);
      } else { // line draw control
        this.clearLayer();
        this.map.removeControl(this.draw);
      }
    }
  }

  clearLayer() {
    if (this.marker) {
      this.marker.remove();
    }
  }

  areCoordinatesSimilar(coords1: any, coords2: any) {
    // Round coordinates to two decimal points for comparison
    const roundedCoords1 = coords1.map((coord: any) => coord.toFixed(2));
    const roundedCoords2 = [coords2.lng.toFixed(2), coords2.lat.toFixed(2)];

    // Check if the rounded coordinates match
    return JSON.stringify(roundedCoords1) === JSON.stringify(roundedCoords2);
  }

  // SINI DRAW ALL & UPLOAD KML FOR WAYPOINT
  drawLineWaypoint() {
    this.isDrawLine = true;
    this.clearDraw();
    this.clearLayer();

    if (this.marker) {
      this.marker.remove();
      this.clearLayer();
    }

    this.map.addControl(this.draw);

    this.map.getCanvas().style.cursor = 'crosshair';
    this.map.on('draw.create', (event) => {
      if (this.isDrawLine) {
        this.clearLayer()
        const lineData = event.features;

        // var feature = event.features[0];

        this.map.addLayer({
          id: 'line-layer',
          type: 'line',
          source: {
            type: 'geojson',
            data: lineData[0],
          },
          paint: {
            'line-color': '#009688',
            'line-opacity': 0.5,
            'line-width': 2, // Adjust line width as needed
          },
        });

        const pointLength = lineData[0].geometry.coordinates

        const isAllTrue = this.validateBoundary(pointLength);

        if (isAllTrue) {
          this.updateElevationProfile(lineData);
        } else {
          console.log('Point is outside the area!');
        }

        // const points:any = []
        this.map.on('click', (e) => {
          if (this.isDrawLine) {
            const lineCoordinates = event.features[0].geometry.coordinates;
            const isSimilar = lineCoordinates.some((coords: any) => this.areCoordinatesSimilar(coords, e.lngLat));

            this.createMission = isSimilar
          }
        })
      }

    });
  }

  drawPolygonWaypoint() {
    this.isDrawLine = false;

    // Add the draw control to the map
    this.map.addControl(this.drawPolygon);

    this.map.getCanvas().style.cursor = 'crosshair';
    // Create a new polygon feature programmatically
    var polygonFeature = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [[]], // Add coordinates here if needed
      },
    };

    this.map.fire('draw.create', { features: [polygonFeature] });

    this.map.on('draw.create', (e) => {
      this.clearLayer();
      if (this.marker) {
        this.marker.remove();
        this.clearLayer();
      }
      const polygonLine = e.features;
      // var feature = e.features[0];
      this.clearLayer();

      this.map.addLayer({
        id: 'area',
        type: 'fill-extrusion',
        source: {
          type: 'geojson',
          data: polygonLine[0],
        },
        paint: {
          'fill-extrusion-color': '#009688',
          'fill-extrusion-opacity': 0.5,
          // 'fill-extrusion-height': elevation,
        },
      });

      const coord = polygonLine[0].geometry.coordinates[0]

      const isAllTrue = this.validateBoundary(coord);

      if (isAllTrue) {
        this.updateElevationProfile(polygonLine);
      } else {
        console.log('Point is outside the area!');
      }

    });
  }

  loadFile(file: File) {

  }

  validateBoundary(pointsArr: Array<any>) {

    const currentArea: any = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: this.specCoordinates.area.coordinates[0], // Add coordinates here if needed
      },
    };

    const checkResults = pointsArr.every((item: any) => turf.booleanPointInPolygon(turf.point(item), turf.polygon([currentArea.geometry.coordinates])))

    return checkResults;
  }

  // ELEVATION GRAPH HERE
  updateElevationProfile(data: any) {
    const featureProper: any = {
      type: "FeatureCollection",
      features: data

    }
    // split the line into 1m segments
    const chunks = turf.lineChunk(featureProper, 1, { units: 'meters' }).features;

    // get the elevation for the leading coordinate of each segment
    const elevations = [
      ...chunks.map((feature) => {
        return this.map.queryTerrainElevation(
          feature.geometry.coordinates[0] as [number, number]
        );
      }),
      // do not forget the last coordinate
      this.map.queryTerrainElevation(
        chunks[chunks.length - 1].geometry.coordinates[1] as [number, number]
      )
    ];
    // add dummy labels
    this.graphLabels = elevations.map(() => '');
    this.graphElevationData = elevations;

    // this.isElevationGraph = true;
  }
}
