<!DOCTYPE html>
<html>

<head>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@100;400&display=swap" rel="stylesheet">
  <script src='https://kit.fontawesome.com/a076d05399.js' crossorigin='anonymous'></script>
</head>

<body>
  <app-navbar></app-navbar>

  <div class="grid-container">
    <div class="content">

      <div class="form">
        <h1 class="title">CREATE PILOT</h1>
        <form (ngSubmit)="onSubmit()">

          <div class="input-group">
            <input class="file"
              onchange="document.getElementById('profileImage').src = window.URL.createObjectURL(this.files[0])"
              id="imageUpload" type="file" name="profile_photo" capture>
          </div>
          <div class="input-group">
            <p class="label">First Name</p>
            <input class="input" type="text" [(ngModel)]="first_name" name="first_name" placeholder="Enter first name">
          </div>
          <div class="input-group">
            <p class="label">Last Name</p>
            <input class="input" type="text" [(ngModel)]="last_name" name="profile_photo" placeholder="Enter last name">
          </div>
          <div class="input-group">
            <p class="label">Email</p>
            <input class="input" type="text" [(ngModel)]="email" name="email" placeholder="Enter email address">
          </div>
          <div class="input-group">
            <p class="label">Phone Number</p>
            <input class="input" type="text" [(ngModel)]="phone" name="phone" placeholder="Enter phone number">
          </div>
          <div class="input-group">
            <p class="label">Address</p>
            <input class="input" type="text" [(ngModel)]="address" name="address" placeholder="Enter address">
          </div>
          <div class="input-group">
            <p class="label">Date of Birth</p>
            <input class="input" type="text" [(ngModel)]="dob" name="dob" placeholder="Enter date of birth">
          </div>
          <div class="input-group">
            <p class="label">Country</p>
            <input class="input" type="text" [(ngModel)]="country" name="country" placeholder="Select country">
          </div>
          <h1 class="title">LOGIN INFORMATION</h1>
          <div class="input-group">
            <p class="label">Username</p>
            <input class="input" type="text" [(ngModel)]="username" name="username" placeholder="Enter username">
          </div>
          <div class="input-group">
            <p class="label">Password</p>
            <input class="input" type="text" [(ngModel)]="password" name="password" placeholder="Enter password">
          </div>
          <div class="input-group">
            <p class="label">Confirm Password</p>
            <input class="input" type="text" name="confirm_password" placeholder="Confirm password">
          </div>
          <h1 class="title">WORK INFORMATION</h1>
          <div class="input-group">
            <p class="label">Model of Uas</p>
            <select id="brandId" name="brandId" [(ngModel)]="model_uas" class="dropdown" required>
              <option value="1">DJI</option>
              <option value="2">VGG2454</option>
            </select>
          </div>
          <div class="input-group">
            <p class="label">Competency</p>
            <input class="input" type="text" [(ngModel)]="competency" name="competency" placeholder="Ex: Evios/Agri">
          </div>
          <div class="input-group">
            <p class="label">Flight Hours</p>
            <input class="input" type="number" [(ngModel)]="flightHours" name="flight_hours"
              placeholder="Enter flight hours">
          </div>
          <div class="input-group">
            <p class="label">Last Flown Date</p>
            <input class="input" type="date" [(ngModel)]="lastFlownDate" name="last_flown_date"
              placeholder="Select last flown date">
          </div>
          <div class="input-group">
            <p class="label">Currency</p>
            <input class="input" type="number" [(ngModel)]="currency" name="currency" placeholder="Ex: 3">
          </div>
          <div class="input-group">
            <p class="label">Training Certificates</p>
            <input class="file"
              onchange="document.getElementById('trainingCertificate').src = window.URL.createObjectURL(this.files[0])"
              id="trainingCertificate" type="file" name="trainingCertificate" capture>
          </div>
          <button class="btn">Save</button>
        </form>
      </div>
    </div>
  </div>
</body>

</html>
