import { Area } from './../shared/interface/mission.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MapdataService } from '../services/mapdata.service';
import { state } from '@app/utility';
import { ApiService } from '../api-permit.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { ViewPdfComponent } from '../modal-contents/view-pdf/view-pdf.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IMission } from '../models/mission.model';
import { IDrone } from '../models/drone.model';
import { PermitService } from '../services/permit.service';
import { RoleType } from '../enums/roleType.enum';
import { UserService } from '../services/user.service';
import { firstValueFrom } from 'rxjs';
import { IMissionSchedule } from '../models/mission.model';
import { DroneService } from '../services/drone.service';
import { PayloadService } from '../services/payload.service';
import { IUser, IUserCertificate, initializeUser } from '../models/user.model';
import AWS from 'aws-sdk';

const tokml = require('tokml');

type MissionExtend =
  | (IMission & {
    authorities: any;
    area: {
      maxAltitude: number;
      geoCoordinate: GeoCoordinateType;
    };
    centroid: {
      latitude: number;
      longitude: number;
    };
    // schedules: any[];
    scheduleIds: IMissionSchedule[];
    missionArea: any[];
    operators: string[];
    drones: IDrone[];
    attachmentIds: any;
  })
  | null;

type GeoCoordinateType = {
  latitude: string;
  longitude: string;
};

@Component({
  selector: 'app-utm-mission-summary',
  templateUrl: './mission-summary.component.html',
  styleUrls: ['./mission-summary.component.scss'],
})
export class MissionSummaryComponent implements OnInit {
  readonly RoleType = RoleType;
  mission!: MissionExtend;
  on_mission_tap = 1;
  isApproveModal = false;
  expandDetails = true;
  expandDate = true;
  expandAttachment = false;
  applicationId = 'aaaaa';
  showAllPayload = false;
  currentSerialNo = null;
  allPilots: any = [];
  remarks = '';
  role: string = '';
  id = '64e1d32cb8016281a58e7639';
  permit_id = '';
  showCoordinate = false;
  allDrones: any = [];
  allPayload: any = [];
  showToast: boolean = false;
  remarkMission: any = [];
  missionStatus: any;
  rejectMission = false;
  isRemarkModal = false;

  remarksList: any = [
    {
      authority: 'JUPEM',
      comment: 'Please adjust the mission area to this geographic coordinates: [27.2126° N, 77.5000° E]',
    },
  ];
  bufferCoords: any = []
  constructor(
    private router: Router,
    private mapDataService: MapdataService,
    private api: ApiService,
    private modalService: NgbModal,
    private permitService: PermitService,
    private userService: UserService,
    private droneSer: DroneService,
    private payloadSer: PayloadService,
  ) { }

  ngDoCheck() {
    // this.mission = this.api.getMissionPermitId().mission.missionId;
    this.permit_id = this.api.getMissionPermitId().permitId;
    this.applicationId = this.api.getMissionPermitId().applicationId;
  }

  ngOnInit() {
    this.getUserRole();
    this.mapMission();

    setTimeout(() => {
      // this.mission = this.api.getMissionPermitId().mission.missionId;
      this.mapDataService.initializeMap(
        'map-mission-summary',
        parseFloat(this.mission!.area.geoCoordinate.longitude),
        parseFloat(this.mission!.area.geoCoordinate.latitude),
        10,
      );
      setTimeout(() => {
        this.mapDataService.drawAreaBuffer(this.mission!.area.location, this.mission!.area.geoCoordinate, this.mission!.trackingId);
        // const tengokJap = bufferCoords.buffer1[0].map(item => ({
        //   latitude: item[0],
        //   longitude: item[1]
        // }))
        // this.bufferCoords = bufferCoords
      }, 2000);
    }, 1000);
  }

  getUserRole(): void {
    const role = state.getUserInfo().member[0].role.name;
    this.role = role;
  }

  openModal(item: any) {
    // this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(filename);
    const modalRef = this.modalService.open(ViewPdfComponent, {
      centered: true,
      windowClass: 'view-receipt',
      backdropClass: 'view-receipt-static',
      backdrop: 'static',
      size: 'xl',
    });

    // modalRef.componentInstance.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(filename)
    modalRef.componentInstance.pdfUrl = item ?? item.url;

    // Open the modal
    // this.modalService.open(this.pdfModal, { size: 'xl' });
  }

  checkAltitude() {
    const areaalti = this.mission?.area.maxAltitude
    if (areaalti && areaalti > 120) {
      return true;
    } else {
      return false;
    }
  }

  downloadKml(url: string) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Convert blob to Data URI using FileReader
        const reader = new FileReader();
        reader.onloadend = function () {
          // Create a temporary anchor element for the download
          const a = document.createElement('a');
          a.href = `${reader.result}`;
          a.download = 'DRONOS-PETRONAS.kml';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        };
        reader.readAsDataURL(blob);
      })
      .catch((err) => {
        console.error('Error in downloading blob', err);
      });
  }

  geoJSONtoKML(geoJSON: any) {
    // Start the KML string with the necessary headers
    let kml = `<?xml version="1.0" encoding="UTF-8"?>
      <kml xmlns="http://www.opengis.net/kml/2.2">
      <Document>`;

    // Convert each feature in the GeoJSON to KML
    geoJSON.features.forEach((feature: any) => {
      if (feature.geometry.type === 'Polygon') {
        kml += `<Placemark>
                  <name>Polygon</name>
                  <Polygon>
                      <outerBoundaryIs>
                          <LinearRing>
                              <coordinates>`;

        // Add each coordinate to the KML
        feature.geometry.coordinates[0].forEach((coordinate: any) => {
          kml += `${coordinate[0]},${coordinate[1]},0 `;
        });

        kml += `</coordinates>
                          </LinearRing>
                      </outerBoundaryIs>
                  </Polygon>
              </Placemark>`;
      }
    });

    // Close the KML string
    kml += `</Document>
      </kml>`;

    return kml;
  }

  // getCertificate(id: string | null) {
  //   var allString: string = '';
  //   if (id != null) {

  //   this.permitService.getCertificates(id).subscribe((data: any) => {

  //     allString = `https://${data.storage.files[0].s3Bucket}.s3.ap-southeast-1.amazonaws.com/${data.storage.files[0].s3Key}`;
  //   })


  //   }
  //   return allString;


  // }

  async getCertificate(id: string | null): Promise<string> {
    if (id != null) {
      try {
        const data: any = await this.permitService.getCertificates(id).toPromise();
        console.log('url', data, `https://${data.storage.files[0].s3Bucket}.s3.ap-southeast-1.amazonaws.com/${data.storage.files[0].s3Key}`)

        // const testData = data.storage.files[0];

        // const s3 = new AWS.S3();

        // const params = {
        //   Bucket: testData.s3Bucket,
        //   Key: testData.s3Key,
        //   ResponseContentDisposition: 'inline; filename="your-filename.pdf"',
        // };

        // const url = s3.getSignedUrl('getObject', params);
        
        return data.storage.files[0].preSignedUrl
        // return `https://${data.storage.files[0].s3Bucket}.s3.ap-southeast-1.amazonaws.com/${data.storage.files[0].s3Key}`;
      } catch (error) {
        console.error('Error fetching certificate:', error);
        throw error; // Optional: Rethrow the error if needed
      }
    }

    return ''; // Return an empty string when id is null
  }

  async mapMission() {
    const data = await this.api.getMissionPermitId().mission.missionId;

    const user = await this.getUser(this.api.getMissionPermitId().mission.authorities);

    this.missionStatus = this.api.getMissionPermitId().mission.permitStatus;

    this.mission = data;

    let allAttachment = [];
    const geoJSON = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              data.area.coordinate.map((point: { latitude: string; longitude: string }) => [parseFloat(point.latitude), parseFloat(point.longitude)]),
            ],
          },
          // properties: {} kalau guna tokml
        },
      ],
    };

    const kmlURL = this.geoJSONtoKML(geoJSON);

    const kml = tokml(geoJSON);

    // var kml = tokml(geoJSON)

    const blob = new Blob([kmlURL], { type: 'application/vnd.google-earth.kml+xml' });

    // Create an Object URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // data.attachmentIds.push(url);
    allAttachment.push(url);

    const all = allAttachment.map((item: string, idx: number) => ({
      filename: idx == allAttachment.length - 1 ? `${data.name}.kml` : `${item.split('/')[item.split('/').length - 1]}`,
      ext: idx == allAttachment.length - 1 ? 'kml' : item.split('.').pop(),
      url: item,
    }));

    this.mission!.authorities = user;
    this.mission!.attachmentIds = all;
    this.mission!.objective = {
      _id: data.objective._id,
      name: data.objective.name,
    };
    this.mission!.centroid = {
      latitude: data.area.geoCoordinate.latitude,
      longitude: data.area.geoCoordinate.longitude,
    };
    this.mission!.area.maxAltitude = data.area.maxAltitude;
    (this.mission!.area.geoCoordinate = data.area.geoCoordinate),
      // (this.mission!.scheduleIds = data.scheduleIds.map((schedule: any) => ({
      //   startDate: schedule.startDate,
      //   endDate: schedule.endDate,
      //   operatorAssign: schedule.operatorList,
      //   drones: schedule.droneList,
      // })));

      (this.mission!.missionArea = data.area.coordinate);

    this.getAllOperators();
    this.getAllDrones();

    this.remarkMission = this.mission!.authorities.filter((item: { approvalStatus: string }) => item.approvalStatus == 'REJECTED');
  }

  async getUser(item: any) {
    const filteredItems = item.filter((value: { approvalStatus: string }) => value.approvalStatus !== 'PENDING');
    for (const usr of filteredItems) {
      if (usr.approvalBy !== undefined && usr.approvalStatus == 'REJECTED') {
        const data = await firstValueFrom(this.userService.get(usr.approvalBy));

        usr.name = `${data!.firstName} ${data!.lastName}`;
        usr.workspace = data!.member[0].workspace.name;
      }
    }

    return filteredItems;
  }

  async getAllDrones() {
    const schedules = this.mission!.scheduleIds;
    if (!schedules || schedules.length === 0) {
      console.error('No schedule data available.');
      return;
    }

    //const apiUrl = ''; // Define your API URL here

    try {
      for (const schedule of schedules) {
        if (!schedule.droneList || schedule.droneList.length === 0) {
          console.error('No drone data available for schedule.');
          continue;
        }
        // this.allDrones = schedule.droneList;

        for (const drone of schedule.droneList) {
          const data = await firstValueFrom(this.droneSer.get(drone));
          this.allDrones.push(data);
        }
        for (const payload of schedule.payloadList) {
          const data = await firstValueFrom(this.payloadSer.get(payload));
          this.allPayload.push(data);
        }
      }
    } catch (error) {
      console.error('Error fetching drone data:', error);
    }
  }

  async getAllOperators() {
    const schedules = this.mission!.scheduleIds; // Assuming mission has been populated

    if (!schedules || schedules.length === 0) {
      console.error('No schedule data available.');
      return;
    }

    try {
      for (const schedule of schedules) {
        if (!schedule.operatorList || schedule.operatorList.length === 0) {
          console.error('No operatorAssign data available for schedule.');
          continue;
        }

        for (const item of schedule.operatorList) {
          const data = await firstValueFrom(this.userService.get(item));

          const cert = await this.getCertificate(data.userCertificate ? data.userCertificate[0].attachment[0] : null);
          data.userCertificate = data.userCertificate.map(item => ({
            ...item,
            urls: [cert],
          }))

          this.allPilots.push(data);
        }
      }
    } catch (error) {
      console.error('Error fetching operator data:', error);
    }
  }

  parseCoordinate(coordinate: any) {
    if (coordinate) {
      let latitude = coordinate.latitude;
      let longitude = coordinate.longitude;

      let latDirection = parseFloat(latitude) >= 0 ? 'N' : 'S';
      let lonDirection = parseFloat(longitude) >= 0 ? 'E' : 'W';

      latitude = Math.abs(parseFloat(latitude)).toFixed(5); // Assuming 8 decimal places for precision
      longitude = Math.abs(parseFloat(longitude)).toFixed(5);

      return `${latitude}°${latDirection} ${longitude}°${lonDirection}`;
    } else {
      return '';
    }
  }

  formatDate(date: string) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const dateObj = new Date(date);

    return `${months[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
  }

  formatTime(date: string) {
    const dateObj = new Date(date);

    let hours = dateObj.getHours();
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const amOrPm = hours >= 12 ? 'PM' : 'AM';

    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${hours}:${minutes}${amOrPm}`;
  }

  showPayload(serialNo: any) {
    (this.showAllPayload = true), (this.currentSerialNo = serialNo);
  }

  hidePayload(idx: any) {
    this.currentSerialNo = null;
    this.showAllPayload = false;
  }

  nextTab() {
    // on_mission_tap == 2 ? on_mission_tap = 3 : on_mission_tap = 2
    if (this.on_mission_tap == 2) {
      this.on_mission_tap = 3;
    } else {
      this.on_mission_tap = 2;
    }
  }

  viewUserCertificate(item: any) {
    // sini nak view user certificate
  }

  approveRejectMission(status: string) {
    const body = {
      missionId: this.mission!._id,
      approvalStatus: status,
      remarks: this.remarks,
    };

    this.permitService.missionApproval(this.permit_id, body).subscribe((data: any) => {
      this.showToast = true;
      setTimeout(() => (this.showToast = false), 5100);
      window.history.back();
    });
  }
}
