<div class="container">
    <!-- icon x -->
    <div class="row">
        <div class="col">
            <p class="txt-header">VERIFY RECEIPT</p>
        </div>
        <div class="col text-right" style="text-align: right;">
            <img (click)="closeModal()" [src]="'/icon/icon_x.png' | assetUrl" alt="warning" class="icon-close" />
        </div>
    </div>

    <hr style="color: #ffffff">

    <p class="sub">Please view the attached receipt and provide your approval.</p>

    <!-- <div class="pdf-viewer"> -->
        <!-- letak url -->
        <!-- <ngx-doc-viewer [url]="fileURL" viewer="google"
            style="width: 100%; height: 50vh;"></ngx-doc-viewer> -->
            <img [src]="fileURL" *ngIf="checkExtension() == 'png' || checkExtension() == 'jpeg' || checkExtension() == 'jpg'" style="width: 100%;">
            <iframe [src]="fileURL | safeUrl" width="100%" height="500px" frameborder="0" *ngIf="checkExtension() == 'pdf'"></iframe>
<!-- </div> -->
    



    <div class="button-container" style="text-align: right;">
        <button type="button btn-submit" class="btn btn-secondary btn-reject" (click)="approveRejectReceipt('rejected')">Reject</button>
        <button type="button btn-submit" class="btn btn-secondary btn-submit" (click)="approveRejectReceipt('confirm')">Approve</button>
    </div>

</div>