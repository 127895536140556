import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from 'src/app/services/environment.service'; 
import { IFlight } from '../models/flight';

const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl() + '/api/missionflights';
const flightPatternUrl = `${apiUrl}/api/get-flight-pattern`;
const flightFinishActionUrl = `${apiUrl}/api/get-flight-finish-action`;
const headers =  new HttpHeaders({
    Authorization: 'Bearer ' + environmentService.getToken(),
  })

@Injectable({
  providedIn: 'root'
})
export class FlightService {

  constructor(private http: HttpClient) {}

  getAll(missionId: number): Observable<IFlight> {
    const params = new HttpParams().set('missionId', missionId.toString());
    return this.http.get<IFlight>(apiUrl, { headers : headers, params: params });
  }

  get(id: number): Observable<IFlight> {
    return this.http.get<IFlight>(`${apiUrl}/${id}`, { headers : headers });
  }

  create(data: IFlight): Observable<any> {
    return this.http.post(`${apiUrl}`, data, { headers : headers });
  }

  update(id: number, data: IFlight): Observable<any> {
    return this.http.put(`${apiUrl}/${id}`, data);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${apiUrl}/${id}`, { headers : headers });
  }

  getFlightPattern(): Observable<any> {
    return this.http.get<any>(flightPatternUrl, { headers : headers });
  }

  getFlightFinishAction(): Observable<any> {
    return this.http.get<any>(flightFinishActionUrl, { headers : headers });
  }
}
